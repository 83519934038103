import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Product, ProductPayload, TokenData } from '../../../types';

import { Button, ButtonDiv } from '../../FormProgress/style';
import showToast from '../../Toast';
import { Container, Wrapper, UploadContainer, Input, TextContainer } from './style';

type Props = {
	setActiveTab: (tab: string) => void;
	product: Product;
	setProduct: (product: Product) => void;
	token: TokenData;
};

const ProducPayload = ({ setActiveTab, product, setProduct, token }: Props) => {
	const { t } = useTranslation();

	const [payload, setPayload] = useState<ProductPayload[]>([]);

	const payloadTypes = (fieldName: string) => {
		return {
			upload: (
				<UploadContainer>
					<p>{fieldName} </p>
					<Button width="300px" >
						{t<string>('createProduct.information.basics.placeholder.browse')}
						<input type="file" onChange={(event) => changeHandler(event, fieldName)} />
					</Button>
				</UploadContainer>
			),
			text: (
				<div>
					<label>{fieldName} </label>
					<Input
						onChange={(event) => changeHandler(event, fieldName)}
						data-testid="text-input"
					/>
				</div>
			),
			number: (
				<div>
					<label>{fieldName} </label>
					<Input
						type="number"
						pattern="[0-9]*"
						onChange={(event) => changeHandler(event, fieldName)}
						data-testid="number-input"
					/>
				</div>
			),
		};
	};

	const changeHandler = (
		event: ChangeEvent<HTMLInputElement>,
		fieldName: string
	) => {
		let index = payload.findIndex((item) => item.field === fieldName);
		let currentField: ProductPayload;

		if (event.target?.files) {
			const reader = new FileReader();

			reader.onload = () => {
				if (reader.readyState === 2) {
					currentField = {
						field: fieldName,
						value: reader.result as string,
					};

					setPayload((prevState) => {
						if (index === -1) {
							return [...prevState, currentField];
						} else {
							return [
								...prevState.slice(0, index),
								currentField,
								...prevState.slice(index + 1),
							];
						}
					});
				}
			};
			reader.readAsDataURL(event.target.files[0]);
		} else {
			currentField = {
				field: fieldName,
				value: event.target.value,
			};

			setPayload((prevState) => {
				if (index === -1) {
					return [...prevState, currentField];
				} else {
					return [
						...prevState.slice(0, index),
						currentField,
						...prevState.slice(index + 1),
					];
				}
			});
		}
	};

	const handleSubmit = () => {
		let hasEmptyFields = payload.some((item) => item.value === '');

		if (hasEmptyFields || payload.length !== token?.metadata?.length) {
			return showToast.warn(t<string>('toast.fillAllFields'));
		}
		setProduct({
			...product,
			payload: payload,
		});
		setActiveTab(`3. ${t<string>('createProduct.information.navigation.supply')}`);
	};

	return (
		<Wrapper>
			<TextContainer>
				Maybe a text here which explain this step? To make it more friendly, understandable, and fill out this otherwise empty step?
			</TextContainer>
			<Container>
				{token?.metadata?.map((item: any, index: number) => (
					<div key={index} style={{ display: "flex", marginBottom: "24px" }} >
						{'Upload' === item.fieldType
							? payloadTypes(item.fieldName).upload
							: 'Text' === item.fieldType
							? payloadTypes(item.fieldName).text
							: payloadTypes(item.fieldName).number}
					</div>
				))}
			</Container>
			<ButtonDiv>
				<Button onClick={() => setActiveTab('1. Basics')}>{t<string>('createProduct.information.button.back')}</Button>
				<Button type="button" onClick={handleSubmit} data-testid="submit">
					{t<string>('createProduct.information.button.next')}
				</Button>
			</ButtonDiv>
		</Wrapper>
	);
};

export default ProducPayload;
