import { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';

import {
    modalStyles,
    Container,
    InfoContainer,
    MarkPoint,
    Strong,
    Flex,
} from "./style";


type Props = {
    order: any;
    modalIsOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const OrderDetails = ({ order, modalIsOpen, setIsOpen }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={modalStyles}
            contentLabel="Order Details Modal"
        >
            <Container>
                <IoClose onClick={() => setIsOpen(false)} />
                <h1>{order?.name}</h1>
                <InfoContainer>
                    <div>
                        <InfoAttribute label={t<string>('orderMaintenance.details.orderNumber')} value={order?.orderNumber || ""} />
                        <InfoAttribute label={t<string>('orderMaintenance.details.externalOrderNumber')} value={order?.externalOrderNumber || ""} />
                        <InfoAttribute label={t<string>('orderMaintenance.details.personId')} value={order?.personId || ""} />
                        <InfoAttribute label={t<string>('orderMaintenance.details.orderLines')} value={order?.orderLines?.length || ""} />
                    </div>
                    <div>
                        <MarkPoint >{t<string>('orderMaintenance.details.products')}</MarkPoint >
                        {order?.orderLines?.map((orderLine: any) => (
                            <Flex>
                                <InfoAttribute label={t<string>('orderMaintenance.details.productId')} value={orderLine?.productId || ""} />
                                <InfoAttribute label={t<string>('orderMaintenance.details.status')} value={orderLine?.status || ""} />
                            </Flex>
                        ))}
                    </div>
                </InfoContainer>
            </Container>
        </Modal>
    );
}

type infoAttributeTypes = {
	label: string;
	value: string;
};

export const InfoAttribute = ({ label, value }: infoAttributeTypes) => (
	<div style={{ display: 'flex', marginBottom: "12px" }}>
		<Strong>{label}:</Strong>
		<span>{value}</span>
	</div>
);

Modal.setAppElement(document.getElementById('root')!);

export default OrderDetails;
