import Modal from 'react-modal';
import { IoClose } from 'react-icons/io5';

import {
    modalStyles,
    Container,
} from "./style";
import { ImageModalHandler } from '../../../types';

type Props = {
    modalIsOpen: ImageModalHandler;
    setIsOpen: (isOpen: ImageModalHandler) => void;
}

const ImageModal = ({ modalIsOpen, setIsOpen }: Props) => (
    <Modal
        isOpen={modalIsOpen.isOpen}
        onRequestClose={() => setIsOpen({ ...modalIsOpen, isOpen: false })}
        style={modalStyles}
        contentLabel="Product Details Modal"
    >
        <Container>
            <IoClose onClick={() => setIsOpen({ ...modalIsOpen, isOpen: false })} />
            <img src={modalIsOpen.link} alt="product" />
        </Container>
    </Modal>
);

Modal.setAppElement(document.getElementById('root')!);

export default ImageModal;
