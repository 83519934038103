import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-size: 0.9rem;
    scrollbar-width: 12px;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }
  
  body::-webkit-scrollbar-track {
    background: #D8DEE4;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #82A3C1;
    border-radius: 20px;
    border: none
  }
`;

export default GlobalStyle;
