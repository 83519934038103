export const setTokenLocalStorage = (token: string): void =>
	localStorage.setItem('token', token);

export const getTokenLocalStorage = (): string | undefined => {
	const jwtToken = localStorage.getItem('token');
	if (!jwtToken) {
		return undefined;
	}
	return `Bearer ${jwtToken}`;
};

export const clearLocalStorage = () => localStorage.removeItem('token');
