import styled from 'styled-components';

export const CardContainer = styled.div`
	max-width: 99%;
	border: 1px solid #e0e0e0;
	position: relative;
	box-sizing: border-box;
	padding: 12px 12px 0 12px;

	@media (max-width: 1200px) {
		max-width: 100%;
	}

	@media (max-width: 768px) {
		margin-left: 7%;
	}
`;
