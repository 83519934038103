import { IAndroidPassData, WalletTemplateDefinitions } from '@bambu-meta/interfaces';
import { useTranslation } from 'react-i18next';

import {
	H2,
	InputGroup,
	P,
	StepCounterText,
} from '../../../../components/UpdatedUI';
import { WalletTemplate } from '../../../../types';

type Props = {
	walletTemplate: WalletTemplate;
	setWalletTemplate: (template: WalletTemplate) => void;
	androidWalletTemplate: IAndroidPassData;
	setAndroidWalletTemplate: (androidWalletTemplate: IAndroidPassData) => void;
	isNotCreation?: boolean;
	isTier?: boolean;
	setTierName?: (tierName: string) => void;
	tierName?: string;
}

export const WalletInfo = ({ 
	walletTemplate, 
	setWalletTemplate, 
	androidWalletTemplate, 
	setAndroidWalletTemplate,
	isNotCreation = false,
	isTier = false,
	setTierName,
	tierName= '',
}: Props) => {
	const { t } = useTranslation();

	return (
		<div className='h-[508px] overflow-auto pr-10'>
			<div className="md:flex justify-between mb-5">
				<div className="">
					<H2 className="mb-5">{t<string>('walletCard.walletInformation.title')}</H2>
					<P className="w-8/12 sm:w-full md:w-8/12">
						{t<string>('walletCard.walletInformation.description')}
					</P>
				</div>
				<StepCounterText className="mb-5">{isNotCreation ? t<string>('walletCard.walletInformation.notCreationStep') : t<string>('walletCard.walletInformation.step')}</StepCounterText>
			</div>
			{isTier && setTierName && (
				<div className="flex mb-2 justify-between gap-4">
					<InputGroup
						className="mb-2 w-full"
						id="input_TierName"
						label={t<string>('walletCard.walletInformation.tierName')}tierName
						onChange={(e) => setTierName(e.target.value)}
						placeholder={t<string>('walletCard.walletInformation.tierName')}
						value={tierName}
					/>
				</div>
			)}
			{isTier ? (
				<div className="flex mb-2 justify-between gap-4">
					<InputGroup
						className="mb-2 w-1/2"
						id="input_WalletName"
						label={t<string>('walletCard.walletInformation.walletName')}
						onChange={(e) => {
							setWalletTemplate({
								...walletTemplate,
								name: e.target.value,
							});
							setAndroidWalletTemplate({
								...androidWalletTemplate,
								title: e.target.value,
							});
						}}
						placeholder={t<string>('walletCard.walletInformation.walletName')}
						value={walletTemplate.name}
					/>
					<InputGroup
						className="mb-2 w-1/2"
						id="input_OrgName"
						label={t<string>('walletCard.walletInformation.organizationName')}
						onChange={(e) => setWalletTemplate({
							...walletTemplate,
							organizationName: e.target.value,
						})}
						placeholder={t<string>('walletCard.walletInformation.organizationName')}
						value={walletTemplate.organizationName}
					/>
				</div>
			) : (
				<>
					<div className="flex mb-2 justify-between gap-4">
						<InputGroup
							className="mb-2 w-1/2"
							id="input_ApplePassIdentifier"
							label={t<string>('walletCard.walletInformation.applePassIdentifier')}
							onChange={(e) => setWalletTemplate({
								...walletTemplate,
								passTypeIdentifier: e.target.value,
							})}
							placeholder={t<string>('walletCard.walletInformation.applePassIdentifier')}
							value={walletTemplate.passTypeIdentifier}
						/>
						<InputGroup
							className="mb-2 w-1/2"
							id="input_WalletName"
							label={t<string>('walletCard.walletInformation.walletName')}
							onChange={(e) => {
								setWalletTemplate({
									...walletTemplate,
									name: e.target.value,
								});
								setAndroidWalletTemplate({
									...androidWalletTemplate,
									title: e.target.value,
								});
							}}
							placeholder={t<string>('walletCard.walletInformation.walletName')}
							value={walletTemplate.name}
						/>
					</div>
					<div className="flex mb-2 justify-between gap-4">
						<InputGroup
							className="mb-2 w-1/2"
							id="input_OrgName"
							label={t<string>('walletCard.walletInformation.organizationName')}
							onChange={(e) => setWalletTemplate({
								...walletTemplate,
								organizationName: e.target.value,
							})}
							placeholder={t<string>('walletCard.walletInformation.organizationName')}
							value={walletTemplate.organizationName}
						/>
						<InputGroup
							className="mb-2 w-1/2"
							id="input_TeamIdentifier"
							label={t<string>('walletCard.walletInformation.teamIdentifier')}
							onChange={(e) => {
								setWalletTemplate({
									...walletTemplate,
									teamIdentifier: e.target.value,
								});
							}}
							placeholder={t<string>('walletCard.walletInformation.teamIdentifier')}
							value={walletTemplate.teamIdentifier}
						/>
					</div>
				</>
			)}
			<div className="flex mb-2 justify-between gap-4">
				<InputGroup
					className="mb-2 w-full"
					id="input_WalletDescription"
					label={t<string>('walletCard.walletInformation.walletDescription')}
					onChange={(e) => setWalletTemplate({
						...walletTemplate,
						description: e.target.value,
					})}
					placeholder={t<string>('walletCard.walletInformation.walletDescription')}
					value={walletTemplate.description}
				/>
			</div>
			<div className="flex mb-2 justify-between gap-4">
				{(JSON.stringify(walletTemplate?.passType) === JSON.stringify(WalletTemplateDefinitions.storeCard)
					|| JSON.stringify(walletTemplate?.passType) === JSON.stringify(WalletTemplateDefinitions.generic)) && (
					<InputGroup
						className="mb-2 w-1/2"
						id="input_androidHeader"
						label={t<string>('walletCard.walletInformation.androidHeader')}
						onChange={(e) => {
							setAndroidWalletTemplate({
								...androidWalletTemplate,
								header: e.target.value,
							});
						}}
						placeholder={t<string>('walletCard.walletInformation.androidHeader')}
						value={androidWalletTemplate.header}
					/>
				)}
				{JSON.stringify(walletTemplate?.passType) === JSON.stringify(WalletTemplateDefinitions.generic) && (
					<InputGroup
						className="mb-2 w-1/2"
						id="input_androidSubHeader"
						label={t<string>('walletCard.walletInformation.androidSubHeader')}
						onChange={(e) => {
							setAndroidWalletTemplate({
								...androidWalletTemplate,
								subheader: e.target.value,
							});
						}}
						placeholder={t<string>('walletCard.walletInformation.androidSubHeader')}
						value={androidWalletTemplate.subheader || ''}
					/>
				)}
			</div>
			<input 
				className='mr-2'
				type="checkbox"
				id="checkbox-shareable"
				name="checkbox-shareable"
				checked={!walletTemplate.sharingProhibited}
				onChange={(e) => setWalletTemplate({
					...walletTemplate,
					sharingProhibited: !e.target.checked,
				})}
			/>
			<label className="font-sans mb-1" htmlFor='checkbox-shareable'>{t<string>('walletCard.walletInformation.shareable')}</label>
		</div>
	);
};
