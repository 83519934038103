import { ReactNode } from 'react';
import { P } from './Typography';

interface InnerTextProps {
	children: string;
	className?: string;
}

interface WidgetProps {
	children: ReactNode;
	className?: string;
}

export const InnerText = ({ children, className }: InnerTextProps) => {
	return (
		<P className={`text-stone-800 text-center ${className}`}>{children}</P>
	);
};

export const Widget = ({ children, className }: WidgetProps) => {
	return (
		<div
			className={`bg-blue-100 mb-4 rounded-lg flex flex-col justify-center items-center ${className}`}
		>
			{children}
		</div>
	);
};

export const Card = (props: { children: ReactNode; className?: string }) => {
	return (
		<div className={`bg-white shadow-xl rounded-lg ${props.className}`}>
			{props.children}
		</div>
	);
};
