import { BarcodeFormat, IWalletPassBarcodeDescriptor, WalletTemplateDefinitions } from "@bambu-meta/interfaces";

import { WalletTemplate } from '../../../types';
import {
    Wrapper,
    WalletCard,
    Header,
    FlipCard,
    BarCode,
    QrCode,
    FrontFields,
    NoValue,
    Input,
} from './style';

import EventTicket from './EventTicket';
import GenericPass from './GenericPass';
import StoreCardAndCoupon from './StoreCardAndCoupon';
import AppleBackCard from './AppleCardBack';
import { Droppable } from 'react-beautiful-dnd';
import { GrClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import CustomTooltip from "../../Tooltip";
import { Select } from "../../Select";

type Props = {
    walletTemplate: WalletTemplate;
    setWalletTemplate: (walletTemplate: WalletTemplate) => void;
    handleFieldRemove: (index: number, type: string) => void;
    isPassSelect?: boolean;
    isBack?: boolean;
}

const WalletPreview = (props: Props) => {
    const {
        walletTemplate,
        setWalletTemplate,
        isBack,
        isPassSelect,
        handleFieldRemove,
    } = props;

    const { t } = useTranslation();

    const barOptions = [t<string>('walletCard.description.selectType'), 'PKBarcodeFormatQR', 'PKBarcodeFormatPDF417', 'PKBarcodeFormatAztec', 'PKBarcodeFormatCode128'];

    const handleBarChange = (e: any) => {
        if (e.target.value === t<string>('walletCard.description.selectType')) {
            setWalletTemplate({ ...walletTemplate, barCodes: undefined });
            return;
        }

        const barCode: IWalletPassBarcodeDescriptor = {
            format: e.target.value as BarcodeFormat,
            altText: {
                defaultValue: '',
                mappedValue: '',
            },
            message: {
                defaultValue: '',
                mappedValue: '',
            }
        }

        setWalletTemplate({ ...walletTemplate, barCodes: [barCode] });
    }

    const handleBarInputs = (value: string, input: string) => {
        if (input === 'altTextDefault') {
            const bar = walletTemplate?.barCodes?.[0];
            if (bar) {
                bar.altText.defaultValue = value;
                setWalletTemplate({ ...walletTemplate, barCodes: [bar] });
            }
        } else if (input === 'altTextMapped') {
            const bar = walletTemplate?.barCodes?.[0];
            if (bar) {
                bar.altText.mappedValue = value;
                setWalletTemplate({ ...walletTemplate, barCodes: [bar] });
            }
        } else if (input === 'messageDefault') {
            const bar = walletTemplate?.barCodes?.[0];
            if (bar) {
                bar.message.defaultValue = value;
                setWalletTemplate({ ...walletTemplate, barCodes: [bar] });
            }
        } else if (input === 'messageMapped') {
            const bar = walletTemplate?.barCodes?.[0];
            if (bar) {
                bar.message.mappedValue = value;
                setWalletTemplate({ ...walletTemplate, barCodes: [bar] });
            }
        }
    }
    
    return (
        <Wrapper>
            {isBack ? (
                <AppleBackCard 
                    icon={walletTemplate?.images?.icon}
                    name={walletTemplate?.name}
                    backFields={walletTemplate?.backFields}
                    imgUrl={walletTemplate?.images?.background}
                    background={walletTemplate?.backgroundColor}
                    label={walletTemplate?.labelColor}
                    handleFieldRemove={handleFieldRemove}
                />
            ) : (
                <FlipCard>
                    <WalletCard 
                        imgUrl={walletTemplate?.images?.background} 
                        background={walletTemplate?.backgroundColor}
                        label={walletTemplate?.labelColor}
                    >
                        <FrontFields>
                            <Header>
                                {walletTemplate?.images?.logo ? <img src={walletTemplate?.images?.logo} alt="logo" /> : <div style={{ 
                                    backgroundColor: "rgba(101, 131, 161, 0.1)", 
                                    width: "40px",
                                    height: "40px",
                                    margin: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>Logo</div>}
                                <Droppable droppableId="headerField">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{ backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`, }}
                                        >
                                            {walletTemplate?.headerFields?.length ? (
                                                <div>
                                                    <span>{walletTemplate?.headerFields[0]?.label}</span>
                                                    {walletTemplate?.headerFields[0]?.defaultValue || walletTemplate?.headerFields[0]?.mappedValue
                                                        ?   (
                                                            <p>{walletTemplate?.headerFields[0]?.defaultValue as string || walletTemplate?.headerFields[0]?.mappedValue as string}</p>
                                                        ) : (
                                                            <NoValue />
                                                        )
                                                    }
                                                    <GrClose
                                                        style={{ position: "absolute", top: "2px", right: "100px", cursor: "pointer" }}
                                                        onClick={() => handleFieldRemove(0, "headerField")}
                                                    />
                                                </div>
                                            ) : (
                                                <div style={{
                                                    backgroundColor: "rgba(101, 131, 161, 0.1)",
                                                    width: "80px",
                                                    height: "40px",
                                                    margin: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}>
                                                    Header
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Droppable>
                            </Header>
                            {walletTemplate?.passType === WalletTemplateDefinitions.coupon && (
                                <div>
                                    <StoreCardAndCoupon
                                        secondaryFields={walletTemplate?.secondaryFields}
                                        auxiliaryFields={walletTemplate?.auxiliaryFields}
                                        strip={walletTemplate?.images?.strip}
                                        handleFieldRemove={handleFieldRemove}
                                    />
                                </div>
                            )}
                            {walletTemplate?.passType === WalletTemplateDefinitions.eventTicket && (
                                <div>
                                    <EventTicket 
                                        primaryFields={walletTemplate?.primaryFields}
                                        secondaryFields={walletTemplate?.secondaryFields}
                                        auxiliaryFields={walletTemplate?.auxiliaryFields}
                                        strip={walletTemplate?.images?.strip}
                                        thumbnail={walletTemplate?.images?.thumbnail}
                                        handleFieldRemove={handleFieldRemove}
                                    />
                                </div>
                            )}
                            {walletTemplate?.passType === WalletTemplateDefinitions.generic && (
                                <div>
                                    <GenericPass
                                        primaryFields={walletTemplate?.primaryFields}
                                        secondaryFields={walletTemplate?.secondaryFields}
                                        auxiliaryFields={walletTemplate?.auxiliaryFields}
                                        thumbnail={walletTemplate?.images?.thumbnail}
                                        handleFieldRemove={handleFieldRemove}
                                    />
                                </div>
                            )}
                            {walletTemplate?.passType === WalletTemplateDefinitions.storeCard && (
                                <div>
                                    <StoreCardAndCoupon
                                        secondaryFields={walletTemplate?.secondaryFields}
                                        auxiliaryFields={walletTemplate?.auxiliaryFields}
                                        strip={walletTemplate?.images?.strip}
                                        handleFieldRemove={handleFieldRemove}
                                    />
                                </div>
                            )}
                            {!isPassSelect && (
                                <>
                                    <div style={{ margin: "4px 12px" }}>
                                        <label>{t<string>('walletCard.description.barCode')}</label>
                                        <CustomTooltip text="" />
                                        <Select
                                            options={barOptions}
                                            noOptionsLabel={t<string>('walletCard.description.barCode')}
                                            onChange={(e) => handleBarChange(e)}
                                            width="300px"
                                            selectedOption={barOptions.find((option) => option === walletTemplate?.barCodes?.[0]?.format)}
                                        />
                                    </div>
                                    {walletTemplate?.barCodes && (
                                        <>
                                            <div style={{ margin: "8px 12px" }}>
                                                <label>{t<string>('walletCard.description.altText')}</label>
                                                <CustomTooltip text="" />
                                                <div />
                                                <Input
                                                    name="alttextDefault"
                                                    placeholder={t<string>('walletCard.description.altDefault')}
                                                    type="text"
                                                    onChange={(e) => handleBarInputs(e.target.value, 'altTextDefault')}
                                                    value={walletTemplate?.barCodes?.[0]?.altText?.defaultValue}
                                                    style={{ display: "inline-block" }}
                                                />
                                                <Input
                                                    name="alttextMapped"
                                                    placeholder={t<string>('walletCard.description.altMapped')}
                                                    type="text"
                                                    onChange={(e) => handleBarInputs(e.target.value, 'altTextMapped')}
                                                    value={walletTemplate?.barCodes?.[0]?.altText?.mappedValue}
                                                    style={{ display: "inline-block", marginLeft: "8px" }}
                                                />
                                            </div>
                                            <div style={{ margin: "8px 12px" }}>
                                                <label>{t<string>('walletCard.description.message')}</label>
                                                <CustomTooltip text="" />
                                                <div />
                                                <Input
                                                    name="messageDefault"
                                                    placeholder={t<string>('walletCard.description.messageDefault')}
                                                    type="text"
                                                    onChange={(e) => handleBarInputs(e.target.value, 'messageDefault')}
                                                    value={walletTemplate?.barCodes?.[0]?.message?.defaultValue}
                                                    style={{ display: "inline-block" }}
                                                />
                                                <Input
                                                    name="messageMapped"
                                                    placeholder={t<string>('walletCard.description.messageMapped')}
                                                    type="text"
                                                    onChange={(e) => handleBarInputs(e.target.value, 'messageMapped')}
                                                    value={walletTemplate?.barCodes?.[0]?.message?.mappedValue}
                                                    style={{ display: "inline-block", marginLeft: "8px" }}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {walletTemplate?.barCodes?.[0]?.format === "PKBarcodeFormatQR" || walletTemplate?.barCodes?.[0]?.format === "PKBarcodeFormatAztec" ? (
                                <QrCode>
                                    {t<string>('walletCard.description.qrCode')}
                                </QrCode>
                            ) : (
                                <BarCode>
                                    {t<string>('walletCard.description.barCode')}
                                </BarCode>
                            )}
                        </FrontFields>
                    </WalletCard>
                </FlipCard>
            )}
        </Wrapper>
    )
}

export default WalletPreview;
