import Modal from "react-modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { modalStyles, ModalContainer } from "./style";
import showToast from '../../Toast';
import Button from "../../Button";
import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";

type Props = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setFields: (fields: IWalletPassFieldMappings[]) => void;
    fields: IWalletPassFieldMappings[];
    index: number;
}

const EditFieldModal = ({ isOpen, setIsOpen, setFields, fields, index }: Props) => {
    const [mappedValue, setMappedValue] = useState<string>("");
    const [changeMessage, setChangeMessage] = useState<string>("");

    const { t } = useTranslation();

    const handleButton = () => {
        if (mappedValue === "" && changeMessage === "") {
            showToast.info(t<string>("walletCard.customization.toastAnyChanges"));
            return;
        }

        const newFields = fields.map((field, i) => {
            if (i === index) {
                return {
                    ...field,
                    mappedValue,
                    changeMessage,
                }
            }

            return field;
        });

        setFields(newFields);
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            style={modalStyles}
            contentLabel="Custom Field Modal"
        >
            <ModalContainer>
                <div>
                    <label>{t<string>("walletCard.fields.mappedValue")}</label>
                    <input type="text" onChange={(e) => setMappedValue(e.target.value)} />
                </div>
                <div>
                    <label>{t<string>("walletCard.fields.changeMessage")}</label>
                    <input type="text" onChange={(e) => setChangeMessage(e.target.value)} />
                </div>
                <Button
                    onClick={() => setIsOpen(false)}
                    width="688px"
                    color="#82A3C1"
                    backgroundColor="#F2F2F2"
                >{t<string>("walletCard.customization.cancelChanges")}</Button>
                <Button
                    onClick={handleButton}
                    width="688px"
                >{t<string>("walletCard.customization.saveChanges")}</Button>
            </ModalContainer>
        </Modal>
    );
}

export default EditFieldModal;
