import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { UploadContainer, ImageContainer, PickerContainer, DateHeader, ExpDateHeader } from "./style";
import Button from "../../Button";
import { IAndroidPassData } from "@bambu-meta/interfaces";
import DateRangeModal from "./DateRangeModal";
import { add } from "date-fns";

type Props = {
  androidWalletTemplate: IAndroidPassData;
  setAndroidWalletTemplate: (androidWalletTemplate: IAndroidPassData) => void;
}

const date = new Date().toISOString();
const threeDaysAfter = add(new Date(), { days: 3 }).toISOString();

const ImageAndColor = ({ androidWalletTemplate, setAndroidWalletTemplate }: Props) => {
  const [image, setImages] = useState<any>();
  const [dateRange, setDateRange] = useState([date, threeDaysAfter]);
	const [appliedDates, setAppliedDates] = useState([date, threeDaysAfter]);
	const [dateIsOpen, setDateIsOpen] = useState(false);

  const { t } = useTranslation();

  let start = new Date(dateRange[0]);
	let end = new Date(dateRange[1]);

  const selectionRange = {
		startDate: start,
		endDate: end,
		key: 'selection'
	};

  const formatDate = (date: string) => {
    const onlyDate = date.split('T')[0];
    const dateArray = onlyDate.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`.replaceAll("-", "/");
  }

  const handleSelect = (ranges: any) => {
    let startDate = new Date(ranges.selection.startDate).toISOString();
		let endDate = new Date(ranges.selection.endDate).toISOString();
		
    setDateRange([startDate, endDate]);
	}

	const handleApply = () => {
		setAppliedDates(dateRange);
		setDateIsOpen(false);
    androidWalletTemplate.validTimeInterval = {
      start: {
        date: appliedDates[0]
      },
      end: {
        date: appliedDates[1]
      }
    }
    setAndroidWalletTemplate({
      ...androidWalletTemplate,
      validTimeInterval: androidWalletTemplate.validTimeInterval
    })
	}
  
  const changeHandler = (event: ChangeEvent<HTMLInputElement>, image: string) => {
    if (event.target.files) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages(reader.result);
  
          androidWalletTemplate.images = {
            logo: reader.result as string,
          }
  
          setAndroidWalletTemplate({
              ...androidWalletTemplate,
              images: androidWalletTemplate.images,
          });
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleBackgroundColorChange = (color: any) => {
      setAndroidWalletTemplate({
          ...androidWalletTemplate,
          hexBackgroundColor: color,
      });
  }

  return (
      <ImageContainer>
          <h1>{t<string>("walletCard.customization.imgTitle")}</h1>
          <UploadContainer>
              <p>{t<string>('walletCard.customization.icon')}</p>
              <Button
                  width='300px'
              >
                  {(image || androidWalletTemplate.images?.logo)
                      ? t<string>('createProduct.information.basics.placeholder.uploaded') 
                      : t<string>('createProduct.information.basics.placeholder.browse')
                  }
                  <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => changeHandler(e, image)} />
              </Button>
          </UploadContainer>
          
          <PickerContainer>
              <p>{t<string>('walletCard.description.backgroundColor')}</p>
              <div>
                  <input type="color" value={androidWalletTemplate?.hexBackgroundColor} onChange={(e) => handleBackgroundColorChange(e.target.value)} />
              </div>
          </PickerContainer>
          <h1>{t<string>("walletCard.customization.expTitle")}</h1>
          <ExpDateHeader>
            <div>
						  <span>{`${formatDate(appliedDates[0])} - ${formatDate(appliedDates[1])}`}</span>
						  <p>{t<string>('home.dateRange')}</p>
            </div>
          </ExpDateHeader>
          <DateHeader>
						<Button width="260px" fontSize='1.25rem' onClick={() => setDateIsOpen(true)}>{t<string>('home.filterDate')}</Button>
					</DateHeader>
          <DateRangeModal
            isModalOpen={dateIsOpen}
            setIsModalOpen={setDateIsOpen}
            selectionRange={selectionRange}
            handleSelect={handleSelect}
            handleApply={handleApply}
          />
      </ImageContainer>
  );
}

export default ImageAndColor;
