import { TokenData, TokenType } from '../types';
import api from './api';

const getTokens = (tenantId: string) => api.get(`/${tenantId}/tokens`);

const postToken = (tenantId: string, tokenData: TokenData) =>
	api.post(`/${tenantId}/tokens`, tokenData);

const deleteToken = (selectedToken: TokenType) =>
	api.delete(`/${selectedToken.tenantId}/tokens/${selectedToken.tokenId}`);

const patchToken = (selectedToken: TokenType | TokenData) =>
	api.patch(
		`/${selectedToken.tenantId}/tokens/${selectedToken.tokenId}`,
		selectedToken
	);

const getTokenTypes = (tenantId: string) => api.get(`/${tenantId}/token-types`);

export default {
	getTokens,
	postToken,
	patchToken,
	deleteToken,
	getTokenTypes,
};
