/* eslint-disable no-lone-blocks */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import CreateEventModal from '../CreateEventModal';
import DeleteEventModal from '../DeleteEventModal';
import { Tr, Td } from './style';

type Props = {
    actions: any[];
    tabToOpen: number;
    index: number;
    event: any
}

const EventDropdown = ({ actions, tabToOpen, index, event }: Props) => {
    const [createModalIsOpen, createSetIsOpen] = useState(false);
    const [deleteModalIsOpen, deleteSetIsOpen] = useState(false);

    const { t } = useTranslation();

    if (tabToOpen !== index) return(<></>);

    return (
        <>
            {actions?.map((action: any) => {
                {
                    action.tenantActionTrigger?.actionTypesToTrigger
                    && !Array.isArray(action.tenantActionTrigger?.actionTypesToTrigger)
                    && (action.tenantActionTrigger.actionTypesToTrigger = [action.tenantActionTrigger.actionTypesToTrigger])
                }

                return action.tenantActionTrigger?.actionTypesToTrigger?.map((actionToTrigger: any) => (
                    <Tr key={`${event.name}_${actionToTrigger.actionType}`}>
                        <Td>{actionToTrigger.action?.description}</Td>
                        <Td>
                            <p>{`${actionToTrigger.endpoint || t<string>('event.noEndpoint')}`} </p>
                        </Td>
                        <Td className="flex">
                            <AiFillEdit onClick={() => createSetIsOpen(true)} />
                            <AiFillDelete onClick={() => deleteSetIsOpen(true)} />
                        </Td>
                        <DeleteEventModal 
                            modalIsOpen={deleteModalIsOpen} 
                            setIsOpen={deleteSetIsOpen} 
                            event={event} 
                            actionId={event.name} 
                        />
                    </Tr>
                ))
                }
            )}
            <CreateEventModal modalIsOpen={createModalIsOpen} setIsOpen={createSetIsOpen} isEdit={true} editEvent={event} />
        </>
    );
}

export default EventDropdown;
