import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import Button, { FacebookButton, GoogleButton } from '../../components/Button';
import { AppRoutesEnum } from '../../constants';
import AuthContext from '../../context/AuthContext';
import cognitoService, { userTypes } from '../../services/cognitoService';
import { RegisterInput } from '../SignUp';

import { MainDiv } from '../SignUp/styles';
import {
	Description,
	PaddingDiv,
	Title,
	Form,
	RedirectLinkDiv,
	InputsDiv,
	WelcomeSpan,
	BannerImg,
	BannerDiv,
} from './styles';
import { SignInErrorMessages, testIds } from './constants';

const blkLogo = require('../../assets/logos/blk-logo.png');

function SignInPage() {
	return (
		<MainDiv>
			<WelcomeToBambuBanner />
			<Login />
			<div className="login-footer"></div>
		</MainDiv>
	);
}

const schema: any = yup.object({
	email: yup
		.string()
		.email(SignInErrorMessages.INVALID_EMAIL)
		.required(SignInErrorMessages.REQUIRED_EMAIL),
	password: yup
		.string()
		.required(SignInErrorMessages.REQUIRED_PASSWORD)
		.min(6, SignInErrorMessages.PASSWORD_SMALL),
});

export function Login() {
	const navigate = useNavigate();
	const { setUser } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const { t } = useTranslation();

	function handleLogin(credentials: any) {
		setLoading(true);
		cognitoService
			.signIn(credentials)
			.then((userAttributes: userTypes) => {
				navigate(AppRoutesEnum.HOME);
				setUser(userAttributes);
			})
			.catch((e: any) => setError(e?.message))
			.finally(() => {
				setLoading(false);
			});
	}

	// function handleGoogleLogin() {
	// 	return cognitoService.googleLogin().then(() => {
	// 		cognitoService
	// 			.getCurrentAuthenticatedUser()
	// 			.then((userData: userTypes) => {
	// 				setUser(userData);
	// 			});
	// 	});
	// }

	return (
		<PaddingDiv>
			<div className="row">
				<div className="col-md-12 login">
					<Title>{t<string>('signIn.title')}</Title>
					<Description>
						{t<string>('signIn.description')}
					</Description>
					<Form onSubmit={handleSubmit(handleLogin)}>
						<InputsDiv>
							<RegisterInput
								register={register}
								name="email"
								type="text"
								placeholder={t<string>('signIn.placeholder.email')}
								errors={errors}
							/>

							<RegisterInput
								type="password"
								placeholder={t<string>('signIn.placeholder.password')}
								register={register}
								name="password"
								errors={errors}
							/>
						</InputsDiv>
						<Button loading={loading} width="400px">{t<string>('signIn.button.login')}</Button>
					</Form>
					<span style={{ color: 'red' }} data-testid={testIds.LOGIN_ERROR}>
						{error}
					</span>
					{/* <hr style={{ color: '#f0f0f0' }} />

					<FacebookButton onClick={() => console.log('Login facbook')} width="400px" />
					<GoogleButton onClick={handleGoogleLogin} width="400px" /> */}

					<RedirectLinkDiv>
						<a onClick={() => navigate(AppRoutesEnum.SIGN_UP)}>
							{t<string>('signIn.links.signUp')}
						</a>
						<a onClick={() => navigate(AppRoutesEnum.FORGOT_PASSWORD)}>
							{t<string>('signIn.links.forgotPassword')}
						</a>
						<a onClick={() => navigate(AppRoutesEnum.CONFIRM_ACCOUNT)}>
							{t<string>('signIn.links.code')}
						</a>
					</RedirectLinkDiv>
				</div>
			</div>
		</PaddingDiv>
	);
}

export function WelcomeToBambuBanner() {
	const { t } = useTranslation();

	return (
		<BannerDiv>
			<WelcomeSpan>{t<string>('signIn.welcome')}</WelcomeSpan>
			<BannerImg src={blkLogo} />
		</BannerDiv>
	);
}

export default SignInPage;
