/* eslint-disable import/no-anonymous-default-export */
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import walletService from '../../services/walletService';

export default (isDeleteModalOpen?: boolean) => {
	const { user } = useContext(AuthContext);
	return useQuery(['walletTemplates', user?.tenantId, isDeleteModalOpen], async () => {
		const response = await walletService.getWalletTemplates(user?.tenantId);
		return response.data;
	});
};
