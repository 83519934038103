import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

import { PrimaryButton } from './Button';
import AuthContext from '../../context/AuthContext';
import cognitoService from '../../services/cognitoService';
import { AppRoutesEnum } from '../../constants';
const Logo = require('../../assets/logos/BambuMeta-logo-white.png');

export const Nav = () => {
	const { user } = useContext(AuthContext);

	const navigate = useNavigate();

	const handleSignOut = () => {
		cognitoService.signOut().then(() => navigate(AppRoutesEnum.SIGN_IN));
	};

	return (
		<div className="px-10 py-2 bg-bluejay-700 flex justify-between items-center">
			<img className="max-h-[20px]"  src={Logo} alt="BambuMeta Text Logo" />
			<div className="flex justify-end items-center">
				<p className="text-white font-bold font-sans text-lg mr-10 ">
					{user?.displayName}
				</p>
				<PrimaryButton onClick={handleSignOut}>
					Log Out
					<ArrowRightIcon className="w-4 stroke-2" />
				</PrimaryButton>
			</div>
		</div>
	);
};
