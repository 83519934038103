import { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';

import { ProductResponse, ImageModalHandler } from "../../../types";
import {
    modalStyles,
    Container,
    ImageContainer,
    InfoContainer,
    MarkPoint,
    AttributesContainer,
    ImageAttribute,
    Position,
    TextAttribute,
} from "./style";

import { InfoAttribute } from "../ProductCard"
import ImageModal from "../ImageModal"

type Props = {
    product: ProductResponse | null;
    modalIsOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const ProductDetails = ({ product, modalIsOpen, setIsOpen }: Props) => {
    const { t } = useTranslation();

    const [imageModalIsOpen, setImageModalIsOpen] = useState<ImageModalHandler>({
        isOpen: false,
        link: "",
    });

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={modalStyles}
            contentLabel="Product Details Modal"
        >
            <Container>
                <IoClose onClick={() => setIsOpen(false)} />
                <ImageContainer onClick={() => setImageModalIsOpen({ isOpen: true, link: product?.image})}>
                    <h1>{product?.name}</h1>
                    {typeof product?.image === 'string'  && <img src={product.image} alt="product" />}
                </ImageContainer>
                <InfoContainer>
                    <InfoAttribute label={t<string>('productList.details.tokenName')} value={product?.token?.name || ""} />
                    <InfoAttribute label={t<string>('productList.details.chain')} value={product?.token?.ledgerType || ""} />
                    <InfoAttribute label={t<string>('productList.details.chainId')} value={product?.token?.externalTokenId || ""} />
                    <InfoAttribute label={t<string>('productList.details.fungible')} value={(!product?.token?.isNFT)?.toString() || ""} />
                    <MarkPoint >{t<string>('productList.details.other')}</MarkPoint >
                    <InfoAttribute 
                        label={t<string>('productList.details.wipe')} 
                        value={product?.token?.wipe?.toString() || t<string>('productList.details.false')} 
                    />
                    <InfoAttribute 
                        label={t<string>('productList.details.kyc')} 
                        value={product?.token?.kyc?.toString() || t<string>('productList.details.false')} 
                    />
                    <InfoAttribute 
                        label={t<string>('productList.details.freezable')} 
                        value={product?.token?.freeze?.toString() || t<string>('productList.details.false')} 
                    />
                    <InfoAttribute 
                        label={t<string>('productList.details.unlimitedSupply')} 
                        value={product?.token?.supply?.toString() || t<string>('productList.details.false')} 
                    />

                </InfoContainer>
            </Container>
            {product && product?.payload?.length > 0 && (
                <Position>
                    <MarkPoint >{t<string>('productList.details.customAttributes')}</MarkPoint >
                    <AttributesContainer>
                        {product?.payload.map((attribute, index) => {
                            if (typeof attribute?.value === 'string' && attribute?.value?.split(".")[0] === "https://gateway") {
                                return (
                                    <ImageAttribute key={index}>
                                        <h3>{attribute?.field}</h3>
                                        {
                                            typeof attribute.value == 'string' 
                                            && <img 
                                            src={attribute.value}
                                            alt={attribute.field}
                                            onClick={() => setImageModalIsOpen({ isOpen: true, link: attribute?.value})}
                                            />
                                        }
                                        
                                    </ImageAttribute>
                                )
                            }

                            return (
                                <TextAttribute key={index}>
                                    <h3>{attribute?.field}</h3>
                                    <p>{typeof attribute?.value === 'string' && attribute?.value}</p>
                                </TextAttribute>
                            )
                        })}
                    </AttributesContainer>  
                </Position>
            )}
            <ImageModal modalIsOpen={imageModalIsOpen} setIsOpen={setImageModalIsOpen} />
        </Modal>
    );
}

Modal.setAppElement(document.getElementById('root')!);

export default ProductDetails;
