import { useTranslation } from "react-i18next";

import { WalletTemplate } from "../../../types";
import { Container, Input, Wrapper } from "./style";
import CustomTooltip from "../../Tooltip";

type Props = {
    walletTemplate: WalletTemplate;
    setWalletTemplate: (template: WalletTemplate) => void;
}

const Description = ({ walletTemplate, setWalletTemplate }: Props) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container>
                <div>
                    <label>{t<string>('walletCard.description.name')}</label>
                    <CustomTooltip text="" />
                    <Input
                        name="name"
                        placeholder={t<string>('walletCard.description.name')}
                        type="text"
                        onChange={(e) => setWalletTemplate({ ...walletTemplate, name: e.target.value})}
                        value={walletTemplate?.name}
                    />
                </div>
                <div>
                    <label>{t<string>('walletCard.description.passTypeIdentifier')}</label>
                    <CustomTooltip text="" />
                    <Input
                        name="passTypeIdentifier"
                        placeholder={t<string>('walletCard.description.passTypeIdentifier')}
                        type="text"
                        onChange={(e) => setWalletTemplate({ ...walletTemplate, passTypeIdentifier: e.target.value})}
                        value={walletTemplate?.passTypeIdentifier}
                    />
                </div>
                <div>
                    <label>{t<string>('walletCard.description.teamIdentifier')}</label>
                    <CustomTooltip text="" />
                    <Input
                        name="teamIdentifier"
                        placeholder={t<string>('walletCard.description.teamIdentifier')}
                        type="text"
                        onChange={(e) => setWalletTemplate({ ...walletTemplate, teamIdentifier: e.target.value})}
                        value={walletTemplate?.teamIdentifier}
                    />
                </div>
                <div>
                    <label>{t<string>('walletCard.description.organizationName')}</label>
                    <CustomTooltip text="" />
                    <Input
                        name="organizationName"
                        placeholder={t<string>('walletCard.description.organizationName')}
                        type="text"
                        onChange={(e) => setWalletTemplate({ ...walletTemplate, organizationName: e.target.value})}
                        value={walletTemplate?.organizationName}
                    />
                </div>
                <div>
                    <label>{t<string>('walletCard.description.description')}</label>
                    <CustomTooltip text="" />
                    <Input
                        name="description"
                        placeholder={t<string>('walletCard.description.description')}
                        type="text"
                        onChange={(e) => setWalletTemplate({ ...walletTemplate, description: e.target.value})}
                        value={walletTemplate?.description}
                    />
                </div>
            </Container>
        </Wrapper>
    );
}

export default Description;