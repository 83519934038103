import styled from 'styled-components';

export const SideBarContainer = styled.div`
	background: #C7D8DE;
	width: 200px;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	padding: 0 16px;
	box-sizing: border-box;
	z-index: 10000;

	&>div {
		margin: 8px;
		display: flex;
		justify-content: center;
	}

	@media (max-width: 1200px) {
		display: none;
	}
`;
