import styled from 'styled-components';

export const Wrapper = styled.div`
	background: #F4F6F8;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	label {
		font-size: 1.25rem;
		margin: 6px 24px 0 0;
	}
`;

export const Container = styled.div`
	width: 100%;
	height: 300px;
	margin: 20px 0 4px 20%;

	.file-input {
		border: none;
	}

	@media (max-width: 768px) {
		input {
			width: 90%;
		}

		div {
			width: 97%;
		}
	}
`;

export const UploadContainer = styled.div`
	width: 60%;

	p {
		width: 200px;
		font-size: 1.25rem;
		margin: 6px 24px 0 0;
		display: inline-block;
	}

	button {
		position: relative;
	}

	input {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		top: 0;
		left: 0;
		cursor: pointer;
	}
`;

export const Input = styled.input`
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	width: 524px;
	height: 32px;
	font-size: 1.25rem;
	border: none;
	padding: 0 24px;
	display: block;
	box-sizing: border-box;
	color: #647E93;
	margin-top: 8px;

	&:placeholder {
		color: #647E93;
	}

	&:focus {
		outline: none;
	}
`;

export const TextContainer = styled.div`
	font-size: 1.25rem;
	width: 25%;
	margin: 24px 0 24px 20%;
`;
