import api from './api';

const getNFTsReport = (tenantId: string) => api.get(`/reports/${tenantId}/pending-tokens`);

const getTokensReport = (tenantId: string) => api.get(`/reports/${tenantId}/tokens`);

const getProductsReport = (tenantId: string) => api.get(`/reports/${tenantId}/products`);

const getOrdersReport = (tenantId: string) => api.get(`/reports/${tenantId}/orders`);

const getWalletReport = (tenantId: string) => api.get(`/reports/${tenantId}/wallets`);

const getPeopleReport = (tenantId: string) => api.get(`/reports/${tenantId}/people`);

const getEvents = (tenantId: string) => api.get(`/reports/${tenantId}/events`);

const getBalance = (tenantId: string) => api.get(`/tenants/${tenantId}/balance`);

const getReports = (tenantId: string, startDate?: number, endDate?: number) => 
    api.get(`/reports/${tenantId}?start_date=${startDate}&end_date=${endDate}`);

export default {
    getNFTsReport,
    getTokensReport,
    getProductsReport,
    getOrdersReport,
    getWalletReport,
    getPeopleReport,
    getEvents,
    getBalance,
    getReports,
};
