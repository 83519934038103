import styled from 'styled-components';

export const Container = styled.div`
	background: #F4F6F8;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	width: 100%;
	height: 60vh;
	margin: 0 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;

	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		margin-left: 1rem;
	}

	@media (max-width: 896px) {
		height: 70vh;
	}

	@media (max-width: 768px) {
		width: 96%;
		margin: 1rem 0;
	}
`;
