import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { WelcomeToBambuBanner } from '../SignIn';
import {
	CenterButtonDiv,
	ErrorText,
	MainDiv,
	Title,
	BackButton,
} from '../SignUp/styles';
import { PaddingDiv } from './styles';
import { RegisterInput } from '../SignUp';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cognitoService from '../../services/cognitoService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutesEnum } from '../../constants';
import { IoIosArrowRoundBack } from 'react-icons/io';

function ConfirmCode() {
	let [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const schema: any = yup.object({
		email: yup.string().email(t<string>('forgotPassword.errors.emailInvalid')).required(t<string>('forgotPassword.errors.email')),
		code: yup
			.string()
			.required(t<string>('forgotPassword.errors.code'))
			.min(6, t<string>('forgotPassword.errors.codeLength')),
	});

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const email = searchParams.get('email') || '';
	const {
		register,
		handleSubmit,

		formState: { errors },
	} = useForm({
		defaultValues: { email },
		resolver: yupResolver(schema),
	});

	function onSubmit(data: any) {
		setLoading(true);
		cognitoService
			.confirmSignUp(data.email, data.code)
			.then((_x: any) => {
				alert(t<string>('confirmAccount.alert.success'));
				navigate(AppRoutesEnum.SIGN_IN);
			})
			.catch((e: any) => setErrorMessage(e?.message))
			.finally(() => setLoading(false));
	}
	
	return (
		<MainDiv>
			<WelcomeToBambuBanner />
			<PaddingDiv>
				<BackButton>
					<IoIosArrowRoundBack
						onClick={() => navigate(AppRoutesEnum.SIGN_IN)}
					/>
				</BackButton>
				<Title>{t<string>('confirmAccount.title')}</Title>
				{email && <h3>{t<string>('confirmAccount.description')}</h3>}
				<form onSubmit={handleSubmit(onSubmit)}>
					<RegisterInput
						name="email"
						placeholder={t<string>('confirmAccount.placeholder.email')}
						register={register}
						errors={errors}
					/>
					<RegisterInput
						name="code"
						placeholder={t<string>('confirmAccount.placeholder.code')}
						register={register}
						errors={errors}
					/>
					<CenterButtonDiv>
						<Button loading={loading} width="240px">{t<string>('confirmAccount.button.confirm')}</Button>
					</CenterButtonDiv>
				</form>
				{errorMessage && <ErrorText>{errorMessage}</ErrorText>}
			</PaddingDiv>
		</MainDiv>
	);
}

export default ConfirmCode;
