import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { IAndroidPassData, WalletTemplateDefinitions } from "@bambu-meta/interfaces";

import { WalletTemplate } from "../../../types";
import { modalStyles, Container, Previews } from "./style";
import CustomTooltip from '../../Tooltip';
import { Select } from '../../Select';
import WalletPreview from '../Preview';
import Button from '../../Button';
import AndroidWalletPrevirew from '../GoogleWallet/AndroidWalletPreview';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  walletTemplate: WalletTemplate;
  setWalletTemplate: (template: WalletTemplate) => void;
  androidWalletTemplate: IAndroidPassData;
  setAndroidWalletTemplate: (template: IAndroidPassData) => void;
  handleFieldRemove: (index: number, type: string) => void;
}

const PassSelectModal = ({ 
  isOpen,
  setIsOpen,
  walletTemplate,
  setWalletTemplate,
  androidWalletTemplate,
  setAndroidWalletTemplate,
  handleFieldRemove
}: Props) => {
  const { t } = useTranslation();

  const typeOptions = Object.keys(WalletTemplateDefinitions);
  const selectOptions = typeOptions.map((option) => t<string>(`walletCard.description.${option}`) !== "Boarding Pass"
    ? t<string>(`walletCard.description.${option}`) : null).filter((option) => option !== null );

  const handleChange = (value: string) => {
    const valueWithNoSpaces = value.replace(" ", '');
    const key = valueWithNoSpaces.charAt(0).toLowerCase() + valueWithNoSpaces.slice(1);
    const passType = WalletTemplateDefinitions[key as keyof typeof WalletTemplateDefinitions];
    setWalletTemplate({
      ...walletTemplate,
      passType,
      images: {
        icon: '',
        logo: '',
        thumbnail: '',
        background: '',
        strip: '',
        footer: '',
      },
      primaryFields: [],
      secondaryFields: [],
      auxiliaryFields: [],
      backFields: [],
      headerFields: [],
    });
  }

  return (
    <Modal
			isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
			style={modalStyles}
			contentLabel="Pass Select Modal"
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={false}
		>
      <Container>
        <h1>{t<string>('walletCard.passSelect')}</h1>
        <CustomTooltip text="" />
        <Previews>
          <WalletPreview 
            walletTemplate={walletTemplate}
            setWalletTemplate={setWalletTemplate}
            handleFieldRemove={handleFieldRemove}
            isPassSelect={true}
          />
          <AndroidWalletPrevirew
            walletTemplate={walletTemplate}
            androidWalletTemplate={androidWalletTemplate}
            setAndroidWalletTemplate={setAndroidWalletTemplate}
            handleFieldRemove={handleFieldRemove}
            isPassSelection={true}
          />
        </Previews>
        <div style={{ marginTop: "24px" }}>
          <Select
            options={selectOptions}
            onChange={(e) => handleChange(e.target.value)}
            noOptionsLabel={t<string>('walletCard.description.noOptions')}
            width="352px"
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: "24px" }}
        >
          <Button
            onClick={() => setIsOpen(false)}
            width="100px"
            height="40px"
          >
            {t<string>('walletCard.select')}
          </Button>
        </div>
      </Container>
    </Modal>
  );
}

export default PassSelectModal;
