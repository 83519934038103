import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { WelcomeToBambuBanner } from '../SignIn';
import {
	CenterButtonDiv,
	ErrorText,
	MainDiv,
	Title,
	BackButton,
} from '../SignUp/styles';
import { PaddingDiv } from '../ConfirmAccount/styles';
import { RegisterInput } from '../SignUp';
import { IoIosArrowRoundBack } from 'react-icons/io';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cognitoService, { userTypes } from '../../services/cognitoService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutesEnum } from '../../constants';
import AuthContext from '../../context/AuthContext';

function ForgotPassword() {
	let [searchParams] = useSearchParams();
	const { setUser } = useContext(AuthContext);
	const navigate = useNavigate();

	const { t } = useTranslation();

	const emailSchema: any = yup.object({
		email: yup.string().email(t<string>('forgotPassword.errors.emailInvalid')).required(t<string>('forgotPassword.errors.email')),
	});

	const fullSchema: any = yup.object({
		email: yup.string().email(t<string>('forgotPassword.errors.emailInvalid')).required(t<string>('forgotPassword.errors.email')),
		code: yup
			.string()
			.required(t<string>('forgotPassword.errors.code'))
			.min(6, t<string>('forgotPassword.errors.codeLength')),
		password: yup
			.string()
			.required(t<string>('forgotPassword.errors.password'))
			.min(6, t<string>('forgotPassword.errors.passwordLength')),
	});

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [sendCodeSuccess, setSendCodeSuccess] = useState(false);
	const email = searchParams.get('email') || '';
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: { email },
		resolver: yupResolver(!sendCodeSuccess ? emailSchema : fullSchema),
	});

	function onSubmitCode(data: any) {
		setLoading(true);
		cognitoService
			.forgotLogin(data.email)
			.then((_x: any) => setSendCodeSuccess(true))
			.catch((e: any) => setErrorMessage(e?.message))
			.finally(() => setLoading(false));
	}
	function onSubmitFull(data: any) {
		setLoading(true);
		cognitoService
			.forgotLoginConfirm(data.email, data.code, data.password)
			.then((_x: any) =>
				cognitoService.signIn(data).then((userAttributes: userTypes) => {
					navigate(AppRoutesEnum.HOME);
					setUser(userAttributes);
				})
			)
			.catch((e: any) => setErrorMessage(e?.message))
			.finally(() => setLoading(false));
	}
	return (
		<MainDiv>
			<WelcomeToBambuBanner />
			<PaddingDiv>
				<BackButton>
					<IoIosArrowRoundBack
						onClick={() => navigate(AppRoutesEnum.SIGN_IN)}
					/>
				</BackButton>
				<Title>{t<string>('forgotPassword.title')}</Title>
				{email && <h3>{t<string>('forgotPassword.send')}</h3>}
				<form
					onSubmit={handleSubmit(sendCodeSuccess ? onSubmitFull : onSubmitCode)}
				>
					<RegisterInput
						name="email"
						placeholder={t<string>('forgotPassword.placeholder.email')}
						register={register}
						errors={errors}
						disabled={sendCodeSuccess}
					/>
					{sendCodeSuccess && (
						<>
							<RegisterInput
								name="code"
								placeholder={t<string>('forgotPassword.placeholder.code')}
								register={register}
								errors={errors}
							/>
							<RegisterInput
								name="password"
								placeholder={t<string>('forgotPassword.placeholder.password')}
								register={register}
								errors={errors}
								type="password"
							/>
						</>
					)}
					<CenterButtonDiv>
						<Button loading={loading} width="240px">
							{sendCodeSuccess ? t<string>('forgotPassword.button.reset') : t<string>('forgotPassword.button.confirm')}
						</Button>
					</CenterButtonDiv>
				</form>

				{errorMessage && <ErrorText>{errorMessage}</ErrorText>}
			</PaddingDiv>
		</MainDiv>
	);
}

export default ForgotPassword;
