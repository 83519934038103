import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import showToast from '../../components/Toast';
import ProductsCreationContainer from '../../components/Products/ProductsCreationContainer';
import TokenSelectModal from '../../components/Products/TokenSelectModal';

import { AppRoutesEnum } from '../../constants';
import { Product, TokenData } from '../../types';
import { Background, Container } from './style';

import productService from '../../services/productService';
import AuthContext from '../../context/AuthContext';

const productCreation: Product = {
	name: '',
	description: '',
	tokenId: undefined,
	image: '',
	payload: [
		{
			field: '',
			value: '',
		},
	],
	metadata: [
		{
			field: '',
			value: '',
		},
	],
	category_id: '',
	prices: [
		{
			price: 0,
			currency: '',
		},
	],
};

const ProductCreation = () => {
	const [selectedFile, setSelectedFile] = useState<string>();
	const [product, setProduct] = useState(productCreation);
	const { user } = useContext(AuthContext);
	const [modalIsOpen, setIsOpen] = useState(true);
	const [token, setToken] = useState<TokenData | null>(null);

	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleProductCreation = async () => {
		product.image = selectedFile as string;

		productService.postProducts(user?.tenantId, product)
			.then(() => {
				navigate(AppRoutesEnum.PRODUCTS_LIST);
				showToast.success(t<string>('toast.productCreated'));
			})
			.catch((error) => {
				showToast.error(`${error.message}`);
			}
		);
	};

	return (
		<Background>
			<TokenSelectModal
				modalIsOpen={modalIsOpen}
				setIsOpen={setIsOpen}
				setToken={setToken}
				product={product}
				setProduct={setProduct}
			/>
			<h1>{t<string>('createProduct.title')}</h1>
			<Container>
				<ProductsCreationContainer
					product={product}
					setProduct={setProduct}
					handleProductCreation={handleProductCreation}
					token={token}
					selectedFile={selectedFile}
					setSelectedFile={setSelectedFile}
				/>
			</Container>
		</Background>
	);
};

export default ProductCreation;
