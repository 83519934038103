import styled from 'styled-components';

export const Container = styled.div`
	background: #5F88AF;
	width: calc(100vw - 208px);
	height: 140px;
	font-size: 24px;
	color: #fff;
	line-height: 1.5;
	box-sizing: border-box;
	margin: 0 0 0 -16px;
	position: relative;

	h1 {
		font-size: 2rem;
		font-weight: 500;
		padding: 12px;
	}

	p {
		padding: 1rem;
	}

	@media (max-width: 1706px) {
		width: calc(100vw - 180px);
	}

	@media (max-width: 1550px) {
		width: calc(100vw - 160px);
	}

	@media (max-width: 1408px) {
		width: calc(100vw - 140px);
	}

	@media (max-width: 1280px) {
		width: calc(100vw - 120px);
	}

	@media (max-width: 1200px) {
		width: 100vw;
		margin: 0;
	}

	@media (max-width: 768px) {
		width: 130vw;
		padding: 0;
	}
`;

export const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	a {
		color: #fff;
		text-decoration: none;
		margin: 0 1rem;
		font-weight: 800;
	}

	small { 
		display: flex;
		align-items: center;

		p {
			margin-right: 0.5rem;
		}
	}
`;

export const Absolute = styled.div`
	position: absolute;
	left: 12px;
	bottom: 24px;
`;
