import styled from 'styled-components';

export const PanelContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	max-width: 100%;

	@media (max-width: 768px) {
		margin-left: 2%;
	}
`;
