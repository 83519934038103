import styled from "styled-components";

export const Tr = styled.tr`
    width: 100%;

    div {
        display: flex;
    }
`;

export const Td = styled.td`
	padding: 0.75rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 33%;
    vertical-align: middle;
    font-size: 0.75rem;

	svg {
		cursor: pointer;
		font-size: 1rem;
        margin-right: 0.5rem;
	}

    p {
        margin: 0;
		overflow: hidden;
    }

    &:first-child {
        padding-left: 1rem;
    }

    &:last-child {
		text-align: right;
	}

    @media (max-width: 600px) {
        &:first-child {
            padding-left: .5rem;
        }

		padding: 0.75rem 0;
        font-size: 0.8rem;

        p, svg {
            font-size: .7rem;
        }
	}
`;

