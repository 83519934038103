import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Wrapper, UploadContainer, TextArea } from './style';
import { Input } from '../../Token/TokenBasics/style';
import { ButtonDiv } from '../../FormProgress/style';
import Button from '../../Button';
import { Product } from '../../../types';
import showToast from '../../Toast';
import CustomTooltip from '../../Tooltip';

type Props = {
	setActiveTab: (tab: string) => void;
	product: Product;
	setProduct: (product: Product) => void;
	isNFT: boolean;
	selectedFile: any;
	setSelectedFile: (arg0: any) => void;
};

const ProductsBasics = ({
	setActiveTab,
	product,
	setProduct,
	isNFT,
	selectedFile,
	setSelectedFile,
}: Props) => {
	const [productName, setProductName] = useState(product?.name);
	const [productDescription, setProductDescription] = useState(product?.description);
	const [submitClicked, setSubmitClicked] = useState(false);

	const [isFilePicked, setIsFilePicked] = useState(false);

	const { t } = useTranslation();

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.readyState === 2) {
					setIsFilePicked(true);
					setSelectedFile(reader.result);
				}
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		setIsFilePicked(true);
	};

	const handleSubmit = () => {
		setSubmitClicked(true);

		if (productName && productDescription) {
			setProduct({
				...product,
				name: productName,
				description: productDescription,
				image: selectedFile,
			});

			isNFT 
				? setActiveTab(`2. ${t<string>('createProduct.information.navigation.payload')}`) 
				: setActiveTab(`2. ${t<string>('createProduct.information.navigation.supply')}`);
		} else {
			showToast.warn(t<string>('toast.fillAllFields'));
		}
	};

	return (
		<Wrapper>
			<Container>
				<label>{t<string>('createProduct.information.basics.name')}</label>
				<CustomTooltip text={t<string>('tooltip.productName')} />
				<Input 
					type="text"
					value={productName}
					onChange={(e) => setProductName(e.target.value)}
					placeholder={t<string>('createProduct.information.basics.placeholder.name')}
					className={`${submitClicked && !productName ? 'error' : ''}`}
				/>
				<label>{t<string>('createProduct.information.basics.description')}</label>
				<CustomTooltip text={t<string>('tooltip.productDescription')} />
				<TextArea
					value={productDescription}
					onChange={(e) => setProductDescription(e.target.value)} 
					placeholder={t<string>('createProduct.information.basics.placeholder.description')}
					className={`${submitClicked && !productDescription ? 'error' : ''}`} 
				/>
				
			</Container>
			<UploadContainer>
				<label>{t<string>('createProduct.information.basics.upload')}</label>
				<Button
					width='160px'
				>
					{isFilePicked 
						? t<string>('createProduct.information.basics.placeholder.uploaded') 
						: t<string>('createProduct.information.basics.placeholder.browse')
					}
					<input type="file" onChange={changeHandler} />
				</Button>
			</UploadContainer>
			<ButtonDiv>
				<Button width='160px' onClick={handleSubmit}>
					{t<string>('createProduct.information.button.next')}
				</Button>
			</ButtonDiv>
		</Wrapper>
	);
};

export default ProductsBasics;
