import styled from "styled-components";

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '.5rem',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
	},
};

export const StyledModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
    min-width: 50rem;
    min-height: 22rem;
    background-color: #fff;
    border-radius: .5rem;
    font-size: 1.25rem;
    font-weight: bold;
    border: none;

    h1 {
        margin-bottom: 3rem;
        font-size: 1.5rem;
    }

    div {
        margin: .5rem 0;
        width: 100%;

        select, input, button {
            width: 100%;
            padding: .5rem;
        }

        button {
            border: none;
            border-radius: .25rem;
            font-size: 1rem;
            background-color: #417082;
            color: #fff;
            cursor: pointer;


            &:hover {
                background-color: #51899e;
            }

            &:active {
                background-color: #0062cc;
            }

            &:disabled {
                background-color: #6c757d;
                cursor: not-allowed;
            }
        }
    }

    @media (max-width: 905px) {
        width: 90vw;
        min-height: 17rem;
        min-width: 0;
        padding: 0 1rem;
        box-sizing: border-box;

        h1 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
    }
`;

export const Input = styled.input`
    height: 2.2rem;
    border: 1px solid #ccc;
    border-radius: .25rem;
    padding: 0 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;

    &:focus {
        outline: none;
        border-color: #007bff;
    }

    &:disabled {
        background-color: #e9ecef;
        cursor: not-allowed;
    }
`;
