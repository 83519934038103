import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { GrClose } from "react-icons/gr";
import styled from "styled-components";

type Props = {
    handleFieldRemove: (index: number, type: string) => void;
    primaryFields?: IWalletPassFieldMappings[];
    auxiliaryFields?: IWalletPassFieldMappings[];
    secondaryFields?: IWalletPassFieldMappings[];
    strip?: string;
    thumbnail?: string;
}

const EventTicket = ({
    handleFieldRemove,
    primaryFields,
    auxiliaryFields,
    secondaryFields,
    strip,
    thumbnail,
}: Props) => (
    <Containter>
        {thumbnail ? (
            <ThumbnailDiv>
                <div style={{ 
                    height: "100px",  
                    overflow: "scroll",
                }}>
                    <Droppable droppableId="primaryField">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}` }}
                            >
                                {!primaryFields?.[0] && <PlaceHolder style={{ height: "60px" }}>Primary Field</PlaceHolder>}
                                {primaryFields?.map((field, index: number) => (
                                    <Flex key={index}>
                                        <div>
                                            <p>{field.label}</p>
                                        </div>
                                        <div>
                                            {field?.defaultValue || field?.mappedValue ? (
                                                <span>{field?.defaultValue as string || field?.mappedValue}</span>
                                            ) : (
                                                <NoValue></NoValue>
                                            )}                                
                                        </div>
                                    </Flex>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <Droppable droppableId="secondaryField">
                        {(provided, snapshot) => (
                            <div style={{ 
                                display: "flex", 
                                flexWrap: "nowrap", 
                                height: "36px",  
                                overflowX: "scroll",
                                marginTop: "4px",
                                backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
                            }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {!secondaryFields?.length && <PlaceHolder style={{ fontSize: ".9rem", height: "32px", width: "124px" }}>Secondary Fields</PlaceHolder>}
                                {
                                    secondaryFields?.map((field, index: number) => (
                                        <Flex key={index}>
                                            <div>
                                                <p>{field.label}</p>
                                            </div>
                                            <div>
                                                {field?.defaultValue || field?.mappedValue ? (
                                                    <span style={{ fontSize: "1rem" }}>{field?.defaultValue as string || field?.mappedValue}</span>
                                                ) : (
                                                    <NoValue></NoValue>
                                                )} 
                                            </div>
                                            <GrClose
                                                style={{ position: "absolute", top: "0", left: "96px", cursor: "pointer" }}
                                                onClick={() => handleFieldRemove(index, "secondaryField")}
                                            />
                                        </Flex>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
                <Thumb>
                    {thumbnail ? <img src={thumbnail} alt="thumbnail" /> : <PlaceHolder style={{ marginLeft: "0" }}>Thumb</PlaceHolder>}
                </Thumb>
            </ThumbnailDiv>
        ) : (
            <>
                {!strip ? (
                    <Primary>
                        <PlaceHolder>Strip Image</PlaceHolder>
                    </Primary>
                ) : (
                    <Primary strip={strip}>
                        <div style={{ 
                            display: "flex", 
                            flexWrap: "nowrap", 
                            height: "40px",  
                            overflowX: "scroll",
                            marginTop: "12px",
                        }}>
                            {
                                primaryFields?.map((field, index: number) => (
                                    <Flex key={index}>
                                        <div>
                                            <p>{field.label}</p>
                                        </div>
                                        <div>
                                            {field?.defaultValue || field?.mappedValue ? (
                                                <span>{field?.defaultValue as string || field?.mappedValue}</span>
                                            ) : (
                                                <NoValue></NoValue>
                                            )} 
                                        </div>
                                    </Flex>
                                ))
                            }
                        </div>
                    </Primary>
                )}
                <Droppable droppableId="secondaryField">
                    {(provided, snapshot) => (
                        <div style={{ 
                            display: "flex", 
                            flexWrap: "nowrap", 
                            height: "40px",  
                            overflowX: "scroll",
                            marginTop: "12px",
                            backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
                        }}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {!secondaryFields?.length && <PlaceHolder>Secondary Fields</PlaceHolder>}
                            {
                                secondaryFields?.map((field, index: number) => (
                                    <Flex key={index}>
                                        <div>
                                            <p>{field.label}</p>
                                        </div>
                                        <div>
                                            {field?.defaultValue || field?.mappedValue ? (
                                                <span>{field?.defaultValue as string || field?.mappedValue}</span>
                                            ) : (
                                                <NoValue></NoValue>
                                            )} 
                                        </div>
                                        <GrClose
                                            style={{ position: "absolute", top: "0", left: "96px", cursor: "pointer" }}
                                            onClick={() => handleFieldRemove(index, "secondaryField")}
                                        />
                                    </Flex>
                                ))
                            }
                        </div>
                    )}
                </Droppable>
            </>
        )}
        <Droppable droppableId="auxiliaryField">
            {(provided, snapshot) => (
                <div style={{ 
                    display: "flex", 
                    flexWrap: "nowrap", 
                    height: "40px",  
                    overflowX: "scroll",
                    backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
                }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {!auxiliaryFields?.length && <PlaceHolder>Auxiliary Fields</PlaceHolder>}
                    {
                        auxiliaryFields?.map((field, index: number) => (
                            <Flex key={index}>
                                <div>
                                    <p>{field.label}</p>
                                </div>
                                <div>
                                    {field?.defaultValue || field?.mappedValue ? (
                                        <span>{field?.defaultValue as string || field?.mappedValue}</span>
                                    ) : (
                                        <NoValue></NoValue>
                                    )} 
                                </div>
                                <GrClose
                                    style={{ position: "absolute", top: "0", left: "96px", cursor: "pointer" }}
                                    onClick={() => handleFieldRemove(index, "auxiliaryField")}
                                />
                            </Flex>
                        ))
                    }
                </div>
            )}
        </Droppable>
    </Containter>
);

const Containter = styled.div`
    display: flex;
    flex-direction: column;
`;

type ImgProp = {
    strip?: string;
}

const Primary = styled.div<ImgProp>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    background: url(${({ strip }) => strip}) no-repeat center;
    background-size: contain;
`;

const PlaceHolder = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(101, 131, 161, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin: 0 8px;
`;

const Thumb = styled.div`
    height: 68px;
    width: 60px !important;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const ThumbnailDiv = styled.div`
    display: flex;

    div {
        margin-right: 8px;

        &:first-child {
            width: 75%;
        }

        &:last-child {
            height: 72px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
`;

const Flex = styled.div`
    margin-top: 0px;
    position: relative;
    width: 50%;
    height: 40px;

    div {
        display: flex;
        
        p {
            margin: 0 0 0 8px;
            font-size: .7rem;
            overflow: ellipsis;

            &:first-child {
                width: 100px;
            }
        }

        span {
            margin-left: 8px;
            width: 100px;
            font-size: 1.25rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
`;

const NoValue = styled.span`
    margin: 4px 0 0 8px;
    width: 100px;
    height: 20px;
    background-color: #989c9e;
`;

export default EventTicket;
