import { IAndroidPassData } from '@bambu-meta/interfaces';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { Widget, InnerText, Img } from '../../../../components';
import { WalletTemplate } from '../../../../types';

type Props = {
	androidWalletTemplate: IAndroidPassData;
	walletTemplate: WalletTemplate;
	isReview?: boolean;
};

export const AndroidCard = ({
	androidWalletTemplate,
	walletTemplate,
	isReview,
}: Props) => {
	return (
		<div
			style={{ backgroundColor: walletTemplate.backgroundColor }}
			className="rounded-lg !p-5"
		>
			<div className="box-border h-[408px] relative">
				<div className="flex">
					{androidWalletTemplate?.images?.logo ? (
						<div className="w-10 h-10 mr-5 mb-4 overflow-hidden rounded-lg">
							<Img
								src={androidWalletTemplate?.images?.logo}
								className=""
								alt="logo"
							/>
						</div>
					) : (
						<Widget className="w-10 h-10 mr-5">
							<InnerText className="-rotate-45">Logo</InnerText>
						</Widget>
					)}
					{androidWalletTemplate?.title ? (
						<p className="w-[200px] h-10 mb-[3px]">
							{androidWalletTemplate.title}
						</p>
					) : (
						<Widget className="w-[200px] h-10">
							<InnerText>Title</InnerText>
						</Widget>
					)}
				</div>
				{(walletTemplate.passStyle === 'storeCard' ||
					walletTemplate.passStyle === 'generic') && (
					<div className="flex mb4">
						{androidWalletTemplate?.header ? (
							<p className="w-full h-12 mb-[3px]">
								{androidWalletTemplate.header}
							</p>
						) : (
							<Widget className="w-full h-12">
								<InnerText>Header</InnerText>
							</Widget>
						)}
					</div>
				)}
				{walletTemplate.passStyle === 'generic' && (
					<div className="flex mb4">
						{androidWalletTemplate?.subheader ? (
							<p className="w-full h-6 mb-[3px]">
								{androidWalletTemplate.subheader}
							</p>
						) : (
							<Widget className="w-full h-6">
								<InnerText>Subheader</InnerText>
							</Widget>
						)}
					</div>
				)}
				{isReview ? (
					androidWalletTemplate?.rows?.[0]?.items?.length ? (
						<div className='flex justify-between'>
							{androidWalletTemplate?.rows[0]?.items?.map((field: any, index) => (
								<div key={index} className="w-1/3">
									<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
										{field.label}
									</p>
									<p className="whitespace-nowrap text-ellipsis overflow-hidden">
										{field.mappedValue || field.defaultValue}
									</p>
								</div>
							))}
						</div>
					) : (
						<Widget className="w-full h-10 mb4">
							<InnerText>First Row</InnerText>
						</Widget>
					)
				) : (
					<Droppable droppableId="row1">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`${
									snapshot.isDraggingOver
										? 'rounded-lg shadow-xl'
										: 'rounded-lg'
								} h-10 mb-4 w-full`}
							>
								{androidWalletTemplate?.rows?.[0]?.items?.length ? (
									<div className='flex justify-between'>
										{androidWalletTemplate?.rows[0]?.items?.map(
											(field: any, index) => (
												<Draggable
													key={index}
													draggableId={`row1 ${index}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`
															${
																snapshot.isDragging
																	? 'rounded-lg shadow-xl border-2 border-dashed border-stone-200 bg-white'
																	: ''
															} 
															w-1/3
														`}
															style={{
																top: `${snapshot.isDragging ? '0px' : '0px'}`,
																right: `${snapshot.isDragging ? '0px' : '0px'}`,
																transition:
																	provided?.draggableProps?.style?.transition,
																transform:
																	provided?.draggableProps?.style?.transform,
															}}
														>
															<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
																{field.label}
															</p>
															<p className="whitespace-nowrap text-ellipsis overflow-hidden">
																{field.mappedValue || field.defaultValue}
															</p>
														</div>
													)}
												</Draggable>
											)
										)}
									</div>
								) : (
									<Widget className="w-full h-10 mb4">
										<InnerText>First Row</InnerText>
									</Widget>
								)}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				)}
				{isReview ? (
					androidWalletTemplate?.rows?.[1]?.items?.length ? (
						<div className='flex justify-between'>
							{androidWalletTemplate?.rows[1]?.items?.map((field: any, index) => (
								<div key={index} className="w-1/3">
									<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
										{field.label}
									</p>
									<p className="whitespace-nowrap text-ellipsis overflow-hidden">
										{field.mappedValue || field.defaultValue}
									</p>
								</div>
							))}
						</div>
					) : (
						<Widget className="w-full h-10 mb4">
							<InnerText>Second Row</InnerText>
						</Widget>
					)
				) : (
					<Droppable droppableId="row2">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`${
									snapshot.isDraggingOver
										? 'rounded-lg shadow-xl'
										: 'rounded-lg'
								} h-10 mb-4 w-full`}
							>
								{androidWalletTemplate?.rows?.[1]?.items?.length ? (
									<div className='flex justify-between'>
										{androidWalletTemplate?.rows[1]?.items?.map(
											(field: any, index) => (
												<Draggable
													key={index}
													draggableId={`row2 ${index}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`
															${
																snapshot.isDragging
																	? 'rounded-lg shadow-xl border-2 border-dashed border-stone-200 bg-white'
																	: ''
															} w-1/3
														`}
															style={{
																top: `${snapshot.isDragging ? '0px' : '0px'}`,
																right: `${snapshot.isDragging ? '0px' : '0px'}`,
																transition:
																	provided?.draggableProps?.style?.transition,
																transform:
																	provided?.draggableProps?.style?.transform,
															}}
														>
															<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
																{field.label}
															</p>
															<p className="whitespace-nowrap text-ellipsis overflow-hidden">
																{field.mappedValue || field.defaultValue}
															</p>
														</div>
													)}
												</Draggable>
											)
										)}
									</div>
								) : (
									<Widget className="w-full h-10 mb4">
										<InnerText>Second Row</InnerText>
									</Widget>
								)}
							</div>
						)}
					</Droppable>
				)}
				{isReview ? (
					androidWalletTemplate?.rows?.[2]?.items?.length ? (
						<div className='flex justify-between'>
							{androidWalletTemplate?.rows[2]?.items?.map((field: any, index) => (
								<div key={index} className="w-1/3">
									<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
										{field.label}
									</p>
									<p className="whitespace-nowrap text-ellipsis overflow-hidden">
										{field.mappedValue || field.defaultValue}
									</p>
								</div>
							))}
						</div>
					) : (
						<Widget className="w-full h-10 mb4">
							<InnerText>Third Row</InnerText>
						</Widget>
					)
				) : (
					<Droppable droppableId="row3">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`${
									snapshot.isDraggingOver
										? 'rounded-lg shadow-xl'
										: 'rounded-lg'
								} h-10 mb-4 w-full`}
							>
								{androidWalletTemplate?.rows?.[2]?.items?.length ? (
									<div className='flex justify-between'>
										{androidWalletTemplate?.rows[2]?.items?.map(
											(field: any, index) => (
												<Draggable
													key={index}
													draggableId={`row3 ${index}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`
															${
																snapshot.isDragging
																	? 'rounded-lg shadow-xl border-2 border-dashed border-stone-200 bg-white'
																	: ''
															} w-1/3
														`}
															style={{
																top: `${snapshot.isDragging ? '0px' : '0px'}`,
																right: `${snapshot.isDragging ? '0px' : '0px'}`,
																transition:
																	provided?.draggableProps?.style?.transition,
																transform:
																	provided?.draggableProps?.style?.transform,
															}}
														>
															<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
																{field.label}
															</p>
															<p className="whitespace-nowrap text-ellipsis overflow-hidden">
																{field.mappedValue || field.defaultValue}
															</p>
														</div>
													)}
												</Draggable>
											)
										)}
									</div>
								) : (
									<Widget className="w-full h-10 mb4">
										<InnerText>Third Row</InnerText>
									</Widget>
								)}
							</div>
						)}
					</Droppable>
				)}
				<div className="w-full flex justify-center">
					{walletTemplate?.barCodes?.[0]?.format === 'PKBarcodeFormatQR' ||
					walletTemplate?.barCodes?.[0]?.format === 'PKBarcodeFormatAztec' ? (
						<Widget className="absolute w-16 h-16 bottom-0">
							<InnerText>Barcode</InnerText>
						</Widget>
					) : (
						(walletTemplate?.barCodes?.[0]?.format ===
							'PKBarcodeFormatPDF417' ||
							walletTemplate?.barCodes?.[0]?.format ===
								'PKBarcodeFormatCode128') && (
							<Widget className="absolute w-full h-16 bottom-0">
								<InnerText>Barcode</InnerText>
							</Widget>
						)
					)}
				</div>
			</div>
		</div>
	);
};
