import styled from 'styled-components';

export const Wrapper = styled.div`
	background: #F4F6F8;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	label {
		font-size: 1.25rem;
		margin: 6px 8px 8px 0;
	}

	button { 
		margin-bottom: 48px;
	}
`;

export const Container = styled.div`
	width: 80%;
	margin: 40px 80px 260px 80px;

	.error {
		border: 1px solid #d9534f;
	}
`;

export const PriceContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 24px;

	label {
		display: inline-block;
	}

	button {
		margin: 0;
	}

	p {
		display: inline-block;
		padding: 0.43rem;
		border: 1px solid #ced4da;
		border-radius: 0.25rem 0 0 0.25rem;
	}
`;

export const Input = styled.input`
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	width: 300px;
	height: 32px;
	font-size: 1.25rem;
	border: none;
	padding: 0 24px;
	display: block;
	box-sizing: border-box;
	color: #647E93;

	&:placeholder {
		color: #647E93;;
	}

	&:focus {
		outline: none;
	}
`;
