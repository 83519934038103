import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Button from '../../components/Button';

import { modalStyles, ModalContainer } from './style';

type Props = {
  name: string;
  setName: (name: string) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setIsCreatingTier: (isCreatingTier: boolean) => void;
}

const TierModal = ({ name, setName, isModalOpen, setIsModalOpen, setIsCreatingTier }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={modalStyles}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <ModalContainer>
        <h1>{t<string>('walletCard.tierName')}</h1>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Bronze"
        />
        <div style={{ display: "flex" }}>
          <Button
            onClick={() => setIsCreatingTier(false)}
          >
            {t<string>('walletCard.cancel')}
          </Button>
          {name ? (
            <Button onClick={() => setIsModalOpen(false)}>
              {t<string>('walletCard.continue')}
            </Button>
          ) : (
            <Button 
              disabled
              backgroundColor="rgba(101, 131, 161, 0.1)"
              color="#647E93"
            >
              {t<string>('walletCard.continue')}
            </Button>
          )}
        </div>
      </ModalContainer>
    </Modal>
  )
}

export default TierModal;
