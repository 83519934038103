import { useTranslation } from 'react-i18next';

import { Container, Flex  } from './style';
import TenantDropdown from '../TenantDropdown';
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';

const TopHeader = () => {
	const { user } = useContext(AuthContext);
	const { t } = useTranslation();

	return (
		<Container>
			<Flex>
				<div>
					<h1>{`${t<string>('header.home')} ${user?.displayName}`}</h1>
				</div>
				<TenantDropdown />
			</Flex>
		</Container>
	);
};

export default TopHeader;
