import styled from 'styled-components';

export const Container = styled.nav`
	display: flex;
	justify-content: space-between;
	width: 90%;

	div {
		color: rgba(100, 126, 147, 0.5);
		margin: 12px;
		font-size: 2.5rem;

		svg {
			font-size: 3rem;
			margin-left: -48px;
			margin-bottom: -10px;
			color: #AECA37;
		}

		&:last-child {
			margin-right: 100px;
		}
	}

	.active {
		color: #647E93;
		font-weight: 600;
	}
`;
