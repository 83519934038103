import styled from 'styled-components';

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '.5rem',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
	},
};

export const ButtonsDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const HomeContainer = styled.div`
	display: flex;
	width: calc(100vw - 208px);
	background-color: #F5F5F6;
	box-sizing: border-box;
	margin: 4px 0 0 -16px;
	padding: 0 24px;

	h2 {
		font-size: 1.25rem;
		color: #647E93;
		margin-left: 40%;
	}

	@media (max-width: 800px) {
		width: 100vw;
		box-sizing: border-box;
	}
`;

export const Title = styled.div`
	font-size: 1.25rem;
	font-weight: 900;
	color: #647E93;
	margin: 24px;
`;

export const HomeHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #647E93;
	margin: 0;
	padding: 0;				

	h2 {
		font-size: 1.25rem;
		font-weight: 500;
		margin-bottom: 0.5rem;
	}

	a {
		text-decoration: none;
	}

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		span {
			font-size: 1.25rem;
			font-weight: 700;
			margin-top: 28px;
		}

		p {
			font-size: .75rem;
			font-weight: 500;
			margin-top: 4px;
		}
	}

	@media (max-width: 800px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 125vw;
	}
`;

export const DateHeader = styled.div`
	padding-top: 0px;
	z-index: 50;

	button {
		height: 40px;
		padding: 0 40px;
	}
`;
