import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { AppRoutesEnum } from "../../constants";
import { TopRouterType } from "../../types";

interface TopNavigationProps {
  routes: TopRouterType[];
  state?: any;
}

export const TopNavigation = ({ routes, state }: TopNavigationProps) => {
  const { t } = useTranslation();
  
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    if (path === window.location.pathname) return;

    navigate(path, { state });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <p 
          className='font-sans text-xs text-stone-600 cursor-pointer mx-2'
          onClick={() => navigate(AppRoutesEnum.HOME)}
        >{t<string>('walletCard.home')}</p>
        {routes.map((route, index) => (
          <>
            <ChevronRightIcon height={12} />
            <p
              key={index}
              className='font-sans text-xs text-stone-600 cursor-pointer mx-2'
              onClick={() => handleNavigate(route.path)}
            >{t<string>(route.label)}</p>
          </>
        ))}
      </div>
    </div>
  );
};
