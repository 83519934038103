import { Droppable } from 'react-beautiful-dnd';

import {
	CreateNewField,
	DraggableInput,
	H4,
	LineBreak,
} from '../../../../../components';
import { IWalletPassFieldMappings } from '@bambu-meta/interfaces';

type Props = {
	fields: IWalletPassFieldMappings[];
	setFields: (fields: IWalletPassFieldMappings[]) => void;
	setIsOpen: (bool: boolean) => void;
	setFieldIndex: (index: number | undefined) => void;
	setIsEdit: (bool: boolean) => void;
	handleOpenEditField: (index: number) => void;
};

export const Fields = ({ fields, setFields, setIsOpen, setFieldIndex, setIsEdit, handleOpenEditField }: Props) => {
	const handleMessageChange = (value: string, index: number) => {
		const newFields = [...fields];

		if (newFields[index] && newFields[index].mappedValue) {
			newFields[index].mappedValue = value;
		} else {
			newFields[index].defaultValue = value;
		}

		setFields(newFields);
	}

	return (
		<>
			<H4 className="mb-2">Drag N' Drop Fields</H4>
			<LineBreak />
			<div className="mt-5 pr-4 w-[580px] h-[280px] overflow-y-auto pb-10">
				<Droppable droppableId="fields">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<div className="flex justify-between gap-4 flex-wrap">
								{fields?.map((field, index: number) => (
									<DraggableInput
										key={field.key}
										className="w-full md:w-[48%]"
										id={index.toString()}
										index={index}
										label={field.label}
										onChange={(e) => handleMessageChange(e.target.value, index)}
										onClick={() => {
											setFieldIndex(index);
											handleOpenEditField(index);
											setIsOpen(true);
											setIsEdit(true);
										}}
										placeholder={field.label}
										value={
											(field.mappedValue as string) ||
											(field.defaultValue as string)
										}
										readOnly
									/>
								))}
							</div>
							{provided.placeholder}
							<CreateNewField className='mt-5' onClick={() => {
								setIsOpen(true);
								setFieldIndex(undefined);
								setIsEdit(false);
							}} />
						</div>
					)}
				</Droppable>
			</div>
		</>
	);
};
