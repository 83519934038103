import { InputBasic } from '../../pages/SignUp/styles';
import styled from 'styled-components';

export const Container = styled.div`
	margin: 1rem 2rem;

	@media (max-width: 768px) {
		margin: 1rem 0;
	}
  }
`;

export const StyledSelect = styled.select`
	${InputBasic}
	margin-top: 12px;
`;
