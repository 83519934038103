import { useState } from 'react';
import Button from '../../components/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cognitoService from '../../services/cognitoService';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import ReactPhoneInput from 'react-phone-number-input/react-hook-form-input';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {
	Form,
	MainDiv,
	FlexDiv,
	FlexColumnDiv,
	ErrorText,
	PaddingDiv,
	CenterButtonDiv,
	RequiredDiv,
	Input,
	Title,
} from './styles';
import { WelcomeToBambuBanner } from '../SignIn';
import { AppRoutesEnum } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { RiAsterisk } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import showToast from '../../components/Toast';

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

function SignUp() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);	

	const schema: any = yup.object({
		phone: yup.string().required().matches(phoneRegExp, t<string>('signUp.errors.invalidPhone')),
		firstname: yup.string().required(t<string>('signUp.errors.firstName')),
		lastname: yup.string().required(t<string>('signUp.errors.lastName')),
		company: yup.string().required(t<string>('signUp.errors.company')),
		email: yup.string().email().required(t<string>('signUp.errors.email')),
		password: yup
			.string()
			.required(t<string>('signUp.errors.passwordRequired'))
			.min(6, t<string>('signUp.errors.passwordLength')),
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: { phone: '' },
		resolver: yupResolver(schema),
	});

	function handleSubmit2(user: any) {
		setLoading(true);		
		user.email = user.email.toLowerCase()

		cognitoService
			.signUp(user)
			.then((_s: any) =>
				navigate(`${AppRoutesEnum.CONFIRM_ACCOUNT}?email=${user.email}`)
			)
			.catch((e: any) => {
				console.log('err', e)
				showToast.error(e?.message)
			})
			.finally(() => setLoading(false));
	}

	return (
		<MainDiv>
			<WelcomeToBambuBanner />
			<PaddingDiv>
				<Title>
					<IoIosArrowRoundBack
						onClick={() => navigate(AppRoutesEnum.SIGN_IN)}
					/>
					{t<string>('signUp.title')}
				</Title>
				<Form onSubmit={handleSubmit(handleSubmit2)}>
					<FlexDiv>
						<RegisterInput
							name={'firstname'}
							errors={errors}
							register={register}
							placeholder={t<string>('signUp.placeholder.firstName')}
							required={true}
						/>
						<RegisterInput
							name={'lastname'}
							errors={errors}
							register={register}
							placeholder={t<string>('signUp.placeholder.lastName')}
							required={true}
						/>
					</FlexDiv>

					<FlexDiv>
						<RegisterInput
							name={'company'}
							errors={errors}
							register={register}
							placeholder={t<string>('signUp.placeholder.company')}
							required={true}
						/>
					</FlexDiv>
					<FlexDiv>
						<RegisterInput
							name={'email'}
							errors={errors}
							register={register}
							placeholder={t<string>('signUp.placeholder.email')}
							flex={0.99}
							required={true}
						/>

						<PhoneInput control={control} errors={errors} required={true} />
					</FlexDiv>
					<RegisterInput
						name={'password'}
						errors={errors}
						register={register}
						placeholder={t<string>('signUp.placeholder.password')}
						type="password"
						required={true}
					/>
					<CenterButtonDiv>
						<Button loading={loading} width="800px">{t<string>('signUp.register')}</Button>
					</CenterButtonDiv>
				</Form>				
			</PaddingDiv>
		</MainDiv>
	);
}

interface registerInputTypes {
	name: string;
	placeholder: string;
	flex?: number;
	register: any;
	errors?: any;
	disabled?: boolean;
	type?: string;
	required?: boolean;
}

export function RegisterInput({
	name,
	placeholder,
	flex = 1,
	register,
	errors,
	disabled,
	type,
	required,
}: registerInputTypes) {
	return (
		<RequiredDiv
			style={{ flex, minWidth: 60, display: 'flex', flexDirection: 'column' }}
		>
			<Input
				name={name}
				{...register(name)}
				placeholder={placeholder}
				disabled={disabled}
				type={type}
				style={{ flex, borderColor: errors[name] && '#f55442' }}
			/>
			{required && <RiAsterisk />}
			{errors[name] && <ErrorText>{errors[name].message}</ErrorText>}
		</RequiredDiv>
	);
}

function PhoneInput({ control, errors }: any) {
	const { t } = useTranslation();

	return (
		<FlexColumnDiv>
			<ReactPhoneInput
				placeholder="Phone number"
				name="phone"
				control={control}
				defaultCountry="US"
				rules={{ required: true }}
				style={{
					marginLeft: 4,
					marginTop: 3.9,
					minWidth: 0,
					width: 'auto',
					height: '32px',
					border: `${errors?.phone ? '1px solid #f55442' : 'none'}`,
					borderRadius: "16px",
					outline: `initial !important`,
					boxShadow: `5px 5px 8px rgba(0, 0, 0, 0.1)`,
					fontSize: `1rem`,
					padding: `0 24px`,
					color: '#647E93',
					placeholderColor: '#647E93',
					lineHeight: 1,
				}}
			/>
			<RiAsterisk />
			{errors.phone && <ErrorText>{t<string>('signUp.errors.invalidPhone')}</ErrorText>}
		</FlexColumnDiv>
	);
}

export default SignUp;
