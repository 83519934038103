import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GeneralInfo from '../GeneralInfo';
import MainPanel from '../MainPanel';

import { HomeContainer, Title, DateHeader, HomeHeader } from './style';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from '../Button';
import DateRangeModal from './DateRangeModal';

const day = (new Date().getMonth() + 1 === 1 || new Date().getMonth() + 1 === 3
	|| new Date().getMonth() + 1 === 5 || new Date().getMonth() + 1 === 7
	|| new Date().getMonth() + 1 === 8 || new Date().getMonth() + 1 === 10 || new Date().getMonth() + 1 === 12)
	? 31
	: (new Date().getMonth() + 1 === 4 || new Date().getMonth() + 1 === 6 || new Date().getMonth() + 1 === 9 || new Date().getMonth() + 1 === 11)
		? 30
		: 28;

const date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
const fiveDaysAgo = new Date().getDate() > 5
	? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate() - 4}`
	: `${new Date().getFullYear()}-${new Date().getMonth()}-${day - 4}`;

const HomeBody = () => {
	const [dateRange, setDateRange] = useState([fiveDaysAgo, date]);
	const [appliedDates, setAppliedDates] = useState([fiveDaysAgo, date]);
	const [dateIsOpen, setDateIsOpen] = useState(false);
	const [updateReport, setUpdateReport] = useState(false);

	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const { t } = useTranslation();

	const formatDate = (date: string) => {
		const dateArray = date.split('-');
		return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`.replaceAll("-", "/");
	}

	let startDate = new Date(formatDate(dateRange[0]));
	let endDate = new Date(formatDate(dateRange[1]));

	const selectionRange = {
		startDate: startDate,
		endDate: endDate,
		key: 'selection'
	};

	const handleSelect = (ranges: any) => {
		let startDate = new Date(ranges.selection.startDate).toISOString().split('T')[0];
		let endDate = new Date(ranges.selection.endDate).toISOString().split('T')[0];

		setDateRange([startDate, endDate]);
	}

	const handleApply = () => {
		setUpdateReport(!updateReport);
		setAppliedDates(dateRange);
		setDateIsOpen(false);
		setIsFirstLoad(false);
	}

	return (
		<HomeContainer>
			<div>
				<HomeHeader>
					<Title>{t<string>('mobileNav.home')}</Title>
					<div>
						<span>{`${formatDate(appliedDates[0])} - ${formatDate(appliedDates[1])}`}</span>
						<p>{t<string>('home.dateRange')}</p>
					</div>
					<DateHeader>
						<Button width="260px" fontSize='1.25rem' onClick={() => setDateIsOpen(true)}>{t<string>('home.filterDate')}</Button>
					</DateHeader>
				</HomeHeader>
				<GeneralInfo dateRange={dateRange} updateReport={updateReport} isFirstLoad={isFirstLoad} />
				<MainPanel />
				<DateRangeModal
					isModalOpen={dateIsOpen}
					setIsModalOpen={setDateIsOpen}
					selectionRange={selectionRange}
					handleSelect={handleSelect}
					handleApply={handleApply}
				/>
			</div>
		</HomeContainer>
	);
};

export default HomeBody;
