import { ThreeDots } from 'react-loader-spinner';
import CustomTooltip from '../Tooltip';

import { CardContainer, Title, DataContainer } from './style';

type Props = {
    title: string;
    value: number;
    isLoading: boolean;
    tooltip: string;
}

const ReportList = ({ title, value, isLoading, tooltip }: Props) => (
    <CardContainer>
        <div>
            <Title>{title}</Title>
            <CustomTooltip text={tooltip} />
            <DataContainer className="py-4 m-0 text-center h1 text-success">
                {isLoading
                    ?  (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ThreeDots color="#647E93" />
                        </div>
                    ) : (value || (value !== 0 ? 'N/A' : value))
                }
            </DataContainer>
        </div>
    </CardContainer>
);

export default ReportList;