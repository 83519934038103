import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ThreeDots } from "react-loader-spinner"

import useQueryWalletTiers from "../../hooks/useQuery/useQueryWalletTiers"
import { Select } from "../../components/Select"

import { Container, SelectContainer, InfoContainer } from "./style"
import walletService from "../../services/walletService"
import AuthContext from "../../context/AuthContext"
import Button from "../../components/Button"
import { AppRoutesEnum } from "../../constants"
import showToast from "../../components/Toast"

const WalletUpdateTiers = () => {
  const { user } = useContext(AuthContext)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { state } = useLocation() as any
  const { pass } = state
  
  const { data: walletTemplate, isLoading: templateIsLoading } = useQueryWalletTiers(pass?.templateId)
  let walletTemplateOptions: any = []
  let templatesNames = []

  if (walletTemplate) walletTemplateOptions = [{ name: t<string>('walletCard.noTier'), templateTierId: "0", templateId: pass.templateId }, ...walletTemplate]

  if (walletTemplateOptions) templatesNames = walletTemplateOptions?.map((template: any) => template.name)

  const [tier, setTier] = useState(templatesNames?.[0] || "")
  const [load, setLoad] = useState(false)

  useEffect(() => {
    const myTier = walletTemplate?.find((template: any) => template.templateTierId === pass?.templateTierId)
    setTier(myTier?.name)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTierChange = (value: string) => {
    setTier(value)
  }

  const handleTierUpdate = () => {
    setLoad(true)
    const tierTemplate = walletTemplateOptions?.find((template: any) => template.name === tier)

    walletService.patchWalletPasses(user?.tenantId, pass?.passId, tierTemplate)
      .then(() => {
        navigate(AppRoutesEnum.WALLET_TEMPLATE_TABLE)
        showToast.success(t<string>('walletCard.tierUpdate.success'))
      })
      .catch((err) => {
        showToast.error(err.message)
      })
      .finally(() => {
        setLoad(false)
      })
  }

  if (templateIsLoading) {
    return (
      <Container style={{ justifyContent: "center", alignItems: "center" }}>
        <ThreeDots color="#647E93" height={60} width={60} />
      </Container>
    )
  }

  return (
    <Container>
      <SelectContainer>
        <h1>{t<string>('walletCard.tierUpdate.title')}</h1>
        <InfoContainer>
          <div>
            <strong>{`${t<string>('walletCard.tierUpdate.member')}:`}</strong>
            <p>{`${pass.person.firstName} ${pass.person.lastName}`}</p>
          </div>
          <div>
            <strong>{`${t<string>('walletCard.tierUpdate.templateName')}:`}</strong>
            <p>{pass.walletTemplate.name}</p>
          </div>
        </InfoContainer>
        <label>{t<string>('walletCard.tierUpdate.tiers')}</label>
        <Select
          options={templatesNames}
          noOptionsLabel={t<string>('walletCard.noOptions')}
          needDisabledOption={t<string>('walletCard.selectTemplate')}
          onChange={(e) => handleTierChange(e.target.value)}
          selectedOption={tier}
          width="400px"
        />
      </SelectContainer>
      <Button
        onClick={handleTierUpdate}
        width="400px"
        loading={load}
      >
        {t<string>('walletCard.tierUpdate.update')}
      </Button>
    </Container>
  )
}

export default WalletUpdateTiers
