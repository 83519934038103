import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TokenField, TokenData } from '../../../types';
import { AppRoutesEnum } from '../../../constants';

import { Form } from '../TokenBasics/style';

import { ButtonDiv, Button } from '../../FormProgress/style';
import SubmitButton from '../../Button';
import { Container, FieldsDiv, FieldButton, FieldContainer, Labels, SelectDiv } from './style';
import showToast from '../../Toast';
import { Select } from '../../Select';
import tokenService from '../../../services/tokenService';
import AuthContext from '../../../context/AuthContext';
import Tooltip from "../../Tooltip";

type Props = {
	setActiveTab: (tab: string) => void;
	setTokenData: (data: TokenData) => void;
	tokenData: TokenData | null;
};

const TokenFields = ({ setActiveTab, setTokenData, tokenData }: Props) => {
	const { user } = useContext(AuthContext);
	const { t } = useTranslation();

	const [fields, setFields] = useState<TokenField[] | null>(null);
	const [disableButton, setDisableButton] = useState(false);

	const navigate = useNavigate();

	const handleFormSubmit = (event: any) => {
		event.preventDefault();
		setDisableButton(true);

		if (tokenData) {
			const attributeData: TokenData = {
				...tokenData,
				metadata: fields,
			};

			setTokenData(attributeData);

			tokenService
				.postToken(user?.tenantId, attributeData)
				.then(() => {
					navigate(AppRoutesEnum.TOKEN_LIST);
					showToast.success(t<string>('toast.tokenCreated'));
				})
				.catch((error) => {
					setActiveTab('1');
					showToast.error(error.message);
				})
				.finally(() => {
					setDisableButton(false);
				});
		} else {
			setActiveTab('1');
		}
	};

	return (
		<Form onSubmit={(event) => handleFormSubmit(event)}>
			<Container>
				<SetFields fields={fields} setFields={setFields} />
			</Container>
			<ButtonDiv>
				<Button onClick={() => setActiveTab('2')}>{t<string>('createToken.button.back')}</Button>
				<SubmitButton 
					type="submit" 
					loading={disableButton} 
					disabled={disableButton}
					width="160px"
				>{t<string>('createToken.button.create')}</SubmitButton>
			</ButtonDiv>
		</Form>
	);
};

export default TokenFields;

export function SetFields({ setFields, fields, token }: any) {
	const { t } = useTranslation();

	const fieldTypes = [
		t<string>('createToken.defineFields.types.text'),
		t<string>('createToken.defineFields.types.number'),
		t<string>('createToken.defineFields.types.upload'),
	];

	const handleAddName = (name: string, index: number) => {
		if (fields) {
			fields[index].fieldName = name;
			setFields([...fields]);
		}
	};

	const handleAddType = (type: string, index: number) => {
		if (fields) {
			fields[index].fieldType = type;
			setFields([...fields]);
		}
	};

  	const handleAddField = (name: string, type: string) => {
		if (fields) {
			setFields([...fields, { fieldName: name, fieldType: type }]);
		} else {
			setFields([{ fieldName: name, fieldType: type }]);
		}
	};

	const handleRemoveField = (index: number) => {
		if (fields) {
			fields.splice(index, 1);
			setFields([...fields]);
		}

		if (token.metadata) {
		token.metadata = fields;
		tokenService.patchToken(token)
			.then(() => {
					showToast.success(t<string>('toast.successfullyDeleted'));
				})
			.catch((error: any) => {
					showToast.error(error.message);
				});
			}
	};

	return (
		<FieldsDiv>
			<Labels>
				<div>
					<label>{t<string>('createToken.defineFields.label.name')}</label>
					<Tooltip text={t<string>('tooltip.fieldName')} />
				</div>
				<div>
					<label>{t<string>('createToken.defineFields.label.type')}</label>
					<Tooltip text={t<string>('tooltip.fieldType')} />
				</div>
				<label></label>
				<label></label>
			</Labels>
			{fields?.map((field: TokenField, index: number) => (
				<FieldContainer key={index}>
					<div>
						<input
							type="text"
							name="name"
							value={field.fieldName || ''}
							onChange={(e) => handleAddName(e.target.value, index)}
							placeholder={t<string>('createToken.basics.placeholder.fieldName')}
						/>
					</div>
					<SelectDiv>
						<Select
							options={fieldTypes}
							onChange={(e: any) => handleAddType(e.target.value, index)}
							selectedOption={field.fieldType}
							noOptionsLabel={t<string>('select.noTypes')}
							needDisabledOption={t<string>('select.typeDisabled')}
							width="400px"
						/>
					</SelectDiv>
					<div>
						<Button
							onClick={() => handleRemoveField(index)}
							type="button"
							width="400px"
						>
							{t<string>('createToken.defineFields.button.remove')}
						</Button>
					</div>
					<div />
				</FieldContainer>
			))}
			<FieldButton>
				<Button onClick={() => handleAddField('', 'Text')} type="button">
					{t<string>('createToken.defineFields.button.add')}
				</Button>
			</FieldButton>
		</FieldsDiv>
	);
}
