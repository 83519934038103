import { CardContainer } from './style';
import LinearChart from '../Charts/LinearChart';

const StandardCard = () => {
	return (
		<CardContainer className="card">
			<LinearChart />
		</CardContainer>
	);
};

export default StandardCard;
