interface TypographyProps {
	/** Text to communicate */
	children: string;
	/** Extra classNames */
	className?: string;
}

export const H1 = ({ children, className }: TypographyProps) => {
	return (
		<h1
			className={`font-sans text-bluejay-900 font-bold text-2xl ${className}`}
		>
			{children}
		</h1>
	);
};

export const H2 = ({ children, className }: TypographyProps) => {
	return (
		<h2 className={`font-sans text-2xl text-stone-900 font-light ${className}`}>
			{children}
		</h2>
	);
};

export const StepCounterText = ({ children, className }: TypographyProps) => {
	return (
		<h3
			className={`font-sans text-2xl text-stone-500 font-light text-left md:text-right min-w-[100px] ${className}`}
		>
			{children}
		</h3>
	);
};

export const H4 = ({ children, className }: TypographyProps) => {
	return (
		<h4 className={`font-sans text-lg text-stone-900 font-light ${className}`}>
			{children}
		</h4>
	);
};

export const P = ({ children, className }: TypographyProps) => {
	return (
		<p className={`font-sans text-xs text-stone-600 ${className}`}>
			{children}
		</p>
	);
};

export const LineBreak = () => {
	return <div className="h-[1px] w-full bg-bluejay-900 mt-3" />;
};
