import { useTranslation } from 'react-i18next';

import BarChart from '../Charts/BarChart';
import PieChart from '../Charts/PieCart';
import CustomTooltip from '../Tooltip';
import { CardContainer, Title } from './style';

type Props = {
	data: any;
	isLoading: boolean;
	tooltip: string;
	title?: string;
	isBar?: boolean;
}

const DashboardWidget = ({ data, isLoading, tooltip, title, isBar }: Props) => {
	const { t } = useTranslation();

	if (isBar) {
		const { xData, yData } = data;

		return (	
			<CardContainer isBarChart={true} className="card">
				<div className="card-body">
					<Title>{t<string>('dashboardCards.bar.title')}</Title>
				</div>
				<BarChart xData={xData} yData={yData} isLoading={isLoading} />
			</CardContainer>
		);
	}

	return (
		<CardContainer>
			<div>
				<Title>{title}</Title>
				<CustomTooltip text={tooltip} />
			</div>
			<PieChart data={data} isLoading={isLoading} title={title} />
		</CardContainer>
	);
};

export default DashboardWidget;
