/* eslint-disable no-lone-blocks */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { RiArrowDropDownLine } from 'react-icons/ri';

import useQueryEventTable from '../../../hooks/useQuery/useQueryEventTable';
import EventDropdown from '../EventDropdown';
import useQueryEventOptions from '../../../hooks/useQuery/useQueryEventOptions';
import { ResponsiveDiv, Table, Thead, Th, Td, CenterDiv } from './style';


const EventsTable = () => {
  const { data: eventOptions, isLoading: eventOptionsLoading } = useQueryEventOptions();
  const { data: actionData, isLoading } = useQueryEventTable();
  const { t } = useTranslation();

	const events = eventOptions?.map((event: any) => {
		const actions = actionData?.filter((action: any) => action.tenantActionTrigger.triggeringEventType === event.name);
		return { ...event, actions };
	});

  const [tabToOpen, setTabToOpen] = useState<number>(-1);

  const handleOpenTabs = (index: number) => {
    if (tabToOpen === index) setTabToOpen(-1);
    else setTabToOpen(index);
  }

  return (
    <ResponsiveDiv>
        <Table>
            <Thead>
                <tr>
                    <Th>{t<string>('event.name')}</Th>
                    <Th>{t<string>('event.actions')}</Th>
                    <Th></Th>
                </tr>
            </Thead>
            {isLoading || eventOptionsLoading ? (
                <tr>
                    <td colSpan={6} height="283">
                        <CenterDiv>
                            <ThreeDots color="#647E93" height={50} width={50} />
                        </CenterDiv>
                    </td>
                </tr>
            ) : !eventOptions?.length ? (
                <tr>
                    <td colSpan={6} height="283">
                        <CenterDiv>
                            <h1>{t<string>('event.noEvents')}</h1>
                        </CenterDiv>
                    </td>
                </tr>
            ) : (
                <tbody>
                    {events?.map((event: any, index: number) => (
                        <>                            
                            <tr key={`${event.eventTypeId}-${index}`}>
                                <Td>{event.description}</Td>
                                <Td>{event.actions?.map((action: any, key: number) => {
                                    {!Array.isArray(action.tenantActionTrigger.actionTypesToTrigger) 
                                        && (action.tenantActionTrigger.actionTypesToTrigger = [action.tenantActionTrigger.actionTypesToTrigger])}
                                    
                                    return action.tenantActionTrigger.actionTypesToTrigger.map((actionToTrigger: any) => (
                                        <p key={`${key}-${index}`}>{`${actionToTrigger.action?.description}`}</p>
                                    ))
                                }
                               )}</Td>
                                <Td onClick={() => handleOpenTabs(index)}><RiArrowDropDownLine /></Td>
                            </tr>
                            <EventDropdown actions={event.actions} tabToOpen={tabToOpen} index={index} event={event} />
                        </>
                    ))}
                </tbody>
            )}
        </Table>
    </ResponsiveDiv>
  );
}

export default EventsTable;
