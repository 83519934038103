import styled from 'styled-components';

type Props = {
	isBarChart?: boolean;
}

export const CardContainer = styled.div<Props>`
	border-radius: 0.75rem;
	margin-left: 16px;	
	width: ${(props) => (props.isBarChart ? "36vw" : "28vw")};
	background-color: #fff;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	padding: 16px 24px;
	box-sizing: border-box;
	height: 364px;

	&:hover {
		transform: scale(1.02);
		transition: all 0.5s ease-in-out;
	}

	@media (max-width: 800px) {
		width: 90%;
	}
`;

export const Title = styled.div`
	font-size: 1rem;
	display: inline-block;
	margin-bottom: 16px;
`;