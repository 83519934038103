/* eslint-disable import/no-anonymous-default-export */
import { useQuery } from '@tanstack/react-query';

import tenantEventsService from '../../services/tenantEventService';

export default (() => (
    useQuery(['eventOptions'], async () => {
      const response = await tenantEventsService.getEventOptions();
      return response.data;
    })
));
