import Tooltip from '@mui/material/Tooltip';
import { AiFillInfoCircle } from 'react-icons/ai';

import { Container } from './style';

type Props = {
	text: string;
}

const CustomTooltip = ({ text }: Props) => (
	<Container>
		<Tooltip title={text} placement="right-start" arrow>
			<button onClick={(event) => event.preventDefault()}><AiFillInfoCircle /></button>
		</Tooltip>
	</Container>
);

export default CustomTooltip;
