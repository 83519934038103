import { IAndroidPassData, IWalletPassFieldMappings } from "@bambu-meta/interfaces";

import FieldList from "./FieldList";
import AndroidWalletPreview from "./AndroidWalletPreview";
import AndroidBackFields from "./AndroidBackFields";
import ImageAndColor from "./ImageAndColor";

import { Container } from "./style";
import { WalletTemplate } from "../../../types";

type Props = {
  androidWalletTemplate: IAndroidPassData;
  setAndroidWalletTemplate: (androidWalletTemplate: IAndroidPassData) => void;
  walletTemplate: WalletTemplate;
  fields: IWalletPassFieldMappings[];
  setFields: (fields: IWalletPassFieldMappings[]) => void;
  handleFieldRemove: (index: number, type: string) => void;
  isLoading: boolean;
}

const GoogleWallet = ({ 
  androidWalletTemplate,
  setAndroidWalletTemplate,
  walletTemplate,
  fields,
  setFields,
  handleFieldRemove,
}: Props) => {
  return (
    <Container>
      <FieldList
        fields={fields}
        setFields={setFields}
      />
      <AndroidWalletPreview
        androidWalletTemplate={androidWalletTemplate}
        setAndroidWalletTemplate={setAndroidWalletTemplate}
        walletTemplate={walletTemplate}
        handleFieldRemove={handleFieldRemove}
      />
      <AndroidBackFields
        androidWalletTemplate={androidWalletTemplate}
        handleFieldRemove={handleFieldRemove}
      />
      <ImageAndColor
        androidWalletTemplate={androidWalletTemplate}
        setAndroidWalletTemplate={setAndroidWalletTemplate}
      />
    </Container>
  )
}

export default GoogleWallet;
