import { ThreeDots } from "react-loader-spinner";

import { Table, Thead, Th, Tr, Td, CenterDiv } from "./style";

type Props = {
    tableHeaders: any[];
    tableData: any[];
    noDataText: string;
    loading?: boolean;
}

const TableComponent = ({ tableHeaders, tableData, noDataText, loading }: Props) => {
    return (
        <Table>
            <Thead>
                <tr>
                    {tableHeaders?.map((header: any, key: number) => (
                        <Th key={key}>{header}</Th>
                    ))}
                </tr>
            </Thead>
            {loading ? (
                <tr>
                    <td colSpan={6} height="283">
                        <CenterDiv>
                            <ThreeDots color="#647E93" height={50} width={50} />
                        </CenterDiv>
                    </td>
                </tr>
            ) : !tableData?.length ? (
                <tr>
                    <td colSpan={6} height="283">
                        <CenterDiv>
                            <h1>{noDataText}</h1>
                        </CenterDiv>
                    </td>
                </tr>
            ) : (
                <tbody>
                    {tableData?.map((item: any, key: number) => (
                        <Tr key={key}>
                            {Object.values(item).map((value: any, tdkey: number) => (
                                <Td key={tdkey}>{
                                    (typeof value !== 'string' && value?.length)
                                    ? value.length
                                    : value
                                }</Td>
                            ))}
                        </Tr>
                    ))}
                </tbody>
            )}
        </Table>
    );
};

export default TableComponent;
