import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { AppRoutesEnum } from '../../constants';
import cognitoService from '../../services/cognitoService';
import { PaddingDiv } from '../SignUp/styles';

export default function Unauthorized() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	function handleSignOut() {
		cognitoService.signOut().then(() => navigate(AppRoutesEnum.SIGN_IN));
	}

	return (
		<PaddingDiv>
			<h3>
				{t<string>('unauthorized.title')}
				<a href="https://support@bambumeta.com">{` support@bambumeta.com`}</a>
			</h3>
			<Button onClick={handleSignOut}>{t<string>('unauthorized.button')}</Button>
		</PaddingDiv>
	);
}
