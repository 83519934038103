import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowPathIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {
	IAndroidPassData,
	IAndroidRow,
	IWalletPassFieldMappings,
	IWalletTemplateInput,
	IWalletTemplatePassData,
	PassStyle,
	WalletTemplateDefinitions,
} from '@bambu-meta/interfaces';
import uuid from 'react-uuid';

import { TrashIcon } from '@heroicons/react/24/outline';

import '../../../tailwind.css';

import { DragNDrop, PassType, WalletInfo, Review } from './Steps';
import {
	AppleFrontTemplate,
	AppleBackTemplate,
	AndroidCard,
	AndroidDetailsCard,
} from './WalletCards';

import { useOnDragEnd } from '../../../hooks/dragAndDrop/useOnDragEnd';

import {
	AlertModal,
	ButtonSwitch,
	Card,
	H1 /** Typography */,
	LineBreak,
	Nav,
	P /** Typography */,
	PrimaryButton,
	ProgressIndicator,
	TopNavigation,
} from '../../../components/UpdatedUI';
import { WalletTemplate } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { AppRoutesEnum } from '../../../constants';

import AuthContext from '../../../context/AuthContext';
import walletService from '../../../services/walletService';
import showToast from '../../../components/Toast';

export const CreateWallet = () => {
	const navigate = useNavigate();

	const [isLoading, setLoading] = useState(false);

	const { t } = useTranslation();
	const { user } = useContext(AuthContext);
	const { tenantId } = user;

	const [activeStep, setActiveStep] = useState(0);
	const [isAndroid, setIsAndroid] = useState(false);
	const [isAppleBackFields, setIsAppleBackFields] = useState(false);
	const [isAndroidBackFields, setIsAndroidBackFields] = useState(false);

	const [isFlipping, setIsFlipping] = useState(false);
	const [isShowingCard, setIsShowingCard] = useState(false);

	const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	const [walletTemplate, setWalletTemplate] = useState<WalletTemplate>({
		name: '',
		passType: WalletTemplateDefinitions.generic,
		passTypeIdentifier: '',
		teamIdentifier: '',
		organizationName: '',
		description: '',
		passStyle: 'generic',
		images: { icon: '', logo: '' },
		sharingProhibited: false,
		backgroundColor: '#ffffff',
		labelColor: '#000000',
		foregroundColor: '#000000',
	});

	const [androidWalletTemplate, setAndroidWalletTemplate] =
		useState<IAndroidPassData>({
			title: '',
			header: '',
			hexBackgroundColor: '#ffffff',
			rows: [{ items: [] }, { items: [] }, { items: [] }],
			detailRows: [{ items: [] }, { items: [] }, { items: [] }, { items: [] }],
			images: { icon: '', logo: '' },
		});

	const [fields, setFields] = useState<IWalletPassFieldMappings[]>([
		{
			key: uuid(),
			label: 'Name',
			defaultValue: '',
			mappedValue: '{{person.firstName}} {{person.lastName}}',
			changeMessage: '',
		},
		{
			key: uuid(),
			label: 'Email',
			defaultValue: '',
			mappedValue: 'person.email',
			changeMessage: '',
		},
		{
			key: uuid(),
			label: 'Phone',
			defaultValue: '',
			mappedValue: 'person.phone',
			changeMessage: '',
		},
		{
			key: uuid(),
			label: 'Message',
			defaultValue: 'Custom Message Here',
			mappedValue: '',
			changeMessage: '%@',
		},
	]);

	const handleFlip = () => {
		setIsFlipping(true);

		if (isAndroid) {
			setIsAndroidBackFields(!isAndroidBackFields);
		} else {
			setIsAppleBackFields(!isAppleBackFields);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setIsFlipping(false);
		}, 500);
	}, [isAndroidBackFields, isAppleBackFields]);

	useEffect(() => {
		setTimeout(() => {
			setIsShowingCard(false);
		}, 500);
	}, [isAndroid]);

	const useOnDragEndHandler = (result: DropResult) => {
		useOnDragEnd(
			result,
			walletTemplate,
			setWalletTemplate,
			androidWalletTemplate,
			setAndroidWalletTemplate,
			fields
		);
	};

	const handleSubmit = () => {
		setLoading(true);

		let passStyle: PassStyle;
		const imagesType = ['logo', 'icon'];

		switch (walletTemplate.passType) {
			case WalletTemplateDefinitions.generic:
				passStyle = 'generic';
				imagesType.push('thumbnail');
				break;
			case WalletTemplateDefinitions.eventTicket:
				passStyle = 'eventTicket';

				if (walletTemplate?.images?.strip) {
					imagesType.push('strip');
				} else {
					imagesType.push('thumbnail');
					imagesType.push('background');
				}

				break;
			case WalletTemplateDefinitions.storeCard:
				passStyle = 'storeCard';
				imagesType.push('strip');
				break;
			case WalletTemplateDefinitions.coupon:
				passStyle = 'coupon';
				imagesType.push('strip');
				break;
			default:
				passStyle = 'generic';
				imagesType.push('thumbnail');
		}

		const passdata: IWalletTemplatePassData = {
			primaryFields: walletTemplate.primaryFields?.map((field) => field.key),
			secondaryFields: walletTemplate.secondaryFields?.map(
				(field) => field.key
			),
			auxiliaryFields: walletTemplate.auxiliaryFields?.map(
				(field) => field.key
			),
			backFields: walletTemplate.backFields?.map((field) => field.key),
			headerFields: walletTemplate.headerFields?.map((field) => field.key),
			backgroundColor: walletTemplate.backgroundColor,
			foregroundColor: walletTemplate.foregroundColor,
			labelColor: walletTemplate.labelColor,
			description: walletTemplate.description,
			passStyle,
			barCodes: walletTemplate.barCodes,
		};

		const images = Object.values(walletTemplate.images)?.map(
			(image: string, index: number) => {
				return {
					image: imagesType[index],
					imageFile: image,
				};
			}
		);

		const androidRows = androidWalletTemplate.rows.map((row: any) => {
			return {
				items: row.items.map((item: any) => ( item.key ))
			}
		});

		const androidBackRows = androidWalletTemplate.detailRows?.map(
			(row: any) => {
				return {
					items: row.items.map((item: any) => ( item.key ))
				}
			}
		);

		const androidPassData = {
			...androidWalletTemplate,
			rows: androidRows,
			detailRows: androidBackRows,
			images: undefined
		};

		const body: IWalletTemplateInput = {
			name: walletTemplate.name,
			passTypeIdentifier: walletTemplate.passTypeIdentifier,
			teamIdentifier: walletTemplate.teamIdentifier,
			organizationName: walletTemplate.organizationName,
			sharingProhibited: false,
			passdata,
			androidPassData,
			tenantId,
			fieldMappings: fields,
			images,
		};

		walletService
			.createWalletTemplate(tenantId, body)
			.then((res) => {
				showToast.success('Wallet template created successfully');
				navigate(AppRoutesEnum.WALLET_LIST);
			})
			.catch((err) => {
				showToast.error(err.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleNext = () => {
		if (activeStep === 1) {
			if (!walletTemplate.name) {
				showToast.error('Wallet Name is required');
				return;
			} else {
				setIsAndroid(false);
			}
		}

		if (activeStep === 2) {
			const androidRows = androidWalletTemplate.rows.filter(
				(row: IAndroidRow) => row.items.length > 0
			);

			const androidDetailRows = androidWalletTemplate.detailRows?.filter(
				(row: IAndroidRow) => row.items.length > 0
			);

			if (androidRows.length === 0 && androidDetailRows?.length === 0) {
				setIsAlertModalOpen(true);
				setAlertMessage(t<string>('walletCard.anyAndroidFilled'));
				return;
			}
		}

		if (activeStep === 3) {
			handleSubmit();
		} else if (activeStep < 3) {
			setActiveStep(activeStep + 1);
		}
		return;
	};

	const handlePrevious = () => {
		if (activeStep > 0) {
			setActiveStep(activeStep - 1);
		}
		return;
	};

	return (
		<DragDropContext onDragEnd={useOnDragEndHandler}>
			<div className="relative overflow-hidden">
				<Nav />
				<div className="px-2 md:px-[64px] pt-5 pb-10 max-w-[1200px] mx-auto">
					<TopNavigation
						routes={[
							{
								label: t<string>('walletCard.walletCardList'),
								path: AppRoutesEnum.WALLET_LIST,
							},
							{
								label: t<string>('walletCard.title'),
								path: AppRoutesEnum.WALLET_TEMPLATE_CREATION,
							},
						]}
					/>
					<div className="px-4 pt-2">
						<div className="flex justify-between items-center">
							<H1>Create Wallet Card</H1>
						</div>
						<LineBreak />
						{isLoading ? (
							<div className="w-10 h-10 mt-20 rounded-full mx-auto relative animate-spin bg-slate-500 flex justify-center items-center">
								<div className="bg-white w-7 h-7 rounded-full" />
								<div className="absolute top-0 left-0 w-4 h-4 bg-white" />
							</div>
						) : (
							<div className="mt-10 lg:flex flex-row-reverse justify-between items-start px-5">
								<div className="mb-10 lg:mb-0 lg:w-8/12 lg:ml-5">
									<Card className="!pt-2 h-[520px] w-[660px] flex-col flex justify-between">
										<div className="mx-10">
											{activeStep === 0 ? (
												<PassType
													walletTemplate={walletTemplate}
													setWalletTemplate={setWalletTemplate}
													androidWalletTemplate={androidWalletTemplate}
													setAndroidWalletTemplate={setAndroidWalletTemplate}
												/>
											) : activeStep === 1 ? (
												<WalletInfo
													walletTemplate={walletTemplate}
													setWalletTemplate={setWalletTemplate}
													androidWalletTemplate={androidWalletTemplate}
													setAndroidWalletTemplate={setAndroidWalletTemplate}
												/>
											) : activeStep === 2 ? (
												<DragNDrop
													setFields={setFields}
													fields={fields}
													walletTemplate={walletTemplate}
													setWalletTemplate={setWalletTemplate}
													androidWalletTemplate={androidWalletTemplate}
													setAndroidWalletTemplate={setAndroidWalletTemplate}
												/>
											) : (
												<Review
													walletTemplate={walletTemplate}
													androidWalletTemplate={androidWalletTemplate}
													isLoading={isLoading}
												/>
											)}
										</div>
										<div className="bg-bluejay-700 w-fill p-2 rounded-b-lg flex justify-between items-center">
											<button
												className="flex items-center text-xs cursor-pointer text-white font-sans font-bold px-4 py-2 bg-transparent hover:bg-bluejay-600 active:bg-bluejay-800 border-0 rounded-lg"
												disabled={activeStep <= 0}
												onClick={handlePrevious}
											>
												<ArrowLeftIcon className="h-4 w-4 text-white mr-2" />
												Previous Step
											</button>
											<ProgressIndicator
												active={activeStep}
												numSteps={4}
												onClick={setActiveStep}
											/>
											<PrimaryButton onClick={handleNext}>
												{activeStep < 3 ? 'Next Step' : 'Create Card'}
											</PrimaryButton>
										</div>
									</Card>
								</div>
								<div className="mx-auto max-w-[300px] mt-[80px] lg:mt-0">
									<P className="text-center mb-2">Type of Device</P>
									<ButtonSwitch
										isAndroid={isAndroid}
										setIsAndroid={setIsAndroid}
										setIsShowingCard={setIsShowingCard}
									/>
									<div className="mb-10 w-[260px]" />
									<div
										className={`relative w-[340px] h-[500px] rounded-lg ml-[-16px] ${
											isShowingCard ? 'overflow-hidden' : ''
										}`}
									>
										<div
											className={`[perspective:1000px] w-[300px] transition-all duration-500 
											${isAndroid ? 'ml-[-360px]' : 'ml-4'}`}
										>
											<div
												className={`h-full w-full transition-all duration-500 [transform-style:preserve-3d] ${
													isAppleBackFields ? '[transform:rotateY(180deg)]' : ''
												}`}
											>
												<div className="absolute inset-0 object-cover">
													<Card
														className={`${
															isAppleBackFields && !isFlipping ? 'hidden' : ''
														}
														${isAndroid && !isShowingCard ? 'hidden' : ''}
														!text-[${walletTemplate.labelColor}]
													`}
													>
														<AppleFrontTemplate
															walletTemplate={walletTemplate}
														/>
													</Card>
												</div>
												<div className="absolute inset-0 object-cover [backface-visibility:hidden] [transform:rotateY(180deg)]">
													<Card
														className={`${
															isAppleBackFields || isFlipping ? '' : 'hidden'
														}
														${isAndroid && !isShowingCard ? 'hidden' : ''}
														!bg-[${walletTemplate.backgroundColor}]
														!text-[${walletTemplate.labelColor}]
													`}
													>
														<AppleBackTemplate
															walletTemplate={walletTemplate}
														/>
													</Card>
												</div>
											</div>
										</div>
										<div
											className={`[perspective:1000px] w-[300px] transition-all duration-500 ${
												isAndroid ? 'ml-4' : 'ml-[360px]'
											}`}
										>
											<div
												className={`h-full w-full transition-all duration-500 [transform-style:preserve-3d] ${
													isAndroidBackFields
														? '[transform:rotateY(180deg)]'
														: ''
												}`}
											>
												<div className="absolute inset-0 object-cover">
													<Card
														className={`${
															isAndroidBackFields && !isFlipping ? 'hidden' : ''
														}
														${!isAndroid && !isShowingCard ? 'hidden' : ''}
														!bg-[${walletTemplate.backgroundColor}]
													`}
													>
														<AndroidCard
															androidWalletTemplate={androidWalletTemplate}
															walletTemplate={walletTemplate}
														/>
													</Card>
												</div>
												<div className="absolute inset-0 object-cover [backface-visibility:hidden] [transform:rotateY(180deg)]">
													<Card
														className={`${
															isAndroidBackFields || isFlipping ? '' : 'hidden'
														}
														${!isAndroid && !isShowingCard ? 'hidden' : ''}
														!bg-[${walletTemplate.backgroundColor}]
													`}
													>
														<AndroidDetailsCard
															androidWalletTemplate={androidWalletTemplate}
														/>
													</Card>
												</div>
											</div>
										</div>
									</div>
									<P className="text-center mb-2 -mt-5">
										{(!isAndroid && isAppleBackFields) ||
										(isAndroid && isAndroidBackFields)
											? 'Back of Card'
											: 'Front of Card'}
									</P>
									<PrimaryButton
										className="mx-auto px-10"
										intent="secondary"
										onClick={handleFlip}
									>
										<ArrowPathIcon className="h-6 w-6 text-white" />
									</PrimaryButton>
								</div>
								<Droppable droppableId="bin">
									{(provided, snapshot) => (
										<div className="h-[600px] flex items-center mr-10 max-lg:h-0">
											<div
												className={`w-12 h-12 flex justify-center items-center 
												hover:bg-stone-100 border-2 border-dashed border-stone-200 rounded-lg
												max-lg:mt-[-700px]
												${snapshot.isDraggingOver ? 'shadow-xl bg-stone-100' : ''}
											`}
												ref={provided.innerRef}
												{...provided.droppableProps}
											>
												<TrashIcon
													className={`w-8 h-8 p-1 ${
														snapshot.isDraggingOver
															? 'text-bluejay-500'
															: 'text-bluejay-200'
													}`}
												/>
											</div>
										</div>
									)}
								</Droppable>
							</div>
						)}
					</div>
				</div>
			</div>
			<AlertModal
				modalIsOpen={isAlertModalOpen}
				setIsOpen={setIsAlertModalOpen}
				activeStep={activeStep}
				setActiveStep={setActiveStep}
				message={alertMessage}
			/>
		</DragDropContext>
	);
};
