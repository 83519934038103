import { Draggable, Droppable } from 'react-beautiful-dnd';

import { Widget, InnerText, Img } from '../../../../components';
import {
	Generic,
	StoreCardAndCoupon,
	EventTicket,
} from '../../../../components/UpdatedUI/ApplePasses';
import { WalletTemplate } from '../../../../types';

type Props = {
	walletTemplate: WalletTemplate;
	isReview?: boolean;
};

export const AppleFrontTemplate = ({ walletTemplate, isReview }: Props) => {
	return (
		<div
			style={{
				backgroundColor: walletTemplate.backgroundColor,
				color: walletTemplate.labelColor,
			}}
			className="rounded-lg !p-5"
		>
			<div className="h-[408px] relative">
				<div className="flex">
					{walletTemplate?.images?.logo ? (
						<div className="overflow-hidden rounded-lg w-10 h-10 mr-[100px] mb-4">
							<Img src={walletTemplate?.images?.logo} className="" alt="logo" />
						</div>
					) : (
						<Widget className="w-10 h-10 mr-[100px]">
							<InnerText className="-rotate-45">Logo</InnerText>
						</Widget>
					)}
					{isReview ? (
						walletTemplate.headerFields?.length ? (
							<div>
								{walletTemplate.headerFields.map((field, index) => (
									<div key={index} className="rounded-lg h-10 w-[120px]">
										<p>{field.label}</p>
										<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden"
											style={{
												color: walletTemplate.foregroundColor,
											}}
										>
											{(field.mappedValue as string) ||
												(field.defaultValue as string)}
										</p>
									</div>
								))}
							</div>
						) : (
							<Widget className="w-[120px] h-10">
								<InnerText>Header</InnerText>
							</Widget>
						)
					) : (
						<Droppable droppableId="headerField">
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
									className={`${
										snapshot.isDraggingOver
											? 'rounded-lg h-10 shadow-xl border-dashed'
											: 'rounded-lg h-10'
									}`}
								>
									{walletTemplate.headerFields?.length ? (
										<div>
											{walletTemplate.headerFields.map((field, index) => (
												<Draggable
													key={index}
													draggableId={`headerField ${index}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`
																${
																	snapshot.isDragging
																		? 'rounded-lg h-10 w-[120px] shadow-xl border-2 px-2 border-dashed border-stone-200'
																		: 'rounded-lg h-10 w-[120px]'
																} 
																`}
															style={{
																top: `${snapshot.isDragging ? '0px' : '0px'}`,
																right: `${snapshot.isDragging ? '0px' : '0px'}`,
																transition:
																	provided?.draggableProps?.style?.transition,
																transform:
																	provided?.draggableProps?.style?.transform,
															}}
														>
															<p>{field.label ?? ''}</p>
															<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden"
																style={{
																	color: walletTemplate.foregroundColor,
																}}
															>
																{(field.mappedValue as string) ||
																	(field.defaultValue as string)
																}
															</p>
														</div>
													)}
												</Draggable>
											))}
										</div>
									) : (
										<Widget className="w-[120px] h-10">
											<InnerText>Header</InnerText>
										</Widget>
									)}
								</div>
							)}
						</Droppable>
					)}
				</div>
				{walletTemplate.passStyle === 'generic' && (
					<Generic walletTemplate={walletTemplate} isReview={isReview} />
				)}
				{(walletTemplate.passStyle === 'coupon' ||
					walletTemplate.passStyle === 'storeCard') && (
					<StoreCardAndCoupon
						walletTemplate={walletTemplate}
						isReview={isReview}
					/>
				)}
				{walletTemplate.passStyle === 'eventTicket' && (
					<EventTicket walletTemplate={walletTemplate} isReview={isReview} />
				)}
				<div className="w-full flex justify-center">
					{walletTemplate?.barCodes?.[0]?.format === 'PKBarcodeFormatQR' ||
					walletTemplate?.barCodes?.[0]?.format === 'PKBarcodeFormatAztec' ? (
						<Widget className="absolute w-20 h-20 bottom-0">
							<InnerText>Barcode</InnerText>
						</Widget>
					) : (
						(walletTemplate?.barCodes?.[0]?.format ===
							'PKBarcodeFormatPDF417' ||
							walletTemplate?.barCodes?.[0]?.format ===
								'PKBarcodeFormatCode128') && (
							<Widget className="absolute w-full h-20 bottom-0">
								<InnerText>Barcode</InnerText>
							</Widget>
						)
					)}
				</div>
			</div>
		</div>
	);
};
