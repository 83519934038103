import styled from 'styled-components';

export const Background = styled.div`
	width: calc(100vw - 208px);
background-color: #F5F5F6;
	height: calc(100vh - 170px);
	box-sizing: border-box;
	margin: -16px 0 0 -16px;
	color: #647E93;

	h1 {
		font-size: 2rem;
		padding: 24px;
	}
`;

export const Container = styled.div`
	margin: 24px;
	border-radius: 24px;
	background-color: #F4F6F8;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);

	@media (max-width: 768px) {
		margin: 0 0.5rem 0 0;
	}
`;
