import { WalletTemplate } from "../../../types";
import { Container } from "./style";
import WalletPreview from "../Preview";

import FieldList from "./FieldList";
import ImageAndColor from "./ImageAndColor";
import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";

type Props = {
    walletTemplate: WalletTemplate;
    setWalletTemplate: (walletTemplate: WalletTemplate) => void;
    fields: IWalletPassFieldMappings[];
    setFields: (fields: IWalletPassFieldMappings[]) => void;
    handleFieldRemove: (index: number, type: string) => void;
}

const CustomizeWallet = ({ 
    walletTemplate, 
    setWalletTemplate, 
    fields, 
    setFields,
    handleFieldRemove,
}: Props) => {
    return (
        <div style={{ margin: "12px 0" }}>
            <Container>
                <FieldList 
                    fields={fields}
                    setFields={setFields}
                />
                <WalletPreview
                    walletTemplate={walletTemplate}
                    setWalletTemplate={setWalletTemplate}
                    handleFieldRemove={handleFieldRemove}
                />
                <WalletPreview
                    walletTemplate={walletTemplate}
                    setWalletTemplate={setWalletTemplate}
                    handleFieldRemove={handleFieldRemove}
                    isBack={true}
                />
                <ImageAndColor walletTemplate={walletTemplate} setWalletTemplate={setWalletTemplate} />
            </Container>
        </div>
    )
}

export default CustomizeWallet;
