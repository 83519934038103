import styled from 'styled-components';

type Props = {
	width?: string;
	color?: string;
	backgroundColor?: string;
	fontSize?: string;
}

export const StyledButton = styled.button<Props>`
	border-radius: 100px;
	border: none;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	color: ${({ color }) => color || '#fff'};
	background-color: ${({ backgroundColor }) => backgroundColor || '#82A3C1'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	font-weight: 900;
	height: 32px;
	min-width: ${({ width }) => width || '120px'};
	cursor: pointer;
	transition: background-color 0.3s, box-shadow 0.3s;

	&:focus {
		outline: none;
	}
`;

export const StyledFbButton = styled(StyledButton)<Props>`
	background-color: #5890ff;
	color: white;
	font-weight: bold;
	font-size: 1.2em;
	margin-bottom: 12px;
	width: ${({ width }) => width || '120px'};
	min-width: ${({ width }) => width || '120px'};

	svg {
		margin-right: 10px;
		font-size: 18px;
	}
`;

export const StyledGoogleButton = styled(StyledButton)<Props>`
	transition: background-color 0.3s, box-shadow 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25) !important;
	color: #757575;
	width: ${({ width }) => width || '120px'};
	font-size: 14px;
	font-weight: 500;
	background-color: #eeeeee;

	img {
		width: 20px;
		margin-bottom: 3px;
		margin-right: 5px;
		content: url('https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png');
	}

	&:focus {
		outline: none;
		box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
			0 0 0 3px #c8dafc !important;
	}

	&:disabled {
		filter: grayscale(100%);
		background-color: #ebebeb;
		box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25) !important;
		cursor: not-allowed;
	}

	svg {
		margin-right: 10px;
		font-size: 18px;
	}
`;
