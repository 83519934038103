import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from  'react-modal' ;

import AuthContext from '../../../context/AuthContext';
import tenantEventService from '../../../services/tenantEventService';

import { EventType } from '../../../types';
import showToast from '../../Toast';
import { modalStyles, StyledModal } from './style';

type Props = {
    modalIsOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    event: EventType;
    actionId: string;
}

const DeleteEventModal = ({ modalIsOpen, setIsOpen, event, actionId }: Props) => {
    const [disable, setDisable] = useState<boolean>(false);

    const { user } = useContext(AuthContext);
    const { t } = useTranslation();

    const handleDelete = (actionId: string) => {
        setDisable(true);

        tenantEventService.deleteEventConfig(user?.tenantId, actionId)
            .then(() => {
                window.location.reload();
            })
            .catch((err) => {
                setIsOpen(false);
                showToast.error(t<string>('toast.deleteEventFailed'));
            })
            .finally(() => {
                setDisable(false);
            }
        );
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={modalStyles}
            contentLabel="Event Deletion Modal"
        >
            <StyledModal>
                <h2>{t<string>('event.modal.deleteTitle')}</h2>
                <p>({event.description})</p>
                <div>
                    <button className='cancel' onClick={() => setIsOpen(false)}>{t<string>('event.modal.cancelButton')}</button>
                    <button onClick={() => handleDelete(actionId)} disabled={disable}>{t<string>('event.modal.deleteButton')}</button>
                </div>
            </StyledModal>
        </Modal>
    );
}

export default DeleteEventModal;