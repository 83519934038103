import { IAndroidPassData, IWalletPassFieldMappings, WalletTemplateDefinitions } from "@bambu-meta/interfaces";

import { WalletTemplate } from "../../types";

export const onRepopulateTemplateData = (template: any) => {
  const passType = WalletTemplateDefinitions[template.passdata?.passStyle as keyof typeof WalletTemplateDefinitions];

  const headerFields = template?.passdata?.headerFields?.map((field: string) => {
      let fieldMapped: any = {};

      template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
          if (mapping.key === field) {
              fieldMapped.key = mapping.key;
              fieldMapped.label = mapping.label;
              fieldMapped.defaultValue = mapping.defaultValue;
              fieldMapped.mappedValue = mapping.mappedValue;
              fieldMapped.changeMessage = mapping.changeMessage;
          }
      });
      return fieldMapped as IWalletPassFieldMappings;
  });

  const primaryFields = template?.passdata?.primaryFields?.map((field: string) => {
      let fieldMapped: any = {};

      template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
          if (mapping.key === field) {
              fieldMapped.key = mapping.key;
              fieldMapped.label = mapping.label;
              fieldMapped.defaultValue = mapping.defaultValue;
              fieldMapped.mappedValue = mapping.mappedValue;
              fieldMapped.changeMessage = mapping.changeMessage;
          }
      });
      return fieldMapped as IWalletPassFieldMappings;
  });

  const secondaryFields = template?.passdata?.secondaryFields?.map((field: string) => {
      let fieldMapped: any = {};
      
      template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
          if (mapping.key === field) {
              fieldMapped.key = mapping.key;
              fieldMapped.label = mapping.label;
              fieldMapped.defaultValue = mapping.defaultValue;
              fieldMapped.mappedValue = mapping.mappedValue;
              fieldMapped.changeMessage = mapping.changeMessage;
          }
      });
      return fieldMapped as IWalletPassFieldMappings;
  });

  const auxiliaryFields = template?.passdata?.auxiliaryFields?.map((field: string) => {
      let fieldMapped: any = {};
      
      template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
          if (mapping.key === field) {
              fieldMapped.key = mapping.key;
              fieldMapped.label = mapping.label;
              fieldMapped.defaultValue = mapping.defaultValue;
              fieldMapped.mappedValue = mapping.mappedValue;
              fieldMapped.changeMessage = mapping.changeMessage;
          }
      });
      return fieldMapped as IWalletPassFieldMappings;
  });

  const backFields = template?.passdata?.backFields?.map((field: string) => {
      let fieldMapped: any = {};

      template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
          if (mapping.key === field) {
              fieldMapped.key = mapping.key;
              fieldMapped.label = mapping.label;
              fieldMapped.defaultValue = mapping.defaultValue;
              fieldMapped.mappedValue = mapping.mappedValue;
              fieldMapped.changeMessage = mapping.changeMessage;
          }
      });
      return fieldMapped as IWalletPassFieldMappings;
  });

  const rows = template?.androidPassData?.rows?.map((row: any) => {
      const rowMapped: any = [];

      row.items?.forEach((item: string) => {
          template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
              if (mapping.key === item) {
                  rowMapped.push(mapping);
              }
          });
      })

      return { items: rowMapped }
  });

  const detailRows = template?.androidPassData?.detailRows?.map((row: any) => {
      const rowMapped: any = [];

      row.items?.forEach((item: string) => {
          template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
              if (mapping.key === item) {
                  rowMapped.push(mapping);
              }
          });
      })

      return { items: rowMapped }
  });

  const iosWalletTemplate: WalletTemplate = {
      name: template.androidPassData?.title as string,
      passTypeIdentifier: template.passTypeIdentifier,
      teamIdentifier: template.teamIdentifier,
      organizationName: template.organizationName,
      description: template.passdata?.description,
      passType,
      passStyle: template.passdata?.passStyle,
      images: template?.passdata?.images,
      backgroundColor: template?.passdata?.backgroundColor as string,
      foregroundColor: template?.passdata?.foregroundColor as string,
      labelColor: template?.passdata?.labelColor as string,
      headerFields: headerFields as unknown as IWalletPassFieldMappings[],
      primaryFields: primaryFields as unknown as IWalletPassFieldMappings[],
      secondaryFields: secondaryFields as unknown as IWalletPassFieldMappings[],
      auxiliaryFields: auxiliaryFields as unknown as IWalletPassFieldMappings[],
      backFields: backFields as unknown as IWalletPassFieldMappings[],
      barCodes: template?.passdata?.barCodes as any,
  }

  const androidWalletTemplate: IAndroidPassData = {
      title: template.androidPassData?.title as string,
      header: template.androidPassData?.header as string,
      subheader: template.androidPassData?.subheader as string,
      hexBackgroundColor: template.androidPassData?.hexBackgroundColor as string,
      rows: rows as any,
      detailRows: detailRows as any,
      images: template.androidPassData?.images as any,
  }

  return {
    iosWalletTemplate,
    androidWalletTemplate,
  }
}
