type Props = {
	tableHeaders: any[];
	tableData: any[];
	noDataText: string;
	loading?: boolean;
};

export const Table = ({
	tableHeaders,
	tableData,
	noDataText,
	loading,
}: Props) => {
	return (
		<table className="w-full">
			<thead className="">
				<tr className="">
					{tableHeaders?.map((header: any, key: number) => (
						<th className={`${key === 0 ? "flex justify-items-start ml-5" : ""}`} key={key}>
							{header}
						</th>
					))}
				</tr>
			</thead>
			{loading ? (
				<tr>
					<td className="text-center mx-auto" colSpan={6} height="283">
						<div className="w-10 h-10 rounded-full mx-auto relative animate-spin bg-slate-500 flex justify-center items-center">
							<div className="bg-white w-7 h-7 rounded-full" />
							<div className='absolute top-0 left-0 w-4 h-4 bg-white' />
						</div>
					</td>
				</tr>
			) : !tableData?.length ? (
				<tr>
					<td className="text-center" colSpan={6} height="283">
						<p>{noDataText}</p>
					</td>
				</tr>
			) : (
				<tbody>
					{tableData?.map((item: any, key: number) => (
						<tr className="odd:bg-stone-100" key={key}>
							{Object.values(item).map((value: any, tdkey: number) => (
								<td className="" key={tdkey}>
									{typeof value !== 'string' && value?.length
										? value.length
										: value}
								</td>
							))}
						</tr>
					))}
				</tbody>
			)}
		</table>
	);
};
