import {
	BarcodeFormat,
	IWalletPassBarcodeDescriptor,
} from '@bambu-meta/interfaces';
import { useTranslation } from 'react-i18next';

import {
	H4,
	InputGroup,
	LineBreak,
	SelectInput,
} from '../../../../../components';
import { WalletTemplate } from '../../../../../types';

type Props = {
	walletTemplate: WalletTemplate;
	setWalletTemplate: (walletTemplate: WalletTemplate) => void;
};

export const Barcode = ({ walletTemplate, setWalletTemplate }: Props) => {
	const { t } = useTranslation();

	const barOptions = [
		t<string>('walletCard.description.selectType'),
		'PKBarcodeFormatQR',
		'PKBarcodeFormatPDF417',
		'PKBarcodeFormatAztec',
		'PKBarcodeFormatCode128',
	];

	const handleBarChange = (e: any) => {
		if (e.target.value === t<string>('walletCard.description.selectType')) {
			setWalletTemplate({ ...walletTemplate, barCodes: undefined });
			return;
		}

		const barCode: IWalletPassBarcodeDescriptor = {
			format: e.target.value as BarcodeFormat,
			altText: {
				defaultValue: '',
				mappedValue: '',
			},
			message: {
				defaultValue: '',
				mappedValue: '',
			},
		};

		setWalletTemplate({ ...walletTemplate, barCodes: [barCode] });
	};

	const handleBarInputs = (value: string, input: string) => {
		if (input === 'altTextDefault') {
			const bar = walletTemplate?.barCodes?.[0];
			if (bar) {
				bar.altText.defaultValue = value;
				setWalletTemplate({ ...walletTemplate, barCodes: [bar] });
			}
		} else if (input === 'altTextMapped') {
			const bar = walletTemplate?.barCodes?.[0];
			if (bar) {
				bar.altText.mappedValue = value;
				setWalletTemplate({ ...walletTemplate, barCodes: [bar] });
			}
		} else if (input === 'messageDefault') {
			const bar = walletTemplate?.barCodes?.[0];
			if (bar) {
				bar.message.defaultValue = value;
				setWalletTemplate({ ...walletTemplate, barCodes: [bar] });
			}
		} else if (input === 'messageMapped') {
			const bar = walletTemplate?.barCodes?.[0];
			if (bar) {
				bar.message.mappedValue = value;
				setWalletTemplate({ ...walletTemplate, barCodes: [bar] });
			}
		}
	};
	
	return (
		<>
			<H4 className="mb-2">Select The Barcode Type</H4>
			<LineBreak />
			<div className="mt-5 h-[284px] w-[580px] pr-4 overflow-y-auto pb-10">
				<SelectInput
					className="mb-4"
					id="select_barcodeType"
					label="Barcode Type"
					onChange={handleBarChange}
					value={walletTemplate?.barCodes?.[0]?.format}
				>
					{barOptions.map((option) => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</SelectInput>
				<div className="flex justify-between gap-4">
					<InputGroup
						className="mb-4 w-1/2"
						id="input_Alt"
						label="Alt Text"
						onChange={(e) => handleBarInputs(e.target.value, 'altTextDefault')}
						placeholder="Alt Text"
						value={walletTemplate?.barCodes?.[0]?.altText?.defaultValue || ''}
					/>
					<InputGroup
						className="mb-4 w-1/2"
						id="input_AltMapped"
						label="Alt Text Mapped Value"
						onChange={(e) => handleBarInputs(e.target.value, 'altTextMapped')}
						placeholder="Alt Text Mapped Value"
						value={walletTemplate?.barCodes?.[0]?.altText?.mappedValue || ''}
					/>
				</div>
				<div className="flex justify-between mb-0 gap-4">
					<InputGroup
						className="mb-4 w-1/2"
						id="input_Message"
						label="Message Text"
						onChange={(e) => handleBarInputs(e.target.value, 'messageDefault')}
						placeholder="Message Text"
						value={walletTemplate?.barCodes?.[0]?.message?.defaultValue || ''}
					/>
					<InputGroup
						className="mb-4 w-1/2"
						id="input_MessageMapped"
						label="Message Text Mapped Value"
						onChange={(e) => handleBarInputs(e.target.value, 'messageMapped')}
						placeholder="Message Text Mapped Value"
						value={walletTemplate?.barCodes?.[0]?.message?.mappedValue || ''}
					/>
				</div>
			</div>
		</>
	);
};
