import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { PrimaryButton } from './Button/PrimaryButton';

interface AlertModalProps {
  modalIsOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
  message: string;
}

const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(95, 136, 175, .5)',
		zIndex: 999999,
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '.5rem',
		border: 'none',
	},
};

export const AlertModal = ({ modalIsOpen, setIsOpen, activeStep, setActiveStep, message }: AlertModalProps) => {
  const { t } = useTranslation();

  const handleContinue = () => {
    setIsOpen(false);
    setActiveStep(activeStep + 1);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={modalStyles}
    >
      <div className='w-[400px] px-10 font-regular text-center'>
        <p className='text-xl'>{message}</p>
        <div className='flex justify-around mt-4'>
          <PrimaryButton className='w-[140px]' onClick={() => setIsOpen(false)}>{t<string>('walletCard.stayOnPage')}</PrimaryButton>
          <PrimaryButton className='w-[140px]' onClick={handleContinue}>{t<string>('walletCard.continue')}</PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}