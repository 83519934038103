import styled from 'styled-components';

export const Container = styled.div`
    display: inline-block;
    cursor: pointer;
    z-index: 99999999;
    color: #647E93;

    button {
        border: 0;
        background: transparent;
        cursor: pointer;
        outline: none;
        color: #647E93;
    }
`;