import { primaryButtonStyles, secondaryButtonStyles } from '../Button';

type Props = {
	isAndroid: boolean;
	setIsAndroid: (isAndroid: boolean) => void;
	setIsShowingCard: (isShowingCard: boolean) => void;
}

export const ButtonSwitch = ({ isAndroid, setIsAndroid, setIsShowingCard }: Props) => {
	const handleSwitch = (number: number) => {
		setIsAndroid(number === 1 ? true : false);
		setIsShowingCard(true);
	}

	return (
		<div className="flex justify-center">
			<button
				className={
					!isAndroid
						? primaryButtonStyles + ' bg-bluejay-700 w-1/2 rounded-r-none'
						: secondaryButtonStyles + ' w-1/2 rounded-r-none'
				}
				onClick={() => handleSwitch(0)}
			>
				Apple
			</button>
			<button
				className={
					isAndroid
						? primaryButtonStyles + ' bg-bluejay-700 w-1/2 rounded-l-none'
						: secondaryButtonStyles + ' w-1/2 rounded-l-none'
				}
				onClick={() => handleSwitch(1)}
			>
				Android
			</button>
		</div>
	);
};
