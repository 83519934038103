import { useContext, useState } from 'react';
import AuthContext from '../../context/AuthContext';
import Card from '../StandardCard';

import { PanelContainer } from './style';

const MainPanel = () => {
	const { user } = useContext(AuthContext);
	const { groups } = user;

	const [hasCard, setHasCard] = useState(false);

	if (!groups.includes('SuperAdmin') && !groups.includes('Dashboard')) {
		return <></>;
	}

	return (
		<div className="row clearfix row-deck">
			<PanelContainer className="col-lg-12 col-md-12">
				{hasCard && <Card />}
			</PanelContainer>
		</div>
	);
};

export default MainPanel;
