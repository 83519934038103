import styled from "styled-components";

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '.5rem',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
	},
};

export const StyledModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.1rem;

    h2 {
        margin-bottom: .25rem;
        font-size: 1.5rem;
    }

    button {
        border: none;
        border-radius: .25rem;
        padding: .5rem;
        font-size: 1rem;
        background-color: #417082;
        color: #fff;
        cursor: pointer;
        margin: 1rem 1rem 0 0;
        width: 10rem;


        &:hover {
            background-color: #51899e;
        }

        &:active {
            background-color: #0062cc;
        }

        &.cancel {
            background-color: #d9534f;
            color: #fff;
        }

        &.cancel:hover {
            background-color: #b56559;
        }
    }

    @media (max-width: 905px) {
        width: 90vw;
        min-width: 0;
        padding: 0 1rem;
        box-sizing: border-box;

        h2 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1rem;
        }
    }
`;
