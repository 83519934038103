import styled from 'styled-components';

export const TokenNavigationContainer = styled.nav`
	display: flex;
	justify-content: space-between;

	div {
		color: rgba(100, 126, 147, 0.5);
		margin: 12px;
		font-size: 2.5rem;

		svg {
			font-size: 3rem;
			margin-left: -48px;
			margin-bottom: -10px;
			color: #AECA37;
		}

		&:last-child {
			margin-right: 100px;
		}
	}

	.selected {
		color: #647E93;
		font-weight: 600;
	}

	div:first-child {
		margin-left: 100px;
	}

	@media (max-width: 768px) {
		div:first-child {
			margin-left: 0.5rem;
		}
	}

	@media (max-width: 470px) {
		div {
			width: 15%;
		}
	}

	@media (max-width: 340px) {
		div {
			width: 13%;
		}
	}
`;
