/* eslint-disable import/no-anonymous-default-export */
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import tokenService from '../../services/tokenService';

export default () => {
	const { user } = useContext(AuthContext);

	return useQuery(['tokens', user.tenantId], async () => {
		const response = await tokenService.getTokens(user?.tenantId);
		return response.data;
	});
};
