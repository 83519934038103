import { PlusIcon } from '@heroicons/react/24/solid';

type CreateNewProps = {
	className?: string;
	onClick: () => void;
};

export const CreateNewField = ({ className, onClick }: CreateNewProps) => {
	return (
		<button
			className={`pl-3 py-1 w-full border-2 cursor-pointer bg-white hover:bg-stone-50 active:bg-stone-100 text-stone-400 font-bold font-sans border-dashed border-stone-200 rounded-lg flex justify-between items-center ${className}`}
			onClick={onClick}
		>
			Create your Own...
			<PlusIcon className="w-7 h-7 text-stone-600 p-1 hover:bg-stone-200 active:bg-stone-300 rounded-lg" />
		</button>
	);
};
