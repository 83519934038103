import { IAndroidPassData, WalletTemplateDefinitions } from '@bambu-meta/interfaces';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	ButtonSelect,
	H2,
	P,
	StepCounterText,
} from '../../../../components/UpdatedUI';
import { WalletTemplate } from '../../../../types';

type Props = {
	walletTemplate: WalletTemplate;
	setWalletTemplate: (template: WalletTemplate) => void;
	androidWalletTemplate: IAndroidPassData;
	setAndroidWalletTemplate: (androidWalletTemplate: IAndroidPassData) => void;
}

export const PassType = ({ walletTemplate, setWalletTemplate, androidWalletTemplate, setAndroidWalletTemplate }: Props) => {
	const { t } = useTranslation();

	const numberActive = walletTemplate.passType === WalletTemplateDefinitions.generic ? 0
		: walletTemplate.passType === WalletTemplateDefinitions.storeCard ? 1
		: walletTemplate.passType === WalletTemplateDefinitions.coupon ? 2
		: walletTemplate.passType === WalletTemplateDefinitions.eventTicket ? 3
		: 0;

	const [active, setActive] = useState(numberActive);

	const typeOptions = Object.keys(WalletTemplateDefinitions);
  const options = typeOptions.map((option) => t<string>(`walletCard.description.${option}`) !== "Boarding Pass"
    ? t<string>(`walletCard.description.${option}`) : null).filter((option) => option !== null );

	const handleButtonClick = (index: number) => {
		setActive(index);

		if (active !== index) {
			setWalletTemplate({
				...walletTemplate,
				primaryFields: [],
				secondaryFields: [],
				backFields: [],
				auxiliaryFields: [],
				headerFields: [],
				images: {
					icon: '',
					logo: '',
					thumbnail: '',
					background: '',
					footer: '',
					strip: '',

				},
			});

			setAndroidWalletTemplate({
				...androidWalletTemplate,
				rows: [{
					items: [],
				}, {
					items: [],
				}, {
					items: [],
				}],
				detailRows: [{
					items: [],
				}, {
					items: [],
				}, {
					items: [],
				}, {
					items: [],
				}],
				images: {
					icon: '',
					logo: '',
					strip: '',
				}
			});
		}

		switch(index) {
			case 0:
				setWalletTemplate({
					...walletTemplate,
					passType: WalletTemplateDefinitions.generic,
					passStyle: 'generic',
				});
				break;
			case 1:
				setWalletTemplate({
					...walletTemplate,
					passType: WalletTemplateDefinitions.storeCard,
					passStyle: 'storeCard',
				});
				break;
			case 2:
				setWalletTemplate({
					...walletTemplate,
					passType: WalletTemplateDefinitions.coupon,
					passStyle: 'coupon',
				});
				break;
			case 3:
				setWalletTemplate({
					...walletTemplate,
					passType: WalletTemplateDefinitions.eventTicket,
					passStyle: 'eventTicket',
				});
				break;
			default:
				break;
		}
	}

	const ButtonSelectGroup = () => {
		return (
			<div className="flex justify-between gap-4 flex-wrap">
				{options.map((option, index) => {
					return (
						<ButtonSelect
							active={active === index}
							className="min-w-[48%] h-[120px] block text-2xl"
							onClick={() => handleButtonClick(index)}
						>
							{option}
						</ButtonSelect>
					);
				})}
			</div>
		);
	};

	return (
		<div className='h-[508px]'>
			<div className="md:flex justify-between mb-5">
				<div className="mb-10">
					<H2 className="mb-5">{t<string>('walletCard.passSelectNew.title')}</H2>
					<P className="w-8/12 sm:w-full md:w-8/12">
					{t<string>('walletCard.passSelectNew.description')}
					</P>
				</div>
				<StepCounterText className="mb-5">{t<string>('walletCard.passSelectNew.step')}</StepCounterText>
			</div>
			<ButtonSelectGroup />
		</div>
	);
};
