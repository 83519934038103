import { RiArrowRightSFill } from 'react-icons/ri';

import { Container } from './style';
import { ProductCreationTabs } from '../../../types';

type Props = {
	tabs: ProductCreationTabs[];
	activeTab: string;
};

const ProductNavigationBar = ({ tabs, activeTab }: Props) => (
	<Container>
		{tabs.map((element, index) => (
			<div className={activeTab === element.name ? 'active' : ''} key={index}>
				{activeTab === element.name && <RiArrowRightSFill />}
				{element.name}
			</div>
		))}
	</Container>
);

export default ProductNavigationBar;
