import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductResponse } from '../../../types';
import ProductDetails from '../ProductDetails';

import { InfoDiv, ProductCardDiv, Strong, DetailsDiv } from './style';
import Button from '../../Button';

type ProductCardTypes = {
	product: ProductResponse;
};

const ProductCard = ({ product }: ProductCardTypes) => {
	const { t } = useTranslation();

	const [modalIsOpen, setIsOpen] = useState(false);

	return (
		<ProductCardDiv>
			<h1 style={{ margin: 20 }}>{product.name}</h1>
			<ProductImage image={product?.image} />
			<InfoDiv>
				<InfoAttribute label={t<string>('productList.card.id')} value={product?.productId || ""} />
				<InfoAttribute label={t<string>('productList.card.tokenName')} value={product?.token?.name || ""} />
				<InfoAttribute label={t<string>('productList.card.chain')} value={product?.token?.ledgerType || ""} />
				<InfoAttribute label={t<string>('productList.card.chainId')} value={product?.token?.externalTokenId || ""} />
			</InfoDiv>
			<DetailsDiv>
				<Button
					onClick={() => setIsOpen(true)}
				>{t<string>('productList.card.details')}</Button>
			</DetailsDiv>
			<ProductDetails
				product={product}
				modalIsOpen={modalIsOpen}
				setIsOpen={setIsOpen}
			/>
		</ProductCardDiv>
	);
};

type productImageProps = {
	image?: string;
};

const ProductImage = ({ image }: productImageProps) => (
	<div style={{ width: '100%', height: 310, marginBottom: 16, display: "flex", justifyContent: "center" }}>
		<img
			style={{ maxWidth: '100%', maxHeight: '100%' }}
			src={
				image || 'https://www.incathlab.com/images/products/default_product.png'
			}
			alt="product"
		/>
	</div>
);

type infoAttributeTypes = {
	label: string;
	value: string;
};

export const InfoAttribute = ({ label, value }: infoAttributeTypes) => (
	<div style={{ display: 'flex', marginBottom: 4 }}>
		<Strong>{label}:</Strong>
		<span>{value}</span>
	</div>
);

export default ProductCard;
