import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { AiOutlineEdit } from "react-icons/ai";

import EditFieldModal from "./EditFieldModal";
import { Drag } from "./style";

type Props = {
    fields: IWalletPassFieldMappings[];
    setFields: (fields: IWalletPassFieldMappings[]) => void;
    index: number;
}

const DraggableField = ({ fields, index, setFields }: Props) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFields = [...fields];
        newFields[index].defaultValue = e.target.value;
        setFields(newFields);
    }

    return (
        <> 
            <Draggable draggableId={index.toString()} index={index}>
                {(provided, snapshot) => (
                    <Drag
                        isDragging={snapshot.isDragging}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <p>{fields[index].label}</p>
                        <input 
                            type="text" 
                            name={fields[index].label} 
                            placeholder="Default Value" 
                            value={fields[index].defaultValue as string}
                            onChange={(e) => {handleInputChange(e)}}
                        />
                        <AiOutlineEdit 
                            onClick={() => setIsOpen(true)}
                            style={{ fontSize: "1.25rem", position: "absolute", top: "28px", right: "24px", cursor: "pointer" }}
                        />
                    </Drag>
                )}
            </Draggable>
            <EditFieldModal
                isOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                fields={fields}
                setFields={setFields}
                index={index}
            />
        </>
    )
}

export default DraggableField;
