import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 540px;
    padding: 40px;
    box-sizing: border-box;
`;

export const Container = styled.div`
    height: 420px;
    display: flex;
    width: 80%;
    margin-left: 10%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .error {
		border: 1px solid #d9534f;
	}

    label {
        font-size: 1.25rem;
    }
`;

export const Input = styled.input`
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	width: 420px;
	height: 32px;
	font-size: 1rem;
	border: none;
	padding: 0 24px;
	margin-top: 12px;
	display: block;
	color: #647E93;

	&:placeholder {
		color: #647E93;
	}

	&:focus {
		outline: none;
	}
`;
