interface ProgressIndicatorProps {
	/** Index of active step */
	active: number;
	/** Number of steps to represent */
	numSteps: number;
	onClick: (numStep: number) => void;
}

export const ProgressIndicator = ({
	active,
	numSteps,
	onClick,
}: ProgressIndicatorProps) => {
	return (
		<div className="flex">
			{new Array(numSteps).fill('').map((empty, index) => (
				<button
					aria-label={`Go to step ${index + 1}`}
					className={`p-1 cursor-pointer hover:bg-bluejay-500 border-white border-solid cursor- pointer border rounded-full mr-2 ${
						index <= active ? 'bg-white' : 'bg-transparent'
					}`}
					onClick={() => onClick(index)}
				/>
			))}
		</div>
	);
};
