import styled from 'styled-components';

export const ProductCardDiv = styled.div`
	padding: 24px;
	border-radius: 24px;
	margin: 2rem 1rem;
	background: white;
	border-radius: 4;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	transition: 0.3s;
	width: 310px;
	strong,
	span {
		font-size: 1rem;
		width: 50%;
		overflow: hidden;
	}

	span {
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	strong, span {
		font-weight: 500;
	}

	@media (max-width: 768px) {
		margin: 1rem;

		&:first-child {
			margin-top: 1.5rem;
		}
	}
`;

export const InfoDiv = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	row-gap: 8px;
`;

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	background: #dcdcdc;
`;

export const Strong = styled.strong`
	width: 105px;
`;

export const DetailsDiv = styled.div`
	margin-top: 24px;
	text-align: center;
`;
