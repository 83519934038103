import { Draggable, Droppable } from 'react-beautiful-dnd';

import { Widget, InnerText, Img } from '../../../../components';
import { WalletTemplate } from '../../../../types';

type Props = {
	walletTemplate: WalletTemplate;
	isReview?: boolean;
};

export const AppleBackTemplate = ({ walletTemplate, isReview }: Props) => {
	return (
		<div
			style={{
				backgroundColor: walletTemplate.backgroundColor,
				color: walletTemplate.labelColor,
			}}
			className="rounded-lg !p-5 h-[448px]"
		>
			<div className="flex justify-center">
				{walletTemplate?.images?.icon ? (
					<div className="w-10 h-10 mb-4 overflow-hidden rounded-lg">
						<Img src={walletTemplate?.images?.icon} alt="logo" />
					</div>
				) : (
					<Widget className="w-10 h-10">
						<InnerText className="-rotate-45">Icon</InnerText>
					</Widget>
				)}
			</div>
			<div className="flex">
				<Widget className="w-full h-16">
					<InnerText>ADD APP</InnerText>
				</Widget>
			</div>
			<Widget className="w-full h-8">
				<InnerText>Automatic Updates</InnerText>
			</Widget>
			<Widget className="w-full h-8">
				<InnerText>Allow Notifications</InnerText>
			</Widget>
			<Widget className="w-full h-6">
				<InnerText>Remove Pass</InnerText>
			</Widget>
			{isReview ? (
				<>
					{walletTemplate.backFields?.map((field, index) => (
						<div key={index}>
							<p>{field.label}</p>
							{field.mappedValue || field.defaultValue ? (
								<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden"
									style={{
										color: walletTemplate.foregroundColor,
									}}
								>
									{(field.mappedValue as string) ||
										(field.defaultValue as string)}
								</p>
							) : (
								<p>No Value</p>
							)}
						</div>
					))}
					{!walletTemplate.backFields?.length && (
						<Widget className="mb-0 w-full h-24">
							<InnerText>Back Fields</InnerText>
						</Widget>
					)}
				</>
			) : (
				<Droppable droppableId="appleBackPreview">
					{(provided, snapshot) => (
						<div
							className={`${
								snapshot.isDraggingOver ? 'rounded-lg shadow-xl' : 'rounded-lg'
							} mb-0 w-full mt-10 h-[120px]`}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{walletTemplate.backFields?.map((field, index) => (
								<Draggable
									key={index}
									draggableId={`appleBackPreview ${index}`}
									index={index}
								>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className={`
												${
													snapshot.isDragging
														? 'rounded-lg shadow-xl border-2 border-dashed border-stone-200 bg-white'
														: ''
												} 
												`}
											style={{
												top: `${snapshot.isDragging ? '0px' : '0px'}`,
												right: `${snapshot.isDragging ? '0px' : '0px'}`,
												transition: provided?.draggableProps?.style?.transition,
												transform: provided?.draggableProps?.style?.transform,
											}}
										>
											<p>{field.label}</p>
											{field.mappedValue || field.defaultValue ? (
												<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden"
													style={{
														color: walletTemplate.foregroundColor,
													}}
												>
													{(field.mappedValue as string) ||
														(field.defaultValue as string)}
												</p>
											) : (
												<p>No Value</p>
											)}
										</div>
									)}
								</Draggable>
							))}
							{!walletTemplate.backFields?.length && (
								<Widget className="mb-0 w-full h-24">
									<InnerText>Back Fields</InnerText>
								</Widget>
							)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			)}
		</div>
	);
};
