import styled from 'styled-components';

export const BannerDiv = styled.div`
	height: 80px;

	@media (max-width: 1000px) {
		display: flex;
		justify-content: center;
	}
`;

export const WelcomeSpan = styled.span`
	font-size: 1.2em;
	font-weight: bold;
	vertical-align: bottom;
	padding-left: 20px;
	padding-bottom: 10px;
	display: inline-block;
	font-weight: 400px;

	@media (max-width: 1000px) {
		display: none;
	}
`;

export const BannerImg = styled.img`
	height: 75px;

	@media (max-width: 1000px) {
		height: 60px;
		margin-top: .5rem;
	}
`;

export const PaddingDiv = styled.div`
	padding-top: 30px;
	padding-left: calc(50% - 200px);
	padding-right: calc(50% - 200px);
	text-align: center;
	color: #647E93;

	@media (max-width: 1000px) {
		padding: 10rem 2rem;
	}

	@media (max-width: 600px) {
		padding: calc(50% - 100px) .5rem 0 .5rem;
	}
`;

export const Title = styled.div`
	font-weight: bold;
	font-size: 2em;
	margin-bottom: 1rem;
`;

export const Description = styled.div`
	margin-bottom: 12px;
	margin-top: 2px;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
`;

export const InputsDiv = styled.div`
	padding: 8px;
	flex-direction: column;
	display: flex;
	row-gap: 8px;
`;
export const RedirectLinkDiv = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	row-gap: 4px;
	a {
		color: blue;
		cursor: pointer;
	}
`;
