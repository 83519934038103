import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TokenSelect from '../TokenSelect';
import CustomTooltip from '../../Tooltip';
import { TokenData } from '../../../types';
import { Container, Form, Input, TextDiv } from './style';

import { Button, ButtonDiv } from '../../FormProgress/style';
import showToast from '../../Toast';

type Props = {
	setActiveTab: (tab: string) => void;
	setTokenData: (data: TokenData) => void;
	tokenData: TokenData | null;
};

const TokenBasics = ({ setActiveTab, setTokenData, tokenData }: Props) => {
	const [tokenName, setTokenName] = useState('');
	const [tokenSymbol, setTokenSymbol] = useState('');
	const [selectType, setSelectType] = useState<any>(null);

	const [submitClicked, setSubmitClicked] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		if (tokenData) {
			setTokenName(tokenData.name);
			setTokenSymbol(tokenData.tokenSymbol);
		}
	}, [tokenData]);

	const handleFormSubmit = () => {
		setSubmitClicked(true);

		if (tokenName && tokenSymbol && selectType) {
			const basicData: TokenData = {
				name: tokenName,
				tokenSymbol: tokenSymbol,
				ledgerType: selectType.ledgerType,
				isNFT: selectType.isNFT,
				kyc: false,
				freeze: false,
				wipe: false,
				supply: true,
				tokenMemo: '',
				tokenTypeId: selectType.tokenTypeId,
				metadata: [
					{
						fieldType: '',
						fieldName: '',
					},
				],
			};

			setActiveTab('2');
			setTokenData(basicData);
		} else {
			showToast.warn(t<string>('toast.fillAllFields'));
		}
	};

	return (
		<>
			<Form>
				<Container>
					<div>
						<label>{t<string>('createToken.basics.label.name')}</label>
						<CustomTooltip text={t<string>('tooltip.tokenName')} />
						<Input
							name="tokenName"
							placeholder={t<string>('createToken.basics.placeholder.name')}
							type="text"
							onChange={(e) => setTokenName(e.target.value)}
							className={`${submitClicked && !tokenName ? 'error' : ''}`}
							value={tokenName || tokenData?.name}
						/>
					</div>
					<div>
						<label>{t<string>('createToken.basics.label.symbol')}</label>
						<CustomTooltip text={t<string>('tooltip.tokenSymbol')} />
						<Input
							name="tokenSymbol"
							type="text"
							placeholder={t<string>('createToken.basics.placeholder.symbol')}
							onChange={(e) => setTokenSymbol(e.target.value)}
							className={`${submitClicked && !tokenSymbol ? 'error' : ''}`}
							value={tokenSymbol || tokenData?.tokenSymbol}
						/>
					</div>
					<div>
						<label>{t<string>('createToken.basics.label.type')}</label>
						<CustomTooltip text={t<string>('tooltip.tokenType')} />
						<TokenSelect
							onChange={(object) => setSelectType(object)}
							selectType={selectType}
							setSelectType={setSelectType}
							width="300px"
						/>
					</div>
				</Container>
				<TextDiv>
					<p>Maybe a text here which explain this step? To make it more friendly, understandable, and fill out this otherwise empty step?</p>
				</TextDiv>
				<ButtonDiv>
					<Button type="button" onClick={handleFormSubmit}>
						{t<string>('createToken.button.next')}
					</Button>
				</ButtonDiv>
			</Form>
		</>
	);
};

export default TokenBasics;
