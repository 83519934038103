/* eslint-disable import/no-anonymous-default-export */
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import walletService from '../../services/walletService';

export default (templateId: number) => {
	const { user } = useContext(AuthContext);

	return useQuery(['walletPasses', user?.tenantId, templateId], async () => {
		const response = await walletService.getWalletPassesWithoutDeleted(user?.tenantId, templateId);
		return response.data;
	});
};
