import api from './api';
import { EventDataRequest } from '../types';

const getEventTable = (tenantId: string) => api.get(`/${tenantId}/event-config`);

const postTenantEvent = (tenantId: string, event: any) => {
  const body: EventDataRequest[] = [{
    actionType: event.action.name,
    endpoint: event.url,
    action: event.action
  }];

  return api.post(`/${tenantId}/event-config/${event.event_type}`, body);
};

const getEventOptions = () => api.get('/event-types');

const getActionOptions = (eventId: number) => api.get(`/action-types/${eventId}`);

const patchEventConfig = (tenantId: string, event: any) => {
  const body: EventDataRequest[] = [{
    actionType: event.action.name,
    endpoint: event.url,
    action: event.action
  }];

  return api.patch(`/${tenantId}/event-config/${event.eventType}`, body);
}

const deleteEventConfig = (tenantId: string, eventType: string) => api.delete(`/${tenantId}/event-config/${eventType}`);

export default {
  getEventTable,
  postTenantEvent,
  getEventOptions,
  getActionOptions,
  patchEventConfig,
  deleteEventConfig,
};
