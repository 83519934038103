import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ThreeDots } from 'react-loader-spinner'
import { BiDetail } from 'react-icons/bi'

import { Select } from '../../components/Select'
import useQueryWalletPasses from '../../hooks/useQuery/useQueryWalletPasses'
import useQueryWalletTemplates from '../../hooks/useQuery/useQueryWalletTemplates'

import TableComponent from '../../components/Table'

import { Container, SelectContainer } from './style'
import { useNavigate } from 'react-router-dom'
import { AppRoutesEnum } from '../../constants'

const WalletMembers = () => {
  const { data: walletTemplates, isLoading: templateIsLoading } = useQueryWalletTemplates()
  const templatesName = walletTemplates?.map((template: any) => template.name)

  const [templateId, setTemplateId] = useState<number>(0)

  const { data: walletPasses, isLoading: walletPassesIsLoading } = useQueryWalletPasses(templateId)
  const members = walletPasses?.map((pass: any) => `${pass.person.firstName} ${pass.person.lastName}`)

  useEffect(() => {
    setTemplateId(walletTemplates?.[0].templateId || 0)
  }, [walletTemplates])

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleTemplateChange = (value: string) => {
    const template = walletTemplates?.find((template: any) => template.name === value)
    setTemplateId(template?.templateId)
  }

  const tableHeader = [
    t<string>('walletCard.membersPage.members'),
    t<string>('walletCard.membersPage.details'),
  ]

  const tableData = members?.map((member: string) => ({ 
    member,
    details: <BiDetail
      style={{ cursor: "pointer", marginLeft: "28px" }}
      onClick={() => handleDetailsClick(member)}
    />
  }))

  const handleDetailsClick = (member: string) => {
    const pass = walletPasses?.find((pass: any) => `${pass.person.firstName} ${pass.person.lastName}` === member)

    navigate(AppRoutesEnum.WALLET_UPDATE_TIER, { state: { pass: pass }})
  }

  if (templateIsLoading) {
    return (
      <Container style={{ justifyContent: "center", alignItems: "center" }}>
        <ThreeDots color="#647E93" height={60} width={60} />
      </Container>
    )
  }

  return (
    <Container>
      <SelectContainer>
        <h1>{t<string>('walletCard.members')}</h1>
        <div style={{ margin: "0px 25%", width: "10%" }}>
          <label>{t<string>('walletCard.membersPage.walletTemplates')}</label>
          <Select
            options={templatesName}
            noOptionsLabel={t<string>('walletCard.noOptions')}
            needDisabledOption={t<string>('walletCard.selectTemplate')}
            onChange={(e) => handleTemplateChange(e.target.value)}
            width="400px"
          />
        </div>
      </SelectContainer>
      <TableComponent
        tableHeaders={tableHeader}
        tableData={tableData}
        noDataText={t<string>('walletCard.noMembers')}
        loading={walletPassesIsLoading}
      />
    </Container>
  )
}

export default WalletMembers
