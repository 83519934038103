import Modal from "react-modal";
import { useState } from "react";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";

import { modalStyles, ModalContainer } from "./style";
import showToast from '../../Toast';
import Button from "../../Button";
import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";

type Props = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setFields: (fields: IWalletPassFieldMappings[]) => void;
    fields: IWalletPassFieldMappings[];
}

const CustomFieldModal = ({ isOpen, setIsOpen, setFields, fields }: Props) => {
    const [label, setLabel] = useState<string>("");
    const [defaultValue, setDefaultValue] = useState<string>("");
    const [mappedValue, setMappedValue] = useState<string>("");
    const [changeMessage, setChangeMessage] = useState<string>("");

    const { t } = useTranslation();

    const handleButton = () => {
        if (!label) {
            showToast.info(t<string>("walletCard.customization.toastModal"));
            return;
        }

        const newFields = [...fields, {
            key: uuid(),
            label,
            defaultValue,
            mappedValue,
            changeMessage
        }];

        setFields(newFields);
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            style={modalStyles}
            contentLabel="Custom Field Modal"
        >
            <ModalContainer>
                <div>
                    <label>{t<string>("walletCard.fields.label")}</label>
                    <input type="text" onChange={(e) => setLabel(e.target.value)} />
                </div>
                <div>
                    <label>{t<string>("walletCard.fields.defaultValue")}</label>
                    <input type="text" onChange={(e) => setDefaultValue(e.target.value)} />
                </div>
                <div>
                    <label>{t<string>("walletCard.fields.mappedValue")}</label>
                    <input type="text" onChange={(e) => setMappedValue(e.target.value)} />
                </div>
                <div>
                    <label>{t<string>("walletCard.fields.changeMessage")}</label>
                    <input type="text" onChange={(e) => setChangeMessage(e.target.value)} />
                </div>
                <Button
                    onClick={handleButton}
                    width="688px"
                >{t<string>("walletCard.customization.addField")}</Button>
            </ModalContainer>
        </Modal>
    );
}

export default CustomFieldModal;
