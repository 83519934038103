/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import uuid from 'react-uuid';
import {
	PaintBrushIcon,
	QrCodeIcon,
	QueueListIcon,
} from '@heroicons/react/24/solid';
import {
	IAndroidPassData,
	IWalletPassFieldMappings,
} from '@bambu-meta/interfaces';
import { useTranslation } from 'react-i18next';

import {
	H2,
	H4,
	InputGroup,
	LineBreak,
	P,
	PrimaryButton,
	StepCounterText,
} from '../../../../components';
import { Barcode, Design, Fields } from './SubSteps';
import showToast from '../../../../components/Toast';
import { WalletTemplate } from '../../../../types';
import { TextareaGroup } from '../../../../components/UpdatedUI/Input/TextareaGroup';

const TabButton = (props: {
	active: boolean;
	icon: FC<{ className?: string }>;
	label: string;
	onClick: () => void;
}) => {
	const IconComponent = props.icon;

	return (
		<button
			onClick={props.onClick}
			className={`w-1/3 cursor-pointer flex bg-transparent border-0 justify-center items-center py-2 font-sans hover:bg-stone-100 active:bg-stone-200 border-b-[8px] ${
				props.active
					? 'border-bluejay-700 font-bold text-bluejay-700'
					: 'border-bluejay-100 hover:border-bluejay-200 text-stone-600 '
			}`}
		>
			<IconComponent
				className={`w-4 h-4 mr-2 ${
					props.active ? 'text-bluejay-700' : 'text-stone-600'
				}`}
			/>
			{props.label}
		</button>
	);
};

type Props = {
	fields: IWalletPassFieldMappings[];
	setFields: (fields: IWalletPassFieldMappings[]) => void;
	walletTemplate: WalletTemplate;
	setWalletTemplate: (walletTemplate: WalletTemplate) => void;
	androidWalletTemplate: IAndroidPassData;
	setAndroidWalletTemplate: (androidWalletTemplate: IAndroidPassData) => void;
	isNotCreation?: boolean;
};

export const DragNDrop = ({ 
	fields, 
	setFields, 
	walletTemplate, 
	setWalletTemplate, 
	androidWalletTemplate, 
	setAndroidWalletTemplate,
	isNotCreation = false,
}: Props) => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const [label, setLabel] = useState<string>('');
	const [defaultValue, setDefaultValue] = useState<string>('');
	const [mappedValue, setMappedValue] = useState<string>('');
	const [changeMessage, setChangeMessage] = useState<string>('');

	const [fieldIndex, setFieldIndex] = useState<number | undefined>(undefined);

	const ref = useRef(null);

	useClickAway(ref, () => {
		setIsOpen(false);

		setLabel("");
		setDefaultValue("");
		setMappedValue("");
		setChangeMessage("");
	});

	const handleCancel = () => {
		setIsOpen(false);

		setLabel("");
		setDefaultValue("");
		setMappedValue("");
		setChangeMessage("");
	};

	const handleCreateField = () => {
		if (!label) {
			showToast.info(t<string>('walletCard.customization.toastModal'));
			return;
		}

		const newFields = [
			...fields,
			{
				key: uuid(),
				label,
				defaultValue,
				mappedValue,
				changeMessage,
			},
		];

		setFields(newFields);
		setIsOpen(false);
	}

	const handleOpenEditField = (index: number) => {
		setLabel(fields[index].label);
		setDefaultValue((fields[index]?.defaultValue || "") as string);
		setMappedValue((fields[index]?.mappedValue || "") as string);
		setChangeMessage((fields[index]?.changeMessage || "") as string);
	}

	const handleEditField = () => {
		if (fieldIndex === undefined) return;

		const newField = {
			key: fields[fieldIndex].key,
			label,
			defaultValue,
			mappedValue,
			changeMessage,
		}

		const newFields = [...fields];
		newFields[fieldIndex] = newField;

		const newWalletTemplate = { ...walletTemplate };
		newWalletTemplate?.primaryFields?.forEach((item) => {
			if (item.key === fields[fieldIndex].key) {
				item.label = label;
				item.defaultValue = defaultValue;
				item.mappedValue = mappedValue;
				item.changeMessage = changeMessage;
			}
		});

		newWalletTemplate?.secondaryFields?.forEach((item) => {
			if (item.key === fields[fieldIndex].key) {
				item.label = label;
				item.defaultValue = defaultValue;
				item.mappedValue = mappedValue;
				item.changeMessage = changeMessage;
			}
		});

		newWalletTemplate?.auxiliaryFields?.forEach((item) => {
			if (item.key === fields[fieldIndex].key) {
				item.label = label;
				item.defaultValue = defaultValue;
				item.mappedValue = mappedValue;
				item.changeMessage = changeMessage;
			}
		});
		
		newWalletTemplate?.backFields?.forEach((item) => {
			if (item.key === fields[fieldIndex].key) {
				item.label = label;
				item.defaultValue = defaultValue;
				item.mappedValue = mappedValue;
				item.changeMessage = changeMessage;
			}
		});

		newWalletTemplate?.headerFields?.forEach((item) => {
			if (item.key === fields[fieldIndex].key) {
				item.label = label;
				item.defaultValue = defaultValue;
				item.mappedValue = mappedValue;
				item.changeMessage = changeMessage;
			}
		});

		const newAndroidWalletTemplate = { ...androidWalletTemplate };

		newAndroidWalletTemplate?.rows?.forEach((items) => {
			items.items.forEach((item: any) => {
				if (item.key === fields[fieldIndex].key) {
					item.label = label;
					item.defaultValue = defaultValue;
					item.mappedValue = mappedValue;
					item.changeMessage = changeMessage;
				}
			})
		});

		newAndroidWalletTemplate?.detailRows?.forEach((items) => {
			items.items.forEach((item: any) => {
				if (item.key === fields[fieldIndex].key) {
					item.label = label;
					item.defaultValue = defaultValue;
					item.mappedValue = mappedValue;
					item.changeMessage = changeMessage;
				}
			})
		});

		setFields(newFields);
		setWalletTemplate(newWalletTemplate);
		setAndroidWalletTemplate(newAndroidWalletTemplate);
		setIsOpen(false);
	}

	return (
		<div className='h-[508px]'>
			{isOpen && (
				<div className="bg-bluejay-700 opacity-70 absolute top-0 right-0 bottom-0 h-full w-full z-20" />
			)}
			<div
				className={`fixed overflow-scroll top-0 right-0 bottom-0 h-full w-[360px] px-5 py-10 bg-white rounded-l-lg shadow-lg transition-all z-30 ${
					!isOpen && '-mr-[400px]'
				}`}
				ref={ref}
			>
				<H4>{isEdit ? "Edit your Field" : "Create your own Field"}</H4>
				<LineBreak />
				<div className="mt-5" />
				<InputGroup
					className="mb-4"
					id="0"
					label="Label"
					onChange={(e) => setLabel(e.target.value)}
					placeholder="Label"
					value={label}
				/>
				<TextareaGroup
					className="mb-4"
					id="1"
					label="Default Value"
					onChange={(e) => setDefaultValue(e.target.value)}
					placeholder="Default Value"
					value={defaultValue}
				/>
				<TextareaGroup
					className="mb-4"
					id="2"
					label="Mapped Value"
					onChange={(e) => setMappedValue(e.target.value)}
					placeholder="Mapped Value"
					value={mappedValue}
				/>
				<TextareaGroup
					className="mb-4"
					id="3"
					label="Change Message"
					onChange={(e) => setChangeMessage(e.target.value)}
					placeholder="Change Message"
					value={changeMessage}
				/>
				<div className="flex justify-between">
					<button
						className="flex items-center text-xs border-0 bg-transparent cursor-pointer text-bluejay-900 font-sans font-bold px-4 py-2 hover:bg-stone-200 active:bg-stone-300 rounded-lg"
						onClick={handleCancel}
					>
						Cancel
					</button>
					{isEdit ? (
						<PrimaryButton onClick={handleEditField}>
							Edit Field
						</PrimaryButton>
					) : (
						<PrimaryButton onClick={handleCreateField}>
							Create Field
						</PrimaryButton>
					)}
				</div>
			</div>

			<div className="md:flex justify-between mb-5">
				<div>
					<H2 className="mb-5">{t<string>('walletCard.dragAndDrop.title')}</H2>
					<P className="w-8/12 sm:w-full md:w-8/12">
					{t<string>('walletCard.dragAndDrop.description')}
					</P>
				</div>
				<StepCounterText className="mb-5">{isNotCreation ? t<string>('walletCard.dragAndDrop.notCreationStep') : t<string>('walletCard.dragAndDrop.step')}</StepCounterText>
			</div>
			<div className="flex mb-2" id="tabsGroup">
				<TabButton
					active={activeTab === 0}
					icon={QueueListIcon}
					label="Fields"
					onClick={() => setActiveTab(0)}
				/>
				<TabButton
					active={activeTab === 1}
					icon={PaintBrushIcon}
					label="Design"
					onClick={() => setActiveTab(1)}
				/>
				<TabButton
					active={activeTab === 2}
					icon={QrCodeIcon}
					label="Barcode"
					onClick={() => setActiveTab(2)}
				/>
			</div>
			<div
				className="px-4"
			>
				{activeTab === 0 ? (
					<Fields {...{ fields, setFields, setIsOpen, setFieldIndex, setIsEdit, handleOpenEditField}} />
				) : activeTab === 1 ? (
					<Design
						walletTemplate={walletTemplate}
						setWalletTemplate={setWalletTemplate}
						androidWalletTemplate={androidWalletTemplate}
						setAndroidWalletTemplate={setAndroidWalletTemplate}
					/>
				) : (
					<Barcode
						walletTemplate={walletTemplate}
						setWalletTemplate={setWalletTemplate}
					/>
				)}
			</div>
		</div>
	);
};
