import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BiCommentAdd, BiCommentDetail } from 'react-icons/bi';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { IWalletTemplateInput, IAndroidPassData, WalletTemplateDefinitions, IWalletPassFieldMappings } from '@bambu-meta/interfaces';

import { AppRoutesEnum } from '../../constants';
import { WalletTemplate } from '../../types';
import { Container, PageHeader, NameFilter, Input } from './style';

import TableComponent from '../../components/Table';
import useQueryWalletTemplates from '../../hooks/useQuery/useQueryWalletTemplates';

import EditWalletTemplate from '../WalletTemplateEdit';
import CreateWalletTier from '../WalletCreateTier';
import DeleteWalletModal from '../WalletTemplateEdit/DeleteModal';
import TierListModal from './TierListModal';

import { onRepopulateTemplateData } from '../../hooks/repopulateData/onRepopulateData'

const WalletTemplateTable = () => {
    const [nameFilter, setNameFilter] = useState<string>('');
	const [applyedNameFilter, setApplyedNameFilter] = useState<string>('');

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isCreatingTier, setIsCreatingTier] = useState<boolean>(false);
    const [isEditingTier, setIsEditingTier] = useState(false);

    const [iosData, setIosData] = useState<WalletTemplate>();
    const [androidData, setAndroidData] = useState<IAndroidPassData>();
    const [fieldMappings, setFieldMappings] = useState<any>([]);
    const [templateId, setTemplateId] = useState<number>(1);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isTierListModalOpen, setIsTierListModalOpen] = useState<boolean>(false);

    const  { data: templates, isLoading } = useQueryWalletTemplates(isDeleteModalOpen); 

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNameFilter = () => {
		setApplyedNameFilter(nameFilter);
		setNameFilter('');
	}

    const repopulateTemplateData = (template: any) => {
        const passType = WalletTemplateDefinitions[template.passdata?.passStyle as keyof typeof WalletTemplateDefinitions];

        const headerFields = template?.passdata?.headerFields?.map((field: string) => {
            let fieldMapped: any = {};

            template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
                if (mapping.key === field) {
                    fieldMapped.key = mapping.key;
                    fieldMapped.label = mapping.label;
                    fieldMapped.defaultValue = mapping.defaultValue;
                    fieldMapped.mappedValue = mapping.mappedValue;
                    fieldMapped.changeMessage = mapping.changeMessage;
                }
            });
            return fieldMapped as IWalletPassFieldMappings;
        });

        const primaryFields = template?.passdata?.primaryFields?.map((field: string) => {
            let fieldMapped: any = {};

            template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
                if (mapping.key === field) {
                    fieldMapped.key = mapping.key;
                    fieldMapped.label = mapping.label;
                    fieldMapped.defaultValue = mapping.defaultValue;
                    fieldMapped.mappedValue = mapping.mappedValue;
                    fieldMapped.changeMessage = mapping.changeMessage;
                }
            });
            return fieldMapped as IWalletPassFieldMappings;
        });

        const secondaryFields = template?.passdata?.secondaryFields?.map((field: string) => {
            let fieldMapped: any = {};
            
            template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
                if (mapping.key === field) {
                    fieldMapped.key = mapping.key;
                    fieldMapped.label = mapping.label;
                    fieldMapped.defaultValue = mapping.defaultValue;
                    fieldMapped.mappedValue = mapping.mappedValue;
                    fieldMapped.changeMessage = mapping.changeMessage;
                }
            });
            return fieldMapped as IWalletPassFieldMappings;
        });

        const auxiliaryFields = template?.passdata?.auxiliaryFields?.map((field: string) => {
            let fieldMapped: any = {};
            
            template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
                if (mapping.key === field) {
                    fieldMapped.key = mapping.key;
                    fieldMapped.label = mapping.label;
                    fieldMapped.defaultValue = mapping.defaultValue;
                    fieldMapped.mappedValue = mapping.mappedValue;
                    fieldMapped.changeMessage = mapping.changeMessage;
                }
            });
            return fieldMapped as IWalletPassFieldMappings;
        });

        const backFields = template?.passdata?.backFields?.map((field: string) => {
            let fieldMapped: any = {};

            template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
                if (mapping.key === field) {
                    fieldMapped.key = mapping.key;
                    fieldMapped.label = mapping.label;
                    fieldMapped.defaultValue = mapping.defaultValue;
                    fieldMapped.mappedValue = mapping.mappedValue;
                    fieldMapped.changeMessage = mapping.changeMessage;
                }
            });
            return fieldMapped as IWalletPassFieldMappings;
        });

        const rows = template?.androidPassData?.rows?.map((row: any) => {
            const rowMapped: any = [];

            row.items?.forEach((item: string) => {
                template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
                    if (mapping.key === item) {
                        rowMapped.push(mapping.label);
                    }
                });
            })

            return { items: rowMapped }
        });

        const detailRows = template?.androidPassData?.detailRows?.map((row: any) => {
            const rowMapped: any = [];

            row.items?.forEach((item: string) => {
                template?.fieldMappings?.forEach((mapping: IWalletPassFieldMappings) => {
                    if (mapping.key === item) {
                        rowMapped.push(mapping.label);
                    }
                });
            })

            return { items: rowMapped }
        });

        const iosWalletTemplate: WalletTemplate = {
            name: template.name,
            passTypeIdentifier: template.passTypeIdentifier,
            teamIdentifier: template.teamIdentifier,
            organizationName: template.organizationName,
            description: template.passdata?.description,
            passType,
            images: template?.passdata?.images,
            backgroundColor: template?.passdata?.backgroundColor as string,
            foregroundColor: template?.passdata?.foregroundColor as string,
            labelColor: template?.passdata?.labelColor as string,
            headerFields: headerFields as unknown as IWalletPassFieldMappings[],
            primaryFields: primaryFields as unknown as IWalletPassFieldMappings[],
            secondaryFields: secondaryFields as unknown as IWalletPassFieldMappings[],
            auxiliaryFields: auxiliaryFields as unknown as IWalletPassFieldMappings[],
            backFields: backFields as unknown as IWalletPassFieldMappings[],
            barCodes: template?.passdata?.barCodes as any,
        }

        const androidWalletTemplate: IAndroidPassData = {
            title: template.androidPassData?.title as string,
            header: template.androidPassData?.header as string,
            subheader: template.androidPassData?.subheader as string,
            hexBackgroundColor: template.androidPassData?.hexBackgroundColor as string,
            rows: rows as any,
            detailRows: detailRows as any,
            images: template.androidPassData?.images as any,
        }

        setIosData(iosWalletTemplate);
        setAndroidData(androidWalletTemplate);
        setFieldMappings(template.fieldMappings);
        setTemplateId(template.templateId);
    }

    const handleEdit = (template: any) => {
        const { iosWalletTemplate, androidWalletTemplate } = onRepopulateTemplateData(template);
        navigate(AppRoutesEnum.WALLET_TEMPLATE_UPDATE, { state: {
            iosWalletTemplate: iosWalletTemplate,
            androidWalletTemplateData: androidWalletTemplate,
            fieldMappings: template.fieldMappings,
            templateId: template.templateId,
        }})
    }

    const handleDelete = (template: any) => {
        repopulateTemplateData(template);
        setIsDeleteModalOpen(true);
    }

    const handleCreateTier = (template: any) => {
        repopulateTemplateData(template);
        setIsCreatingTier(true);
    }

    const handleTierList = (template: any) => {
        repopulateTemplateData(template);
        setIsTierListModalOpen(true);
    }

    const formatDate = (date: string) => {
        const onlyDate = date.split('T')[0];
        const dateArray = onlyDate.split('-');
        return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`.replaceAll("-", "/");
    }

    const dataToShow = templates?.map((template: IWalletTemplateInput) => {
        const expiryDate = template.androidPassData?.validTimeInterval?.end.date ? formatDate(template.androidPassData?.validTimeInterval?.end.date) : "No date";
        return {
            name: template.name,
            tier: (
                <div className='flex'>
                    <BiCommentAdd
                        onClick={() => handleCreateTier(template)}
                    />
                    <BiCommentDetail
                        onClick={() => handleTierList(template)}
                        style={{ marginLeft: '8px' }}
                    />
                </div>
            ),
            options: (
                <div className='flex'>
                    <AiOutlineEdit 
                        onClick={() => handleEdit(template)}
                    />
                    <AiOutlineDelete 
                        style={{ marginLeft: '8px' }}
                        onClick={() => handleDelete(template)}
                    />
                </div>
            ),
            expiryDate,
        };
    });

    let walletTemplatesOnPage =
		(applyedNameFilter)
			? dataToShow?.filter(
					(template: any) =>
						template?.name?.toLowerCase()?.includes(applyedNameFilter?.toLowerCase())
			) : dataToShow;

    const tableHeaders = [
        t<string>('walletCard.table.name'),
        t<string>('walletCard.table.createTiers'),
        t<string>('walletCard.table.options'),
        t<string>('walletCard.table.expDate'),
    ];

    return (
        <>
            {isEditing && (
                <EditWalletTemplate
                    iosWalletTemplateData={iosData}
                    androidWalletTemplateData={androidData}
                    setIsEditing={setIsEditing} 
                    fieldMappings={fieldMappings}
                    templateId={templateId}
                />
            )}
            {isCreatingTier && (
                <CreateWalletTier
                    iosWalletTemplateData={iosData}
                    androidWalletTemplateData={androidData}
                    fieldMappings={fieldMappings}
                    templateId={templateId}
                    setIsCreatingTier={setIsCreatingTier}
                />
            )}
            {!isEditing && !isCreatingTier && !isEditingTier && (
                <Container>
                    <PageHeader>
                        <NameFilter>
                            <h1>{t<string>('walletCard.table.title')}</h1>
                            <Input
                                placeholder={t<string>('tokenTable.placeholder.name')}
                                onChange={(e) => setNameFilter(e.target.value)}
                            />
                            <button onClick={handleNameFilter}>{t<string>('tokenTable.placeholder.search')}</button>
                        </NameFilter>
                        <div>
                            <button onClick={() => navigate(AppRoutesEnum.WALLET_TEMPLATE)}>{t<string>('tokenTable.create')}</button>
                        </div>
                    </PageHeader>
                    <h1>{t<string>('walletCard.table.list')}</h1>
                    <TableComponent tableHeaders={tableHeaders} tableData={walletTemplatesOnPage} noDataText={t<string>('walletCard.table.noWalletCards')} loading={isLoading} />
                    {templateId && (
                        <DeleteWalletModal
                            name={iosData?.name}
                            templateId={templateId}
                            isModalOpen={isDeleteModalOpen}
                            setIsModalOpen={setIsDeleteModalOpen}
                        />
                    )}
                </Container>  
            )}
            {templateId && ( 
                <TierListModal 
                    name={iosData?.name}
                    templateId={templateId}
                    isModalOpen={isTierListModalOpen}
                    setIsModalOpen={setIsTierListModalOpen}
                    iosWalletTemplateData={iosData}
                    androidWalletTemplateData={androidData}
                    fieldMappings={fieldMappings}
                    isEditingTier={isEditingTier}
                    setIsEditingTier={setIsEditingTier}
                    repopulateTemplateData={repopulateTemplateData}
                />
            )}
        </>
    );
}

export default WalletTemplateTable;