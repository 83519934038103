interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

export const Img = ({ src, alt, className }: ImageProps) => {
	return (
		<img src={src} alt={alt} className={`rounded-lg w-full ${className}`} />
	);
};