import { ReactNode } from 'react';

import { primaryButtonStyles, secondaryButtonStyles } from './PrimaryButton';

interface ButtonSelectProps {
	/** Whether a button is selected or not */
	active: boolean;
	/** Text to communicate */
	children: string | ReactNode;
	/** Extra classNames */
	className?: string;
	/** Function button performs */
	onClick: () => void;
}

export const ButtonSelect = ({
	active,
	children,
	className,
	onClick,
}: ButtonSelectProps) => {
	return (
		<button
			onClick={onClick}
			className={`${
				active
					? `${primaryButtonStyles} bg-bluejay-800`
					: `${secondaryButtonStyles} font-regular`
			} ${className}`}
		>
			{children}
		</button>
	);
};
