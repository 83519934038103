import styled from 'styled-components';

type Props = {
	isOpen: boolean;
	height?: string;
};

export const TokensList = styled.ul`
	list-style: none;
	height: ${(props: Props) => (props.isOpen ? props.height : '0')};
	overflow: hidden;
	transition: height .3s ease-in-out;

	li {
		transform: ${(props: Props) => (props.isOpen ? 'translateY(0)' : 'translateY(-200%)')};
		transition: transform .3s ease-in-out;
		line-height: 2;
		margin: 12px 0 12px 8px;
		position: relative;
	}

	svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left:-8px;
	}
`;

export const Container = styled.nav<Props>`
	position: relative;
	height: calc(100% - 140px);

	.metismenu ul > li:last-child {
		padding-bottom: 0;
	}

	&>ul > li > button > img {
		margin: 0 24px -3px 0;
		width: 1.25rem;
		height: 1.25rem;
	}

	svg {
		margin-right:8px;
	}

	.g_heading {
		margin-bottom: 1rem;
	}

	button {
		cursor: pointer;
		color: #6583A1;
		font-size: 1rem;
		font-weight: 800;
		border: none;
		background: none;

		&:hover {
			color: #6583A1;
		}
	}
`;

export const List = styled.ul`
	list-style: none;
	margin-top: 5rem;
	padding: 0;

	&>li {
		margin-bottom: 24px;
	}
`;

export const OpenedText = styled.span`
	color: #6583A1;
	font-size: 0.95rem;
	font-weight: 400;
	margin-left:16px;
`;

export const SignOutButton = styled.button`
	position: absolute;
	bottom: 0;
	left: 0;
`;
