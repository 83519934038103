import styled from 'styled-components';

export const Table = styled.table`
	text-align: left;
	border-collapse: collapse;
	width: calc(100vw - 300px);
	font-size: .9rem;
	background-color: #FFF;
	color: #647E93;
	margin: 24px;
	border-radius: 16px;
	
	@media (max-width: 600px) {
		font-size: 8px;
	}
`;

export const Thead = styled.thead`	
`;

export const Th = styled.th`
	border: none;
	font-weight: 500;
	color: #647E93;
	padding: 24px 24px 48px 48px;
	font-size: 1.25rem;

	@media (max-width: 600px) {
		select {
			padding: .25rem;
			width: 2rem;
		}
	}
`;

export const Td = styled.td`
	border: none;
	font-weight: 500;
	color: #647E93;	
	font-size: 1rem;
	padding: 12px 24px 12px 48px;

	@media (max-width: 600px) {
		padding: 0.75rem 0;
		font-size: .75rem;

		&:last-child {
			text-align: right;
		}
	}

	svg {
		cursor: pointer;
		font-size: 20px;
		margin-right: .25rem;

		@media (max-width: 600px) {
			font-size: .75rem;
		}
	}
`;

export const Tr = styled.tr`
	&:nth-child(odd) {
		background-color: rgba(101, 131, 161, 0.1);
	}
`;

export const CenterDiv = styled.td`
	display: flex;
	align-items: center;
	justify-content: center;
`;
