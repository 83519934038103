import { primaryButtonStyles } from '../Button';

type FileDropProps = {
	/** Extra classNames */
	className?: string;
	/** ID */
	id: string;
	/** Extra classNames */
	label: string;
	/** Function input performs onChange */
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	/** All other props for input */
	[x: string]: any;
};

export const FileDrop = ({
	className,
	id,
	label,
	onChange,
	...rest
}: FileDropProps) => {
	return (
		<div className={className}>
			<label className="block font-sans" htmlFor={id}>
				{label}
				<div className={`bg-bluejay-700 mt-2 w-1/2 ${primaryButtonStyles}`}>Upload a File</div>
				<input
					className="hidden"
					type="file"
					id={id}
					name={id}
					accept="image/png, image/jpeg"
					onChange={onChange}
					{...rest}
				/>
			</label>
		</div>
	);
};
