import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  color: #647E93;
  padding: 12px 20px;
`;

export const SelectContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;

  label { 
    font-size: 1rem;
  }

  select { 
    margin: 8px 0 16px 0;
  }
`;
