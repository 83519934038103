import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiInformationFill } from 'react-icons/ri';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';

import { Container, PageHeader, NameFilter, Input, SortContainer } from './style';
import TableComponent from '../../components/Table';
import OrderDetails from '../../components/OrderDetails';

const orders = [
    {
        name: "Order 1",
        orderNumber: 1,
        externalOrderNumber: 1,
        personId: 1,
        orderLines: [
            {
                productId: 1,
                status: 'pending',
            },
            {
                productId: 2,
                status: 'pending',
            },
        ],
    },
    {
        name: "Order 2",
        orderNumber: 2,
        externalOrderNumber: 2,
        personId: 2,
        orderLines: [
            {
                productId: 1,
                status: 'pending',
            },
            {
                productId: 2,
                status: 'pending',
            },
            {
                productId: 3,
                status: 'pending',
            }
        ],
    },
]

const OrderMaintenance = () => {
    const [nameFilter, setNameFilter] = useState<string>('');
	const [applyedNameFilter, setApplyedNameFilter] = useState<string>('');

    const [sortName, setSortName] = useState<string>('asc');
    const [sortOrderNumber, setSortOrderNumber] = useState<string>('asc');

    const [modalIsOpen, setIsOpen] = useState(false);
    const [orderDetail, setOrderDetail] = useState<any>(null);

    const isLoading = false;

    const { t } = useTranslation();

    const handleNameFilter = () => {
		setApplyedNameFilter(nameFilter);
		setNameFilter('');
	}

    let ordersOnPage =
		(applyedNameFilter)
			? orders?.filter(
					(order: any) =>
						order.name.toLowerCase().includes(applyedNameFilter.toLowerCase())
			) : orders;

    const nameHeader = (
        <SortContainer>
            <span>{t<string>('orderMaintenance.table.name')}</span>
            {sortName === 'asc' 
                ? <AiOutlineArrowUp onClick={() => setSortName('desc')} /> 
                : <AiOutlineArrowDown onClick={() => setSortName('asc')} />
            }
        </SortContainer>
    );

    const orderNumberHeader = (
        <SortContainer>
            <span>{t<string>('orderMaintenance.table.orderNumber')}</span>
            {sortOrderNumber === 'asc'
                ? <AiOutlineArrowUp onClick={() => setSortOrderNumber('desc')} />
                : <AiOutlineArrowDown onClick={() => setSortOrderNumber('asc')} />
            }
        </SortContainer>
    );
            
    const tableHeaders = [
        nameHeader,
        orderNumberHeader,
        t<string>('orderMaintenance.table.orderLines'),
        t<string>('orderMaintenance.table.details'),
    ];

    let tableData = ordersOnPage?.map((order: any) => {
        return {
            name: order.name,
            orderNumber: order.orderNumber,
            orderLines: order.orderLines.length,
            details: <RiInformationFill onClick={() => {
                setIsOpen(true);
                setOrderDetail(order);
            }} />
        }
    });

    // eslint-disable-next-line array-callback-return
    tableData.sort((a: any, b: any) => {
        if (sortName === 'desc') {
            return b.name.localeCompare(a.name);
        }

        if (sortOrderNumber === 'desc') {
            return b.orderNumber - a.orderNumber;
        }
    });

    return (
        <Container>
            <PageHeader>
				<NameFilter>
					<h1>{t<string>('orderMaintenance.table.title')}</h1>
					<Input
						placeholder={t<string>('tokenTable.placeholder.name')}
						onChange={(e) => setNameFilter(e.target.value)}
					/>
					<button onClick={handleNameFilter}>{t<string>('tokenTable.placeholder.search')}</button>
				</NameFilter>
			</PageHeader>
            <h1>{t<string>('walletCard.table.list')}</h1>
            <TableComponent tableHeaders={tableHeaders} tableData={tableData} noDataText={t<string>('orderMaintenance.table.noOrders')} loading={isLoading} />
            <OrderDetails modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} order={orderDetail} />
        </Container>
    )
}

export default OrderMaintenance;
