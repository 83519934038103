import { ThreeDots } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import useQueryProducts from '../../hooks/useQuery/useQueryProducts';
import { ProductResponse } from '../../types/Product';
import ProductCard from '../../components/Products/ProductCard';
import ProductTable from '../../components/Products/ProductTable';

import { CenterDiv, ProductsDiv, PageHeader, NameFilter, Input, Container, ListingOptions } from './style';
import { useState } from 'react';
import { AppRoutesEnum } from '../../constants';
import { useNavigate } from 'react-router-dom';

const ProductList = () => {
	const { t } = useTranslation();
	const { data:  products, isLoading } = useQueryProducts();

	const navigate = useNavigate()

	const [nameFilter, setNameFilter] = useState<string>('');
	const [applyedNameFilter, setApplyedNameFilter] = useState<string>('');

	const [isList, setIsList] = useState<boolean>(false);

	const handleNameFilter = () => {
		setApplyedNameFilter(nameFilter);
		setNameFilter('');
	}

	let productsOnPage =
		(applyedNameFilter)
			? products?.filter(
					(product: ProductResponse) =>
						product.name.toLowerCase().includes(applyedNameFilter.toLowerCase())
			) : products;

	return (
		<Container>
			<PageHeader>
				<NameFilter>
					<h1>{t<string>('productList.title')}</h1>
					<Input
						placeholder={t<string>('tokenTable.placeholder.name')}
						onChange={(e) => setNameFilter(e.target.value)}
					/>
					<button onClick={handleNameFilter}>{t<string>('tokenTable.placeholder.search')}</button>
				</NameFilter>
				<div>
					<button onClick={() => navigate(AppRoutesEnum.PRODUCTS_CREATION)}>{t<string>('tokenTable.create')}</button>
				</div>
			</PageHeader>
			<ListingOptions>
				<h1 className={isList ? "" : "active"} onClick={() => setIsList(false)}>{t<string>('productList.gallery')}</h1>
				<h1 className={isList ? "active" : ""} onClick={() => setIsList(true)}>{t<string>('productList.list')}</h1>
			</ListingOptions>
			{isLoading ? (
				<CenterDiv>
					<ThreeDots color="#647E93" height={80} width={80} />
				</CenterDiv>
			) : isList ? (
				<ProductTable
					productsOnPage={productsOnPage}
				/>
			) : (
				<ProductsDiv>
					{!productsOnPage?.length ? (
						<CenterDiv>
							<h1>{t<string>('productList.noProducts')}</h1>
						</CenterDiv>
					) : (
						productsOnPage?.map((product: ProductResponse) => (
							<ProductCard product={product} />
						))
					)}
				</ProductsDiv>
			)}
		</Container>
	);
};

export default ProductList;
