import { IAndroidPassData } from '@bambu-meta/interfaces';
import { useTranslation } from 'react-i18next';

import { WalletTemplate } from '../../../../types';
import {
  Card,
	H2,
	LineBreak,
	P,
	StepCounterText,
} from '../../../../components';
import { AndroidCard, AndroidDetailsCard, AppleBackTemplate, AppleFrontTemplate } from '../WalletCards';
import { ThreeDots } from 'react-loader-spinner';

type Props = {
  walletTemplate: WalletTemplate;
  androidWalletTemplate: IAndroidPassData;
  isLoading: boolean;
  isNotCreation?: boolean;
};

export const Review = ({ walletTemplate, androidWalletTemplate, isLoading, isNotCreation }: Props) => {
  const { t } = useTranslation()

  return (
    <div className='h-[508px]'>
      <div className="md:flex justify-between mb-5">
				<div>
					<H2 className="mb-5">{t<string>('walletCard.review.title')}</H2>
					<P className="w-8/12 sm:w-full md:w-8/12">
          {t<string>('walletCard.review.description')}
					</P>
				</div>
				<StepCounterText className="mb-5">{isNotCreation ? t<string>('walletCard.review.notCreationStep') : t<string>('walletCard.review.step')}</StepCounterText>  
			</div>
      {isLoading ? (
        <div className='h-[404px] flex justify-center items-center'>
          <ThreeDots width={80} height={80} color="#1f4569" />
        </div>
      ) : (
        <div className='h-[404px] overflow-y-scroll'>
          <div>
            <H2 className='mb-0'>{t<string>('walletCard.review.appleWallet')}</H2>
            <LineBreak />
            <div style={{ transform: "scale(.6)", margin: "-104px 80px -92px 0" }}>
              <div className='flex w-[700px] justify-around my-10'>
                <div className="w-[300px] rounded-lg">
                  <Card>
                    <AppleFrontTemplate walletTemplate={walletTemplate} isReview={true} />
                  </Card>
                </div>
                <div className="w-[300px] rounded-lg">
                  <Card>
                    <AppleBackTemplate walletTemplate={walletTemplate} isReview={true} />
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <div>
            <H2 className='mb-0'>{t<string>('walletCard.review.googleWallet')}</H2>
            <LineBreak />
            <div style={{ transform: "scale(.6)", margin: "-104px 80px -140px 0" }}>
              <div className='flex w-[700px] place-content-around my-10'>
                <div className="w-[300px] rounded-lg">
                  <Card>
                    <AndroidCard androidWalletTemplate={androidWalletTemplate} walletTemplate={walletTemplate} isReview={true} />
                  </Card>
                </div>
                <div className="w-[300px] rounded-lg">
                  <Card>
                    <AndroidDetailsCard androidWalletTemplate={androidWalletTemplate} isReview={true} />
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
