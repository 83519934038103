import { useTranslation } from 'react-i18next';
import { RiInformationFill } from 'react-icons/ri';

import { ProductResponse } from '../../../types';

import ProductDetails from '../ProductDetails';
import { useState } from 'react';
import TableComponent from '../../Table';

type Props = {
    productsOnPage: ProductResponse[];
}

const ProductTable = ({ productsOnPage }: Props) => {
	const { t } = useTranslation();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [detailProduct, setDetailProduct] = useState<ProductResponse | null>(null);

    const tableHeaders = [
        t<string>('productList.table.name'),
        t<string>('productList.table.id'),
        t<string>('productList.table.chain'),
        t<string>('productList.table.chainId'),
        t<string>('productList.table.details'),
    ];

    const tableData = productsOnPage?.map((product: ProductResponse) => ({
        name: product?.name,
        productId: product?.productId,
        chain: product?.token?.ledgerType,
        chainId: product?.token?.externalTokenId,
        element: <RiInformationFill onClick={() => {
            setIsOpen(true);
            setDetailProduct(product);
        }} />
    }));

	return (
        <>
            <TableComponent tableHeaders={tableHeaders} tableData={tableData} noDataText={t<string>('productList.noProducts')}  />
            <ProductDetails
                product={detailProduct}
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            />
        </>
	);
}

export default ProductTable;
