import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useQueryReport from '../../hooks/useQuery/useQueryReport';

import DashboardWidget from '../DashboardWidget';
import showToast from '../Toast';
import ReportList from './ReportList';
import { GeneralInfoContainer, RowClearfix, ListContainer } from './style';

type Props = {
	dateRange: string[];
	updateReport: boolean;
	isFirstLoad: boolean;
}

const GeneralInfo = ({ dateRange, updateReport, isFirstLoad }: Props) => {
	const { data: reports, isLoading: isReportLoading, error } = useQueryReport(dateRange, updateReport);

	const { t } = useTranslation();

	useEffect(() => {
		if(isFirstLoad) return;
		if (!error) {
			showToast.success(t<string>('dashboardCards.toast.success'));
		} else {
			showToast.error(t<string>('dashboardCards.toast.error'));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateReport, isFirstLoad]);

	const pendingTokensData = [
		{ value: reports?.pending?.tokensCreated || 0, name: t<string>('dashboardCards.tokensCreated') },
		{ value: reports?.pending?.tokensMinted || 0, name: t<string>('dashboardCards.tokensMinted')},
		{ value: reports?.pending?.tokensPending || 0, name: t<string>('dashboardCards.pendingTokens') },
		{ value: reports?.pending?.tokensFailed || 0, name: t<string>('dashboardCards.tokensFailed') },
	];
	const pendingTitle = t<string>('dashboardCards.nfts');

	const installStatus = [
		{ value: reports?.wallets?.walletPassesCreated || 0, name: t<string>('dashboardCards.created') },
		{ value: reports?.wallets?.walletPassesUninstalled || 0, name: t<string>('dashboardCards.uninstalled') },
		{ value: reports?.wallets?.walletPassesInstalled || 0, name: t<string>('dashboardCards.installed') },
		{ value: reports?.wallets?.walletPassesCreated - reports?.wallets?.walletPassesInstalled - reports?.wallets?.walletPassesUninstalled || 0,
			name: "Issued"}
	];
	const installStatusTitle = t<string>('dashboardCards.walletsStatus');

	const iosVsAndroid = [
		{ value: reports?.wallets?.deviceBreakdown?.appleWallet +  reports?.wallets?.deviceBreakdown?.googleWallet 
			|| 0, name: t<string>('dashboardCards.apple') },
		{ value: reports?.wallets?.deviceBreakdown?.appleWallet || 0, name: t<string>('dashboardCards.apple') },
		{ value: reports?.wallets?.deviceBreakdown?.googleWallet || 0, name: t<string>('dashboardCards.google') },
	]
	const iosVsAndroidTitle = t<string>('dashboardCards.walletsByOS');

	const reportList = [
		{ title: t<string>('dashboardCards.bar.tokensCreated'), value: reports?.tokens?.tokensCreated || 0, isLoading: isReportLoading },
		{ title: t<string>('dashboardCards.bar.productsCreated'), value: reports?.products?.productsCreated || 0, isLoading: isReportLoading },
		{ title: t<string>('dashboardCards.bar.orders'), value: reports?.orders?.orderLinesCreated || 0, isLoading: isReportLoading },
		{ title: t<string>('dashboardCards.bar.associated'), value: reports?.people?.personCount || 0, isLoading: isReportLoading },
	];

	const reportsTooltips = [
		t<string>('tooltip.tokensMinted'),
		t<string>('tooltip.nftsCreated'),
		t<string>('tooltip.programsInitiated'),
		t<string>('tooltip.membersEnrolled'),
	]

	return (
		<RowClearfix className="row clearfix">
			<GeneralInfoContainer className="col-lg-3 col-md-6">
				<DashboardWidget data={pendingTokensData} isLoading={isReportLoading} title={pendingTitle} tooltip={t<string>('tooltip.mintingStatus')} />
				<ListContainer>
					{reportList?.map((item, index) => (
						<ReportList	title={item.title} value={item.value} isLoading={item.isLoading} tooltip={reportsTooltips[index]} key={index} />
					))}
				</ListContainer>
			</GeneralInfoContainer>
			<GeneralInfoContainer className="col-lg-3 col-md-6">
				<DashboardWidget data={installStatus} isLoading={isReportLoading} title={installStatusTitle} tooltip={t<string>('tooltip.passesIssued')} />
				<DashboardWidget data={iosVsAndroid} isLoading={isReportLoading} title={iosVsAndroidTitle} tooltip={t<string>('tooltip.passesInstalledByDevices')} />
			</GeneralInfoContainer>
		</RowClearfix>
	);
};

export default GeneralInfo;
