/* eslint-disable import/no-anonymous-default-export */
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import AuthContext from '../../context/AuthContext';
import productService from '../../services/productService';

export default (tokenId?: string) => {
	const { user } = useContext(AuthContext);

	return useQuery(['products', user.tenantId], async () => {
		const response = await productService.getProducts(user?.tenantId, tokenId);
		return response.data;
	});
};
