import styled from "styled-components";
import { FiUpload } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { Switch } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";

type Props = {
    handleFieldRemove: (index: number, type: string) => void;
    icon: string;
    name: string;
    backFields: IWalletPassFieldMappings[] | undefined;
    imgUrl: string | undefined;
    background: string;
    label?: string;
}

const AppleBackCard = ({ handleFieldRemove, icon, name, backFields, imgUrl, background, label }: Props) => {
    return (
        <Container imgUrl={imgUrl} background={background} label={label} >
            <Header>
                <div>
                    {icon ? <img src={icon} alt="icon" /> : <IconPlaceholder>Icon</IconPlaceholder>}
                </div>
                <h1>{name}</h1>
                <FiUpload />
            </Header>
            <Options>
                <div className="add">
                    ADD APP
                </div>
                <div className="updates">
                    <div><p>Automatic Updates</p> <Switch /></div>
                    <div><p>Allow Notifications</p> <Switch /></div>
                </div>
                <div className="remove">
                    Remove Pass
                </div>
            </Options>
            <FieldsDiv>
                <Droppable droppableId="appleBackPreview">
                    {
                        (provided, snapshot) => (
                            <ul 
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}` }}
                            >
                                {backFields?.map((field, index) => (
                                    <Draggable key={index} draggableId={`back ${index}`} index={index}>
                                        {
                                            (provided) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <p>{field.label}</p>
                                                    {field.defaultValue ? <p>{field.defaultValue as string}</p> : <NoValue />}
                                                    <GrClose
                                                        style={{ position: "absolute", top: "8px", right: "12px", cursor: "pointer" }}
                                                        onClick={() => handleFieldRemove(index, "appleBackPreview")}
                                                    />
                                                </li>
                                            )
                                        }
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )
                    }
                </Droppable>
            </FieldsDiv>
        </Container>
    );
}

type BackProps = {
    imgUrl: string | undefined;
    background: string;
    label?: string;
}

const Container = styled.div<BackProps>`
    overflow: scroll;
    height: 100%;
    z-index: 1000;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    background-color: ${(props: BackProps) => props.background};
    background-image: url(${(props: BackProps) => props.imgUrl});
    background-size: cover;
    color: ${(props: BackProps) => props.label};
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    h1 {
        font-size: .9rem !important;
        margin-top: -12px;
    }

    div {
        width: 60px;
        height: 60px;
        margin: 4px 0 0 0;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    svg {
        position: absolute;
        top: 4px;
        right: 8px;
        color: #0e8bfd;
        font-size: 1.5rem;
    }
`;

const Options = styled.div`
    margin-top: -16px;

    .add {
        color: #6662ec;
        background-color: #f9f9f9;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px;
    }

    .updates {
        background-color: #f9f9f9;
        margin: 4px 8px;

        div {
            padding: 4px 0px 4px 12px;
            
            p {
                width: 120px;
                display: inline-block;
            }

            &:first-child {
                margin-left: 12px;
                border-bottom: 1px solid #eaeaea;

                p {
                    margin-left: -12px;
                }
            }
        }
    }

    .remove {
        background-color: #f9f9f9;
        color: #d84647;
        margin: 4px 8px;
        padding: 4px;
    }
`;

export const FieldsDiv = styled.div`
    background-color: #f9f9f9;
    margin: 0 8px;

    ul {
        list-style-type: none;
        padding: 4px 0px 4px 16px;
    }

    li {
        position: relative;
        border-top: 1px solid #eaeaea;
        padding: 8px 0;

        p , input {
            margin: 4px 0 0 0;
            padding: 0;
        }

        input {
            border: none;
            background-color: #f9f9f9;
        }

        &:first-child {
            border: none;
        }
    }
`;

const NoValue = styled.div`
    background-color: #eaeaea;
    height: 16px;
`;

const IconPlaceholder = styled.div`
    background-color: rgba(101, 131, 161, 0.1);
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default AppleBackCard;
