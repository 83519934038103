import LeftSideBar from '../../components/LeftSideBar';
import TopHeader from '../../components/TopHeader';
import { WrapperDiv } from './styles';

type wrapperTypes = { children: JSX.Element };

const Wrapper = ({ children }: wrapperTypes) => {
	return (
		<WrapperDiv>
			<LeftSideBar />
			<TopHeader />
			{children}
		</WrapperDiv>
	);
};

export default Wrapper;
