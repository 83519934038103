import styled from 'styled-components';

export const Background = styled.div`
	width: calc(100vw - 208px);
background-color: #F5F5F6;
	height: calc(100vh - 170px);
	box-sizing: border-box;
	margin: -16px 0 0 -16px;
	color: #647E93;

	&>h1 {
		margin-left: 24px;
		font-size: 2rem;
		padding-top: 24px;
	}
`;

export const Container = styled.div`
	display: flex;
	width: 100%;

	padding: 24px 0;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;
