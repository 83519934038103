import { useTranslation } from 'react-i18next';
import { RiArrowRightSFill } from 'react-icons/ri';

import { TokenNavigationContainer } from './style';

type Props = {
	activeTab: string;
};

const TokenNavigationBar = ({ activeTab }: Props) => {
	const { t } = useTranslation();

	return (
		<TokenNavigationContainer>
			<div className={activeTab === '1' ? "selected" : ""}>
				{activeTab === '1' && <RiArrowRightSFill />}
				{t<string>('createToken.navigationBar.basics')}
			</div>
			<div className={activeTab === '2' ? "selected" : ""}>
				{activeTab === '2' && <RiArrowRightSFill />}
				{t<string>('createToken.navigationBar.tokenAttributes')}
			</div>
			<div className={activeTab === '3' ? "selected" : ""}>
				{activeTab === '3' && <RiArrowRightSFill />}
				{t<string>('createToken.navigationBar.payload')}
			</div>
		</TokenNavigationContainer>
	);
};

export default TokenNavigationBar;
