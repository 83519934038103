import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import EventsTable from '../../components/Event/EventsTable';
import CreateEventModal from '../../components/Event/CreateEventModal';
import { CreateEventDiv } from './style' 

const EventPage = () => {
    const { t } = useTranslation();

    const [modalIsOpen, setIsOpen] = useState(false);

    return (
        <>
            <CreateEventDiv>
                <button
                    onClick={() => setIsOpen(true)}
                >{t<string>('event.createEvent')}</button>
            </CreateEventDiv>
            <CreateEventModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
            <EventsTable />
        </>
    );
}

export default EventPage;
