import { useCallback, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from  'react-modal' ;

import useQueryEventOptions from '../../../hooks/useQuery/useQueryEventOptions';
import tenantEventService from '../../../services/tenantEventService';
import { SelectObjects } from '../../Select';
import { modalStyles, StyledModal, Input } from './style';
import AuthContext from '../../../context/AuthContext';
import showToast from '../../Toast';
import { ActionType, EventType } from '../../../types';

type Props = {
    modalIsOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    isEdit?: boolean;
    editEvent?: EventType;
}

const CreateEventModal = ({ modalIsOpen, setIsOpen, isEdit, editEvent }: Props) => {
    const [event, setEvent] = useState<EventType | null>(editEvent || null);
    const [action, setAction] = useState<ActionType | null>(null);
    const [url, setUrl] = useState<string>('');

    const [disable, setDisable] = useState<boolean>(false);

    const { data: eventOptions, isLoading: eventOptionsLoading } = useQueryEventOptions();
    const [actionOptions, setActionOptions] = useState<any[]>([]);
    const [actionOptionsLoading, setActionOptionsLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (event) {
            setActionOptionsLoading(true);

            tenantEventService.getActionOptions(event.eventTypeId)
                .then((res) => {
                    setActionOptions(res.data);
                    setActionOptionsLoading(false);
                    setAction(res.data?.[0]);
                }).catch(() => {
                    setActionOptionsLoading(false);
                });
        } else {
            setEvent(eventOptions?.[0]);
        }
    }, [event, eventOptions]);

    const handleEventChange = useCallback((event: EventType) => {
        setEvent(event);
        setActionOptionsLoading(true);

        tenantEventService.getActionOptions(event?.eventTypeId)
            .then((res) => {
                setActionOptions(res.data);
                setActionOptionsLoading(false);
                setAction(res.data?.[0]);
                
                if (!res.data?.[0].actionConfig) setUrl('');
            })
            .catch(() => {
                setActionOptionsLoading(false);
            });
    }, []);

    const handleCreateEvent = () => {
        setDisable(true);

        if (!event?.name || !action?.name) {
            showToast.warn(t<string>('toast.fillAllFields'));
            setDisable(false);
            return;
        }

        if (!action?.actionConfig) {
            setUrl('');
        }

        const body = action?.actionConfig ? {
            event_type: event.name,
            action,
            url,
        } : {
            event_type: event.name,
            action,
        };

        if (isEdit) {
            tenantEventService.patchEventConfig(user?.tenantId, body)
                .then(() => {
                    setIsOpen(false);
                    window.location.reload();
                })
                .catch((err) => {
                    setIsOpen(false);
                    showToast.error(err?.message);
                }).finally(() => {
                    setDisable(false);
                }
            );
        } else {
            tenantEventService.postTenantEvent(user?.tenantId, body)
                .then(() => {
                    setIsOpen(false);
                    window.location.reload();
                })
                .catch((err) => {
                    setIsOpen(false);
                    showToast.error(err?.message);
                })
                .finally(() => {
                    setDisable(false);
                }
            );
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={modalStyles}
            contentLabel="Event Configuration Modal"
        >
            <StyledModal>
                <h1>{isEdit ? t<string>('event.modal.editEvent') : t<string>('event.createEvent')}</h1>
                {!isEdit && (
                    <div>
                        <SelectObjects 
                            onChange={(e) => handleEventChange(e)}
                            loading={eventOptionsLoading}
                            noOptionsLabel={t<string>('select.noEvent')}
                            options={eventOptions || []}
                            valueKey="name"
                            labelKey="description"
                        />
                    </div>
                )}
                <div>
                    <SelectObjects 
                        onChange={(e) => setAction(e)}
                        loading={actionOptionsLoading}
                        noOptionsLabel={t<string>('select.noAction')}
                        options={actionOptions|| []}
                        valueKey="name"
                        labelKey="description"
                        disabled={actionOptions?.length === 0}
                    />
                </div>
                <div>
                    <Input
                        placeholder={t<string>('event.modal.placeholder')}
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        disabled={!action?.actionConfig}
                    />
                </div>
                <div>
                    <button
                        onClick={handleCreateEvent}
                        disabled={disable}
                    >{isEdit ? t<string>('event.modal.editButton') : t<string>('event.modal.createButton')}</button>
                </div>
            </StyledModal>
        </Modal>
    );
}

export default CreateEventModal;
