import styled from 'styled-components';

export const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 0.5rem 2rem;

	button {
			&:nth-child(2) {
			margin-left: 36px;
		}
	}
`;

type Props = {
	width?: string;
}

export const Button = styled.button<Props>`
	border-radius: 100px;
	border: none;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	background-color: #82A3C1;
	color: #FFF;
	font-size: 1rem;
	font-weight: 900;
	height: 32px;
	width: ${props => props.width ? props.width : '160px'};
	padding: 0 24px;
	cursor: pointer;

	&:focus {
		outline: none;
	}
`;
