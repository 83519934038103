/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RiWalletFill } from 'react-icons/ri';
import { GiCardboardBoxClosed } from 'react-icons/gi';
import { TbHomeStats, TbLogout } from 'react-icons/tb';
import { MdGeneratingTokens } from 'react-icons/md';
import { BsFillBoxFill, BsFillCalendarEventFill } from 'react-icons/bs';
import { IoMdArrowDropright } from 'react-icons/io';

import { AppRoutesEnum } from '../../constants';
import cognitoService from '../../services/cognitoService';
import { Container, TokensList, List, OpenedText, SignOutButton } from './style';
import AuthContext from '../../context/AuthContext';

const HomeSideBar = () => {
	const { user } = useContext(AuthContext);
	const { groups } = user;

	const navigate = useNavigate();
	const { t } = useTranslation();

	const [tokenIsOpen, setTokenIsOpen] = useState(false);
	const [productIsOpen, setProductIsOpen] = useState(false);
	const [walletIsOpen, setWalletIsOpen] = useState(false);
	const [orderIsOpen, setOrderIsOpen] = useState(false);

	const handleCreateToken = () => {
		navigate(AppRoutesEnum.TOKEN_DEFINITION);
	};

	const handleSignOut = () => {
		cognitoService.signOut().then(() => navigate(AppRoutesEnum.SIGN_IN));
	};

	return (
		<Container isOpen={tokenIsOpen || productIsOpen || walletIsOpen || orderIsOpen}>
			<List>
				<li>
					<button onClick={() => navigate(AppRoutesEnum.HOME)} className='flex items-center'>
						<TbHomeStats />
						<span>{t<string>('leftSideBar.home')}</span>
					</button>
				</li>
				{(groups.includes('SuperAdmin') || groups.includes('Tokens')) && (
					<li>
						<button onClick={() => setTokenIsOpen(!tokenIsOpen)} className='flex items-center'>
							<MdGeneratingTokens />
							<span data-hover={t<string>('leftSideBar.tokens.title')}>{t<string>('leftSideBar.tokens.title')}</span>
						</button>
						<TokensList
							isOpen={tokenIsOpen}
							height="88px"
						>
							{(groups.includes('SuperAdmin') || groups.includes('Tokens')) && (
								<li onClick={handleCreateToken}>
									<button className='flex items-center'>
										{window.location.pathname === AppRoutesEnum.TOKEN_DEFINITION 
										&& <IoMdArrowDropright />}
										<OpenedText>{t<string>('leftSideBar.tokens.create')}</OpenedText>
									</button>
								</li>
							)}
							<li onClick={() => navigate(AppRoutesEnum.TOKEN_LIST)}>
								<button className='flex items-center'>
									{window.location.pathname === AppRoutesEnum.TOKEN_LIST 
										&& <IoMdArrowDropright />}
									<OpenedText>{t<string>('leftSideBar.tokens.list')}</OpenedText>
								</button>
							</li>
						</TokensList>
					</li>
				)}
				{(groups.includes('SuperAdmin') || groups.includes('Products')) && (
					<li>
						<button onClick={() => setProductIsOpen(!productIsOpen)} className='flex items-center '>
							<BsFillBoxFill />
							<span>{t<string>('leftSideBar.products')}</span>
						</button>
						<li>
							<TokensList
								isOpen={productIsOpen}
								height="88px"

							>
								<li onClick={() => navigate(AppRoutesEnum.PRODUCTS_CREATION)}>
									<button className='flex items-center'>
										{window.location.pathname === AppRoutesEnum.PRODUCTS_CREATION 
										&& <IoMdArrowDropright />}
										<OpenedText>{t<string>('leftSideBar.tokens.create')}</OpenedText>
									</button>
								</li>
								<li onClick={() => navigate(AppRoutesEnum.PRODUCTS_LIST)}>
									<button className='flex items-center'>
										{window.location.pathname === AppRoutesEnum.PRODUCTS_LIST 
										&& <IoMdArrowDropright />}
										<OpenedText>{t<string>('leftSideBar.tokens.myProducts')}</OpenedText>
									</button>
								</li>
							</TokensList>
						</li>
					</li>
				)}
				{(groups.includes('SuperAdmin') || groups.includes('Passes')) && (
					<li>
						<button onClick={() => setWalletIsOpen(!walletIsOpen)} className='flex items-center'>
							<RiWalletFill />
							<span>{t<string>('leftSideBar.passes')}</span>
						</button>
						<li>
							<TokensList
								isOpen={walletIsOpen}
								height="128px"
							>
								<li onClick={() => navigate(AppRoutesEnum.WALLET_TEMPLATE)}>
									<button className='flex items-center'>
										{window.location.pathname === AppRoutesEnum.WALLET_TEMPLATE
										&& <IoMdArrowDropright />}
										<OpenedText>{t<string>('leftSideBar.tokens.create')}</OpenedText>
									</button>

								</li>
								<li onClick={() => navigate(AppRoutesEnum.WALLET_TEMPLATE_TABLE)}>
									<button className='flex items-center'>
										{window.location.pathname === AppRoutesEnum.WALLET_TEMPLATE_TABLE
										&& <IoMdArrowDropright />}
										<OpenedText>{t<string>('leftSideBar.tokens.myTemplates')}</OpenedText>
									</button>
								</li>
								<li onClick={() => navigate(AppRoutesEnum.WALLET_MEMBERS)}>
									<button className='flex items-center'>
										{window.location.pathname === AppRoutesEnum.WALLET_MEMBERS
										&& <IoMdArrowDropright />}
										<OpenedText>{t<string>('leftSideBar.tokens.members')}</OpenedText>
									</button>
								</li>
							</TokensList>
						</li>
					</li>
				)}
				{(groups.includes('SuperAdmin') && (
					<>
						<li>
							<button onClick={() => setOrderIsOpen(!orderIsOpen)} className='flex items-center'>
								<GiCardboardBoxClosed />
								<span>{t<string>('leftSideBar.order')}</span>
							</button>
							<li>
								<TokensList
									isOpen={orderIsOpen}
									height="40px"
								>
									<li onClick={() => navigate(AppRoutesEnum.ORDER_MAINTENANCE)}>
										<button className='flex items-center'>
											{window.location.pathname === AppRoutesEnum.ORDER_MAINTENANCE
											&& <IoMdArrowDropright />}
											<OpenedText>{t<string>('leftSideBar.tokens.myOrders')}</OpenedText>
										</button>
									</li>
								</TokensList>
							</li>
							</li>
							<li>
							<button onClick={() => navigate(AppRoutesEnum.EVENT_PAGE)} className='flex items-center'>
								<BsFillCalendarEventFill />
								<span data-hover={t<string>('leftSideBar.hover.events')}>{t<string>('leftSideBar.eventPage')}</span>
							</button>
						</li>
					</>
				))}
			</List>
			<SignOutButton onClick={handleSignOut} className='flex items-center'>
				<TbLogout />
				<span>{t<string>('leftSideBar.logout')}</span>
			</SignOutButton>
		</Container>
	);
};

export default HomeSideBar;
