import { Product } from '../types';
import api from './api';

const getProducts = (tenantId: string, tokenId?: string) =>
	api.get(`/${tenantId}/products${tokenId ? `&tokenId=${tokenId}` : ''}`);

const postProducts = (tenantId: string, product: Product) =>
	api.post(`/${tenantId}/products`, product);

const deleteProduct = (id: string) => api.delete(`/products/${id}`);

const putProduct = (id: string) => api.put(`/products/${id}`);

export default {
	getProducts,
	postProducts,
	putProduct,
	deleteProduct,
};
