import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import { AppRoutesEnum } from './constants';
import { useContext } from 'react';
import AuthContext from './context/AuthContext';
import ConfirmAccount from './pages/ConfirmAccount';
import ForgotPassword from './pages/ForgotPassword';
import Unauthorized from './pages/Unauthorized';
import Wrapper from './pages/Wrapper';
import HomeBody from './components/HomeBody';
import TokenDefinitionPage from './pages/TokenDefinition';
import TokenList from './pages/TokenList';
import ProductCreation from './pages/ProductCreation';
import ProductList from './pages/ProductList';
import EventPage from './pages/EventPage';
import cognitoService from './services/cognitoService';
import showToast from './components/Toast';
import CreateWalletTemplate from './pages/CreateWalletTemplate';
import WalletTemplateTable from './pages/WalletTemplateTable';
import WalletMembers from './pages/WalletMembers';
import OrderMaintenance from './pages/OrderMaintenance';
import WalletUpdateTiers from './pages/WalletUpdateTiers';
import { CreateWallet, WalletList, EditWallet, CreateWalletTier, TiersList, EditWalletTier } from './pages/UpdatedUI'

type privateRouteTypes = { element: JSX.Element };
type requireAuthTypes = { children: JSX.Element };

const Router = () => (
	<BrowserRouter>
		<Routes>
			<Route path={AppRoutesEnum.SIGN_IN} element={<SignIn />} />
			<Route
				path={AppRoutesEnum.FORGOT_PASSWORD}
				element={<ForgotPassword />}
			/>
			<Route
				path={AppRoutesEnum.CONFIRM_ACCOUNT}
				element={<ConfirmAccount />}
			/>
			<Route path={AppRoutesEnum.SIGN_UP} element={<SignUp />} />
			<Route
				path={AppRoutesEnum.HOME}
				element={<PrivateRoute element={<HomeBody />} />}
			/>
			<Route
				path={AppRoutesEnum.TOKEN_DEFINITION}
				element={<PrivateRoute element={<TokenDefinitionPage />} />}
			/>
			<Route
				path={AppRoutesEnum.TOKEN_LIST}
				element={<PrivateRoute element={<TokenList />} />}
			/>
			<Route
				path={AppRoutesEnum.PRODUCTS_CREATION}
				element={<PrivateRoute element={<ProductCreation />} />}
			/>
			<Route
				path={AppRoutesEnum.PRODUCTS_LIST}
				element={<PrivateRoute element={<ProductList />} />}
			/>
			<Route
				path={AppRoutesEnum.EVENT_PAGE}
				element={<PrivateRoute element={<EventPage />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_TEMPLATE}
				element={<PrivateRoute element={<CreateWalletTemplate />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_TEMPLATE_TABLE}
				element={<PrivateRoute element={<WalletTemplateTable />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_MEMBERS}
				element={<PrivateRoute element={<WalletMembers />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_UPDATE_TIER}
				element={<PrivateRoute element={<WalletUpdateTiers />} />}
			/>
			<Route
				path={AppRoutesEnum.ORDER_MAINTENANCE}
				element={<PrivateRoute element={<OrderMaintenance />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_LIST}
				element={<PrivateRouteWithoutWrapper element={<WalletList />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_TEMPLATE_CREATION}
				element={<PrivateRouteWithoutWrapper element={<CreateWallet />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_TEMPLATE_UPDATE}
				element={<PrivateRouteWithoutWrapper element={<EditWallet />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_TIER_LIST}
				element={<PrivateRouteWithoutWrapper element={<TiersList />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_TIER_CREATION}
				element={<PrivateRouteWithoutWrapper element={<CreateWalletTier />} />}
			/>
			<Route
				path={AppRoutesEnum.WALLET_TIER_UPDATE}
				element={<PrivateRouteWithoutWrapper element={<EditWalletTier />} />}
			/>
			<Route path={AppRoutesEnum.UNAUTHORIZED} element={<Unauthorized />} />
			{/* <Route path="/ann" element={<WalletList />} /> */}
		</Routes>
	</BrowserRouter>
);

const PrivateRoute = ({ element }: privateRouteTypes) => {
	return (
		<RequireAuth>
			<Wrapper>{element}</Wrapper>
		</RequireAuth>
	);
};

const PrivateRouteWithoutWrapper = ({ element }: privateRouteTypes) => {
	return <RequireAuth>{element}</RequireAuth>;
};

const RequireAuth = ({ children }: requireAuthTypes) => {
	const { user, isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

	const isAuthorized = user?.groups?.length;

	if (isLoggedIn) {
		cognitoService.isAuthenticated()
			.then((isAuthenticated: boolean) => {
				setIsLoggedIn(isAuthenticated);
				if (!isAuthenticated) {
					showToast.error('Access Token expired. Please login again.');
				}
			});
	}

	// If isLoggedIn is undefined is because the request to check if the login didn't finish yet
	if (isLoggedIn === undefined) return null;
	if (!isLoggedIn) {
		return <Navigate to={AppRoutesEnum.SIGN_IN} />;
	}

	if (!isAuthorized) {
		return <Navigate to={AppRoutesEnum.UNAUTHORIZED} />;
	}

	return children;
};

export default Router;
