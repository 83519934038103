import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 24px 24px 0 10%;
	padding: 2rem;	
	position: relative;
	width: calc(100% - 500px);

	label {
		font-size: 1.25rem;
	}

	select {
		margin-top: 12px;
	}

	option {
		box-shadow: 0 0 0.5rem #e0e0e0;
		border-radius: 0.25rem;
		border: none;
		background-color: #efeeee;
	}

	&>div {
		width: 28%;
	}

	&>div:last-child {
		width: 20%;
	}

	@media (max-width: 768px) {
		margin: 0 0.5rem;
	}
`;

export const Form = styled.form`
	padding-bottom: 2rem;

	.error {
		border: 1px solid #d9534f;
	}
`;

export const Input = styled.input`
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	width: 300px;
	height: 32px;
	font-size: 1rem;
	border: none;
	padding: 0 24px;
	margin-top: 12px;
	display: block;
	color: #647E93;

	&:placeholder {
		color: #647E93;
	}

	&:focus {
		outline: none;
	}
`;

export const TextDiv = styled.div`
	width: 35%;
	margin: 48px 12%;
	font-size: 1.25rem;
	word-wrap: break-word;
`;

export const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 0.5rem 2rem;
`;

export const CenterDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;
