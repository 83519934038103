import styled from 'styled-components';

export const Wrapper = styled.div`
	background: #F4F6F8;
	width: 100%;

	label {
		font-size: 1.25rem;
	}
`;

export const Container = styled.div`
	width: 60%;
	margin: 40px auto;

	input {
		width: 100%;
		margin-bottom: 36px;
	}

	.error {
		border: 1px solid #d9534f;
	}
`;

export const UploadContainer = styled.div`
	width: 60%;
	margin: 72px auto;

	button {
		position: relative;
		margin-left: 24px;
	}

	input {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		top: 0;
		left: 0;
		cursor: pointer;
		color: #647E93;
	}
`;

export const TextArea = styled.textarea`
	width: 100%;
	height: 120px;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	font-size: 1rem;
	border: none;
	padding: 12px 24px;
	margin-top: 12px;
	display: block;
	color: #647E93;

	&:placeholder {
		color: #647E93;
	}

	&:focus {
		outline: none;
	}
`;
