export enum AppRoutesEnum {
	SIGN_IN = '/sign-in',
	SIGN_UP = '/sign-up',
	CONFIRM_ACCOUNT = '/confirm-account',
	FORGOT_PASSWORD = '/forgot-password',
	UNAUTHORIZED = '/unauthorized',
	TOKEN_DEFINITION = '/token-definition',
	TOKEN_LIST = '/token-list',
	PRODUCTS_CREATION = '/products-creation',
	PRODUCTS_LIST = '/products-list',
	EVENT_PAGE = '/event-page',
	WALLET_LIST = '/wallet-list',
	WALLET_TEMPLATE_CREATION = '/create-wallet-template',
	WALLET_TEMPLATE_UPDATE = '/update-wallet-template',
	WALLET_TIER_LIST = '/wallet-tier-list',
	WALLET_TIER_CREATION = '/create-wallet-tier',
	WALLET_TIER_UPDATE = '/update-wallet-tier',
	WALLET_TEMPLATE = '/wallet-template',
	WALLET_TEMPLATE_TABLE = '/wallet-template-table',
	WALLET_MEMBERS = '/wallet-members',
	WALLET_UPDATE_TIER = '/wallet-update-tier',
	ORDER_MAINTENANCE = '/order-maintenance',
	HOME = '/',
}
