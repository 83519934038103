import styled from 'styled-components';

type Props = {
	width?: string;
}

export const StyledSelect = styled.select<Props>`
	display: block;
	border: none;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	min-width: 80px;
	background-color: #fff;
	color: #6583A1;
	font-size: 1rem;
	height: 32px;
	width: ${({ width }) => width || '200px'};
	padding: 0 12px 0 12px;
	appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

	&:focus {
		outline: none;
	}
`;

export const SelectDiv = styled.div<Props>`
	position: relative;
	color: #6583A1;
	width: ${({ width }) => width || '200px'};

	svg {
		position: absolute;
		right: 4px;
		top: 4px;
		font-size: 1.5rem;
	}
`;
