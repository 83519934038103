export enum SignInErrorMessages {
	INVALID_EMAIL = 'Invalid E-mail',
	REQUIRED_EMAIL = 'E-mail is Required',
	REQUIRED_PASSWORD = 'Password is Required',
	PASSWORD_SMALL = 'At least 6 characters',
}

export enum testIds {
	LOGIN_ERROR = 'loginErrorTest',
}
