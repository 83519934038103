import styled from "styled-components";

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
    },
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '24px',
        color: '#647E93',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
	},
};

export const Container = styled.div`
    width: 44vw;
    position: relative;

    h1 {
        font-size: 2rem;
        text-align: center;
    }

    svg {
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 1.5rem;
        cursor: pointer;
    }

    @media (max-width: 1750px) {
        width: 45vw;
    }

    @media (max-width: 1550px) {
        width: 50vw;
    }

    @media (max-width: 1350px) {
        width: 55vw;
    }

    @media (max-width: 1150px) {
        width: 60vw;
    }

    @media (max-width: 1050px) {
        width: 65vw;
    }

    @media (max-width: 950px) {
        width: 70vw;
    }

    @media (max-width: 850px) {
        width: 75vw;
    }

    @media (max-width: 768px) {
        width: 90vw;
    }

    @media (max-width: 650px) {
        display: block;
        text-align: center;
        width: 100%;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    justify-content: space-around;

    strong, span {
        font-size: 1.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    strong {
        width: 272px;
        font-weight: 700;
    }

    span {
        font-weight: 500;
    }
`;

export const MarkPoint = styled.strong`
    font-weight: 700;
`;

export const Flex = styled.div`
    display: flex;
    margin: 8px 0 -8px 0;
    
    div {
        &:first-child {
            margin-right: 32px;
            strong {
                width: 124px;
            }
        }

        &:last-child {
            strong {
                width: 84px;
            }
        }
    }
`;

export const Strong = styled.strong`
	width: 232px;
`;
