
import { IAndroidPassData } from '@bambu-meta/interfaces';
import { Droppable } from 'react-beautiful-dnd';
import { GrClose } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

import {
  AndroidPreviewContainer,
  AndroidBackRows,
  Placeholder,
} from './style';

type Props = {
  androidWalletTemplate: IAndroidPassData;
  handleFieldRemove: (index: number, type: string) => void;
}

const AndroidBackFields = ({ 
  androidWalletTemplate,
  handleFieldRemove,
}: Props) => {
  const { t } = useTranslation();

  return (
    <AndroidPreviewContainer backgroundColor={androidWalletTemplate.hexBackgroundColor}>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60px",
        borderBottom: "1px dashed #ccc"
      }}>
        <h1 style={{ fontSize: "1.25rem" }}>{t<string>("walletCard.detailsPage")}</h1>
      </div>
      <Droppable droppableId='backrow1'>
        {(provided, snapshot) => (
          <AndroidBackRows
            backgroundColor={androidWalletTemplate.hexBackgroundColor}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ 
              backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
            }}
          >
            {!androidWalletTemplate?.detailRows?.[0]?.items?.length && (
              <Placeholder>
                {t<string>("walletCard.placeholder.firstRow")}
              </Placeholder>
            )}
            {androidWalletTemplate?.detailRows?.[0]?.items?.map((item, index) => (
              <div 
                key={index}
                style={{
                  position: "relative",
                }}
              >
                {index === 1 ? `/ ${item}`  : item}
                <GrClose
                  style={{ position: "absolute", top: "-20px", right: "0px", cursor: "pointer" }}
                  onClick={() => handleFieldRemove(index, "backrow1")}
                />
              </div>
            ))}
          </AndroidBackRows>
        )}
      </Droppable>
      <Droppable droppableId='backrow2'>
        {(provided, snapshot) => (
           <AndroidBackRows
            backgroundColor={androidWalletTemplate.hexBackgroundColor}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ 
              backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
            }}
          >
            {!androidWalletTemplate?.detailRows?.[1]?.items?.length && (
              <Placeholder>
                {t<string>("walletCard.placeholder.secondRow")}
              </Placeholder>
            )}
            {androidWalletTemplate?.detailRows?.[1]?.items?.map((item, index) => (
              <div 
                key={index}
                style={{
                  position: "relative",
                }}
              >
                {index === 1 ? `/ ${item}`  : item}
                <GrClose
                  style={{ position: "absolute", top: "-20px", right: "0px", cursor: "pointer" }}
                  onClick={() => handleFieldRemove(index, "backrow2")}
                />
              </div>
            ))}
          </AndroidBackRows>
        )}
      </Droppable>
      <Droppable droppableId='backrow3'>
        {(provided, snapshot) => (
           <AndroidBackRows
            backgroundColor={androidWalletTemplate.hexBackgroundColor}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ 
              backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
            }}
          >
            {!androidWalletTemplate?.detailRows?.[2]?.items?.length && (
              <Placeholder>
                {t<string>("walletCard.placeholder.thirdRow")}
              </Placeholder>
            )}
            {androidWalletTemplate?.detailRows?.[2]?.items?.map((item, index) => (
              <div 
                key={index}
                style={{
                  position: "relative",
                }}
              >
                {index === 1 ? `/ ${item}`  : item}
                <GrClose
                  style={{ position: "absolute", top: "-20px", right: "0px", cursor: "pointer" }}
                  onClick={() => handleFieldRemove(index, "backrow3")}
                />
              </div>
            ))}
          </AndroidBackRows>
        )}
      </Droppable>
      <Droppable droppableId='backrow4'>
        {(provided, snapshot) => (
           <AndroidBackRows
            backgroundColor={androidWalletTemplate.hexBackgroundColor}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ 
              backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
            }}
          >
            {!androidWalletTemplate?.detailRows?.[3]?.items?.length && (
              <Placeholder>
                {t<string>("walletCard.placeholder.fourthRow")}
              </Placeholder>
            )}
            {androidWalletTemplate?.detailRows?.[3]?.items?.map((item, index) => (
              <div 
                key={index}
                style={{
                  position: "relative",
                }}
              >
                {index === 1 ? `/ ${item}`  : item}s
                <GrClose
                  style={{ position: "absolute", top: "-20px", right: "0px", cursor: "pointer" }}
                  onClick={() => handleFieldRemove(index, "backrow4")}
                />
              </div>
            ))}
          </AndroidBackRows>
        )}
      </Droppable>
    </AndroidPreviewContainer>
  );
}

export default AndroidBackFields;