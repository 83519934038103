import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 24px 24px 0 15%;
	padding: 2rem;	

	@media (max-width: 768px) {
		margin: 0 0.5rem;
	}
`;

export const Form = styled.form`
	padding-bottom: 2rem;
`;

export const TextDiv = styled.div`
	width: 35%;
	margin-right: 120px;
	font-size: 1.25rem;
	word-wrap: break-word;
`;

export const Input = styled.input`
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	width: 100%;
	height: 32px;
	font-size: 1rem;
	border: none;
	padding: 0 24px;
	margin-top: 12px;
	display: block;

	&:placeholder {
		color: #CDCDCD;
	}

	&:focus {
		outline: none;
	}
`;

export const SupplyDiv = styled.div`
	margin-top: 12px;
`;

export const OptionsDiv = styled.div`
	display: flex;

	.switch {
		margin-right: 24px;
		color: #82A3C1;
	}

	label {
		font-size: 1rem;

		&:last-child {
			margin-left: 16px;
		}
	}

	margin-bottom: 1rem;

	@media (max-width: 350px) {
		display: block;
		margin: 0 0 0 3rem;
	}
`;

export const TooltipDiv = styled.div`
	display: block;
	justify-content: flex-start;

	p {
		width: 92px;
		display: inline-block;
		font-size: 1rem;
	}
`;
