/* eslint-disable import/no-anonymous-default-export */
import { IWalletTemplateInput } from '@bambu-meta/interfaces';

import { WalletTier } from '../types';
import api from './api';

const getWalletTemplates = (tenantId: string) => api.get(`${tenantId}/wallet-templates`);

const createWalletTemplate = (tenantId: string, walletTemplate: IWalletTemplateInput) => api.post(`${tenantId}/wallet-templates`, walletTemplate);

const editWalletTemplate = (tenantId: string, walletTemplate: IWalletTemplateInput, templateId: number) => 
  api.patch(`${tenantId}/wallet-templates/${templateId}`, walletTemplate);

const deleteWalletTemplate = (tenantId: string, templateId: number) => api.delete(`${tenantId}/wallet-templates/${templateId}`);

const createWalletTier = (tenantId: string, walletTier: WalletTier, templateId: number) => 
  api.post(`${tenantId}/wallet-templates/${templateId}/wallet-tiers`, walletTier);

const getWalletTiers = (tenantId: string, templateId: number) => api.get(`${tenantId}/wallet-templates/${templateId}/wallet-tiers`);

const editWalletTiers = (tenantId: string, walletTier: any, templateId: number, tierId: number) => 
  api.patch(`${tenantId}/wallet-templates/${templateId}/wallet-tiers/${tierId}`, walletTier);

const deleteWalletTiers = (tenantId: string, templateId: number, tierId: number) => 
  api.delete(`${tenantId}/wallet-templates/${templateId}/wallet-tiers/${tierId}`);

const getWalletPassesWithoutDeleted = (tenantId: string, templateId: number) => 
  api.get(`${tenantId}/wallet-passes?status_code=900&template_id=${templateId}`);

const patchWalletPasses = (tenantId: string, passId: number, template: any) =>
  api.patch(`${tenantId}/wallet-passes/${passId}`, template);

export default {
  getWalletTemplates,
  createWalletTemplate,
  editWalletTemplate,
  deleteWalletTemplate,
  createWalletTier,
  getWalletTiers,
  editWalletTiers,
  deleteWalletTiers,
  getWalletPassesWithoutDeleted,
  patchWalletPasses,
}
