import styled from "styled-components";

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
    },
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '24px',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
        color: '#647E93',
	},
};

export const Container = styled.div`
    display: flex;
    width: 600px;
    position: relative;
    padding: 8px;

    svg {
        position: absolute;
        top: -12px;
        right: -12px;
        font-size: 1.5rem;
        cursor: pointer;
    }

    img {
        max-height: 100%;
        max-width: 100%;
    }

    @media (max-width: 1750px) {
        width: 45vw;
    }

    @media (max-width: 1550px) {
        width: 50vw;
    }

    @media (max-width: 1350px) {
        width: 55vw;
    }

    @media (max-width: 1150px) {
        width: 60vw;
    }

    @media (max-width: 1050px) {
        width: 65vw;
    }

    @media (max-width: 950px) {
        width: 70vw;
    }

    @media (max-width: 850px) {
        width: 75vw;
    }

    @media (max-width: 768px) {
        width: 90vw;
    }
`;
