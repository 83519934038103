import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import Button from '../../components/Button';
import showToast from '../../components/Toast';
import AuthContext from '../../context/AuthContext';
import walletService from '../../services/walletService';

import { modalStyles, ModalContainer } from './style';

type Props = {
  templateId: number;
  isModalOpen: boolean;
  templateTierId: number;
  setIsModalOpen: (isModalOpen: boolean) => void;
  name?: string;
}

const DeleteTemplateTierModal = ({ name, templateId, templateTierId, isModalOpen, setIsModalOpen }: Props) => {
  const { user } = useContext(AuthContext);
  const { tenantId } = user;
  const { t } = useTranslation()

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteTemplate = () => {
    if (isDeleting) return;

    setIsDeleting(true);

    walletService.deleteWalletTiers(tenantId, templateId, templateTierId)
      .then(() => {
        showToast.success(t<string>('walletCard.deleteSuccess'));
        setIsModalOpen(false);
      }).catch(() => {
        showToast.error(t<string>('walletCard.deleteError'));
      }).finally(() => {
        setIsDeleting(false);
      });
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={modalStyles}
    >
      <ModalContainer>
        <h1>{t<string>('walletCard.deleteTemplateTier')}</h1>
        <p>{name}</p>
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }} >
          <Button onClick={() => setIsModalOpen(false)} loading={isDeleting} >
            {t<string>('walletCard.cancel')}
          </Button>
          <Button
            onClick={handleDeleteTemplate}
            loading={isDeleting}
          >
            {t<string>('walletCard.delete')}
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  )
}

export default DeleteTemplateTierModal;
