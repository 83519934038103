import { ReactNode } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

type SelectInputProps = {
	/** Extra classNames */
	children: ReactNode;
	/** Extra classNames */
	className?: string;
	/** ID */
	id: string;
	/** Extra classNames */
	label: string;
	/** All other props for input */
	[x: string]: any;
};

export const SelectInput = ({
	children,
	className,
	id,
	label,
	...rest
}: SelectInputProps) => {
	return (
		<div className={className}>
			<label className="block font-sans mb-1" htmlFor={id}>
				{label}
			</label>
			<div className="relative w-full ">
				<ChevronDownIcon className="w-6 h-6 absolute text-stone-700  top-1.5 right-2 bottom-1 pointer-events-none" />
				<select
					className="px-3 py-2 rounded-lg cursor-pointer appearance-none border border-stone-200 w-full"
					id={id}
					name={id}
					{...rest}
				>
					{children}
				</select>
			</div>
		</div>
	);
};
