import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { ImagesType, WalletTemplate } from "../../../types";
import { UploadContainer, ImageContainer, PickerContainer } from "./style";
import Button from "../../Button";

type Props = {
    walletTemplate: WalletTemplate;
    setWalletTemplate: (walletTemplate: WalletTemplate) => void;
}

const ImageAndColor = ({ walletTemplate, setWalletTemplate }: Props) => {
    const imageKeys = Object.keys(walletTemplate.passType.images);
    const imageObject = imageKeys.map((key) => { return { [key]: "" } })

    const [images, setImages] = useState<any[]>(imageObject);
    const [isFilePicked, setIsFilePicked] = useState<boolean[]>([]);

    const { t } = useTranslation();
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>, index: number, image: string) => {
        let imageBody: ImagesType = {
            logo: '',
            icon: '',
        };

		if (event.target.files) {
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.readyState === 2) {
                    images[index][image] = reader.result;
                    setImages([...images]);
                    isFilePicked[index] = true;
                    setIsFilePicked([...isFilePicked]);
            
                    images.forEach((image) => {
                        imageBody = {
                            ...imageBody,
                            [Object.keys(image)[0]]: image[Object.keys(image)[0]],
                        }
                    });
            
                    walletTemplate.images = imageBody;
            
                    setWalletTemplate({
                        ...walletTemplate,
                    });
				}
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

    const handleBackgroundColorChange = (color: any) => {
        setWalletTemplate({
            ...walletTemplate,
            backgroundColor: color,
        });
    }

    const handleForegroundColorChange = (color: any) => {
        setWalletTemplate({
            ...walletTemplate,
            foregroundColor: color,
        });
    }

    const handleLabelColorChange = (color: any) => {
        setWalletTemplate({
            ...walletTemplate,
            labelColor: color,
        });
    }

    return (
        <ImageContainer>
            <h1 className={imageKeys?.length === 5 ? "eventTicket" : ""}>{t<string>("walletCard.customization.imgTitle")}</h1>
            {
                imageKeys?.map((image: string, index) => (
                    <UploadContainer key={index}>
                        <p>{(index === 0 || index === 1) ? `${image} *` : image}</p>
                        <Button
                            width='300px'
                        >
                            {(isFilePicked[index] || Object.values(walletTemplate.images)[index])
                                ? t<string>('createProduct.information.basics.placeholder.uploaded') 
                                : t<string>('createProduct.information.basics.placeholder.browse')
                            }
                            <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => changeHandler(e, index, image)} />
                        </Button>
                    </UploadContainer>
                ))
            }
            <PickerContainer>
                <p>{t<string>('walletCard.description.backgroundColor')}</p>
                <div>
                    <input type="color" value={walletTemplate?.backgroundColor} onChange={(e) => handleBackgroundColorChange(e.target.value)} />
                </div>
            </PickerContainer>
            <PickerContainer>
                <p>{t<string>('walletCard.description.foregroundColor')}</p>
                <div>
                    <input type="color" value={walletTemplate?.foregroundColor} onChange={(e) => handleForegroundColorChange(e.target.value)} />
                </div>
            </PickerContainer>
            <PickerContainer>
                <p>{t<string>('walletCard.description.labelColor')}</p>
                <div>
                    <input type="color" value={walletTemplate?.labelColor} onChange={(e) => handleLabelColorChange(e.target.value)} />
                </div>
            </PickerContainer>
        </ImageContainer>
    );

}

export default ImageAndColor;
