import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import Modal from 'react-modal';

import useQueryWalletTiers from '../../hooks/useQuery/useQueryWalletTiers';

import { modalStyles, ModalContainer } from './style';

import DeleteTemplateTierModal from './DeleteTierModal';
import EditWalletTier from '../../pages/WalletTemplateTierEdit';

type Props = {
  templateId: number;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  iosWalletTemplateData: any;
  androidWalletTemplateData: any;
  fieldMappings: any;
  setIsEditingTier: (isEditingTier: boolean) => void;
  isEditingTier: boolean;
  repopulateTemplateData: (template: any) => void;
  name?: string;
}

const TierListModal = ({ 
  name, 
  templateId, 
  isModalOpen, 
  setIsModalOpen,
  iosWalletTemplateData,
  isEditingTier,
  setIsEditingTier,
  androidWalletTemplateData,
  fieldMappings,
  repopulateTemplateData,
}: Props) => {
  const { data: walletTiers, isLoading } = useQueryWalletTiers(templateId, isModalOpen);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [tierId, setTierId] = useState(0);
  const [tierName, setTierName] = useState('');

  const { t } = useTranslation();

  const handleEditClick = (templateTierId: number) => {
    const template = walletTiers?.find((tier: any) => tier.templateTierId === templateTierId);

    setTierId(templateTierId);
    repopulateTemplateData(template);
    setIsEditingTier(true);
  }

  const handleDeleteClick = (templateTier: any) => {
    const template = walletTiers?.find((tier: any) => tier.templateTierId === templateTier.templateTierId);

    setTierId(template.templateTierId);
    setTierName(template.name);
    setIsDeleteModalOpen(true);
  }

  return (
    <>
      {isEditingTier ? (
        <EditWalletTier
          templateId={templateId}
          templateTierId={tierId}
          iosWalletTemplateData={iosWalletTemplateData}
          androidWalletTemplateData={androidWalletTemplateData}
          fieldMappings={fieldMappings}
          setIsEditingTier={setIsEditingTier}
        />
      ) : (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={modalStyles}
        >
          <ModalContainer>
            {isLoading ? (
              <div style={{ 
                width: "300px", 
                height: "250px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center",
              }}>
                <ThreeDots color="#647E93" />
              </div>
            ) : (
              <>
                <h1>{`${name} ${t<string>('walletCard.tierList')}`}</h1>
                <div>
                  {!walletTiers?.length && <p>{t<string>('walletCard.noTiers')}</p>}
                  {walletTiers?.map((tier: any) => (
                    <div key={tier.templateTierId}>
                      <h2>{tier.name}</h2>
                      <div className='flex'>
                        <AiOutlineEdit onClick={() => handleEditClick(tier?.templateTierId)} />
                        <AiOutlineDelete onClick={() => handleDeleteClick(tier)} />
                      </div>
                    </div>
                  ))}
                </div>
                <DeleteTemplateTierModal
                  name={tierName}
                  templateId={templateId}
                  templateTierId={tierId}
                  isModalOpen={isDeleteModalOpen}
                  setIsModalOpen={setIsDeleteModalOpen}
                />
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}

export default TierListModal;