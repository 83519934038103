import styled from "styled-components";

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
    },
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '24px',
        color: '#647E93',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
	},
};

export const Container = styled.div`
    display: flex;
    width: 40vw;
    position: relative;

    h1 {
        font-size: 1.5rem;
        margin-left: 32px;
    }

    svg {
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 1.5rem;
        cursor: pointer;
    }

    @media (max-width: 1750px) {
        width: 45vw;
    }

    @media (max-width: 1550px) {
        width: 50vw;
    }

    @media (max-width: 1350px) {
        width: 55vw;
    }

    @media (max-width: 1150px) {
        width: 60vw;
    }

    @media (max-width: 1050px) {
        width: 65vw;
    }

    @media (max-width: 950px) {
        width: 70vw;
    }

    @media (max-width: 850px) {
        width: 75vw;
    }

    @media (max-width: 768px) {
        width: 90vw;
    }

    @media (max-width: 650px) {
        display: block;
        text-align: center;
        width: 100%;
    }
`;

export const ImageContainer = styled.div`
    height: 350px;
    width: 350px;
    box-sizing: border-box;
    
    img {
        max-height: 100%;
        max-width: 100%;
        cursor: pointer;
    }
`;

export const InfoContainer = styled.div`
    padding: .5rem;
    display: flex;
    flex-direction: column;
    row-gap: .25rem;

    h1 {
        font-size: 1.5rem;
        color: #417082;
        line-height: 2rem;
        margin-bottom: 1rem;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }

    strong, span {
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    strong {
        width: 11rem;
    }
`;

export const Position = styled.div`
    font-size: 1rem;

    @media (max-width: 650px) {
        text-align: center;
    }
`;

export const MarkPoint = styled.span`
    margin-top: 1rem;
    color: #417082;
`;

export const AttributesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: .5rem;
    column-gap: .5rem;
    font-size: 1rem;
`;

export const ImageAttribute = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12rem;
    height: 12rem;

    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;

    img {
        max-height: 100%;
        max-width: 100%;
        cursor: pointer;
    }

    @media (max-width: 650px) {
        width: 30%;
        height: 9rem;
    }
`;

export const TextAttribute = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 12rem;
    height: 12rem;

    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;

    @media (max-width: 650px) {
        width: 30%;
        height: 9rem;
        margin-bottom: 0;
    }
`;  
