import { IoMdArrowDropdown } from 'react-icons/io';

import { testIds } from './constants';
import { StyledSelect, SelectDiv } from './style';
import { BasicSelectProps } from '../../types';

type SelectProps = {
	options: any[];
	loading?: boolean;
	onChange?: (e: any) => any;
	noOptionsLabel: string;
	selectedOption?: string;
	needDisabledOption?: string;
	width?: string;
};

const Select = ({
	options,
	loading,
	noOptionsLabel,
	selectedOption,
	onChange,
	width,
	needDisabledOption,
}: SelectProps) => (
	<BasicSelect
		onChange={onChange}
		loading={loading}
		noOptionsLabel={noOptionsLabel}
		options={options}
		width={width}
	>
		{needDisabledOption && <option disabled>{needDisabledOption}</option>}
		{options?.map((option: any) => (
			<option
				data-testid={testIds.OPTION}
				key={option}
				value={option}
				selected={option === selectedOption}
			>
				{option}
			</option>
		))}
	</BasicSelect>
);

type SelectObjectsProps = {
	options: any[];
	loading?: boolean;
	onChange: (e: any) => any;
	noOptionsLabel: string;
	selectedOption?: string;
	valueKey: string;
	labelKey: string;
	disabled?: boolean;
	needDisabledOption?: string;
	width?: string;
};

const SelectObjects = ({
	options,
	loading,
	noOptionsLabel,
	selectedOption,
	valueKey,
	labelKey,
	onChange,
	disabled,
	needDisabledOption,
	width,
}: SelectObjectsProps) => {
	const handleOnChange = (e: any) => {
		const selectedObject = JSON.parse(e.target.value);
		onChange(selectedObject);
	};

	return (
		<BasicSelect
			onChange={(e) => handleOnChange(e)}
			loading={loading}
			noOptionsLabel={noOptionsLabel}
			options={options}
			disabled={disabled}
			width={width}
		>
			{needDisabledOption && <option disabled>{needDisabledOption}</option>}
			{options?.map((option: any) => (
				<option
					data-testid={testIds.OPTION}
					key={option?.[valueKey]}
					value={JSON.stringify(option)}
					selected={option?.[valueKey] == selectedOption}
				>
					{option[labelKey]}
				</option>
			))}
		</BasicSelect>
	);
};

const BasicSelect = ({
	onChange,
	loading,
	noOptionsLabel,
	options,
	children,
	disabled,
	width,
}: BasicSelectProps) => (
	<SelectDiv width={width}>
		<StyledSelect onChange={onChange} data-testid={testIds.SELECT} disabled={disabled} width={width} >
			{loading ? (
				<option>Loading...</option>
			) : !options?.length ? (
				<option>{noOptionsLabel}</option>
			) : (
				<>{children}</>
			)}
		</StyledSelect>
		<IoMdArrowDropdown />
	</SelectDiv>
);

export { Select, SelectObjects };
