interface TextareaGroupProps {
	/** Extra classNames */
	className?: string;
	/** ID */
	id: string;
	/** Extra classNames */
	label: string;
	/** Function input performs onChange */
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	/** Placeholder for input */
	placeholder: string;
	/** value of input */
	value: string;
	/** All other props for input */
	[x: string]: any;
}

export const TextareaGroup = ({
	className,
	id,
	label,
	onChange,
	placeholder,
	value,
	...rest
}: TextareaGroupProps) => {
	return (
		<div className={className}>
			<label className="block font-sans mb-1" htmlFor={id}>
				{label}
			</label>
			<textarea
				className="box-border px-3 py-2 w-full border border-solid border-stone-200 rounded-lg flex justify-between placeholder:text-stone-400 placeholder:font-sans"
				id={id}
				name={id}
				onChange={(e) => onChange(e)}
				placeholder={placeholder}
				value={value}
				{...rest}
			/>
		</div>
	);
};
