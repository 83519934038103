type ColorInputProps = {
	/** Extra classNames */
	className?: string;
	/** ID */
	id: string;
	/** Extra classNames */
	label: string;
	/** Function input performs onChange */
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	/** Color hex value */
	value: string;
	/** All other props for input */
	[x: string]: any;
};

export const ColorInput = ({
	className,
	id,
	label,
	onChange,
	value,
	...rest
}: ColorInputProps) => {
	return (
		<div className={className}>
			<label className="block font-sans mb-2" htmlFor={id}>
				{label}
			</label>
			<input
				className="bg-white border border-stone-400 rounded px-2 py-1 h-10 w-20 hover:bg-stone-100 cursor-pointer"
				type="color"
				id={id}
				name={id}
				onChange={onChange}
				value={value}
				{...rest}
			/>
		</div>
	);
};
