import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductNavigationBar from '../ProductNavigationBar';
import ProductsBasics from '../ProductBasics';
import ProductSupply from '../ProductSupply';
import ProductPrice from '../ProductPrice';
import ProducPayload from '../ProductPayload';

import { Product } from '../../../types/Product';

import { Container } from './style';
import { TokenData } from '../../../types';

type Props = {
	product: Product;
	setProduct: (product: Product) => void;
	handleProductCreation: () => void;
	token: TokenData | null;
	selectedFile: any;
	setSelectedFile: (arg0: any) => void;
};

const ProductsCreationContainer = ({
	product,
	setProduct,
	handleProductCreation,
	token,
	selectedFile,
	setSelectedFile,
}: Props) => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState(`1. ${t<string>('createProduct.information.navigation.basics')}`);

	const tabs = (token?.isNFT && token?.metadata?.length)
		? [
				{
					name: `1. ${t<string>('createProduct.information.navigation.basics')}`,
					element: (
						<ProductsBasics
							setActiveTab={setActiveTab}
							product={product}
							setProduct={setProduct}
							isNFT={token.isNFT}
							selectedFile={selectedFile}
							setSelectedFile={setSelectedFile}
						/>
					),
				},
				{
					name: `2. ${t<string>('createProduct.information.navigation.payload')}`,
					element: (
						<ProducPayload
							setActiveTab={setActiveTab}
							product={product}
							setProduct={setProduct}
							token={token}
						/>
					),
				},
				{
					name: `3. ${t<string>('createProduct.information.navigation.supply')}`,
					element: (
						<ProductSupply
							setActiveTab={setActiveTab}
							isNFT={token.isNFT}
							product={product}
							setProduct={setProduct}
						/>
					),
				},
				{
					name: `4. ${t<string>('createProduct.information.navigation.price')}`,
					element: (
						<ProductPrice
							setActiveTab={setActiveTab}
							isNFT={token.isNFT}
							product={product}
							setProduct={setProduct}
							handleProductCreation={handleProductCreation}
						/>
					),
				},
		  ]
		: [
				{
					name: `1. ${t<string>('createProduct.information.navigation.basics')}`,
					element: (
						<ProductsBasics
							setActiveTab={setActiveTab}
							product={product}
							setProduct={setProduct}
							isNFT={false}
							selectedFile={selectedFile}
							setSelectedFile={setSelectedFile}
						/>
					),
				},
				{
					name: `2. ${t<string>('createProduct.information.navigation.supply')}`,
					element: (
						<ProductSupply
							setActiveTab={setActiveTab}
							isNFT={false}
							product={product}
							setProduct={setProduct}
						/>
					),
				},
				{
					name: `3. ${t<string>('createProduct.information.navigation.price')}`,
					element: (
						<ProductPrice
							setActiveTab={setActiveTab}
							isNFT={false}
							product={product}
							setProduct={setProduct}
							handleProductCreation={handleProductCreation}
						/>
					),
				},
		  ];

	return (
		<Container>
			<ProductNavigationBar activeTab={activeTab} tabs={tabs} />
			{tabs.map((tab) => (
				<span style={{ width: "100%", height: "100%" }} key={tab.name}>{activeTab === tab.name && tab.element}</span>
			))}
		</Container>
	);
};

export default ProductsCreationContainer;
