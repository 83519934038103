import styled from 'styled-components';

export const ResponsiveDiv = styled.div`
	width: calc(100vw - 216px);
	background-color: #F5F5F6;
	height: calc(100vh - 170px);
	box-sizing: border-box;
	margin: -20px 0 0 -12px;
	color: #647E93;

	&>h1 {
		margin-left: 24px;
	}
`;

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		color: '#647E93',
		borderRadius: '16px',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
	},
};

export const Table = styled.table`
	text-align: left;
	border-collapse: collapse;
	width: calc(100vw - 400px);
	font-size: .9rem;
	background-color: #FFF;
	color: #647E93;
	margin: 24px;
	border-radius: 16px;
	
	@media (max-width: 600px) {
		font-size: 8px;
	}
`;

export const Thead = styled.thead`	
`;

export const Th = styled.th`
	border: none;
	font-weight: 500;
	color: #647E93;
	padding: 24px 24px 48px 48px;
	font-size: 1.25rem;

	@media (max-width: 600px) {
		select {
			padding: .25rem;
			width: 2rem;
		}
	}
`;

export const Td = styled.td`
	border: none;
	font-weight: 500;
	color: #647E93;	
	font-size: 1rem;
	padding: 12px 24px 12px 48px;

	@media (max-width: 600px) {
		padding: 0.75rem 0;
		font-size: .75rem;

		&:last-child {
			text-align: right;
		}
	}

	svg {
		cursor: pointer;
		font-size: 20px;
		margin-right: .25rem;

		@media (max-width: 600px) {
			font-size: .75rem;
		}
	}
`;

export const Tr = styled.tr`
	&:nth-child(odd) {
		background-color: rgba(101, 131, 161, 0.1);
	}
`;

export const CenterDiv = styled.td`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const PaddingDiv = styled.div`
	font-size: 1.5rem;
	width: 400px;
	height: 320px;
	font-weight: 900;
	padding: 0 24px;	

	p {
		color: #FF0000;
		font-weight: 400;
		margin-top: 48px;
	}
`;

export const EditDiv = styled.div`
	width: 1350px;
	padding: 0 24px;
`;


export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 24px;
	padding-bottom: 12px;
	border-bottom: 4px solid #647E93;
	
	h1 {
		font-size: 2rem;
	}

	button {
		border-radius: 100px;
		border: none;
		box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
		background-color: #82A3C1;
		color: #FFF;
		font-size: 1rem;
		font-weight: 900;
		height: 32px;
		width: 160px;
		padding: 0 24px;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}
`;

export const Input = styled.input`
	height: 32px;
	min-width: 220px;
	border-radius: 100px;
	border: none;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	margin-left: 24px;
	letter-spacing: .5px;
	padding: 0 24px;
	margin-top: 24px;

	&:focus {
		outline: none;
	}

	@media (max-width: 768px) {
		width: 4.25rem;
		font-size: 0.6rem;
		padding: 0.4rem;
		box-sizing: border-box;
		text-align: center;
	}

	font-size: 1rem;
`;

export const NameFilter = styled.div`
	display: flex;

	button {
		margin-left: -32px;
		margin-top: 24px;
	}
`;
