import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 24px 24px 5%;
	padding: 2rem;	

	@media (max-width: 768px) {
		margin: 0 0.5rem;
	}
`;

export const Labels = styled.div`
	display: flex;
	font-size: 1.25rem;
	justify-content: space-between;
	margin-bottom: 48px;
`;

export const SelectDiv = styled.div`
	margin-left: 52px;
`;

export const FieldsDiv = styled.div`
	width: 100%;

	@media (max-width: 768px) {
		text-align: center;
	}
`;

export const FieldButton = styled.div`
	margin-top: 36px;
`;

export const FieldContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 24px 0;

	div:nth-child(2), div:nth-child(3) {
		margin-right: 48px;
	}

	input {
		box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
		border-radius: 100px;
		width: 400px;
		height: 32px;
		font-size: 1rem;
		border: none;
		padding: 0 24px;
		display: block;
		color: #647E93; 
	
		&:placeholder {
			color: #CDCDCD;
		}
	
		&:focus {
			outline: none;
		}
	}

	@media (max-width: 1768px) {
		width: 97%;
	}

	@media (max-width: 1468px) {
		width: 95%;
		margin-left: 1rem;
	}

	@media (max-width: 768px) {
		display: block;
		margin-left: 0;

		div {
			margin-left: 0.5rem;
			width: 100%;
		}
	}

	@media (max-width: 650px) {
		input {
			width: 95%;
		}
	}

	@media (max-width: 500px) {
		input {
			width: 94%;
		}
	}

	@media (max-width: 500px) {
		input {
			width: 93%;
		}
	}

	@media (max-width: 385px) {
		input {
			width: 92%;
		}
	}

	@media (max-width: 345px) {
		input {
			width: 91%;
		}
	}

	@media (max-width: 300px) {
		input {
			width: 89%;
		}
	}
`;
