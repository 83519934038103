import styled from 'styled-components';

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '.5rem',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
		color: '#647E93',
	},
};

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 4px 36px;

  &>h1 {
    font-size: 1.5rem;
    display: inline-block;
  }
`;

export const Previews = styled.div`
	display: flex;

	&>div {
		margin: 8px;
	}
`;
