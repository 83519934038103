import ReactECharts from 'echarts-for-react';

const LinearChart = () => {
  const option = {
    title: {
      text: 'Revenue',
      textStyle: {
        fontFamily: [
          "Avenir Next LT Pro",
          "Montserrat", 
          "serif",
        ],
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: ['Sep 15', 'Sep 30', 'Oct 15', 'Oct 30', 'Nov 15', 'Nov 30', 'Dec 15'],
      textStyle: {
        fontFamily: [
          "Avenir Next LT Pro",
          "Montserrat", 
          "serif",
        ],
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} $'
      },
      fontFamily: [
        "Avenir Next LT Pro",
        "Montserrat", 
        "serif",
      ],
    },
    series: [
      {
        data: [320, 532, 701, 934, 1290, 1330, 1120],
        type: 'line',
        textStyle: {
          fontFamily: [
            "Avenir Next LT Pro",
            "Montserrat", 
            "serif",
          ],
        },
        areaStyle: {
          color: "#81b5c6",
        },
        itemStyle: {
          color: "#81b5c6",
        },
      }
    ]
  };
 
  return (
    <ReactECharts option={ option } />
  );
}

export default LinearChart;
