import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";
import { DropResult } from "react-beautiful-dnd";

import showToast from "../../components/Toast";
import { WalletTemplate } from "../../types";

export const useOnDragEnd = (
  result: DropResult,
  walletTemplate: WalletTemplate,
  setWalletTemplate: (walletTemplate: WalletTemplate) => void,
  androidWalletTemplate: any,
  setAndroidWalletTemplate: (androidWalletTemplate: any) => void,
  fields: IWalletPassFieldMappings[]
) => {
  if (!result.destination || (result.destination.droppableId === result.source.droppableId 
      && result.destination.index === result.source.index)) return;

  if (result.draggableId.split(" ")[0] === "appleBackPreview" && result.destination?.droppableId === "appleBackPreview" && walletTemplate.backFields?.length) {
    const [removed] = walletTemplate.backFields.splice(result.source.index, 1);
    walletTemplate.backFields?.splice(result.destination.index, 0, removed);
    setWalletTemplate({ ...walletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "primaryField" && result.destination?.droppableId === "primaryField" && walletTemplate.primaryFields?.length) {
    const [removed] = walletTemplate.primaryFields.splice(result.source.index, 1);
    walletTemplate.primaryFields?.splice(result.destination.index, 0, removed);
    setWalletTemplate({ ...walletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "secondaryField" && result.destination?.droppableId === "secondaryField" && walletTemplate.secondaryFields?.length) {
    const [removed] = walletTemplate.secondaryFields.splice(result.source.index, 1);
    walletTemplate.secondaryFields?.splice(result.destination.index, 0, removed);
    setWalletTemplate({ ...walletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "auxiliaryField" && result.destination?.droppableId === "auxiliaryField" && walletTemplate.auxiliaryFields?.length) {
    const [removed] = walletTemplate.auxiliaryFields.splice(result.source.index, 1);
    walletTemplate.auxiliaryFields?.splice(result.destination.index, 0, removed);
    setWalletTemplate({ ...walletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "headerField" && result.destination?.droppableId === "headerField" && walletTemplate.headerFields?.length) {
    const [removed] = walletTemplate.headerFields.splice(result.source.index, 1);
    walletTemplate.headerFields?.splice(result.destination.index, 0, removed);
    setWalletTemplate({ ...walletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "row1" && result.destination?.droppableId === "row1" && androidWalletTemplate.rows[0]?.items?.length) {
    const [removed] = androidWalletTemplate.rows[0]?.items?.splice(result.source.index, 1);
    androidWalletTemplate.rows[0]?.items?.splice(result.destination.index, 0, removed);
    setAndroidWalletTemplate({ ...androidWalletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "row2" && result.destination?.droppableId === "row2" && androidWalletTemplate.rows[1]?.items?.length) {
    const [removed] = androidWalletTemplate.rows[1]?.items?.splice(result.source.index, 1);
    androidWalletTemplate.rows[1]?.items?.splice(result.destination.index, 0, removed);
    setAndroidWalletTemplate({ ...androidWalletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "row3" && result.destination?.droppableId === "row3" && androidWalletTemplate.rows[2]?.items?.length) {
    const [removed] = androidWalletTemplate.rows[2]?.items?.splice(result.source.index, 1);
    androidWalletTemplate.rows[2]?.items?.splice(result.destination.index, 0, removed);
    setAndroidWalletTemplate({ ...androidWalletTemplate });

    return;
  }
  
  if (result.draggableId.split(" ")[0] === "backrow1" && result.destination?.droppableId === "row1" && androidWalletTemplate.detailRows[0]?.items?.length) {
    const [removed] = androidWalletTemplate.detailRows[0]?.items?.splice(result.source.index, 1);
    androidWalletTemplate.detailRows[0]?.items?.splice(result.destination.index, 0, removed);
    setAndroidWalletTemplate({ ...androidWalletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "backrow2" && result.destination?.droppableId === "row2" && androidWalletTemplate.detailRows[1]?.items?.length) {
    const [removed] = androidWalletTemplate.detailRows[1]?.items?.splice(result.source.index, 1);
    androidWalletTemplate.detailRows[1]?.items?.splice(result.destination.index, 0, removed);
    setAndroidWalletTemplate({ ...androidWalletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "backrow3" && result.destination?.droppableId === "row3" && androidWalletTemplate.detailRows[2]?.items?.length) {
    const [removed] = androidWalletTemplate.detailRows[2]?.items?.splice(result.source.index, 1);
    androidWalletTemplate.detailRows[2]?.items?.splice(result.destination.index, 0, removed);
    setAndroidWalletTemplate({ ...androidWalletTemplate });

    return;
  }

  if (result.draggableId.split(" ")[0] === "backrow4" && result.destination?.droppableId === "row4" && androidWalletTemplate.detailRows[3]?.items?.length) {
    const [removed] = androidWalletTemplate.detailRows[3]?.items?.splice(result.source.index, 1);
    androidWalletTemplate.detailRows[3]?.items?.splice(result.destination.index, 0, removed);
    setAndroidWalletTemplate({ ...androidWalletTemplate });

    return;
  }
  
  if (result.destination?.droppableId === "appleBackPreview") {
    if (walletTemplate.backFields?.length === walletTemplate.passType.fields.backFields.quantity) {
      showToast.info("You can't add more back fields");
      return;
    }

    if (walletTemplate.backFields?.length) {
      walletTemplate.backFields.splice(result.destination.index, 0, fields[result.source.index]);
      setWalletTemplate({ ...walletTemplate });
    } else setWalletTemplate({ ...walletTemplate, backFields: [fields[result.source.index]] });
  }

  if (result.destination?.droppableId === "primaryField") {
    if (walletTemplate.primaryFields?.length === walletTemplate.passType.fields.primaryFields.quantity) {
      showToast.info("You can't add more primary fields");
      return;
    }
    
    if (result.draggableId.split(" ")[0] === "secondaryField" && walletTemplate?.secondaryFields?.length) {
      setWalletTemplate({ ...walletTemplate, primaryFields: [walletTemplate.secondaryFields[result.source.index]] });
      walletTemplate.secondaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "auxiliaryField" && walletTemplate?.auxiliaryFields?.length) {
      setWalletTemplate({ ...walletTemplate, primaryFields: [walletTemplate.auxiliaryFields[result.source.index]] });
      walletTemplate.auxiliaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "headerField" && walletTemplate?.headerFields?.length) {
      setWalletTemplate({ ...walletTemplate, primaryFields: [walletTemplate.headerFields[result.source.index]] });
      walletTemplate.headerFields.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      setWalletTemplate({ ...walletTemplate, primaryFields: [fields[result.source.index]] });
    }
  }

  if (result.destination?.droppableId === "secondaryField") {
    if (walletTemplate.secondaryFields?.length === walletTemplate.passType.fields.secondaryFields.quantity) {
      showToast.info("You can't add more secondary fields");
      return;
    }

    if (walletTemplate?.secondaryFields?.length) {
      if (result.draggableId.split(" ")[0] === "primaryField" && walletTemplate?.primaryFields?.length) {
        walletTemplate.secondaryFields.splice(result.destination.index, 0, walletTemplate.primaryFields[result.source.index]);
        walletTemplate.primaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else if (result.draggableId.split(" ")[0] === "auxiliaryField" && walletTemplate?.auxiliaryFields?.length) {
        walletTemplate.secondaryFields.splice(result.destination.index, 0, walletTemplate.auxiliaryFields[result.source.index]);
        walletTemplate.auxiliaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else if (result.draggableId.split(" ")[0] === "headerField" && walletTemplate?.headerFields?.length) {
        walletTemplate.secondaryFields.splice(result.destination.index, 0, walletTemplate.headerFields[result.source.index]);
        walletTemplate.headerFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else {
        walletTemplate.secondaryFields.splice(result.destination.index, 0, fields[result.source.index]);
      }
      setWalletTemplate({ ...walletTemplate });
    } else {
      if (result.draggableId.split(" ")[0] === "primaryField" && walletTemplate?.primaryFields?.length) {
        setWalletTemplate({ ...walletTemplate, secondaryFields: [walletTemplate.primaryFields[Number(result.draggableId.split(" ")[1])]] });
        walletTemplate.primaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else if (result.draggableId.split(" ")[0] === "auxiliaryField" && walletTemplate?.auxiliaryFields?.length) {
        setWalletTemplate({ ...walletTemplate, secondaryFields: [walletTemplate.auxiliaryFields[result.source.index]] });
        walletTemplate.auxiliaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else if (result.draggableId.split(" ")[0] === "headerField" && walletTemplate?.headerFields?.length) {
        setWalletTemplate({ ...walletTemplate, secondaryFields: [walletTemplate.headerFields[result.source.index]] });
        walletTemplate.headerFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else {
        setWalletTemplate({ ...walletTemplate, secondaryFields: [fields[result.source.index]] });
      }
    }
  }

  if (result.destination?.droppableId === "auxiliaryField") {
    if (walletTemplate.auxiliaryFields?.length === walletTemplate.passType.fields.auxiliaryFields.quantity) {
      showToast.info("You can't add more auxiliary fields");
      return;
    }
    
    if (walletTemplate?.auxiliaryFields?.length) {
      if (result.draggableId.split(" ")[0] === "primaryField" && walletTemplate?.primaryFields?.length) {
        walletTemplate.auxiliaryFields.splice(result.destination.index, 0, walletTemplate.primaryFields[result.source.index]);
        walletTemplate.primaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else if (result.draggableId.split(" ")[0] === "secondaryField" && walletTemplate?.secondaryFields?.length) {
        walletTemplate.auxiliaryFields.splice(result.destination.index, 0, walletTemplate.secondaryFields[result.source.index]);
        walletTemplate.auxiliaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else if (result.draggableId.split(" ")[0] === "headerField" && walletTemplate?.headerFields?.length) {
        walletTemplate.auxiliaryFields.splice(result.destination.index, 0, walletTemplate.headerFields[result.source.index]);
        walletTemplate.headerFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else {
        walletTemplate.auxiliaryFields.splice(result.destination.index, 0, fields[result.source.index]);
      }
      setWalletTemplate({ ...walletTemplate });
    } else {
      if (result.draggableId.split(" ")[0] === "primaryField" && walletTemplate?.primaryFields?.length) {
        setWalletTemplate({ ...walletTemplate, auxiliaryFields: [walletTemplate.primaryFields[Number(result.draggableId.split(" ")[1])]] });
        walletTemplate.primaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else if (result.draggableId.split(" ")[0] === "secondaryField" && walletTemplate?.secondaryFields?.length) {
        setWalletTemplate({ ...walletTemplate, auxiliaryFields: [walletTemplate.secondaryFields[result.source.index]] });
        walletTemplate.secondaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else if (result.draggableId.split(" ")[0] === "headerField" && walletTemplate?.headerFields?.length) {
        setWalletTemplate({ ...walletTemplate, auxiliaryFields: [walletTemplate.headerFields[result.source.index]] });
        walletTemplate.headerFields.splice(Number(result.draggableId.split(" ")[1]), 1);
      } else {
        setWalletTemplate({ ...walletTemplate, auxiliaryFields: [fields[result.source.index]] });
      }
    }
  }

  if (result.destination?.droppableId === "headerField") {
    if (walletTemplate.headerFields?.length === walletTemplate.passType.fields.headerFields.quantity) {
      showToast.info("You can't add more header fields");
      return;
    }

    if (result.draggableId.split(" ")[0] === "secondaryField" && walletTemplate?.secondaryFields?.length) {
      setWalletTemplate({ ...walletTemplate, headerFields: [walletTemplate.secondaryFields[result.source.index]] });
      walletTemplate.secondaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "auxiliaryField" && walletTemplate?.auxiliaryFields?.length) {
      setWalletTemplate({ ...walletTemplate, headerFields: [walletTemplate.auxiliaryFields[result.source.index]] });
      walletTemplate.auxiliaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "primaryField" && walletTemplate?.primaryFields?.length) {
      setWalletTemplate({ ...walletTemplate, headerFields: [walletTemplate.primaryFields[result.source.index]] });
      walletTemplate.primaryFields.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      setWalletTemplate({ ...walletTemplate, headerFields: [fields[result.source.index]] });
    }
  }

  if (result.destination?.droppableId === "row1") {
    if (androidWalletTemplate?.rows?.[0]?.items?.length === 3) {
      showToast.info("You can't add more fields to this row");
      return;
    }

    let isRepeted = false;
    if (result.draggableId.split(" ")[0] === "row2") {
      androidWalletTemplate.rows[0]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.rows[1]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "row3") {
      androidWalletTemplate.rows[0]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.rows[2]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else {
      androidWalletTemplate.rows[0]?.items?.forEach((item: any) => {
        if (item.key === fields[result.source.index].key) {
          isRepeted = true;
        }
      })
    }

    if (isRepeted) {
      showToast.info("You can't add same field to this row");
      return;
    }

    if (result.draggableId.split(" ")[0] === "row2" && androidWalletTemplate?.rows?.[1]?.items?.length) {
      androidWalletTemplate.rows[0]?.items?.splice(result.destination.index, 0, androidWalletTemplate.rows[1]?.items[result.source.index]);
      androidWalletTemplate.rows[1]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "row3" && androidWalletTemplate?.rows?.[2]?.items?.length) {
      androidWalletTemplate.rows[0]?.items?.splice(result.destination.index, 0, androidWalletTemplate.rows[2]?.items[result.source.index]);
      androidWalletTemplate.rows[2]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      androidWalletTemplate.rows[0]?.items?.splice(result.destination.index, 0, fields[result.source.index]);
    }

    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.destination?.droppableId === "row2") {
    if (androidWalletTemplate?.rows?.[1]?.items?.length === 3) {
      showToast.info("You can't add more fields to this row");
      return;
    }

    let isRepeted = false;
    if (result.draggableId.split(" ")[0] === "row1") {
      androidWalletTemplate.rows[1]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.rows[0]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "row3") {
      androidWalletTemplate.rows[1]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.rows[2]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else {
      androidWalletTemplate.rows[1]?.items?.forEach((item: any) => {
        if (item.key === fields[result.source.index].key) {
          isRepeted = true;
        }
      })
    }

    if (isRepeted) {
      showToast.info("You can't add same field to this row");
      return;
    }

    if (result.draggableId.split(" ")[0] === "row1" && androidWalletTemplate?.rows?.[0]?.items?.length) {
      androidWalletTemplate.rows[1]?.items?.splice(result.destination.index, 0, androidWalletTemplate.rows[0]?.items[result.source.index]);
      androidWalletTemplate.rows[0]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "row3" && androidWalletTemplate?.rows?.[2]?.items?.length) {
      androidWalletTemplate.rows[1]?.items?.splice(result.destination.index, 0, androidWalletTemplate.rows[2]?.items[result.source.index]);
      androidWalletTemplate.rows[2]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      androidWalletTemplate.rows[1]?.items?.splice(result.destination.index, 0, fields[result.source.index]);
    }

    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.destination?.droppableId === "row3") {
    if (androidWalletTemplate?.rows?.[2]?.items?.length === 3) {
      showToast.info("You can't add more fields to this row");
      return;
    }

    let isRepeted = false;
    if (result.draggableId.split(" ")[0] === "row1") {
      androidWalletTemplate.rows[2]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.rows[0]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "row2") {
      androidWalletTemplate.rows[2]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.rows[1]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else {
      androidWalletTemplate.rows[2]?.items?.forEach((item: any) => {
        if (item.key === fields[result.source.index].key) {
          isRepeted = true;
        }
      })
    }

    if (isRepeted) {
      showToast.info("You can't add same field to this row");
      return;
    }

    if (result.draggableId.split(" ")[0] === "row1" && androidWalletTemplate?.rows?.[0]?.items?.length) {
      androidWalletTemplate.rows[2]?.items?.splice(result.destination.index, 0, androidWalletTemplate.rows[0]?.items[result.source.index]);
      androidWalletTemplate.rows[0]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "row2" && androidWalletTemplate?.rows?.[1]?.items?.length) {
      androidWalletTemplate.rows[2]?.items?.splice(result.destination.index, 0, androidWalletTemplate.rows[1]?.items[result.source.index]);
      androidWalletTemplate.rows[1]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      androidWalletTemplate.rows[2]?.items?.splice(result.destination.index, 0, fields[result.source.index]);
    }

    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.destination?.droppableId === "backrow1") {
    if (androidWalletTemplate?.detailRows?.[0]?.items?.length === 2) {
      showToast.info("You can't add more fields to this row");
      return;
    }

    let isRepeted = false;
    if (result.draggableId.split(" ")[0] === "backrow2") {
      androidWalletTemplate.detailRows[0]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[1]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "backrow3") {
      androidWalletTemplate.detailRows[0]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[2]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "backrow4") {
      androidWalletTemplate.detailRows[0]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[3]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else {
      androidWalletTemplate.detailRows[0]?.items?.forEach((item: any) => {
        if (item.key === fields[result.source.index].key) {
          isRepeted = true;
        }
      })
    }

    if (isRepeted) {
      showToast.info("You can't add same field to this row");
      return;
    }

    if (result.draggableId.split(" ")[0] === "backrow2" && androidWalletTemplate?.detailRows?.[1]?.items?.length) {
      androidWalletTemplate.detailRows[0]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[1]?.items[result.source.index]);
      androidWalletTemplate.detailRows[1]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "backrow3" && androidWalletTemplate?.detailRows?.[2]?.items?.length) {
      androidWalletTemplate.detailRows[0]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[2]?.items[result.source.index]);
      androidWalletTemplate.detailRows[2]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "backrow4" && androidWalletTemplate?.detailRows?.[3]?.items?.length) {
      androidWalletTemplate.detailRows[0]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[3]?.items[result.source.index]);
      androidWalletTemplate.detailRows[3]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      androidWalletTemplate?.detailRows?.[0]?.items?.splice(result.destination.index, 0, fields[result.source.index]);
    }

    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.destination?.droppableId === "backrow2") {
    if (androidWalletTemplate?.detailRows?.[1]?.items?.length === 2) {
      showToast.info("You can't add more fields to this row");
      return;
    }

    let isRepeted = false;
    if (result.draggableId.split(" ")[0] === "backrow1") {
      androidWalletTemplate.detailRows[1]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[0]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "backrow3") {
      androidWalletTemplate.detailRows[1]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[2]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "backrow4") {
      androidWalletTemplate.detailRows[1]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[3]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else {
      androidWalletTemplate.detailRows[1]?.items?.forEach((item: any) => {
        if (item.key === fields[result.source.index].key) {
          isRepeted = true;
        }
      })
    }

    if (isRepeted) {
      showToast.info("You can't add same field to this row");
      return;
    }

    if (result.draggableId.split(" ")[0] === "backrow1" && androidWalletTemplate?.detailRows?.[0]?.items?.length) {
      androidWalletTemplate.detailRows[1]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[0]?.items[result.source.index]);
      androidWalletTemplate.detailRows[0]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "backrow3" && androidWalletTemplate?.detailRows?.[2]?.items?.length) {
      androidWalletTemplate.detailRows[1]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[2]?.items[result.source.index]);
      androidWalletTemplate.detailRows[2]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "backrow4" && androidWalletTemplate?.detailRows?.[3]?.items?.length) {
      androidWalletTemplate.detailRows[1]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[3]?.items[result.source.index]);
      androidWalletTemplate.detailRows[3]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      androidWalletTemplate?.detailRows?.[1]?.items?.splice(result.destination.index, 0, fields[result.source.index]);
    }

    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.destination?.droppableId === "backrow3") {
    if (androidWalletTemplate?.detailRows?.[2]?.items?.length === 2) {
      showToast.info("You can't add more fields to this row");
      return;
    }

    let isRepeted = false;
    if (result.draggableId.split(" ")[0] === "backrow1") {
      androidWalletTemplate.detailRows[2]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[0]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "backrow2") {
      androidWalletTemplate.detailRows[2]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[1]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "backrow4") {
      androidWalletTemplate.detailRows[2]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[3]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else {
      androidWalletTemplate.detailRows[2]?.items?.forEach((item: any) => {
        if (item.key === fields[result.source.index].key) {
          isRepeted = true;
        }
      })
    }

    if (isRepeted) {
      showToast.info("You can't add same field to this row");
      return;
    }

    if (result.draggableId.split(" ")[0] === "backrow1" && androidWalletTemplate?.detailRows?.[0]?.items?.length) {
      androidWalletTemplate.detailRows[2]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[0]?.items[result.source.index]);
      androidWalletTemplate.detailRows[0]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "backrow2" && androidWalletTemplate?.detailRows?.[1]?.items?.length) {
      androidWalletTemplate.detailRows[2]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[1]?.items[result.source.index]);
      androidWalletTemplate.detailRows[1]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "backrow4" && androidWalletTemplate?.detailRows?.[3]?.items?.length) {
      androidWalletTemplate.detailRows[2]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[3]?.items[result.source.index]);
      androidWalletTemplate.detailRows[3]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      androidWalletTemplate?.detailRows?.[2]?.items?.splice(result.destination.index, 0, fields[result.source.index]);
    }

    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.destination?.droppableId === "backrow4") {
    if (androidWalletTemplate?.detailRows?.[3]?.items?.length === 2) {
      showToast.info("You can't add more fields to this row");
      return;
    }

    let isRepeted = false;
    if (result.draggableId.split(" ")[0] === "backrow1") {
      androidWalletTemplate.detailRows[3]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[0]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "backrow2") {
      androidWalletTemplate.detailRows[3]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[1]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else if (result.draggableId.split(" ")[0] === "backrow3") {
      androidWalletTemplate.detailRows[3]?.items?.forEach((item: any) => {
        if (item.key === androidWalletTemplate.detailRows[2]?.items[result.source.index].key) {
          isRepeted = true;
        }
      })
    } else {
      androidWalletTemplate.detailRows[3]?.items?.forEach((item: any) => {
        if (item.key === fields[result.source.index].key) {
          isRepeted = true;
        }
      })
    }

    if (isRepeted) {
      showToast.info("You can't add same field to this row");
      return;
    }

    if (result.draggableId.split(" ")[0] === "backrow1" && androidWalletTemplate?.detailRows?.[0]?.items?.length) {
      androidWalletTemplate.detailRows[3]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[0]?.items[result.source.index]);
      androidWalletTemplate.detailRows[0]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "backrow2" && androidWalletTemplate?.detailRows?.[1]?.items?.length) {
      androidWalletTemplate.detailRows[3]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[1]?.items[result.source.index]);
      androidWalletTemplate.detailRows[1]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else if (result.draggableId.split(" ")[0] === "backrow3" && androidWalletTemplate?.detailRows?.[2]?.items?.length) {
      androidWalletTemplate.detailRows[3]?.items?.splice(result.destination.index, 0, androidWalletTemplate.detailRows[2]?.items[result.source.index]);
      androidWalletTemplate.detailRows[2]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    } else {
      androidWalletTemplate?.detailRows?.[3]?.items?.splice(result.destination.index, 0, fields[result.source.index]);
    }

    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "appleBackPreview" && result.destination?.droppableId === "bin") {
    walletTemplate.backFields?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setWalletTemplate({ ...walletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "primaryField" && result.destination?.droppableId === "bin") {
    walletTemplate.primaryFields?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setWalletTemplate({ ...walletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "secondaryField" && result.destination?.droppableId === "bin") {
    walletTemplate.secondaryFields?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setWalletTemplate({ ...walletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "auxiliaryField" && result.destination?.droppableId === "bin") {
    walletTemplate.auxiliaryFields?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setWalletTemplate({ ...walletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "headerField" && result.destination?.droppableId === "bin") {
    walletTemplate.headerFields?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setWalletTemplate({ ...walletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "row1" && result.destination?.droppableId === "bin") {
    androidWalletTemplate.rows[0]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "row2" && result.destination?.droppableId === "bin") {
    androidWalletTemplate.rows[1]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "row3" && result.destination?.droppableId === "bin") {
    androidWalletTemplate.rows[2]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "backrow1" && result.destination?.droppableId === "bin") {
    androidWalletTemplate?.detailRows?.[0]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "backrow2" && result.destination?.droppableId === "bin") {
    androidWalletTemplate?.detailRows?.[1]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "backrow3" && result.destination?.droppableId === "bin") {
    androidWalletTemplate?.detailRows?.[2]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }

  if (result.draggableId.split(" ")[0] === "backrow4" && result.destination?.droppableId === "bin") {
    androidWalletTemplate?.detailRows?.[3]?.items?.splice(Number(result.draggableId.split(" ")[1]), 1);
    setAndroidWalletTemplate({ ...androidWalletTemplate });
  }
}
