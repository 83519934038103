/* eslint-disable import/no-anonymous-default-export */
import { useQuery } from '@tanstack/react-query';

import tenantService from '../../services/tenantService';

export default () =>
	useQuery(['tenants'], async () => {
		const response = await tenantService.getBrands();
		return response.data;
	});
