/* eslint-disable import/no-anonymous-default-export */
import { useQuery } from '@tanstack/react-query';
import tokenService from '../../services/tokenService';
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';

export default () => {
	const { user } = useContext(AuthContext);
	return useQuery(['token', 'token-types'], async () => {
		const response = await tokenService.getTokenTypes(user?.tenantId);
		return response.data;
	});
};
