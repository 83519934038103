import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';

import { TokenData } from '../../../types';
import { AppRoutesEnum } from '../../../constants';

import { Container, Form, Input, SupplyDiv, OptionsDiv, TooltipDiv, TextDiv } from './style';
import { Button, ButtonDiv } from '../../FormProgress/style';
import SubmitButton from '../../Button';
import showToast from '../../Toast';
import tokenService from '../../../services/tokenService';
import AuthContext from '../../../context/AuthContext';
import CustomTooltip from '../../Tooltip';

type Props = {
	setActiveTab: (tab: string) => void;
	setTokenData: (data: TokenData) => void;
	tokenData: TokenData | null;
};

const TokenAttributes = ({ setActiveTab, setTokenData, tokenData }: Props) => {
	const [unlimitedSupply, setUnlimitedSupply] = useState(false);
	const [supply, setSupply] = useState(0);
	const [freezable, setFreezable] = useState(false);
	const [wipe, setWipe] = useState(false);
	const [kyc, setKyc] = useState(false);

	const [disableButton, setDisableButton] = useState(false);

	const { user } = useContext(AuthContext);

	const { t } = useTranslation();

	const navigate = useNavigate();

	const handleFormSubmit = () => {
		setDisableButton(true);

		if (tokenData) {
			const attributeData: TokenData = {
				...tokenData,
				freeze: freezable,
				wipe,
				kyc,
				initialSupply: unlimitedSupply ? 1000 : supply,
				tokenMemo: '',
				metadata: [
					{
						fieldType: '',
						fieldName: '',
					},
				],
			};

			setTokenData(attributeData);

			if (!tokenData.isNFT) {
				tokenService
					.postToken(user?.tenantId, attributeData)
					.then(() => {
						navigate(AppRoutesEnum.TOKEN_LIST);
						showToast.success(t<string>('toast.tokenCreated'));
					})
					.catch((error) => {
						setActiveTab('1');
						showToast.error(error.message);
					})
					.finally(() => {
						setDisableButton(false);
					});
			} else {
				setActiveTab('3');
			}
		} else {
			setActiveTab('1');
		}
	};

	return (
		<Form>
			<Container>
				{!tokenData?.isNFT ? (
					<OptionsDiv>
						<TextDiv>
							<p>Maybe a text here which explain this step? To make it more friendly, understandable, and fill out this otherwise empty step?</p>
						</TextDiv>
						<div>
							<TooltipDiv>
								<Switch
									name="supply"
									value={supply}
									onChange={() => setUnlimitedSupply(!unlimitedSupply)}
								/>
								<label>{t<string>('createToken.tokenAttributes.unlimitedSupply')}</label>
							</TooltipDiv>
							<SupplyDiv>
								<label>{t<string>('createToken.tokenAttributes.supply')}</label>
								<Input
									name="supply"
									type="number"
									onChange={(e) => setSupply(Number(e.target.value))}
									placeholder={t<string>('createToken.basics.placeholder.supply')}
								/>
							</SupplyDiv>
						</div>
					</OptionsDiv>
				) : (
					<OptionsDiv>
						<TextDiv>
							<p>Maybe a text here which explain this step? To make it more friendly, understandable, and fill out this otherwise empty step?</p>
						</TextDiv>
						<div>
							<div>
								<TooltipDiv>
									<Switch
										name="freezable"
										value={freezable}
										className="switch"
										onChange={() => setFreezable(!freezable)}
									/>
									<p>{t<string>('createToken.tokenAttributes.freezable')}</p>
									<CustomTooltip text={t<string>('tooltip.freezable')} />
								</TooltipDiv>
							</div>
							<div>
								<TooltipDiv>
									<Switch
										name="wipe"
										value={wipe}
										className="switch"
										onChange={() => setWipe(!wipe)}
									/>
									<p>{t<string>('createToken.tokenAttributes.wipe')}</p>
									<CustomTooltip text={t<string>('tooltip.wipe')} />
								</TooltipDiv>
								<div />
							</div>
							<div>
								<TooltipDiv>
									<Switch
										name="kyc"
										value={kyc}
										className="switch"
										onChange={() => setKyc(!kyc)}
									/>
									<p>{t<string>('createToken.tokenAttributes.kyc')}</p>
									<CustomTooltip text={t<string>('tooltip.kyc')} />
								</TooltipDiv>
								<div />
							</div>
						</div>
					</OptionsDiv>
				)}
			</Container>
			<ButtonDiv>
				<Button onClick={() => setActiveTab('1')}>{t<string>('createToken.button.back')}</Button>
				{tokenData?.isNFT ? (
					<Button type="button" onClick={handleFormSubmit}>
						{t<string>('createToken.button.next')}
					</Button>
				) : (
					<SubmitButton width="160px" onClick={handleFormSubmit} loading={disableButton} disabled={disableButton}>
						{t<string>('createToken.button.create')}
					</SubmitButton>
				)}
			</ButtonDiv>
		</Form>
	);
};

export default TokenAttributes;
