import styled from "styled-components";

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
    },
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '24px',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
        color: '#647E93',
	},
};

export const ModalContainer = styled.div`
  display: flex;
	flex-direction: column;
  align-items: center;
	justify-content: center;
  padding: 12px;

	&>div {
		display: flex;
		align-items: center;
		align-items: space-around;
  	justify-content: space-around;
		max-width: 90vw;
		overflow-x: scroll; 
		margin: 12px;

		&>div {
			margin: 0 24px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

  h1 {
    font-size: 1.25rem;
  }

	h2 {
		font-size: 1rem;
	}

  svg {
		font-size: 1.5rem;
		cursor: pointer;
	}
`;

export const Container = styled.div`
    width: calc(100vw - 208px);
background-color: #F5F5F6;
    height: calc(100vh - 170px);
    box-sizing: border-box;
    margin: -20px 0 0 -16px;
    color: #647E93;

    &>h1 {
        margin-left: 24px;
    }
`;

export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 24px;
	padding-bottom: 12px;
	border-bottom: 4px solid #647E93;
	
	h1 {
		font-size: 2rem;
	}

	button {
		border-radius: 100px;
		border: none;
		box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
		background-color: #82A3C1;
		color: #FFF;
		font-size: 1rem;
		font-weight: 900;
		height: 32px;
		width: 160px;
		padding: 0 24px;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}
`;

export const NameFilter = styled.div`
	display: flex;

	button {
		margin-left: -32px;
		margin-top: 24px;
	}
`;

export const Input = styled.input`
	height: 32px;
	min-width: 220px;
	border-radius: 100px;
	border: none;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	margin-left: 24px;
	letter-spacing: .5px;
	padding: 0 24px;
	margin-top: 24px;

	&:focus {
		outline: none;
	}

	@media (max-width: 768px) {
		width: 4.25rem;
		font-size: 0.6rem;
		padding: 0.4rem;
		box-sizing: border-box;
		text-align: center;
	}

	font-size: 1rem;
`;
