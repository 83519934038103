import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	PencilSquareIcon,
	TrashIcon,
	PlusIcon,
} from '@heroicons/react/24/outline';

import {
	Card,
	CustomModal,
	MainLayout,
	PrimaryButton,
	Table,
} from '../../../components';
import useQueryWalletTemplates from '../../../hooks/useQuery/useQueryWalletTemplates';
import { AppRoutesEnum } from '../../../constants';
import { onRepopulateTemplateData } from '../../../hooks/repopulateData/onRepopulateData';
import walletService from '../../../services/walletService';
import AuthContext from '../../../context/AuthContext';
import showToast from '../../../components/Toast';
import { ThreeDots } from 'react-loader-spinner';

export const WalletList = () => {
	const { user } = useContext(AuthContext);
	const { tenantId } = user;

	const [nameFilter, setNameFilter] = useState<string>('');
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	const [selectedTemplate, setSelectedTemplate] = useState<any>();

	const [isDeleting, setIsDeleting] = useState(false);

	const { data: templates, isLoading } =
		useQueryWalletTemplates(isDeleteModalOpen);

	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleEdit = (template: any) => {
		const { iosWalletTemplate, androidWalletTemplate } =
			onRepopulateTemplateData(template);

		navigate(AppRoutesEnum.WALLET_TEMPLATE_UPDATE, {
			state: {
				iosWalletTemplate: iosWalletTemplate,
				androidWalletTemplateData: androidWalletTemplate,
				template,
				fieldMappings: template.fieldMappings,
				templateId: template.templateId,
			},
		});
	};

	const dataToShow = templates?.map((template: any) => {
		return {
			name: <p className="pl-5">{template.name}</p>,
			options: (
				<div className="flex justify-center">
					<button
						aria-label={`Edit ${template.name ?? 'Wallet Card'}`}
						className="mr-2 p-1 border-0 cursor-pointer bg-transparent hover:bg-stone-200 active:bg-stone-300 rounded-lg"
						onClick={() => handleEdit(template)}
					>
						<PencilSquareIcon className="w-8 h-8 p-1 text-stone-600" />
					</button>
					<button
						aria-label={`Permanantly delete ${template.name ?? 'Wallet Card'}`}
						className="p-1 border-0 cursor-pointer bg-transparent hover:bg-stone-200 active:bg-stone-300 rounded-lg"
						onClick={() => {
							setSelectedTemplate(template);
							setIsDeleteModalOpen(true);
						}}
					>
						<TrashIcon className="w-8 h-8 p-1 text-stone-600" />
					</button>
					<button
						aria-label={`Create ${template.name ?? 'Tier'}`}
						className="p-1 font-bold border-0 cursor-pointer bg-transparent hover:bg-stone-200 active:bg-stone-300 rounded-lg"
						onClick={() =>
							navigate(AppRoutesEnum.WALLET_TIER_LIST, {
								state: { template: template },
							})
						}
					>
						Tiers
					</button>
				</div>
			),
		};
	});

	let walletTemplatesOnPage = nameFilter
		? dataToShow?.filter((template: any) =>
				template?.name?.toLowerCase()?.includes(nameFilter?.toLowerCase())
		  )
		: dataToShow;

	const tableHeaders = [
		t<string>('walletCard.table.name'),
		t<string>('walletCard.table.options'),
	];

	const handleDeleteTemplate = () => {
		if (isDeleting) return;

		setIsDeleting(true);

		walletService
			.deleteWalletTemplate(tenantId, selectedTemplate.templateId)
			.then(() => {
				showToast.success(t<string>('walletCard.deleteSuccess'));
				setIsDeleteModalOpen(false);
			})
			.catch(() => {
				showToast.error(t<string>('walletCard.deleteError'));
			})
			.finally(() => {
				setIsDeleting(false);
			});
	};

	return (
		<>
			<MainLayout name={nameFilter} setName={setNameFilter} h1="Wallet Cards">
				<Card className="!p-5">
					<Table
						tableHeaders={tableHeaders}
						tableData={walletTemplatesOnPage}
						noDataText={t<string>('walletCard.table.noWalletCards')}
						loading={isLoading}
					/>
				</Card>
				<CustomModal
					modalIsOpen={isDeleteModalOpen}
					setIsOpen={setIsDeleteModalOpen}
				>
					<div className="w-[600px] text-center px-10">
						<h1 className="text-xl font-normal">
							{t<string>('walletCard.deleteModal.title')}
						</h1>
						{isDeleting ? (
							<div className="flex justify-center items-center h-[82px]">
								<ThreeDots width={80} height={40} />
							</div>
						) : (
							<>
								<p className="text-lg">{selectedTemplate?.name}</p>
								<div className="flex justify-around">
									<PrimaryButton
										className="mr-2 w-36"
										onClick={() => setIsDeleteModalOpen(false)}
									>
										{t<string>('walletCard.deleteModal.cancel')}
									</PrimaryButton>
									<PrimaryButton
										className="mr-2 w-36"
										onClick={handleDeleteTemplate}
									>
										{t<string>('walletCard.deleteModal.delete')}
									</PrimaryButton>
								</div>
							</>
						)}
					</div>
				</CustomModal>
			</MainLayout>
		</>
	);
};
