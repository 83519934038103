import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type typeToastMessageType = (message: string) => void;
type showToastTypes = {
	success: typeToastMessageType;
	error: typeToastMessageType;
	info: typeToastMessageType;
	warn: typeToastMessageType;
};

const showToast: showToastTypes = {
	success: (message) => toast.success(message),
	error: (message) => toast.error(message),
	info: (message) => toast.info(message),
	warn: (message) => toast.warn(message),
};

export default showToast;

export const ToastComponent = () => (
	<ToastContainer
		position="top-center"
		autoClose={5000}
		hideProgressBar={false}
		newestOnTop={false}
		closeOnClick
		rtl={false}
		draggable
		pauseOnHover
	/>
);
