import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from './services/queryClient';
import Router from './router';
import AuthContextProvider from './context/AuthContextProvider';
import { ToastComponent } from './components/Toast';

import GlobalStyle from './GlobalStyles';
import "./tailwind.css";

const App = () => {
	return (
		<AuthContextProvider>
			<QueryClientProvider client={queryClient}>
				<GlobalStyle />
				<Router />
				<ToastComponent />
			</QueryClientProvider>
		</AuthContextProvider>
	);
};

export default App;
