import styled from 'styled-components';

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 9999999,
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '.5rem',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
		color: '#647E93',
	},
};

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;

  &>h1 {
    font-size: 1.5rem;
    margin: 0 0 12px 0;
    padding: 0;
    width: 400px;
    text-align: center;
  }

  p {
    font-size: 1.25rem;
    width: 400px;
    text-align: center;
    margin: 0;
    padding: 12px 0;
  }

  button {
    margin: 0 12px;
  }
`;
