import { Auth } from 'aws-amplify';
import { environment } from '../environments/environment';
import {
	clearLocalStorage,
	getTokenLocalStorage,
	setTokenLocalStorage,
} from '../utils/localStorage/tokenStorage';

export interface IUser {
	email: string;
	password: string;
}

function configure() {
	return Auth.configure(environment.cognito);
}

function signUp(user: any): Promise<any> {
	return Auth.signUp({
		username: user.email,
		attributes: {
			email: user.email,
			phone_number: user.phone,
			given_name: user.firstname,
			family_name: user.lastname,
			// "custom:company": user.company,
			// "custom:title": user.title,
			// website: user.website,
			// "custom:linkedin": user.linkedin,
			// picture: user.image,
			address: user.street,
		},
		password: user.password,
	});
}

function resendCode(username: string): Promise<any> {
	return Auth.resendSignUp(username);
}

function forgotLogin(username: string): Promise<any> {
	return Auth.forgotPassword(username);
}

function forgotLoginConfirm(username: string, code: string, password: string) {
	return Auth.forgotPasswordSubmit(username, code, password);
}

function confirmSignUp(username: string, code: string): Promise<any> {
	return Auth.confirmSignUp(username, code);
}

function signIn(user: IUser): Promise<any> {
	return Auth.signIn(user.email, user.password).then((_response: any) => {
		return Auth.currentAuthenticatedUser().then((user) => {
			setTokenLocalStorage(user.signInUserSession?.idToken?.jwtToken);
			return organizeUser(user);
		});
	});
}

function getCurrentAuthenticatedUser() {
	return Auth.currentAuthenticatedUser().then((user) => organizeUser(user));
}

export function signOut(): Promise<any> {
	clearLocalStorage();
	return Auth.signOut();
}

function isAuthenticated(): Promise<boolean> {
	if (getTokenLocalStorage()) {
		return new Promise((response, reject) => {
			response(true);
		});
	}
	return Auth.currentAuthenticatedUser()
		.then((user) => {
			setTokenLocalStorage(user.signInUserSession?.idToken?.jwtToken);
			return true;
		})
		.catch(async (err) => {
			console.log(err);
			return signOut().then(() => false);
		});
}

function googleLogin() {
	// @ts-ignore
	return Auth.federatedSignIn({
		provider: 'Google',
	});
}

const cognitoService: any = {
	configure,
	signUp,
	resendCode,
	forgotLogin,
	forgotLoginConfirm,
	confirmSignUp,
	signIn,
	signOut,
	googleLogin,
	isAuthenticated,
	getCurrentAuthenticatedUser,
};

export interface userTypes {
	uid: string;
	email: string;
	custom_tenant_id?: string;
	photoURL?: string;
	firstName?: string;
	lastName?: string;
	displayName?: string;
	phoneNumber?: string;
	address?: string;
	company?: string;
	title?: string;
	tenantId?: string;
	attributes?: any;
	groups?: string[];
}

function organizeUser(user: any): userTypes {
	const userAttributes = user?.attributes;
	let displayName = '';
	if (userAttributes?.given_name) displayName += userAttributes?.given_name;
	if (userAttributes?.family_name)
		displayName += ` ${userAttributes?.family_name}`;
	return {
		uid: userAttributes?.email,
		email: userAttributes?.email,
		firstName: userAttributes?.given_name,
		lastName: userAttributes?.family_name,
		displayName,
		phoneNumber: userAttributes?.phone_number,
		address: userAttributes?.address,
		company: userAttributes?.company,
		title: userAttributes?.title,
		tenantId: userAttributes?.['custom:tenant_id'] || localStorage.getItem('tenantId'),
		groups: user?.signInUserSession?.accessToken?.payload?.['cognito:groups'],
		custom_tenant_id: userAttributes?.['custom:tenant_id'],
	};
}

export default cognitoService;
