import { useTranslation } from 'react-i18next';

import useQueryTokenTypes from '../../../hooks/useQuery/useQueryTokenTypes';
import { SelectObjects } from '../../../components/Select';
import { tokenSelectTypes } from '../../../types';
import { useEffect } from 'react';

const TokenSelect = ({ onChange, selectType, setSelectType, width }: tokenSelectTypes) => {
	const { data, isLoading } = useQueryTokenTypes();
	const { t } = useTranslation();

	useEffect(() => {
		if (!selectType && data?.length) {
			setSelectType(data[0]);
		}
	}, [data, selectType, setSelectType])

	return (
		<SelectObjects
			onChange={onChange}
			loading={isLoading}
			options={data}
			valueKey="tokenTypeId"
			labelKey="name"
			noOptionsLabel={t<string>('select.noTokens')}
			needDisabledOption={t<string>('select.selectToken')}
			width={width}
		/>
	);
};

export default TokenSelect;
