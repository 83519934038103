import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { DateRangePicker } from 'react-date-range'

import { modalStyles, ButtonsDiv } from './style'
import Button from '../Button'

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  selectionRange: any;
  handleSelect: (ranges: any) => void;
  handleApply: () => void;
}

const DateRangeModal = ({ isModalOpen, setIsModalOpen, selectionRange, handleSelect, handleApply }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={modalStyles}
    >
      <div>
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
          maxDate={new Date()}
        />
      </div>
      <ButtonsDiv>
        <Button onClick={() => setIsModalOpen(false)}>{t<string>('home.modal.cancel')}</Button>
        <Button onClick={handleApply}>{t<string>('home.modal.apply')}</Button>
      </ButtonsDiv>
    </Modal>
  )
}

export default DateRangeModal
