/* eslint-disable import/no-anonymous-default-export */
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import AuthContext from '../../context/AuthContext';
import reportService from '../../services/reportService';

export default (dateRange: string[], updateReport: boolean) => {
    const { user } = useContext(AuthContext);

    const [startDate, endDate] = dateRange;

    const startUnixEpoch = new Date(startDate).getTime() / 1000;
    const endUnixEpoch = new Date(endDate).setUTCHours(23,59,59) / 1000;

    return useQuery(['report', user?.tenantId, updateReport], async () => {
        const response = await reportService.getReports(user?.tenantId, startUnixEpoch, endUnixEpoch);
        return response.data;
    })
}
