import { useEffect, useState } from 'react';
import cognitoService, { userTypes } from '../services/cognitoService';
import AuthContext from '../context/AuthContext';

function AuthContextProvider({ children }: any) {
	const [user, setUser] = useState<userTypes>();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>();

	const checkIsLoggedIn = async () => {
		const isAuthenticated = await cognitoService.isAuthenticated();
		setIsLoggedIn(isAuthenticated);
		if (isAuthenticated) {
			cognitoService
				.getCurrentAuthenticatedUser()
				.then((userData: userTypes) => {
					handleSetUser(userData);
				})
				.catch((e: any) => console.log('error: ', e));
		}
	};

	const setTenantId = (tenantId: string) => {
		const copyUser: any = { ...user, tenantId };
		setUser(copyUser);
	};

	useEffect(() => {
		cognitoService.configure();
		checkIsLoggedIn();
	}, []);

	function handleSetUser(user: userTypes) {
		setUser(user);
		setIsLoggedIn(true);
	}

	return (
		<AuthContext.Provider
			value={{ user, setUser: handleSetUser, setTenantId, isLoggedIn, setIsLoggedIn }}
		>
			{children}
		</AuthContext.Provider>
	);
}

export default AuthContextProvider;
