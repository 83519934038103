import styled from 'styled-components';

export const Wrapper = styled.div`
	background: #F4F6F8;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	label {
		font-size: 1.25rem;
		margin: 6px 8px 8px 0;
	}
`;

export const Container = styled.div`
	width: 60%;
	margin: 40px auto 236px auto;
	display: flex;
	position: relative;

	label {
		font-size: 1.25rem;
		display: inline-block;
	}

	p {
		font-size: 1.25rem;
		width: 40%;
		margin: 28px 0 0 120px;
	}

	@media (max-width: 1473px) {
		.date {
			width: 36%;
		}
	}

	@media (max-width: 1225px) {
		.date {
			width: 32%;
		}
	}

	@media (max-width: 887px) {
		.date {
			width: 28%;
		}
	}

	@media (max-width: 795px) {
		.date {
			width: 25%;
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;

		.date {
			width: 30%;
		}
	}

	.error {
		border: 1px solid #d9534f;
	}
`;

export const TooltipDiv = styled.div`
	display: flex;

	label {
		white-space: nowrap;
	}

	svg {
		margin-top: 8px;
	}
`;

export const Input = styled.input`
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	width: 300px;
	height: 32px;
	font-size: 1.25rem;
	border: none;
	padding: 0 24px;
	display: block;
	box-sizing: border-box;
	color: #647E93;

	&:placeholder {
		color: #647E93;;
	}

	&:focus {
		outline: none;
	}
`;

export const Unlimited = styled.div`
	position: absolute;
	bottom: 2rem;
	left: 2rem;

	@media (max-width: 768px) {
		margin-top: 1rem;
		position: static;
	}
`;
