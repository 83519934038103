import { ThreeDots } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledFbButton, StyledGoogleButton } from './styles';
import { FcGoogle } from 'react-icons/fc';
import { IoLogoFacebook } from 'react-icons/io';
import { testIds } from './constants';

interface buttonTypes {
	loading?: boolean;
	children: any;
	color?: string;
	backgroundColor?: string;
	onClick?: () => void;
	disabled?: boolean;
	width?: string;
	fontSize?: string;
	[x: string]: any;
}

export default function Button({
	loading,
	props,
	children,
	onClick,
	color,
	backgroundColor,
	width,
	fontSize,
	disabled,
}: buttonTypes) {
	return (
		<StyledButton
			type="submit"
			{...props}
			onClick={onClick}
			disabled={disabled || loading}
			color={color}
			backgroundColor={backgroundColor}
			data-testid={testIds.BUTTON}
			width={width}
			fontSize={fontSize}
		>
			{loading ? <ButtonLoader /> : children}
		</StyledButton>
	);
}

function ButtonLoader() {
	return (
		<div data-testid={testIds.BUTTON_LOADER} style={{ display: "flex", justifyContent: "center" }}>
			<ThreeDots color="white" height={19} width={19} />
		</div>
	);
}

interface FbGoogleTypes {
	onClick: () => void;
	width?: string;
}
export const FacebookButton = ({ onClick, width }: FbGoogleTypes) => {
	const { t } = useTranslation();

	return (
		<StyledFbButton onClick={onClick} width={width}>
			<IoLogoFacebook />
			{t<string>('signIn.button.facebook')}
		</StyledFbButton>
	);
};

export const GoogleButton = ({ onClick, width }: FbGoogleTypes) => {
	const { t } = useTranslation();

	return (
		<StyledGoogleButton onClick={onClick} width={width}>
			<FcGoogle />
			{t<string>('signIn.button.google')}
		</StyledGoogleButton>
	);
};
