import styled from "styled-components";

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
    },
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '24px',
        color: '#647E93',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
	},
};

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 12px;

    p {
        font-size: .9rem;
        display: block;
        margin-top: 4px;
    }

    input {
        margin: -4px 0 4px 0;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
`;

type Props = {
    isDragging: boolean;
}

export const Drag = styled.div<Props>`
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 4px 8px;
    position: relative;
    margin: 8px 0;
    background-color: ${props => props.isDragging ? 'rgba(101, 131, 161, 0.1)' : '#fff'};
    color: ${props => props.isDragging ? '#fff' : '#647E93'};
    transition: background-color .3s ease-in-out;
`;

export const OptionsContainer = styled.div`
    background-color: #fff;
    width: 330px;
    height: 500px;
    box-sizing: border-box;
    overflow: scroll;
    border-radius: 8px;

    button { 
        margin: 0 12px;
    }

    h1 {
        font-size: 1.25rem !important;
        text-align: center;
        margin: 24px 0 8px 0;
        padding: 0;
    }
`;

export const UploadContainer = styled.div`
	width: 70%;

    p {
        font-size: .9rem;
        margin: 8px 24px;
    }

	button {
		position: relative;
        margin-left: 16px;
	}

	input {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		top: 0;
		left: 0;
		cursor: pointer;
		color: #647E93;
	}
`;

export const ImageContainer = styled.div`
    border-radius: 8px;
    background-color: #fff;
    width: 330px;
    height: 500px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: scroll;
    

    h1 {
        font-size: 1.25rem !important;
        text-align: center;
        margin: 44px 0 4px 0;
        padding: 0;
    }

    .eventTicket {
        margin-top: 160px;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        width: 308px;
        height: 40px;
        border: none;
        cursor: pointer;
        margin-left: -12px;
    }

    input::-webkit-color-swatch {
        border-radius: 100px;
        border: 1px solid #000000;
    }

    input::-moz-color-swatch {
        border-radius: 100px;
        border: 7px solid #000000;
    }
`;

export const PickerContainer = styled.div`
    margin: 0 24px;
    font-size: .9rem;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 700px;

    label {
        display: block;
        font-size: 1rem;
    }

    input {
        margin: 12px 12px 12px 0;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 320px;
    }

    button {
        margin-top: 16px;
    }
`;
