import { environmentObj } from './rendered-environment-config';

export const environment = {
	production: false,
	SCARF_ANALYTICS: false,
	adminRoot: '',
	apiUrl: 'https://api.coloredstrategies.com',
	defaultMenuType: 'menu-default',
	subHiddenBreakpoint: 1440,
	menuHiddenBreakpoint: 768,
	themeColorStorageKey: 'vien-themecolor',
	isMultiColorActive: false,
	defaultColor: 'light.bluenavy',
	isDarkSwitchActive: false,
	defaultDirection: 'ltr',
	themeRadiusStorageKey: 'vien-themeradius',
	isAuthGuardActive: true,
	domainKey: environmentObj.domainKey,
	rootPath: environmentObj.rootPath,
	cognito: environmentObj.cognito,
};
