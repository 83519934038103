/* eslint-disable import/no-anonymous-default-export */
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import tenantEventsService from '../../services/tenantEventService';
import AuthContext from '../../context/AuthContext';

export default (() => {
    const { user } = useContext(AuthContext);

    return useQuery(['eventTable'], async () => {
        const response = await tenantEventsService.getEventTable(user?.tenantId);
        return response.data;
    });
});
