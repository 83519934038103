import { useState } from 'react';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, ButtonDiv } from '../../FormProgress/style';
import { Container, TooltipDiv, Input, Unlimited, Wrapper } from './style';
import { Product } from '../../../types';
import showToast from '../../Toast';
import CustomTooltip from '../../Tooltip';

type Props = {
	setActiveTab: (tab: string) => void;
	isNFT: boolean;
	product: Product;
	setProduct: (product: Product) => void;
};

const date = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10
		? '0' + (new Date().getMonth() + 1)
		: new Date().getMonth() + 1
}-${new Date().getDate()}`;

const ProductSupply = ({ setActiveTab, isNFT, product, setProduct }: Props) => {
	const [tokenSupply, setTokenSupply] = useState('');
	const [initialDate, setInitialDate] = useState('');
	const [finalDate, setFinalDate] = useState('');
	const [unlimited, setUnlimited] = useState(false);

	const hide = true;

	const [submitClicked, setSubmitClicked] = useState(false);

	const { t } = useTranslation();

	const handleSubmit = () => {
		setSubmitClicked(true);

		if ((tokenSupply)) {
			if ((finalDate && initialDate) && finalDate <= initialDate) {
				showToast.warn('Final date must be after initial date!');
				return;
			}

			if ((finalDate && initialDate) && (initialDate < date || finalDate <= date)) {
				showToast.warn('Both initial and final dates must in the future!');
				return;
			}

			setProduct({
				...product,
				// add to api
			});
			isNFT 
				? setActiveTab(`4. ${t<string>('createProduct.information.navigation.price')}`) 
				: setActiveTab(`3. ${t<string>('createProduct.information.navigation.price')}`);
		} else {
			showToast.warn('Please fill out all fields!');
		}
	};

	return (
		<Wrapper>
			<Container>
				<div>
					<TooltipDiv>
						<label>{t<string>('createProduct.information.supply.supply')}</label>
						<CustomTooltip text={t<string>('tooltip.tokenSupply')} />
					</TooltipDiv>
					<Input
						type="number"
						onChange={(e) => setTokenSupply(e.target.value)}
						value={tokenSupply}
						pattern="[0-9]*"
						data-testid="token-supply"
						className={`${submitClicked && !tokenSupply ? 'error' : ''}`}
					/>
				</div>
				<p>
					Maybe a text here which explain this step? To make it more friendly, understandable, and fill out this otherwise empty step?
				</p>
				{!hide 
					&& (
						<div>
							<TooltipDiv>
								<label>{t<string>('createProduct.information.supply.date')}</label>
								<CustomTooltip text={t<string>('tooltip.datesActive')} />
							</TooltipDiv>
							<div>
								<input
									type="date"
									onChange={(e) => setInitialDate(e.target.value)}
									className="date"
									value={initialDate}
									data-testid="initial-date"
								/>
								<p>To</p>
								<input
									type="date"
									onChange={(e) => setFinalDate(e.target.value)}
									className="date"
									value={finalDate}
									data-testid="final-date"
								/>
							</div>
						</div>
					)
				}
				{
					!hide 
						&& (
							<Unlimited>
								<TooltipDiv>
									<label>{t<string>('createProduct.information.supply.unlimited')}</label>
									<CustomTooltip text={t<string>('tooltip.unlimited')} />
								</TooltipDiv>
								<Switch
									name="unlimited"
									value={unlimited}
									onChange={(e) => setUnlimited(e.target.checked)}
									className="switch"
									data-testid="switch"
								/>
							</Unlimited>
						) 
				}
			</Container>
			<ButtonDiv>
				<Button
					onClick={() => {
						setSubmitClicked(true);
						isNFT 
							? setActiveTab(`2. ${t<string>('createProduct.information.navigation.payload')}`) 
							: setActiveTab(`1. ${t<string>('createProduct.information.navigation.basics')}`)
					}}
				>
					{t<string>('createProduct.information.button.back')}
				</Button>
				<Button type="button" onClick={handleSubmit} data-testid="submit">
					{t<string>('createProduct.information.button.next')}
				</Button>
			</ButtonDiv>
		</Wrapper>
	);
};

export default ProductSupply;
