import styled from 'styled-components';

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '.5rem',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
		color: '#647E93',
	},
};

export const TokenSelection = styled.div`
	background-color: #fff;
	border-radius: 0.25rem;
	padding: 0.025rem 2rem;	

	h2 {
		margin-bottom: 40px;
		margin-left: 40px;

	}

	p {
		font-size: 1rem;
		font-weight: 600;
		margin: 1rem 0;
	}

	ul {
		margin: 2rem 0;
		max-height: 50vh;
		width: 50vw;
		overflow: hidden;
		overflow-y: scroll;
	}

	li {
		list-style-type: none;
		width: 95%;
		padding: 1px 12px;
		border-radius: 0.25rem;
		cursor: pointer;

		&:nth-child(odd) {
			background-color: rgba(101, 131, 161, 0.1);
		}
	}

	svg {
		margin: 0 1rem -0.15rem 0;
	}

	.raw {
		margin-left: 2rem;
	}

	.disabled {
		background-color: rgba(100, 126, 147, 0.5);
	}
`;

export const FilterDiv = styled.div`
	padding: 0.025rem 3rem;	
`;

export const Input = styled.input`
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	width: 300px;
	height: 32px;
	font-size: 1rem;
	border: none;
	padding: 0 24px;
	color: #647E93; 
	margin: 0 48px;

	&:placeholder {
		color: #CDCDCD;
	}

	&:focus {
		outline: none;
	}
`;
