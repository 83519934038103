import styled from "styled-components";

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
    },
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '24px',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
	},
};

type Props = {
    isDragging: boolean;
}

export const Drag = styled.div<Props>`
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 8px 8px 0 8px;
    position: relative;
    margin: 8px 0;
    background-color: ${props => props.isDragging ? 'rgba(101, 131, 161, 0.1)' : '#fff'};
    color: ${props => props.isDragging ? '#fff' : '#647E93'};
    transition: background-color .3s ease-in-out;
`;

export const PickerContainer = styled.div`
    margin: 0 24px 8px 24px;
    font-size: .9rem;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 700px;

    label {
        display: block;
        font-size: 1rem;
    }

    input {
        margin: 12px 12px 12px 0;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 320px;
    }

    button {
        margin-top: 16px;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 24px 0;
`;

export const OptionsContainer = styled.div`
    background-color: #fff;
    height: 500px;
    width: 330px;
    box-sizing: border-box;
    overflow: scroll;
    border-radius: 4px;

    button { 
        margin: 0 12px;
    }

    h1 {
        font-size: 1.25rem !important;
        text-align: center;
        margin: 12px 0 8px 0;
        padding: 0;
    }
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 12px;

    p {
        font-size: .9rem;
        display: block;
        margin-top: 4px;
    }

    input {
        margin: -4px 0 4px 0;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
`;

type BackgroundProps = {
    backgroundColor: string;
    height?: string;
}

export const LoyaltyTemplateContainer = styled.div<BackgroundProps>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.backgroundColor};
    width: 400px;
    height: 240px;
    border-radius: 8px;
    position: relative;

    h2 {
        font-size: 1.25rem;
        padding-left: 16px;
        font-weight: 600;
    }
`;

export const WebPreviewContainer = styled.div<BackgroundProps>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.backgroundColor};
    width: 400px;
    height: 240px;
    border-radius: 8px;
    align-items: center;

    img {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        margin: 24px 0 8px 0;
    }

    p {
        font-size: .9rem;
        margin: 0;
    }

    h1 {
        font-size: 1.25rem;
        margin: 0;
        font-weight: 600;
    }
`;

export const Header = styled.div`
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 16px 12px;

    div {
        height: 40px !important;
        width: 40px !important;
        position: relative;
    }

    img {
        max-width: 100% !important;
        max-height: 100% !important;
        border-radius: 50%;
    }

    h1 {
        margin: 0 12px;
        padding: 8px;
        font-size: .9rem !important;
        font-weight: 500;
    }
`;

export const ImageInputInvisible = styled.input`
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    cursor: pointer;
`;

export const ProgramInput = styled.input`
    margin: 12px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    font-size: 1rem;
`;

export const SubInput = styled.input`
    margin: 0 12px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    font-size: 1rem;
`;

export const BackgroundColorDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 96px;
    left: 8px;

    label {
        font-size: .9rem;
        margin: 0 0 4px 0;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        width: 128px;
        height: 32px;
        border: none;
        cursor: pointer;
    }

    input::-webkit-color-swatch {
        border-radius: 100px;
        border: 1px solid #000000;
    }

    input::-moz-color-swatch {
        border-radius: 100px;
        border: 7px solid #000000;
    }
`;

export const AndroidPreviewContainer = styled.div<BackgroundProps>`
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 330px;
    background-color: ${props => props.backgroundColor};
    border-radius: 8px;
    padding: 12px 0px;
    box-sizing: border-box;
    position: relative;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
`;

export const AndroidPreviewHeader = styled.div`
    display: flex;
    height: 40px !important;
    padding: 0 16px !important;
    border-bottom: 1px dashed #ccc;
    font-size: .9rem;
    font-weight: 500;

    img {
        max-height: 100%;
        max-width: 100%;
        border-radius: 50%;
    }

    input {
        font-size: .9rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 4px;
        height: 24px;
        margin: 0 12px;
    }
`;

export const AndroidFirstRow = styled.div<BackgroundProps>`
    height: ${props => props.height};
    background-color: ${props => props.backgroundColor};
`;

export const AndroidRows = styled.div<BackgroundProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 12px 16px;
    height: 52px;
    border-bottom: 1px dashed #ccc;
    background-color: ${props => props.backgroundColor};

    div {
        width: 30%;
        white-space: wrap;
    }
`;

export const AndroidBackRows = styled.div<BackgroundProps>`
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 16px;
    height: 52px;
    border-bottom: 1px dashed #ccc;
    background-color: ${props => props.backgroundColor};

    div {
        width: 20%;
        white-space: wrap;
    }
`;

type HeightProps = {
    width?: string;
    height?: string;
}

export const Placeholder = styled.span<HeightProps>`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '100%'};
    background-color: rgba(101, 131, 161, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
`;

export const UploadContainer = styled.div`
	width: 70%;

    p {
        font-size: .9rem;
        margin: 8px 24px;
    }

	button {
		position: relative;
        margin-left: 16px;
	}

	input {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		top: 0;
		left: 0;
		cursor: pointer;
	}
`;

export const ImageContainer = styled.div`
    border-radius: 8px;
    background-color: #fff;
    width: 330px;
    height: 500px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    h1 {
        font-size: 1.25rem !important;
        text-align: center;
        margin: 12px 0;
        padding: 0;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        width: 308px;
        height: 40px;
        border: none;
        cursor: pointer;
        margin-left: -12px;
    }

    input::-webkit-color-swatch {
        border-radius: 100px;
        border: 1px solid #000000;
    }

    input::-moz-color-swatch {
        border-radius: 100px;
        border: 7px solid #000000;
    }
`;

export const ButtonsDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const DateHeader = styled.div`
    display: flex;
    justify-content: center;
	padding-top: 0px;
	z-index: 50;

	button {
		height: 40px;
		padding: 0 40px;
	}
`;

export const ExpDateHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #647E93;
	margin: 0;
	padding: 0;				

	h2 {
		font-size: 1.25rem;
		font-weight: 500;
		margin-bottom: 0.5rem;
	}

	a {
		text-decoration: none;
	}

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		span {
			font-size: 1.25rem;
			font-weight: 500;
			margin-top: 28px;
		}

		p {
			font-size: .75rem;
			font-weight: 500;
			margin-top: 4px;
		}
	}

	@media (max-width: 800px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 125vw;
	}
`;