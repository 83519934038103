import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from '../../../types';
import { ButtonDiv, Button } from '../../FormProgress/style';
import SubmitButton from '../../Button';
import showToast from '../../Toast';
import { Container, PriceContainer, Wrapper, Input } from './style';
import { Select } from '../../Select';
import CustomTooltip from '../../Tooltip';

type Props = {
	setActiveTab: (tab: string) => void;
	isNFT: boolean;
	product: Product;
	setProduct: (product: Product) => void;
	handleProductCreation: () => void;
};

const currencyTypes = ['USD', 'hBars', 'MATIC', 'ETH'];

const ProductPrice = ({
	setActiveTab,
	isNFT,
	product,
	setProduct,
	handleProductCreation,
}: Props) => {
	const { t } = useTranslation();

	const [listPrice, setListPrice] = useState('');
	const [salePrice, setSalePrice] = useState('');
	const [currency, setCurrency] = useState('USD');

	const [disableButton, setDisableButton] = useState(false);

	const [submitClicked, setSubmitClicked] = useState(false);

	const handleSubmit = () => {
		setSubmitClicked(true);
		setDisableButton(true);

		if (listPrice && salePrice) {
			const prices = [
				{
					price: Number(listPrice),
					currency: currency,
				},
				{
					price: Number(salePrice),
					currency: currency,
				},
			];

			setProduct({
				...product,
				prices,
			});

			product.prices = prices;
			handleProductCreation();
		} else {
			showToast.warn(t<string>('toast.fillAllFields'));
			setDisableButton(false);
		}
	};

	return (
		<Wrapper>
			<Container>
				<PriceContainer>
					<div>
						<label>{t<string>('createProduct.information.price.list')}</label>
						<CustomTooltip text={t<string>('tooltip.listPrice')} />
						<Input 
							type="number"
							onChange={(e) => setListPrice(e.target.value)}
							pattern="[0-9]*"
							data-testid="list-price"
							className={`${submitClicked && !listPrice ? 'error' : ''}`}
						/>
					</div>
					<div>
						<label>{t<string>('createProduct.information.price.sale')}</label>
						<CustomTooltip text={t<string>('tooltip.salesPrice')} />
						<Input
							type="number"
							onChange={(e) => setSalePrice(e.target.value)}
							pattern="[0-9]*"
							data-testid="sale-price"
							className={`${submitClicked && !salePrice ? 'error' : ''}`}
						/>
					</div>
					<div>
						<label>{t<string>('createProduct.information.price.currency.title')}</label>
						<CustomTooltip text={t<string>('tooltip.currency')} />
						<Select
							onChange={(e) => setCurrency(e.target.value)}
							options={currencyTypes}
							noOptionsLabel={t<string>('createProduct.information.price.currency.noOptions')}
							selectedOption={currency[0]}
							width="260px"
						/>
					</div>
				</PriceContainer>
			</Container>
			<ButtonDiv>
				<Button
					onClick={() =>
						isNFT 
							? setActiveTab(`3. ${t<string>('createProduct.information.navigation.supply')}`) 
							: setActiveTab(`2. ${t<string>('createProduct.information.navigation.supply')}`)
					}
				>
					{t<string>('createProduct.information.button.back')}
				</Button>
				<SubmitButton width="160px" onClick={handleSubmit} data-testid="submit" loading={disableButton} disabled={disableButton}>
					{t<string>('createProduct.information.button.create')}
				</SubmitButton>
			</ButtonDiv>
		</Wrapper>
	);
};

export default ProductPrice;
