import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import { AppRoutesEnum } from '../../../constants';
import useQueryTokens from '../../../hooks/useQuery/useQueryTokens';
import tokenService from '../../../services/tokenService';
import { TokenData, TokenField, TokenType } from '../../../types';
import Button from '../../Button';
import { Select } from '../../Select';
import TableComponent from '../../Table';
import showToast from '../../Toast';
import { SetFields } from '../TokenFields';
import {
	modalStyles,
	PaddingDiv,
	ResponsiveDiv,
	Input,
	PageHeader,
	NameFilter,
	EditDiv,
} from './style';

export default function TokenTable() {
	const [selectedEditId, setSelectedEditId] = useState<number>();
	const [selectedDeleteId, setSelectedDeleteId] = useState<number>();

	const { data: tokens, isLoading } = useQueryTokens();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [nameFilter, setNameFilter] = useState<string>('');
	const [applyedNameFilter, setApplyedNameFilter] = useState<string>('');
	const [typeFilter, setTypeFilter] = useState<string>('');

	const handleNameFilter = () => {
		setApplyedNameFilter(nameFilter);
		setNameFilter('');
	}

	let tokensOnPage =
		(applyedNameFilter || typeFilter)
			? tokens?.filter(
					(token: TokenType) =>
						token.name.toLowerCase().includes(applyedNameFilter.toLowerCase()) &&
						token.ledgerType?.toLowerCase().includes(typeFilter.toLowerCase())
			) : tokens;

	const selectedToken = tokens?.find(
		(token: TokenType) => token.tokenId === (selectedEditId || selectedDeleteId)
	);

	const tableHeaders = [
		t<string>('tokenTable.name'),
		t<string>('tokenTable.placeholder.type'),
		t<string>('tokenTable.chainId'),
		t<string>('tokenTable.fungible'),
		t<string>('tokenTable.options'),
	];

	const tableData = tokensOnPage?.map((token: TokenType) => ({
		name: token.name,
		chain: token.ledgerType,
		externalTokenId: token.externalTokenId,
		isNFT: (!token.isNFT).toString(),
		options:(
			<div className="flex">
				{token.tokenTypeId === 1 && (
					<AiFillEdit
						onClick={() => setSelectedEditId(token.tokenId)}
					/>
				)}
				<AiFillDelete
					onClick={() => setSelectedDeleteId(token.tokenId)}
				/>
			</div>
		),
	}));

	return (
		<ResponsiveDiv>
			<EditMenu
				open={!!selectedEditId}
				setOpen={setSelectedEditId}
				setSeletedEditId={setSelectedEditId}
				selectedToken={selectedToken}
			/>
			<DeleteMenu
				open={!!selectedDeleteId}
				setOpen={setSelectedDeleteId}
				selectedToken={selectedToken}
			/>
			<PageHeader>
				<NameFilter>
					<h1>{t<string>('tokenTable.title')}</h1>
					<div style={{ margin: "24px 0 0 24px" }}>
						<Select
							selectedOption='Hedera'
							options={[ 'Hedera'  ]}
							noOptionsLabel={t<string>('tokenTable.placeholder.type')}
							onChange={(e) => setTypeFilter(e.target.value)}
							needDisabledOption={t<string>('select.chain')}
						/>
					</div>
					<Input
						placeholder={t<string>('tokenTable.placeholder.name')}
						onChange={(e) => setNameFilter(e.target.value)}
					/>
					<button onClick={handleNameFilter}>{t<string>('tokenTable.placeholder.search')}</button>
				</NameFilter>
				<div>
					<button onClick={() => navigate(AppRoutesEnum.TOKEN_DEFINITION)}>{t<string>('tokenTable.create')}</button>
				</div>
			</PageHeader>
			<h1>{t<string>('leftSideBar.hover.allTokens')}</h1>
			<TableComponent 
				tableHeaders={tableHeaders} 
				tableData={tableData} 
				noDataText={t<string>('tokenTable.noTokens')} 
				loading={isLoading} 
			/>
		</ResponsiveDiv>
	);
}

const EditMenu = ({ open, setOpen, setSeletedEditId, selectedToken }: any) => {
	const [fields, setFields] = useState<TokenField[]>();
	const [loading, setLoading] = useState<boolean>(false);

	const { t } = useTranslation();

	useEffect(() => {
		setFields(selectedToken?.metadata);
	}, [selectedToken]);

	const handleEdit = () => {
		setLoading(true);
		const AttributeData: TokenData = {
			...selectedToken,
			metadata: fields,
		};
		tokenService
			.patchToken(AttributeData)
			.then(() => {
				alert(t<string>('toast.successfullyEdited'));
			})
			.catch((e: any) => {
				showToast.error(e.message);
			})
			.finally(() => {
				setLoading(false);
				setSeletedEditId('');
			});
	};
  
	return (
		<Modal
			isOpen={open}
			onRequestClose={() => setOpen(false)}
			style={modalStyles}
			contentLabel="Edit Token"
		>
			<EditDiv>
				<SetFields fields={fields} setFields={setFields} token={selectedToken} />
				<div style={{ marginTop: "24px" }}>
					<Button loading={loading} onClick={handleEdit}>
						Edit
					</Button>
				</div>
			</EditDiv>
		</Modal>
	);
};

const DeleteMenu = ({ open, setOpen, selectedToken }: any) => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const handleDelete = () => {
		setLoading(true);
		tokenService
			.deleteToken(selectedToken)
			.then(() => {
				alert(t<string>('toast.successfullyDeleted'));
			})
			.catch((e: any) => showToast.error(e.message))
			.finally(() => {
				setLoading(false);
				setOpen(false);
			});
	};

	return (
		<Modal
			isOpen={open}
			onRequestClose={() => setOpen(false)}
			style={modalStyles}
			contentLabel="Delete Token"
		>
			<PaddingDiv>
				<h3>{t<string>('tokenTable.delete.title')}</h3>
				<p>{selectedToken?.name}</p>
				<div style={{ display: 'flex', justifyContent: "space-between", marginTop: "64px" }}>
					<Button onClick={() => setOpen('')}>{t<string>('tokenTable.delete.no')}</Button>
					<Button onClick={handleDelete} loading={loading}>
						{t<string>('tokenTable.delete.yes')}
					</Button>
				</div>
			</PaddingDiv>
		</Modal>
	);
};
