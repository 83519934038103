import { Draggable, Droppable } from 'react-beautiful-dnd';

import { Widget, InnerText, Img } from '..';
import { WalletTemplate } from '../../../types';

type Props = {
	walletTemplate: WalletTemplate;
	isReview?: boolean;
};

export const Generic = ({ walletTemplate, isReview }: Props) => {
	return (
		<div>
			<div className="flex">
				{isReview ? (
					walletTemplate.primaryFields?.length ? (
						<div>
							{walletTemplate.primaryFields.map((field, index) => (
								<div className="rounded-lg h-16 w-full" key={index}>
									<p>{field.label}</p>
									<p
										style={{
											color: walletTemplate.foregroundColor,
										}}
									>
										{(field.mappedValue as string) ||
											(field.defaultValue as string)}
									</p>
								</div>
							))}
						</div>
					) : (
						<Widget className="w-full h-16">
							<InnerText>Primary Fields</InnerText>
						</Widget>
					)
				) : (
					<Droppable droppableId="primaryField">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`${
									snapshot.isDraggingOver
										? 'rounded-lg h-16 shadow-xl'
										: 'rounded-lg h-16'
								} w-full mr-4`}
							>
								{walletTemplate.primaryFields?.length ? (
									<div>
										{walletTemplate.primaryFields.map((field, index) => (
											<Draggable
												key={index}
												draggableId={`primaryField ${index}`}
												index={index}
											>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={`${
															snapshot.isDragging
																? 'rounded-lg h-16 shadow-xl border-2 border-dashed border-stone-200 bg-whit'
																: 'rounded-lg h-16'
														} w-full`}
														style={{
															top: `${snapshot.isDragging ? '0px' : '0px'}`,
															right: `${snapshot.isDragging ? '0px' : '0px'}`,
															transition:
																provided?.draggableProps?.style?.transition,
															transform:
																provided?.draggableProps?.style?.transform,
														}}
													>
														<p>{field.label}</p>
														<p
															style={{
																color: walletTemplate.foregroundColor,
															}}
														>
															{(field.mappedValue as string) ||
																(field.defaultValue as string)}
														</p>
													</div>
												)}
											</Draggable>
										))}
									</div>
								) : (
									<Widget className="w-full h-16">
										<InnerText>Primary Fields</InnerText>
									</Widget>
								)}
							</div>
						)}
					</Droppable>
				)}
				{walletTemplate?.images?.thumbnail ? (
					<div className="w-14 h-16 overflow-hidden rounded-lg">
						<Img
							src={walletTemplate?.images?.thumbnail}
							alt="logo"
						/>
					</div>
				) : (
					<Widget className="w-20 h-16">
						<InnerText>Thumb</InnerText>
					</Widget>
				)}
			</div>
			{isReview ? (
				walletTemplate.secondaryFields?.length ? (
					<div>
						{walletTemplate.secondaryFields.map((field, index) => (
							<div className="rounded-lg h-10 w-full" key={index}>
								<p>{field.label}</p>
								<p 
									style={{
										color: walletTemplate.foregroundColor,
									}}
								>
									{(field.mappedValue as string) ||
										(field.defaultValue as string)}
								</p>
							</div>
						))}
					</div>
				) : (
					<Widget className="w-full h-10">
						<InnerText>Secondary Fields</InnerText>
					</Widget>
				)
			) : (
				<Droppable droppableId="secondaryField">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
							className={`${
								snapshot.isDraggingOver ? 'rounded-lg shadow-xl' : 'rounded-lg'
							} w-full h-10 mb-4`}
						>
							{walletTemplate.secondaryFields?.length ? (
								<div>
									{walletTemplate.secondaryFields.map((field, index) => (
										<Draggable
											key={index}
											draggableId={`secondaryField ${index}`}
											index={index}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={`${
														snapshot.isDragging
															? 'rounded-lg h-10 shadow-xl border-2 border-dashed border-stone-200 bg-whit'
															: 'rounded-lg h-10'
													} w-full`}
													style={{
														top: `${snapshot.isDragging ? '0px' : '0px'}`,
														right: `${snapshot.isDragging ? '0px' : '0px'}`,
														transition:
															provided?.draggableProps?.style?.transition,
														transform:
															provided?.draggableProps?.style?.transform,
													}}
												>
													<p>{field.label}</p>
													<p
														style={{
															color: walletTemplate.foregroundColor,
														}}
													>
														{(field.mappedValue as string) ||
															(field.defaultValue as string)}
													</p>
												</div>
											)}
										</Draggable>
									))}
								</div>
							) : (
								<Widget className="w-full h-10">
									<InnerText>Secondary Fields</InnerText>
								</Widget>
							)}
						</div>
					)}
				</Droppable>
			)}
			{isReview ? (
				walletTemplate.auxiliaryFields?.length ? (
					<div>
						{walletTemplate.auxiliaryFields.map((field, index) => (
							<div className="rounded-lg h-10 w-full" key={index}>
								<p>{field.label}</p>
								<p
									style={{
										color: walletTemplate.foregroundColor,
									}}
								>
									{(field.mappedValue as string) ||
										(field.defaultValue as string)}
								</p>
							</div>
						))}
					</div>
				) : (
					<Widget className="w-full h-10">
						<InnerText>Auxiliary Fields</InnerText>
					</Widget>
				)
			) : (
				<Droppable droppableId="auxiliaryField">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
							className={`${
								snapshot.isDraggingOver ? 'rounded-lg shadow-xl' : 'rounded-lg'
							} w-full h-10`}
						>
							{walletTemplate.auxiliaryFields?.length ? (
								<div>
									{walletTemplate.auxiliaryFields.map((field, index) => (
										<Draggable
											key={index}
											draggableId={`auxiliaryField ${index}`}
											index={index}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={`${
														snapshot.isDragging
															? 'rounded-lg h-10 shadow-xl border-2 border-dashed border-stone-200 bg-whit'
															: 'rounded-lg h-10'
													} w-full`}
													style={{
														top: `${snapshot.isDragging ? '0px' : '0px'}`,
														right: `${snapshot.isDragging ? '0px' : '0px'}`,
														transition:
															provided?.draggableProps?.style?.transition,
														transform:
															provided?.draggableProps?.style?.transform,
													}}
												>
													<p>{field.label}</p>
													<p
														style={{
															color: walletTemplate.foregroundColor,
														}}
													>
														{(field.mappedValue as string) ||
															(field.defaultValue as string)}
													</p>
												</div>
											)}
										</Draggable>
									))}
								</div>
							) : (
								<Widget className="w-full h-10">
									<InnerText>Auxiliary Fields</InnerText>
								</Widget>
							)}
						</div>
					)}
				</Droppable>
			)}
		</div>
	);
};
