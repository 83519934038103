import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";
import { Droppable } from "react-beautiful-dnd";
import { GrClose } from "react-icons/gr";
import styled from "styled-components";

type Props = {
    handleFieldRemove: (index: number, type: string) => void;
    auxiliaryFields?: IWalletPassFieldMappings[];
    secondaryFields?: IWalletPassFieldMappings[];
    strip?: string;
    thumbnail?: string;
}

const StoreCardAndCoupon = ({
    handleFieldRemove,
    auxiliaryFields,
    secondaryFields,
    strip,
}: Props) => {
    return (
        <Containter>
            <Primary strip={strip}>
                {!strip && <PlaceHolder>Strip Image</PlaceHolder>}
            </Primary>
            <Droppable droppableId="secondaryField">
                {
                    (provided, snapshot) => (
                        <div style={{ 
                            display: "flex", 
                            flexWrap: "nowrap", 
                            height: "40px",  
                            overflowX: "scroll",
                            marginTop: "12px",
                            backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
                        }}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {!secondaryFields?.length && <PlaceHolder>Secondary and Auxiliary Fields</PlaceHolder>}
                            {secondaryFields?.map((field, index: number) => (
                                <Flex key={index}>
                                    <div>
                                        <p>{field.label}</p>
                                    </div>
                                    <div>
                                        {field?.defaultValue || field?.mappedValue ? (
                                            <span>{field?.defaultValue as string || field?.mappedValue as string}</span>
                                        ) : (
                                            <NoValue></NoValue>
                                        )}                                
                                    </div>
                                    <GrClose
                                        style={{ position: "absolute", top: "0", left: "96px", cursor: "pointer" }}
                                        onClick={() => handleFieldRemove(index, "secondaryField")}
                                    />
                                </Flex>
                            ))}
                            {
                                auxiliaryFields?.map((field, index: number) => (
                                    <Flex key={index}>
                                        <div>
                                            <p>{field.label}</p>
                                        </div>
                                        <div>
                                            {field?.defaultValue || field?.mappedValue ? (
                                                <span>{field?.defaultValue as string || field?.mappedValue}</span>
                                            ) : (
                                                <NoValue></NoValue>
                                            )} 
                                        </div>
                                        <GrClose
                                            style={{ position: "absolute", top: "0", left: "96px", cursor: "pointer" }}
                                            onClick={() => handleFieldRemove(index, "auxiliaryField")}
                                        />
                                    </Flex>
                                ))
                            }
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        </Containter>
    );
}

const Containter = styled.div`
    display: flex;
    flex-direction: column;
`;

type ImgProp = {
    strip?: string;
}

const Primary = styled.div<ImgProp>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    background: url(${({ strip }) => strip}) no-repeat center;
    background-size: contain;
`;

const PlaceHolder = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(101, 131, 161, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin: 0 8px;
`;

const Flex = styled.div`
    margin-top: 0px;
    position: relative;
    width: 50%;
    height: 40px;

    div {
        display: flex;
        
        p {
            margin: 0 0 0 8px;
            font-size: .7rem;
            overflow: ellipsis;

            &:first-child {
                width: 100px;
            }
        }

        span {
            margin-left: 8px;
            width: 100px;
            font-size: 1.25rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
`;

const NoValue = styled.span`
    margin: 4px 0 0 8px;
    width: 100px;
    height: 20px;
    background-color: #989c9e;
`;

export default StoreCardAndCoupon;
