import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { 
    WalletTemplateDefinitions,
    IAndroidPassData,
    IWalletTemplateInput,
    IWalletPassFieldMappings,
    IWalletTemplatePassData,
    PassStyle,
    IAndroidRow,
} from "@bambu-meta/interfaces";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import uuid from 'react-uuid';

import Description from "../../components/Wallet/Description";
import CustomizeWallet from "../../components/Wallet/CustomizeWallet";
import GoogleWallet from "../../components/Wallet/GoogleWallet";
import PassSelectModal from "../../components/Wallet/PassSelectModal";

import { WalletTemplate } from "../../types";
import { Background, Container, ListingOptions } from "./style";
import showToast from "../../components/Toast";
import AuthContext from "../../context/AuthContext";

import walletService from "../../services/walletService";
import { useNavigate } from "react-router-dom";
import { AppRoutesEnum } from "../../constants";
import { ButtonDiv } from "../../components/FormProgress/style";
import Button from "../../components/Button";

import RedirectModal from "../../components/Wallet/RedirectModal";

const CreateWalletTemplate = () => {
    const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const [walletTemplate, setWalletTemplate] = useState<WalletTemplate>({
        name: '',
        passType: WalletTemplateDefinitions.generic,
        passTypeIdentifier: '',
        teamIdentifier: '',
        organizationName: '',
        description: '',
        images: {
            icon: '',
            logo: '',
        },
        backgroundColor: '#ffffff',
        labelColor: '#000000',
        foregroundColor: '#000000',
    });

    const [androidWalletTemplate, setAndroidWalletTemplate] = useState<IAndroidPassData>({
        title: '',
        header: '',
        hexBackgroundColor: '#ffffff',
        rows: [{
            items: [],
        }, {
            items: [],
        }, {
            items: [],
        }],
        detailRows: [{
            items: [],
        }, {
            items: [],
        }, {
            items: [],
        }, {
            items: [],
        }],
        images: {
            icon: '',
            logo: '',
        },
        validTimeInterval: {
            start: {
                date: ''
            },
            end: {
                date: ''
            },
        }
    });

    const formatDate = (date: any) => {
        const onlyDate = date.split('T')[0];
        const dateArray = onlyDate.split('-');
        return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`.replaceAll("-", "/");
    }

    const enableButton = walletTemplate.name !== '' && walletTemplate.passTypeIdentifier !== '' 
        && walletTemplate.teamIdentifier !== '' && walletTemplate.organizationName !== '' 
        && walletTemplate.description !== '' && walletTemplate.images.icon !== '' && walletTemplate.images.logo !== '';

    const [isAndroid, setIsAndroid] = useState(false);

    const [fields, setFields] = useState<IWalletPassFieldMappings[]>([
        {
            key: uuid(),
            label: "Name",
            defaultValue: "",
            mappedValue: "",
            changeMessage: "",
        },
        {
            key: uuid(),
            label: "Email",
            defaultValue: "",
            mappedValue: "",
            changeMessage: "",
        },
        {
            key: uuid(),
            label: "Phone",
            defaultValue: "",
            mappedValue: "",
            changeMessage: "",
        },
        {
            key: uuid(),
            label: "Address",
            defaultValue: "",
            mappedValue: "",
            changeMessage: "",
        }, 
        {
            key: uuid(),
            label: "Expiry date",
            defaultValue: "",
        }, 
    ]);

    const [isCustomizePage, setIsCustomizePage] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const { tenantId } = user;

    const navigate = useNavigate();

    const handleCustomize = () => {
        setIsAndroid(false);
        setIsCustomizePage(false);
    }

    const handleFieldRemove = (index: number, type: string) => {
        if (type === "primaryField") walletTemplate.primaryFields?.splice(index, 1);
        if (type === "secondaryField") walletTemplate.secondaryFields?.splice(index, 1);
        if (type === "auxiliaryField") walletTemplate.auxiliaryFields?.splice(index, 1);
        if (type === "appleBackPreview") walletTemplate.backFields?.splice(index, 1);
        if (type === "headerField") walletTemplate.headerFields?.splice(index, 1);
        if (type === "row1") androidWalletTemplate.rows[0].items.splice(index, 1);
        if (type === "row2") androidWalletTemplate.rows[1].items.splice(index, 1);
        if (type === "row3") androidWalletTemplate.rows[2].items.splice(index, 1);
        if (type === "backrow1") androidWalletTemplate.detailRows?.[0].items.splice(index, 1);
        if (type === "backrow2") androidWalletTemplate.detailRows?.[1].items.splice(index, 1);
        if (type === "backrow3") androidWalletTemplate.detailRows?.[2].items.splice(index, 1);
        if (type === "backrow4") androidWalletTemplate.detailRows?.[3].items.splice(index, 1);

        setWalletTemplate({ ...walletTemplate });
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination || (result.destination.droppableId === result.source.droppableId 
            && result.destination.index === result.source.index)) return;
        
        if (result.destination?.droppableId === "appleBackPreview") {
            if (walletTemplate.backFields?.length === walletTemplate.passType.fields.backFields.quantity) {
                showToast.info("You can't add more back fields");
                return;
            }

            if (result.draggableId.slice(0, 4) === "back" && walletTemplate.backFields) {
                const aux = walletTemplate?.backFields?.[result?.source?.index];
                walletTemplate.backFields[result.source.index] = walletTemplate?.backFields?.[result?.destination?.index] as IWalletPassFieldMappings;
                walletTemplate.backFields[result.destination.index] = aux as IWalletPassFieldMappings;
                setWalletTemplate({ ...walletTemplate });
            } else if (walletTemplate.backFields?.length) {
                walletTemplate.backFields.splice(result.destination.index, 0, fields[result.source.index]);
                setWalletTemplate({ ...walletTemplate });
            } else setWalletTemplate({ ...walletTemplate, backFields: [fields[result.source.index]] });
        }

        if (result.destination?.droppableId === "primaryField") {
            if (walletTemplate.primaryFields?.length === walletTemplate.passType.fields.primaryFields.quantity) {
                showToast.info("You can't add more primary fields");
                return;
            }
            
            if (walletTemplate?.primaryFields?.length) {
                walletTemplate.primaryFields.splice(result.destination.index, 0, fields[result.source.index]);
                setWalletTemplate({ ...walletTemplate });
            } else setWalletTemplate({ ...walletTemplate, primaryFields: [fields[result.source.index]] });
        }

        if (result.destination?.droppableId === "secondaryField") {
            if (walletTemplate.secondaryFields?.length === walletTemplate.passType.fields.secondaryFields.quantity) {
                showToast.info("You can't add more secondary fields");
                return;
            }

            if (walletTemplate?.secondaryFields?.length) {
                walletTemplate.secondaryFields.splice(result.destination.index, 0, fields[result.source.index]);
                setWalletTemplate({ ...walletTemplate });
            } else setWalletTemplate({ ...walletTemplate, secondaryFields: [fields[result.source.index]] });
        }

        if (result.destination?.droppableId === "auxiliaryField") {
            if (walletTemplate.auxiliaryFields?.length === walletTemplate.passType.fields.auxiliaryFields.quantity) {
                showToast.info("You can't add more auxiliary fields");
                return;
            }
            
            if (walletTemplate?.auxiliaryFields?.length) {
                walletTemplate.auxiliaryFields.splice(result.destination.index, 0, fields[result.source.index]);
                setWalletTemplate({ ...walletTemplate });
            } else setWalletTemplate({ ...walletTemplate, auxiliaryFields: [fields[result.source.index]] });
        }

        if (result.destination?.droppableId === "headerField") {
            if (walletTemplate.headerFields?.length === walletTemplate.passType.fields.headerFields.quantity) {
                showToast.info("You can't add more header fields");
                return;
            }

            setWalletTemplate({ ...walletTemplate, headerFields: [fields[result.source.index]] });
        }

        if (result.destination?.droppableId === "row1") {
            if (androidWalletTemplate?.rows?.[0]?.items?.length === 3) {
                showToast.info("You can't add more fields to this row");
                return;
            }

            androidWalletTemplate.rows[0]?.items?.splice(result.destination.index, 0, fields[result.source.index].label);
            setAndroidWalletTemplate({ ...androidWalletTemplate });
        }

        if (result.destination?.droppableId === "row2") {
            if (androidWalletTemplate?.rows?.[1]?.items?.length === 3) {
                showToast.info("You can't add more fields to this row");
                return;
            }

            androidWalletTemplate.rows[1]?.items?.splice(result.destination.index, 0, fields[result.source.index].label);
            setAndroidWalletTemplate({ ...androidWalletTemplate });
        }

        if (result.destination?.droppableId === "row3") {
            if (androidWalletTemplate?.rows?.[2]?.items?.length === 3) {
                showToast.info("You can't add more fields to this row");
                return;
            }

            androidWalletTemplate.rows[2]?.items?.splice(result.destination.index, 0, fields[result.source.index].label);
            setAndroidWalletTemplate({ ...androidWalletTemplate });
        }

        if (result.destination?.droppableId === "backrow1") {
            if (androidWalletTemplate?.detailRows?.[0]?.items?.length === 2) {
                showToast.info("You can't add more fields to this row");
                return;
            }

            androidWalletTemplate?.detailRows?.[0]?.items?.splice(result.destination.index, 0, fields[result.source.index].label);
            setAndroidWalletTemplate({ ...androidWalletTemplate });
        }

        if (result.destination?.droppableId === "backrow2") {
            if (androidWalletTemplate?.detailRows?.[1]?.items?.length === 2) {
                showToast.info("You can't add more fields to this row");
                return;
            }

            androidWalletTemplate?.detailRows?.[1]?.items?.splice(result.destination.index, 0, fields[result.source.index].label);
            setAndroidWalletTemplate({ ...androidWalletTemplate });
        }

        if (result.destination?.droppableId === "backrow3") {
            if (androidWalletTemplate?.detailRows?.[2]?.items?.length === 2) {
                showToast.info("You can't add more fields to this row");
                return;
            }

            androidWalletTemplate?.detailRows?.[2]?.items?.splice(result.destination.index, 0, fields[result.source.index].label);
            setAndroidWalletTemplate({ ...androidWalletTemplate });
        }

        if (result.destination?.droppableId === "backrow4") {
            if (androidWalletTemplate?.detailRows?.[3]?.items?.length === 2) {
                showToast.info("You can't add more fields to this row");
                return;
            }

            androidWalletTemplate?.detailRows?.[3]?.items?.splice(result.destination.index, 0, fields[result.source.index].label);
            setAndroidWalletTemplate({ ...androidWalletTemplate });
        }
    }

    const handleSubmit = () => {
        setLoading(true);

        let passStyle: PassStyle;
        const imagesType = [
            "logo",
            "icon",
        ];
  
        switch (walletTemplate.passType) {
            case WalletTemplateDefinitions.generic:
                passStyle = "generic";
                imagesType.push("thumbnail");
                break;
            case WalletTemplateDefinitions.eventTicket:
                passStyle = "eventTicket";

                if (walletTemplate?.images?.strip) {
                    imagesType.push("strip");
                } else {
                    imagesType.push("thumbnail");
                    imagesType.push("background");
                }

                break;
            case WalletTemplateDefinitions.storeCard:
                passStyle = "storeCard";
                imagesType.push("strip");
                break;
            case WalletTemplateDefinitions.coupon:
                passStyle = "coupon";
                imagesType.push("strip");
                break;
            default:
                passStyle = "generic";       
                imagesType.push("thumbnail");
        }

        const passdata: IWalletTemplatePassData = {
            primaryFields: walletTemplate.primaryFields?.map((field) => field.key),
            secondaryFields: walletTemplate.secondaryFields?.map((field) => field.key),
            auxiliaryFields: walletTemplate.auxiliaryFields?.map((field) => field.key),
            backFields: walletTemplate.backFields?.map((field) => field.key),
            headerFields: walletTemplate.headerFields?.map((field) => field.key),
            backgroundColor: walletTemplate.backgroundColor,
            foregroundColor: walletTemplate.foregroundColor,
            labelColor: walletTemplate.labelColor,
            description: walletTemplate.description,
            passStyle,
            barCodes: walletTemplate.barCodes,
        }

        const images = Object.values(walletTemplate.images)?.map((image: string, index: number) => {
            return {
                image: imagesType[index],
                imageFile: image,
            }
        })

        const androidRows = androidWalletTemplate.rows.map((row: IAndroidRow) => {
            fields.forEach((field) => {
                if (row.items.includes(field.label)) {
                    row.items[row.items.indexOf(field.label)] = field.key;
                }
            })

            return row;
        })

        const androidBackRows = androidWalletTemplate.detailRows?.map((row: IAndroidRow) => {
            fields.forEach((field) => {
                if (row.items.includes(field.label)) {
                    row.items[row.items.indexOf(field.label)] = field.key;
                }
            })

            return row;
        })

        const androidPassData = {
            ...androidWalletTemplate,
            rows: androidRows,
            detailRows: androidBackRows,
        }

        // Set the expiration date defined by the user for the draggable field 'Expiry date'
        const expDateFieldIndex = fields.findIndex(field => field.label.toUpperCase() === "EXPIRY DATE");
        fields[expDateFieldIndex] = {
            ...fields[expDateFieldIndex],
            defaultValue: formatDate(androidPassData.validTimeInterval?.end.date),
        };

        const body: IWalletTemplateInput = {
            name: walletTemplate.name,
            passTypeIdentifier: walletTemplate.passTypeIdentifier,
            teamIdentifier: walletTemplate.teamIdentifier,
            organizationName: walletTemplate.organizationName,
            sharingProhibited: false,
            passdata,
            androidPassData,
            tenantId,
            fieldMappings: fields,
            images,
        }

        walletService.createWalletTemplate(tenantId, body)
            .then((res) => {
                showToast.success("Wallet template created successfully");
                navigate(AppRoutesEnum.WALLET_TEMPLATE_TABLE);
            }).catch((err) => {
                showToast.error(err.message);
            }).finally(() => {
                setLoading(false);
            })
            
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Background>
                <>
                    <h1>{t<string>('walletCard.title')}</h1>
                    <ListingOptions>
                        <h2 onClick={handleCustomize} className={!isCustomizePage && !isAndroid ? "active" : ""} >{t<string>('walletCard.walletDefinition')}</h2>
                        <h2 onClick={() => {
                            setIsAndroid(false)
                            setIsCustomizePage(true)
                        }} className={!isAndroid && isCustomizePage ? "active" : ""} >{t<string>('walletCard.apple')}</h2>
                        <h2 onClick={() => setIsAndroid(true)} className={isAndroid ? "active" : ""} >{t<string>('walletCard.google')}</h2>
                    </ListingOptions>
                    {isAndroid ? (
                        <GoogleWallet
                            androidWalletTemplate={androidWalletTemplate}
                            setAndroidWalletTemplate={setAndroidWalletTemplate}
                            walletTemplate={walletTemplate}
                            fields={fields}
                            setFields={setFields}
                            handleFieldRemove={handleFieldRemove}
                            isLoading={isLoading}
                        />
                    ) : (
                        !isCustomizePage ? (
                            <Container>
                                <Description 
                                    walletTemplate={walletTemplate} 
                                    setWalletTemplate={setWalletTemplate}
                                />
                            </Container>
                        ) : (
                            <CustomizeWallet 
                                walletTemplate={walletTemplate}
                                setWalletTemplate={setWalletTemplate}
                                fields={fields}
                                setFields={setFields}
                                handleFieldRemove={handleFieldRemove}
                            />
                        )
                    )}
                    <ButtonDiv style={{
                        marginTop: "0px",
                    }}>
                        {enableButton ? (
                            <Button 
                                width="160px"
                                onClick={handleSubmit}
                                loading={isLoading}
                                disabled={isLoading && !enableButton}
                                backgroundColor={enableButton ? "#82A3C1" : "rgba(101, 131, 161, 0.1)"}
                                color={enableButton ? "#fff" : "#647E93"}
                            >{t<string>('walletCard.button')}</Button>
                        ) : (
                            <Button 
                                width="160px"
                                disabled={isLoading && !enableButton}
                                backgroundColor={enableButton ? "#82A3C1" : "rgba(101, 131, 161, 0.1)"}
                                color={enableButton ? "#fff" : "#647E93"}
                            >{t<string>('walletCard.button')}</Button>
                        )}
                    </ButtonDiv>
                </>
            </Background>
            <RedirectModal 
                isOpen={isRedirectModalOpen}
                setIsOpen={setIsRedirectModalOpen}
            />
            <PassSelectModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                walletTemplate={walletTemplate}
                androidWalletTemplate={androidWalletTemplate}
                setAndroidWalletTemplate={setAndroidWalletTemplate}
                setWalletTemplate={setWalletTemplate}
                handleFieldRemove={handleFieldRemove}
            />
        </DragDropContext>
    )
}

export default CreateWalletTemplate;