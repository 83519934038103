import styled from 'styled-components';

export const Wrapper = styled.div`
    border-radius: 8px;
    height: 500px;
    width: 330px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 0;
    margin: 0;
    font-weight: 400;
`;

type Props = {
    imgUrl?: string;
    background?: string;
    label?: string;
}

export const FlipCard = styled.div<Props>`
    background-color: transparent;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    perspective: 1000px;
    background-size: cover;
`;

export const NoValue = styled.p`
    width: 100px;
    height: 20px;
    background-color: #989c9e;
`;

export const WalletCard = styled.div<Props>`
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    background-color: ${(props: Props) => props.background};
    background-image: url(${(props: Props) => props.imgUrl});
    color: ${(props: Props) => props.label};
    background-size: cover;
`;

export const FrontFields = styled.div`
    position: absolute;    
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
`;

export const Input = styled.input`
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	width: 112px;
	height: 32px;
	font-size: 1rem;
	border: none;
	padding: 0 16px;
	display: inline-block;
	color: #647E93;

	&:placeholder {
		color: #647E93;
	}

	&:focus {
		outline: none;
	}
`;


export const BackFields = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    font-size: .9rem;
    font-weight: 400;
    z-index: 100;

    span {
        font-size: .7rem;
        position: absolute;
        top: 8px;
    }

    span, p {
        position: absolute;
        right: 12px;
    }

    p {
        font-size: 1rem;
        top: 4px;
    }

    img {
        padding: 8px;
        height: 40px;
        width: 40px;
    }
`;

export const BarCode = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 90%;
    position: absolute;
    bottom: 24px;
    left: 5%;
    background-color: rgba(101, 131, 161, 0.1);
    font-size: .9rem;

    img {
        height: 100%;
        width: 100%;
    }
`;

export const QrCode = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    position: absolute;
    bottom: 12px;
    right: 37%;
    background-color: rgba(101, 131, 161, 0.1);

    img {
        height: 100%;
        width: 100%;
    }
`;
