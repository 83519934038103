import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AuthContext from '../../context/AuthContext';
import useQueryBrands from '../../hooks/useQuery/useQueryBrands';

import { SelectObjects } from '../Select';
import { Container } from './style';

export interface tenantsType {
	audit: {
		createdAt: number;
		createdBy: string;
		updatedAt: number;
		updatedBy: string;
		company: string;
	};
	id: string;
	passIdentifier: string;
	passIdentifiers: string[];
	reportSite: string;
	tenantId: string;
	title: string;
}

let selectedTenant = "";
const TenantDropdown = () => {
	const { data, isLoading } = useQueryBrands();
	const { setTenantId, user } = useContext(AuthContext);
	const { t } = useTranslation();

	const handleTentantUpdate = (tenantId: string) => {
    	setTenantId(tenantId);
		localStorage.setItem('tenantId', tenantId);
	};

	useEffect(() => {
		selectedTenant = localStorage.getItem('tenantId') || user?.tenantId;
		setTenantId(selectedTenant);
	}, []);

	if (user?.custom_tenant_id || data?.length === 1 || isLoading) return(<></>);

	return (
		<Container>
			<SelectObjects
				onChange={(u: any) => handleTentantUpdate(u.tenantId)}
				selectedOption={selectedTenant}
				options={data}
				loading={isLoading}
				valueKey="tenantId"
				labelKey="name"
				noOptionsLabel={t<string>('select.noTenants')}
			/>
		</Container>
	);
};

export default TenantDropdown;
