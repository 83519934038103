import styled from 'styled-components';
const pandaBg = require('../../assets/logos/half-panda.png');

export const MainDiv = styled.div`
	background-image: url(${pandaBg});
	background-size: 300px;
	background-repeat: no-repeat;
	height: 100vh;
	color: #647E93;

	@media (max-width: 1000px) {
		background-image: none;
	}
`;

export const PaddingDiv = styled.div`
	padding-left: calc(40% - 200px);
	padding-top: 50px;
	padding-right: calc(40% - 200px);

	@media (max-width: 1000px) {
		padding: 8rem 2rem;
	}

	@media (max-width: 600px) {
		padding: calc(50% - 100px) .5rem 0 .5rem;
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 8px;
`;

export const FlexDiv = styled.div`
	display: flex;
`;

export const FlexColumnDiv = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	svg {
		position: absolute;
		top: .85rem;
		right: 0.25rem;
		font-size: .5rem;
		color: #eb3446;
	}
`;

export const ErrorText = styled.span`
	color: #f55442;
	margin-left: 6px;
	font-size: 11px;
`;
export const CenterButtonDiv = styled.div`
	padding-top: 12px;
	padding-bottom: 12px;
	display: flex;
	justify-content: center;
`;

export const InputBasic = `
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	height: 32px;
	font-size: 1rem;
	border: none;
	padding: 8px 24px;
	display: block;
	color: #647E93;
	margin-bottom: 16px;
	height: initial;
	margin: 4px;
	min-width: 60px;
	outline: initial !important;
	line-height: 1;
	height: initial;
	min-width: 60px;
	color: #647E93;
`;

export const Input = styled.input`
	${InputBasic}

	&:focus {
		color: #495057;
		border-color: #80bdff;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}

	&.required::after {
		content: ' *';
		color: red;
		position: absolute;
		right: 12px;
		top: 0.1rem;
	}
`;

export const RequiredDiv = styled.div`
	position: relative;

	svg {
		position: absolute;
		top: .85rem;
		right: .5rem;
		font-size: .5rem;
		color: #eb3446;
	}
`;

export const Title = styled.h1`
	text-align: center;
	position: relative;
	font-size: 1.2rem;
	font-weight:600;
	margin-bottom: 8px;

	svg {
		font-size: 1.5rem;
		position: absolute;
		top: 0;
		left: 0.5rem;
		cursor: pointer;
	}
`;

export const BackButton = styled.div`
	svg {
		font-size: 1.5rem;
		cursor: pointer;
	}
`;
