import ReactECharts from 'echarts-for-react';
import { ThreeDots } from 'react-loader-spinner';

type Props = {
  xData: string[];
  yData: number[];
  isLoading: boolean;
}

const BarChart = ({ xData, yData, isLoading }: Props) => {
  const option = {
		xAxis: {
			type: 'category',
			data: xData,
		  },
		  yAxis: {
			type: 'value'
		  },
		  series: [
			{
			  data: yData,
			  type: 'bar',
			  showBackground: true,
			  backgroundStyle: {
				color: 'rgba(180, 180, 180, 0.2)'
			  }
			}
		]
	}

  if (isLoading) {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <ThreeDots color="#647E93" />
        </div>
    );
}
 
  return (
    <ReactECharts option={ option } />
  );
}

export default BarChart;
