import styled from 'styled-components';

export const GeneralInfoContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	box-sizing: border-box;
	margin-right: 0.5rem;
	color: #647E93;

	@media (max-width: 863px) {
		display: flex;
		flex-direction: column;
	}

	@media (max-width: 767px) {
		flex-wrap: wrap;
	}
`;

export const RowClearfix = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 24px;
`;

export const Ul = styled.ul`
	list-style: none;
	padding: 24px;
	margin: 0;

	li {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;

		span {
			width: 160px;
			font-size: 0.9rem;
			font-weight: 500;
		}
	}
`;

export const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 380px;
	flex-wrap: wrap;

	@media (max-width: 863px) {
		flex-wrap: nowrap;
		margin-bottom: 340px;
	}
`;

export const CardContainer = styled.div`
	border-radius: 0.75rem;
	margin-left: 16px;	
	width: 28vw;
	height: 180px;
	margin-bottom: 5px;
	padding: 16px 24px;
	background-color: #fff;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;

	&:hover {
		transform: scale(1.02);
		transition: all 0.5s ease-in-out;
	}

	@media (max-width: 800px) {
		width: 90%;
	}
`;

export const Title = styled.div`
	font-size: 1rem;
	display: inline-block;
`;

export const DataContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 116px;
	font-size: 2.5rem;
`;
