import { ReactNode } from 'react';

interface ButtonProps {
	/** Text to communicate */
	children: string | ReactNode;
	/** Extra classNames */
	className?: string;
	/** Intent of button */
	intent?: string;
	/** Function button performs */
	onClick: () => void;
}

const buttonStyles =
	'py-2 px-4 outline-offset-2 rounded-lg font-bold font-sans text-md transition-all flex items-center justify-center text-center cursor-pointer';

export const primaryButtonStyles =
	buttonStyles +
	' hover:bg-bluejay-600 active:bg-bluejay-500 outline-offset-2 rounded-lg text-white border-0';

export const secondaryButtonStyles =
	buttonStyles +
	' bg-bluejay-50 hover:bg-bluejay-200 active:bg-bluejay-300 text-bluejay-800 border-0';

export const PrimaryButton = ({
	children,
	className,
	intent = 'primary',
	onClick,
}: ButtonProps) => {
	return (
		<button
			className={`${primaryButtonStyles} ${
				intent !== 'primary' ? 'bg-bluejay-700' : 'bg-bluejay-800'
			} ${className}`}
			onClick={onClick}
		>
			{children}
		</button>
	);
};
