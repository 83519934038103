import styled from 'styled-components';

export const ResponsiveDiv = styled.div`
	display: block;
	width: 100%;
	overflow-x: auto;
`;

export const Table = styled.table`
	text-align: left;
	border-collapse: collapse;
	width: 100%;
	font-size: 14px;
	@media (max-width: 600px) {
		font-size: 8px;
	}
`;

export const Thead = styled.thead`
`;

export const Th = styled.th`
	border-top: 0;
	border-bottom-width: 1px;
	padding-top: 0.5rem;
	font-weight: 400;
	text-transform: uppercase;
	padding-bottom: 0.5rem;
	color: #747879;
	border-bottom: 2px solid #dee2e6;
`;

export const Td = styled.td`
	padding: 0.75rem;
	padding-left: 0px;
	border-top: 1px solid #e8e9e9;

	svg {
		cursor: pointer;
		font-size: 20px;
	}

	p {
		margin: 0;
		font-size: .6rem;
		overflow: hidden;
		text-overflow: ellipsis;
		wrap: nowrap;
	}

	&:last-child {
		text-align: right;
	}

	@media (max-width: 600px) {
		padding: 0.75rem 0.1rem 0.75rem 0;
		font-size: 0.9rem;
	}
`;

export const CenterDiv = styled.td`
	display: flex;
	align-items: center;
	justify-content: center;
`;
