import { Draggable, Droppable } from 'react-beautiful-dnd';

import { Widget, InnerText, Img } from '..';
import { WalletTemplate } from '../../../types';

type Props = {
	walletTemplate: WalletTemplate;
	isReview?: boolean;
};

export const StoreCardAndCoupon = ({ walletTemplate, isReview }: Props) => {
	return (
		<div>
			<div className="flex">
				{walletTemplate?.images?.strip ? (
					<div className='w-full h-16 overflow-hidden rounded-lg mb-4' >
						<Img
							src={walletTemplate?.images?.strip}
							className="w-full"
							alt="logo"
						/>
					</div>
				) : (
					<Widget className="w-full h-16">
						<InnerText>Strip Image</InnerText>
					</Widget>
				)}
			</div>
			{isReview ? (
				walletTemplate.secondaryFields?.length ? (
					<div>
						{walletTemplate.secondaryFields.map((field, index) => (
							<div className="rounded-lg h-10 w-full">
								<p>{field.label}</p>
								<p
									style={{
										color: walletTemplate.foregroundColor,
									}}
								>
									{(field.mappedValue as string) ||
										(field.defaultValue as string)}
								</p>
							</div>
						))}
					</div>
				) : (
					<Widget className="w-full h-10">
						<InnerText>Secondary Fields</InnerText>
					</Widget>
				)
			) : (
				<Droppable droppableId="secondaryField">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
							className={`${
								snapshot.isDraggingOver ? 'rounded-lg shadow-xl' : 'rounded-lg'
							} w-full h-10`}
						>
							{walletTemplate.secondaryFields?.length ? (
								<div>
									{walletTemplate.secondaryFields.map((field, index) => (
										<Draggable
											key={index}
											draggableId={`secondaryField ${index}`}
											index={index}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={`${
														snapshot.isDragging
															? 'rounded-lg h-10 shadow-xl border-2 border-dashed border-stone-200 bg-whit'
															: 'rounded-lg h-10'
													} w-full`}
													style={{
														top: `${snapshot.isDragging ? '0px' : '0px'}`,
														right: `${snapshot.isDragging ? '0px' : '0px'}`,
														transition:
															provided?.draggableProps?.style?.transition,
														transform:
															provided?.draggableProps?.style?.transform,
													}}
												>
													<p>{field.label}</p>
													<p
														style={{
															color: walletTemplate.foregroundColor,
														}}
													>
														{(field.mappedValue as string) ||
															(field.defaultValue as string)}
													</p>
												</div>
											)}
										</Draggable>
									))}
								</div>
							) : (
								<Widget className="w-full h-10">
									<InnerText>Secondary Fields</InnerText>
								</Widget>
							)}
						</div>
					)}
				</Droppable>
			)}
		</div>
	);
};
