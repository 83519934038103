import { IAndroidPassData } from '@bambu-meta/interfaces';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { Widget, InnerText } from '../../../../components';

type Props = {
	androidWalletTemplate: IAndroidPassData;
	isReview?: boolean;
};

export const AndroidDetailsCard = ({
	androidWalletTemplate,
	isReview,
}: Props) => {
	return (
		<div
			style={{ backgroundColor: androidWalletTemplate.hexBackgroundColor }}
			className="rounded-lg !p-5"
		>
			<div className="box-border h-[384px]">
				<div className="flex justify-center mb-6 mt-6">
					<InnerText>Details Page</InnerText>
				</div>
				{isReview ? (
					androidWalletTemplate?.detailRows?.[0]?.items?.length ? (
						<div className='flex justify-between'>
							{androidWalletTemplate?.detailRows[0]?.items?.map(
								(field: any, index) => (
									<div key={index} className="w-1/3">
										<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
											{field.label}
										</p>
										<p className="whitespace-nowrap text-ellipsis overflow-hidden">
											{field.mappedValue || field.defaultValue}
										</p>
									</div>
								)
							)}
						</div>
					) : (
						<Widget className="w-full h-10 mb4">
							<InnerText>First Row</InnerText>
						</Widget>
					)
				) : (
					<Droppable droppableId="backrow1">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`${
									snapshot.isDraggingOver
										? 'rounded-lg shadow-xl'
										: 'rounded-lg'
								} h-10 mb-4 w-full`}
							>
								{androidWalletTemplate?.detailRows?.[0]?.items?.length ? (
									<div className='flex justify-between'>
										{androidWalletTemplate?.detailRows[0]?.items?.map(
											(field: any, index) => (
												<Draggable
													key={index}
													draggableId={`backrow1 ${index}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`
															${
																snapshot.isDragging
																	? 'rounded-lg shadow-xl border-2 border-dashed border-stone-200 bg-white'
																	: ''
															} w-1/3
														`}
															style={{
																top: `${snapshot.isDragging ? '0px' : '0px'}`,
																right: `${snapshot.isDragging ? '0px' : '0px'}`,
																transition:
																	provided?.draggableProps?.style?.transition,
																transform:
																	provided?.draggableProps?.style?.transform,
															}}
														>
															<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
																{field.label}
															</p>
															<p className="whitespace-nowrap text-ellipsis overflow-hidden">
																{field.mappedValue || field.defaultValue}
															</p>
														</div>
													)}
												</Draggable>
											)
										)}
									</div>
								) : (
									<Widget className="w-full h-10 mb4">
										<InnerText>First Row</InnerText>
									</Widget>
								)}
							</div>
						)}
					</Droppable>
				)}
				{isReview ? (
					androidWalletTemplate?.detailRows?.[1]?.items?.length ? (
						<div className='flex justify-between'>
							{androidWalletTemplate?.detailRows[1]?.items?.map(
								(field: any, index) => (
									<div key={index} className="w-1/3">
										<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
											{field.label}
										</p>
										<p className="whitespace-nowrap text-ellipsis overflow-hidden">
											{field.mappedValue || field.defaultValue}
										</p>
									</div>
								)
							)}
						</div>
					) : (
						<Widget className="w-full h-10 mb4">
							<InnerText>Second Row</InnerText>
						</Widget>
					)
				) : (
					<Droppable droppableId="backrow2">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`${
									snapshot.isDraggingOver
										? 'rounded-lg shadow-xl'
										: 'rounded-lg'
								} h-10 mb-4 w-full`}
							>
								{androidWalletTemplate?.detailRows?.[1]?.items?.length ? (
									<div className='flex justify-between'>
										{androidWalletTemplate?.detailRows[1]?.items?.map(
											(field: any, index) => (
												<Draggable
													key={index}
													draggableId={`backrow2 ${index}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`
															${
																snapshot.isDragging
																	? 'rounded-lg shadow-xl border-2 border-dashed border-stone-200 bg-white'
																	: ''
															} w-1/3
														`}
															style={{
																top: `${snapshot.isDragging ? '0px' : '0px'}`,
																right: `${snapshot.isDragging ? '0px' : '0px'}`,
																transition:
																	provided?.draggableProps?.style?.transition,
																transform:
																	provided?.draggableProps?.style?.transform,
															}}
														>
															<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
																{field.label}
															</p>
															<p className="whitespace-nowrap text-ellipsis overflow-hidden">
																{field.mappedValue || field.defaultValue}
															</p>
														</div>
													)}
												</Draggable>
											)
										)}
									</div>
								) : (
									<Widget className="w-full h-10 mb4">
										<InnerText>Second Row</InnerText>
									</Widget>
								)}
							</div>
						)}
					</Droppable>
				)}
				{isReview ? (
					androidWalletTemplate?.detailRows?.[2]?.items?.length ? (
						<div className='flex justify-between'>
							{androidWalletTemplate?.detailRows[2]?.items?.map(
								(field: any, index) => (
									<div key={index} className="w-1/3">
										<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
											{field.label}
										</p>
										<p className="whitespace-nowrap text-ellipsis overflow-hidden">
											{field.mappedValue || field.defaultValue}
										</p>
									</div>
								)
							)}
						</div>
					) : (
						<Widget className="w-full h-10 mb4">
							<InnerText>Third Row</InnerText>
						</Widget>
					)
				) : (
					<Droppable droppableId="backrow3">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`${
									snapshot.isDraggingOver
										? 'rounded-lg shadow-xl'
										: 'rounded-lg'
								} h-10 mb-4 w-full`}
							>
								{androidWalletTemplate?.detailRows?.[2]?.items?.length ? (
									<div className='flex justify-between'>
										{androidWalletTemplate?.detailRows[2]?.items?.map(
											(field: any, index) => (
												<Draggable
													key={index}
													draggableId={`backrow3 ${index}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`
															${
																snapshot.isDragging
																	? 'rounded-lg shadow-xl border-2 border-dashed border-stone-200 bg-white'
																	: ''
															} w-1/3
														`}
															style={{
																top: `${snapshot.isDragging ? '0px' : '0px'}`,
																right: `${snapshot.isDragging ? '0px' : '0px'}`,
																transition:
																	provided?.draggableProps?.style?.transition,
																transform:
																	provided?.draggableProps?.style?.transform,
															}}
														>
															<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
																{field.label}
															</p>
															<p className="whitespace-nowrap text-ellipsis overflow-hidden">
																{field.mappedValue || field.defaultValue}
															</p>
														</div>
													)}
												</Draggable>
											)
										)}
									</div>
								) : (
									<Widget className="w-full h-10 mb4">
										<InnerText>Third Row</InnerText>
									</Widget>
								)}
							</div>
						)}
					</Droppable>
				)}
				{isReview ? (
					androidWalletTemplate?.detailRows?.[3]?.items?.length ? (
						<div className='flex justify-between'>
							{androidWalletTemplate?.detailRows[3]?.items?.map(
								(field: any, index) => (
									<div key={index} className="w-1/3">
										<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
											{field.label}
										</p>
										<p className="whitespace-nowrap text-ellipsis overflow-hidden">
											{field.mappedValue || field.defaultValue}
										</p>
									</div>
								)
							)}
						</div>
					) : (
						<Widget className="w-full h-10 mb4">
							<InnerText>Fourth Row</InnerText>
						</Widget>
					)
				) : (
					<Droppable droppableId="backrow4">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`${
									snapshot.isDraggingOver
										? 'rounded-lg shadow-xl'
										: 'rounded-lg'
								} h-10 mb-4 w-full`}
							>
								{androidWalletTemplate?.detailRows?.[3]?.items?.length ? (
									<div className='flex justify-between'>
										{androidWalletTemplate?.detailRows[3]?.items?.map(
											(field: any, index) => (
												<Draggable
													key={index}
													draggableId={`backrow4 ${index}`}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`
															${
																snapshot.isDragging
																	? 'rounded-lg shadow-xl border-2 border-dashed border-stone-200 bg-white'
																	: ''
															} w-1/3
														`}
															style={{
																top: `${snapshot.isDragging ? '0px' : '0px'}`,
																right: `${snapshot.isDragging ? '0px' : '0px'}`,
																transition:
																	provided?.draggableProps?.style?.transition,
																transform:
																	provided?.draggableProps?.style?.transform,
															}}
														>
															<p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
																{field.label}
															</p>
															<p className="whitespace-nowrap text-ellipsis overflow-hidden">
																{field.mappedValue || field.defaultValue}
															</p>
														</div>
													)}
												</Draggable>
											)
										)}
									</div>
								) : (
									<Widget className="w-full h-10 mb4">
										<InnerText>Fourth Row</InnerText>
									</Widget>
								)}
							</div>
						)}
					</Droppable>
				)}
			</div>
		</div>
	);
};
