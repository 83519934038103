import styled from "styled-components";

export const CreateEventDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 1rem 2rem;

    button {
        padding: 0.7rem;
        background-color: #417082;
        border: none;
        border-radius: 0.25rem;
        color: #fff;
        margin-left: 0.25rem;
        margin-bottom: 1rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #51899e;
        }
    }
`;
