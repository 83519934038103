import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { GrClose } from "react-icons/gr";
import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";

type Props = {
    handleFieldRemove: (index: number, type: string) => void;
    primaryFields?: IWalletPassFieldMappings[];
    auxiliaryFields?: IWalletPassFieldMappings[];
    secondaryFields?: IWalletPassFieldMappings[];
    thumbnail?: string;
}

const GenericPass = ({
    handleFieldRemove,
    primaryFields,
    auxiliaryFields,
    secondaryFields,
    thumbnail,
}: Props) => (
    <Containter>
        <ThumbnailDiv>
            <Droppable droppableId="primaryField">
                {(provided, snapshot) => (
                    <div style={{ 
                        display: "flex", 
                        flexWrap: "wrap", 
                        height: "72px",  
                        overflow: "scroll",
                        backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
                    }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {!primaryFields?.[0] && <PlaceHolder>Primary Field</PlaceHolder>}
                        {primaryFields?.map((field, index: number) => (
                            <Flex key={index}>
                                <div>
                                    <p>{field.label}</p>
                                </div>
                                <div>
                                    {field?.defaultValue || field?.mappedValue ? (
                                        <span>{field?.defaultValue as string || field?.mappedValue}</span>
                                    ) : (
                                        <NoValue></NoValue>
                                    )}                                
                                </div>
                                <GrClose
                                    style={{ position: "absolute", top: "0", right: "60px", cursor: "pointer" }}
                                    onClick={() => handleFieldRemove(index, "primaryField")}
                                />
                            </Flex>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <Thumb>
                {thumbnail ? <img src={thumbnail} alt="thumbnail" /> : <PlaceHolder style={{ marginLeft: "0" }}>Thumb</PlaceHolder>}
            </Thumb>
        </ThumbnailDiv>
        <Droppable droppableId="secondaryField">
            {(provided, snapshot) => (
                <div style={{ 
                    display: "flex", 
                    flexWrap: "nowrap", 
                    height: "40px",  
                    overflowY: "scroll",
                    backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
                }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {!secondaryFields?.length && <PlaceHolder>Secondary Fields</PlaceHolder>}
                    {
                        secondaryFields?.map((field, index: number) => (
                            <Flex key={index}>
                                <div>
                                    <p>{field.label}</p>
                                </div>
                                <div>
                                    {field?.defaultValue || field?.mappedValue ? (
                                        <span>{field?.defaultValue as string || field?.mappedValue}</span>
                                    ) : (
                                        <NoValue></NoValue>
                                    )}                                
                                </div>
                                <GrClose
                                    style={{ position: "absolute", top: "0", left: "96px", cursor: "pointer" }}
                                    onClick={() => handleFieldRemove(index, "secondaryField")}
                                />
                            </Flex>
                        ))
                    }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
        <Droppable droppableId="auxiliaryField">
            {(provided, snapshot) => (
                <div style={{ 
                    display: "flex", 
                    flexWrap: "nowrap",
                    height: "40px",  
                    overflow: "scroll",
                    backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
                }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {!auxiliaryFields?.length && <PlaceHolder>Auxiliary Fields</PlaceHolder>}
                    {
                        auxiliaryFields?.map((field, index: number) => (
                            <Flex key={index}>
                                <div>
                                    <p>{field.label}</p>
                                </div>
                                <div>
                                    {field?.defaultValue || field?.mappedValue ? (
                                        <span>{field?.defaultValue as string || field?.mappedValue}</span>
                                    ) : (
                                        <NoValue></NoValue>
                                    )} 
                                </div>
                                <GrClose
                                    style={{ position: "absolute", top: "0", left: "96px", cursor: "pointer" }}
                                    onClick={() => handleFieldRemove(index, "auxiliaryField")}
                                />
                            </Flex>
                        ))
                    }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </Containter>
);

const Containter = styled.div`
    display: flex;
    flex-direction: column;
`;

const PlaceHolder = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(101, 131, 161, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin: 0 8px;
`;

const ThumbnailDiv = styled.div`
    display: flex;

    div {
        margin-right: 8px;
        width: 100%;
    }
`;

const Thumb = styled.div`
    height: 68px;
    width: 60px !important;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const Flex = styled.div`
    margin: 0px;
    position: relative;
    width: 50%;
    height: 40px;

    div {
        display: flex;
        
        p {
            margin: 0 0 0 8px;
            font-size: .7rem;
            overflow: ellipsis;

            &:first-child {
                width: 100px;
            }
        }

        span {
            margin-left: 8px;
            width: 100px;
            font-size: 1.25rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
`;

const NoValue = styled.span`
    margin: 4px 0 0 8px;
    width: 100px;
    height: 20px;
    background-color: #989c9e;
`;

export default GenericPass;
