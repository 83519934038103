
import { IAndroidPassData, WalletTemplateDefinitions } from '@bambu-meta/interfaces';
import { Droppable } from 'react-beautiful-dnd';
import { GrClose } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

import { WalletTemplate } from '../../../types';
import {
  AndroidPreviewContainer,
  AndroidPreviewHeader,
  AndroidFirstRow,
  AndroidRows,
  ProgramInput,
  SubInput,
  ImageInputInvisible,
  Placeholder,
} from './style';

type Props = {
  androidWalletTemplate: IAndroidPassData;
  setAndroidWalletTemplate: (androidWalletTemplate: IAndroidPassData) => void;
  walletTemplate: WalletTemplate;
  handleFieldRemove: (index: number, type: string) => void;
  isPassSelection?: boolean;
}

const AndroidWalletPrevirew = ({ 
  androidWalletTemplate,
  walletTemplate,
  setAndroidWalletTemplate,
  handleFieldRemove,
  isPassSelection,
}: Props) => {
  const { t } = useTranslation();

  const height = walletTemplate?.passType === WalletTemplateDefinitions.storeCard 
    ? "60px"
    : walletTemplate?.passType === WalletTemplateDefinitions.generic
      ? "100px" 
      : "0";

  return (
    <AndroidPreviewContainer backgroundColor={androidWalletTemplate.hexBackgroundColor}>
      <AndroidPreviewHeader>
        <div style={{ height: "36px", width: "36px", position: "relative" }}>
          {androidWalletTemplate?.images?.logo ? <img src={androidWalletTemplate?.images?.logo} alt="logo" /> : <div style={{ 
            backgroundColor: "rgba(101, 131, 161, 0.1)", 
            width: "36px",
            height: "36px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}>Logo</div>}
          <ImageInputInvisible />
        </div>
        {isPassSelection ? (
          <Placeholder height="32px" style={{ margin: "0 12px" }}>
            {t<string>("walletCard.loyaltyTemplate.title")}
          </Placeholder>
        ) : (
          <input 
            type="text" 
            onChange={(e) => setAndroidWalletTemplate({ ...androidWalletTemplate, title: e.target.value })} 
            placeholder={t<string>("walletCard.loyaltyTemplate.title")}
            value={androidWalletTemplate.title}
          />
        )}
      </AndroidPreviewHeader>
      <AndroidFirstRow backgroundColor={androidWalletTemplate.hexBackgroundColor} height={height}>
        {(walletTemplate?.passType === WalletTemplateDefinitions.storeCard
         || walletTemplate?.passType === WalletTemplateDefinitions.generic) && (
            isPassSelection ? (
              <Placeholder height='40px' width='70%' style={{ margin: "8px"}}>
                 {t<string>("walletCard.loyaltyTemplate.header")} 
              </Placeholder>) 
            : (
              <ProgramInput 
                type="text" 
                onChange={(e) => setAndroidWalletTemplate({ ...androidWalletTemplate, header: e.target.value })} 
                placeholder={t<string>("walletCard.loyaltyTemplate.header")}
                value={androidWalletTemplate.header}
              />
            )
         )}
        {(walletTemplate?.passType === WalletTemplateDefinitions.generic) && (
          isPassSelection ? (
            <Placeholder height='32px' width='55%' style={{ margin: "16px 8px 8px 8px" }}>
              {t<string>("walletCard.loyaltyTemplate.subheader")}
            </Placeholder>
          ) : (
            <SubInput
              type="text" 
              onChange={(e) => setAndroidWalletTemplate({ ...androidWalletTemplate, subheader: e.target.value })} 
              placeholder={t<string>("walletCard.loyaltyTemplate.subheader")}
              value={androidWalletTemplate.subheader}
            />
          )
        )}
      </AndroidFirstRow>
      <Droppable droppableId='row1'>
        {(provided, snapshot) => (
          <AndroidRows
            backgroundColor={androidWalletTemplate.hexBackgroundColor}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ 
              backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
            }}
          >
            {!androidWalletTemplate?.rows[0]?.items?.length && (
              <Placeholder>
                {t<string>("walletCard.placeholder.firstRow")}
              </Placeholder>
            )}
            {androidWalletTemplate?.rows[0]?.items?.map((item, index) => (
              <div 
                key={index}
                style={{
                  position: "relative",
                }}
              >
                {item}
                <GrClose
                  style={{ position: "absolute", top: "-20px", right: "0px", cursor: "pointer" }}
                  onClick={() => handleFieldRemove(index, "row1")}
                />
              </div>
            ))}
          </AndroidRows>
        )}
      </Droppable>
      <Droppable droppableId='row2'>
        {(provided, snapshot) => (
           <AndroidRows
            backgroundColor={androidWalletTemplate.hexBackgroundColor}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ 
              backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
            }}
          >
            {!androidWalletTemplate?.rows[1]?.items?.length && (
              <Placeholder>
                {t<string>("walletCard.placeholder.secondRow")}
              </Placeholder>
            )}
            {androidWalletTemplate?.rows[1]?.items?.map((item, index) => (
              <div 
                key={index}
                style={{
                  position: "relative",
                }}
              >
                {item}
                <GrClose
                  style={{ position: "absolute", top: "-20px", right: "0px", cursor: "pointer" }}
                  onClick={() => handleFieldRemove(index, "row2")}
                />
              </div>
            ))}
          </AndroidRows>
        )}
      </Droppable>
      <Droppable droppableId='row3'>
        {(provided, snapshot) => (
           <AndroidRows
            backgroundColor={androidWalletTemplate.hexBackgroundColor}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ 
              backgroundColor: `${snapshot.isDraggingOver ? "#82A3C1" : "#fff"}`,
            }}
          >
            {!androidWalletTemplate?.rows[2]?.items?.length && (
              <Placeholder>
                {t<string>("walletCard.placeholder.thirdRow")}
              </Placeholder>
            )}
            {androidWalletTemplate?.rows[2]?.items?.map((item, index) => (
              <div 
                key={index}
                style={{
                  position: "relative",
                }}
              >
                {item}
                <GrClose
                  style={{ position: "absolute", top: "-20px", right: "0px", cursor: "pointer" }}
                  onClick={() => handleFieldRemove(index, "row3")}
                />
              </div>
            ))}
          </AndroidRows>
        )}
      </Droppable>
    </AndroidPreviewContainer>
  );
}

export default AndroidWalletPrevirew;