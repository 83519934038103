import axios, { AxiosRequestConfig } from 'axios';
import { environment } from '../environments/environment';
import { getTokenLocalStorage } from '../utils/localStorage/tokenStorage';
import { signOut } from './cognitoService';

const api = axios.create({
	baseURL: `${environment.domainKey}/${environment.rootPath}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

api.interceptors.request.use(function (config: AxiosRequestConfig) {
	let token = getTokenLocalStorage();
	if (token) {
		//@ts-ignorets
		config.headers.Authorization = token;
	}
	return config;
});

api.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error

		if (error.message === 'Network Error' && !error.response?.data) {
			// signOut()
		}

		return Promise.reject(error.response.data);
	}
);

export default api;
