import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';

type Props = {
    data: any;
    isLoading: boolean;
    title?: string;
}

const PieChart = ({ data, isLoading, title }: Props) => {
    const { t } = useTranslation();

    const sum = data?.[0].value;
    const chartData = data.slice(1);

    const colorPallete = [
      "#7392b4",
      "#b0a1a8",
      "#d4caab",
    ];

    const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '-1%',
          left: 'center'
        },
        series: [
          {
            name: title,
            type: 'pie',
            radius: ['45%', '70%'],
            avoidLabelOverlap: false,
            color: colorPallete,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: chartData,
            subtext: sum,
          }
        ],

    };

    if (isLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <ThreeDots color="#647E93" />
            </div>
        );
    }

    return (
        <div style={{
            position: "relative"
        }}>
            <ReactECharts option={ option } />
            <p style={{
                position: "absolute",
                top: "42%",
                left: "45%",
                fontWeight: "bold",
                width: "100px",
            }}>{`${t<string>('dashboardCards.totalTokens')} ${sum}`}</p>
        </div>
    );
};

export default PieChart;
