import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

import { Card, CustomModal, MainLayout, PrimaryButton, Table } from '../../../components';
import { AppRoutesEnum } from '../../../constants';
import { onRepopulateTemplateData } from '../../../hooks/repopulateData/onRepopulateData';
import walletService from '../../../services/walletService';
import AuthContext from '../../../context/AuthContext';
import showToast from '../../../components/Toast';
import { ThreeDots } from 'react-loader-spinner';
import useQueryWalletTiers from '../../../hooks/useQuery/useQueryWalletTiers';

export const TiersList = () => {
	const { user } = useContext(AuthContext);
  const { tenantId } = user;

  const { state } = useLocation() as any;
  const { template } = state;
  const { templateId } = template;

	const [nameFilter, setNameFilter] = useState<string>('');
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	const [selectedTemplate, setSelectedTemplate] = useState<any>();

	const [isDeleting, setIsDeleting] = useState(false);

	const { data: walletTiers, isLoading } = useQueryWalletTiers(templateId, isDeleting);

	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleTierEdit = (template: any) => {
		const { iosWalletTemplate, androidWalletTemplate } =
			onRepopulateTemplateData(template);

		navigate(AppRoutesEnum.WALLET_TIER_UPDATE, {
			state: {
				iosWalletTemplate: iosWalletTemplate,
				androidWalletTemplateData: androidWalletTemplate,
        template,
				fieldMappings: template.fieldMappings,
			},
		});
	};

	const handleCreateTier = () => {
		const { iosWalletTemplate, androidWalletTemplate } =
			onRepopulateTemplateData(template);
		navigate(AppRoutesEnum.WALLET_TIER_CREATION, {
			state: {
				iosWalletTemplate: iosWalletTemplate,
				androidWalletTemplateData: androidWalletTemplate,
        template,
				fieldMappings: template.fieldMappings,
			},
		});
	};

	const dataToShow = walletTiers?.map((template: any) => {
		return {
			name: <p className="pl-5">{template.name}</p>,
			options: (
				<div className="flex justify-center">
					<button
						aria-label={`Edit ${template.name ?? 'Tier'}`}
						className="mr-2 p-1 border-0 cursor-pointer bg-transparent hover:bg-stone-200 active:bg-stone-300 rounded-lg"
						onClick={() => handleTierEdit(template)}
					>
						<PencilSquareIcon className="w-8 h-8 p-1 text-stone-600" />
					</button>
					<button
						aria-label={`Permanantly delete ${template.name ?? 'Tier'}`}
						className="p-1 border-0 cursor-pointer bg-transparent hover:bg-stone-200 active:bg-stone-300 rounded-lg"
						onClick={() => {
							setSelectedTemplate(template)
							setIsDeleteModalOpen(true)
						}}
					>
						<TrashIcon className="w-8 h-8 p-1 text-stone-600" />
					</button>
				</div>
			),
		};
	});

	let walletTemplatesOnPage = nameFilter
		? dataToShow?.filter((template: any) =>
				template?.name?.toLowerCase()?.includes(nameFilter?.toLowerCase())
		  )
		: dataToShow;

	const tableHeaders = [
		t<string>('walletCard.table.name'),
		t<string>('walletCard.table.options'),
	];

	const handleDeleteTier = () => {
    if (isDeleting) return;

    setIsDeleting(true);

    walletService.deleteWalletTiers(tenantId, selectedTemplate.templateId, selectedTemplate.templateTierId)
      .then(() => {
        showToast.success(t<string>('walletCard.deleteSuccess'));
        setIsDeleteModalOpen(false);
      }).catch(() => {
        showToast.error(t<string>('walletCard.deleteError'));
      }).finally(() => {
        setIsDeleting(false);
      });
  }

	return (
		<>
			<MainLayout name={nameFilter} setName={setNameFilter} h1={template?.walletTemplate?.name || template?.androidPassData?.title} handleCreateTier={handleCreateTier}>
				<Card className="!p-5">
					<Table
						tableHeaders={tableHeaders}
						tableData={walletTemplatesOnPage}
						noDataText={t<string>('walletCard.table.noTiersCreated')}
						loading={isLoading}
					/>
				</Card>
				<CustomModal
					modalIsOpen={isDeleteModalOpen}
					setIsOpen={setIsDeleteModalOpen}
				>
					<div className='w-[600px] text-center px-10'>
						<h1 className="text-xl font-normal">
							{t<string>('walletCard.deleteModal.tierTitle')}
						</h1>
						{
							isDeleting ? (
								<div className='flex justify-center items-center h-[82px]'>
									<ThreeDots width={80} height={40} />
								</div>
							) : (
								<>
									<p className='text-lg'>
										{selectedTemplate?.name}
									</p>
									<div className='flex justify-around'>
										<PrimaryButton
											className="mr-2 w-36"
											onClick={() => setIsDeleteModalOpen(false)}
										>
											{t<string>('walletCard.deleteModal.cancel')}
										</PrimaryButton>
										<PrimaryButton
											className="mr-2 w-36"
											onClick={handleDeleteTier}
										>
											{t<string>('walletCard.deleteModal.delete')}
										</PrimaryButton>
									</div>
								</>
							)
						}
					</div>
				</CustomModal>
			</MainLayout>
		</>
	);
};
