import HomeSideBar from '../HomeSideBar';

import { SideBarContainer } from './style';

const icon = require('../../assets/img/icons/logo-side-bar.png');

const LeftSideBar = () => {
	return (
		<SideBarContainer>
			<div>
				<img
					src={icon}
					alt="Avatar"
					width="135px"
					height="25px"
				/>
			</div>
			<HomeSideBar />
		</SideBarContainer>
	);
};

export default LeftSideBar;
