import { useState } from 'react';
import TokenNavigationBar from '../../components/Token/TokenNavigationBar';
import TokenBasics from '../../components/Token/TokenBasics';
import TokenAttributes from '../../components/Token/TokenAttributes';
import TokenFields from '../../components/Token/TokenFields';
import { TokenData } from '../../types';
import { Background, Container } from './style';
import { useTranslation } from 'react-i18next';

const TokenDefinitionPage = () => {
	const [activeTab, setActiveTab] = useState('1');
	const [tokenData, setTokenData] = useState<TokenData | null>(null);

	const { t } = useTranslation();

	return (
		<Background>
			<h1>{t<string>('createToken.title')}</h1>
			<Container>
				<TokenNavigationBar activeTab={activeTab} />
				{activeTab === '1' && (
					<TokenBasics setActiveTab={setActiveTab} setTokenData={setTokenData} tokenData={tokenData} />
				)}
				{activeTab === '2' && (
					<TokenAttributes
						setActiveTab={setActiveTab}
						setTokenData={setTokenData}
						tokenData={tokenData}
					/>
				)}
				{activeTab === '3' && (
					<TokenFields
						setActiveTab={setActiveTab}
						setTokenData={setTokenData}
						tokenData={tokenData}
					/>
				)}
			</Container>
		</Background>
	);
};

export default TokenDefinitionPage;
