import { IAndroidPassData } from '@bambu-meta/interfaces';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorInput, FileDrop, H4, LineBreak } from '../../../../../components';
import { ImagesType, WalletTemplate } from '../../../../../types';

type Props = {
	walletTemplate: WalletTemplate;
	setWalletTemplate: (walletTemplate: WalletTemplate) => void;
	androidWalletTemplate: IAndroidPassData;
	setAndroidWalletTemplate: (androidWalletTemplate: IAndroidPassData) => void;
};

export const Design = ({
	walletTemplate,
	setWalletTemplate,
	androidWalletTemplate,
	setAndroidWalletTemplate,
}: Props) => {
	const { t } = useTranslation();

	const imageKeys = Object.keys(walletTemplate.passType.images);
	const imageObject = imageKeys.map((key) => {
		return { [key]: '' };
	});

	const [images, setImages] = useState<any[]>(imageObject);
	const [isFilePicked, setIsFilePicked] = useState<boolean[]>([]);

	const changeHandler = (
		event: ChangeEvent<HTMLInputElement>,
		index: number,
		image: string
	) => {
		let imageBody: ImagesType = walletTemplate.images;

		if (event.target.files) {
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.readyState === 2) {
					images[index][image] = reader.result;
					setImages([...images]);
					isFilePicked[index] = true;
					setIsFilePicked([...isFilePicked]);

					images.forEach((image) => {
						imageBody = {
							...imageBody,
							[Object.keys(image)[0]]: image[Object.keys(image)[0]],
						};
					});

					walletTemplate.images = imageBody;

					setWalletTemplate({
						...walletTemplate,
					});

					if (images[index].logo) {
						androidWalletTemplate.images = {
							logo: images[index].logo,
						};

						setAndroidWalletTemplate({
							...androidWalletTemplate,
							images: androidWalletTemplate.images,
						});
					}
				}
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	return (
		<>
			<H4 className="mb-2">{t('walletCard.design.title')}</H4>
			<LineBreak />
			<div className="mt-5 h-[290px] w-[580px] pr-4 overflow-y-scroll pb-10">
				{imageKeys?.map((image: string, index) => (
					<FileDrop
						className="mb-4"
						id={`file_${image}`}
						label={t(`walletCard.design.${image}`)}
						onChange={(e) => changeHandler(e, index, image)}
						key={index}
					/>
				))}
				<ColorInput
					className="mb-4"
					id="color_background"
					label={t("walletCard.design.backgroundColor")}
					onChange={(e) => {
						setWalletTemplate({
							...walletTemplate,
							backgroundColor: e.target.value,
						});
						setAndroidWalletTemplate({
							...androidWalletTemplate,
							hexBackgroundColor: e.target.value,
						});
					}}
					value={walletTemplate.backgroundColor}
				/>
				<ColorInput
					className="mb-4"
					id="color_background"
					label={t("walletCard.design.foregroundColor")}
					onChange={(e) => {
						setWalletTemplate({
							...walletTemplate,
							foregroundColor: e.target.value,
						});
					}}
					value={walletTemplate.foregroundColor || "#000000"}
				/>
				<ColorInput
					className="mb-4"
					id="color_background"
					label={t("walletCard.design.labelColor")}
					onChange={(e) => {
						setWalletTemplate({
							...walletTemplate,
							labelColor: e.target.value,
						});
					}}
					value={walletTemplate.labelColor || "#000000"}
				/>
			</div>
		</>
	);
};
