import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { modalStyles, Container } from './style';
import Button from '../../Button';
import { AppRoutesEnum } from '../../../constants';

type Props = {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
};

const RedirectModal = ({ isOpen, setIsOpen }: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleRedirect = () => {
		navigate(AppRoutesEnum.WALLET_LIST);
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
			style={modalStyles}
			contentLabel="Pass Select Modal"
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={false}
		>
			<Container>
				<h1>{t<string>('walletCard.redirect.title')}</h1>
				<p>{t<string>('walletCard.redirect.description')}</p>
				<div style={{ display: 'flex', marginTop: '12px' }}>
					<Button onClick={() => setIsOpen(false)} width="120px" height="40px">
						{t<string>('walletCard.redirect.no')}
					</Button>
					<Button onClick={handleRedirect} width="120px" height="40px">
						{t<string>('walletCard.redirect.yes')}
					</Button>
				</div>
			</Container>
		</Modal>
	);
};

export default RedirectModal;
