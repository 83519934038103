import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import Modal from 'react-modal';
import { FaCheck } from 'react-icons/fa';

import useQueryTokens from '../../../hooks/useQuery/useQueryTokens';
import { Product, TokenData } from '../../../types';

import { modalStyles, TokenSelection, Input, FilterDiv } from './style';
import { ButtonDiv, Button } from '../../FormProgress/style';
import { AppRoutesEnum } from '../../../constants';

type Props = {
	modalIsOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	setToken: (token: TokenData) => void;
	product: Product;
	setProduct: (product: Product) => void;
};

const TokenSelectModal = ({
	modalIsOpen,
	setIsOpen,
	setToken,
	product,
	setProduct,
}: Props) => {
	const [selectedToken, setSelectedToken] = useState<number | null>(null);

	const [nameFilter, setNameFilter] = useState<string>('');
	const [typeFilter, setTypeFilter] = useState<string>('');

	const { data: tokens, isLoading } = useQueryTokens();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleSelection = (token: TokenData, index: number) => {
		setToken(token);
		setSelectedToken(index);
		setProduct({
			...product,
			tokenId: token.tokenId,
		});
	};

	const tokensMap = tokens?.filter((token: TokenData, index: number) => {
		return (
			token.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
			token.ledgerType.toLowerCase().includes(typeFilter.toLowerCase())
		);
	});

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={() => setIsOpen(false)}
			style={modalStyles}
			contentLabel="Token Select Modal"
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={false}
		>
			{isLoading ? (
				<div>
					<ThreeDots color="#647E93" height={19} width={19} />
				</div>
			) : (
				<TokenSelection>
					<h2 data-testid="title">{t<string>('createProduct.modal.title')}</h2>
					<FilterDiv>
						<Input
							placeholder={t<string>('createProduct.modal.placeholder.name')}
							onChange={(e) => setNameFilter(e.target.value)}
						/>
						<Input
							placeholder={t<string>('createProduct.modal.placeholder.type')}
							onChange={(e) => setTypeFilter(e.target.value)}
						/>
					</FilterDiv>
					<ul>
						{tokensMap?.map((token: any, index: number) => (
							<li key={index} data-testid={index.toString()}>
								<p
									className={selectedToken === index ? 'selected' : 'raw'}
									onClick={() => handleSelection(token, index)}
								>
									{selectedToken === index ? (
										<FaCheck className="selected" />
									) : (
										<></>
									)}
									{token.name} ({token.ledgerType})
								</p>
							</li>
						))}
						{!tokensMap?.length && (
							<p data-testid="no-tokens">No tokens found</p>
						)}
					</ul>
					<ButtonDiv className="margin">
						<Button
							onClick={() => navigate(AppRoutesEnum.HOME)}
							className="cancel"
							data-testid="title"
						>
							{t<string>('createProduct.modal.button.cancel')}
						</Button>
						{selectedToken !== null ? (
							<Button
								onClick={() => setIsOpen(false)}
								className="confirm"
								data-testid="submit"
							>
								{t<string>('createProduct.modal.button.select')}
							</Button>
						) : (
							<Button disabled className="disabled">{t<string>('createProduct.modal.button.select')}</Button>
						)}
					</ButtonDiv>
				</TokenSelection>
			)}
		</Modal>
	);
};

Modal.setAppElement(document.getElementById('root')!);

export default TokenSelectModal;
