import { useState } from "react";
import { IWalletPassFieldMappings } from "@bambu-meta/interfaces";
import { useTranslation } from "react-i18next";

import { FieldsContainer, OptionsContainer } from "./style";
import DraggableField from "./Draggable";
import { Droppable } from "react-beautiful-dnd";

import Button from "../../Button";
import CustomFieldModal from "../CustomizeWallet/CustomFieldModal";

type Props = {
    fields: IWalletPassFieldMappings[];
    setFields: (fields: IWalletPassFieldMappings[]) => void;
}

const FieldList = ({ fields, setFields }: Props) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const { t } = useTranslation();

    return (
        <OptionsContainer>
            <h1>{t<string>("walletCard.customization.dndTitle")}</h1>
            <Droppable droppableId="fields">
                {(provided) => (
                    <FieldsContainer
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {fields.map((field, index) => (
                            <DraggableField key={index} fields={fields} index={index} setFields={setFields} />
                        ))}
                        {provided.placeholder}
                    </FieldsContainer>
                )}
            </Droppable>
            <Button onClick={() => setIsOpen(true)} width="300px">
                {t<string>("walletCard.customization.addField")}
            </Button>
            <CustomFieldModal isOpen={modalIsOpen} setIsOpen={setIsOpen} setFields={setFields} fields={fields} />
        </OptionsContainer>
    )
}

export default FieldList;
