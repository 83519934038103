import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AppRoutesEnum } from '../../../constants';
import { Nav } from '../Nav';
import { TopNavigation } from '../TopNavigation';
import { H1, LineBreak } from '../Typography';
import { useNavigate } from 'react-router';
import { PlusIcon } from '@heroicons/react/24/solid';

import { PrimaryButton } from '../Button';

type LayoutProps = {
	name: string;
	setName: (name: string) => void;
	/** Contents of page */
	children: ReactNode;
	/** H1 text for page */
	h1: string;
	handleCreateTier?: () => void;
};

export const MainLayout = ({ name, setName, children, h1, handleCreateTier }: LayoutProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const isWalletList = window.location.pathname === AppRoutesEnum.WALLET_LIST;

	return (
		<>
			<Nav />
			<div className="px-2 md:px-[64px] pt-5 pb-10 max-w-[1200px] mx-auto">
				<TopNavigation
					routes={ isWalletList ? ([
						{
							label: t<string>('walletCard.walletCardList'),
							path: AppRoutesEnum.WALLET_LIST,
						},
					]) : (
						[
							{
								label: t<string>('walletCard.walletCardList'),
								path: AppRoutesEnum.WALLET_LIST,
							},
							{
								label: `${h1} ${t<string>('walletCard.tiers')}`,
								path: window.location.pathname,
							}
						]
					)}
				/>
				<div className="px-4 pt-5">
					<div className="flex justify-between items-center">
						<H1>{h1}</H1>
						{/* <InputGroup
							label=""
							value={name}
							onChange={(e: any) => setName(e.target.value)}
							id="nameFilter"
							placeholder='Search by name'
						/> */}
						<PrimaryButton
							className="flex items-center w-[180px]"
							onClick={() => {
								isWalletList 
									? navigate(AppRoutesEnum.WALLET_TEMPLATE_CREATION)
									: handleCreateTier && handleCreateTier();
							}}
						>
							{isWalletList ? "Add Wallet Card" : "Add Tier"}
							<PlusIcon className="w-5 h-5 ml-3 text-white" />
						</PrimaryButton>
					</div>
					<LineBreak />
					<div className="mt-10" />
					{children}
				</div>
			</div>
		</>
	);
};
