import { Bars3Icon, PencilIcon } from '@heroicons/react/24/solid';
import { Draggable } from 'react-beautiful-dnd';

interface DraggableInputProps {
	id: string;
	index: number;
	label: string;
	placeholder: string;
	value: string;
	className?: string;
	createable?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onClick?: () => void;
	[x: string]: any;
}

export const DraggableInput = ({
	createable,
	className,
	id,
	index,
	label,
	onChange,
	onClick,
	placeholder,
	value,
	...rest
}: DraggableInputProps) => {
	return (
		<div className={className}>
			<Draggable draggableId={id} index={Number(id)}>
				{(provided, snapshot) => (
					<div
						className="w-full"
						ref={provided.innerRef}
						{...provided.draggableProps}
					>
						<label className="block font-sans mb-1">{label}</label>
						<div className="h-10 flex bg-white justify-between items-center border-2 border-dashed border-stone-200 rounded-lg">
							<div className="p-2" {...provided.dragHandleProps}>
								<Bars3Icon className="w-6 h-6 m-1 text-stone-600" />
							</div>
							<p className="w-full pr-2 whitespace-nowrap text-ellipsis overflow-hidden">{value}</p>
							{/* <textarea
								className="border-0 w-full h-fit bg-transparent focus:outline-none overflow-hidden"
								name={value}
								onChange={onChange}
								placeholder={placeholder}
								value={value}
								readOnly
								{...rest}
							/> */}
							<button
								className="p-1 border-0 cursor-pointer bg-transparent hover:bg-stone-200 active:bg-stone-300 rounded-lg"
								onClick={onClick}
							>
								<PencilIcon className="w-6 h-6 p-1 text-stone-600" />
							</button>
						</div>
					</div>
				)}
			</Draggable>
		</div>
	);
};
