import styled from 'styled-components';

export const modalStyles = {
	overlay: {
		backgroundColor: 'rgba(100, 126, 147, 0.5)',
		zIndex: 999999,
    },
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#fff',
		borderRadius: '24px',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		border: 'none',
        color: '#647E93',
	},
};

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  h1 {
    font-size: 1.25rem;
  }

  input {
    width: 100%;
    margin: 12px 0 16px 0;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #647E93;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(101, 131, 161, 0.4);
    }
  }
`;

export const Background = styled.div`
	position: relative;
	width: calc(100vw - 208px);
	background-color: #F5F5F6;
	height: calc(100vh - 170px);
	box-sizing: border-box;
	margin: -16px 0 0 -16px;
	color: #647E93;

	h1 {
		font-size: 2rem;
		padding: 24px;
	}
`;

export const ListingOptions = styled.div`
	display: flex;
	margin-left: 24px;
	cursor: pointer;
	
	h2 {
		font-weight: 400;
		margin-left: 24px;
	}
	
	.active {
		font-weight: 900;
		border-bottom: 4px solid #647E93;
	}
`;

export const Container = styled.div`
	margin: 24px;
	border-radius: 24px;
	background-color: #F4F6F8;
	box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);

	width: calc(100vw - 400px);

	@media (max-width: 768px) {
		margin: 0 0.5rem 0 0;
	}
`;

export const Wrapper = styled.div`
	display: flex;
`;
